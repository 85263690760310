import {TemplateCoreClass} from "./template-core.class";

export class TemplateViewClass {

  public root: HTMLIFrameElement;

  constructor(private  templateCoreClass:TemplateCoreClass) {
    this.root = document.createElement('iframe');
    this.root.style.width="100%";
    this.root.style.height="100%";
    this.root.style.background="white";
    this.root.src='javascript:void(0)';
    templateCoreClass.events.on('change', this.onChange.bind(this));
  }

  async onChange() {
    await this.writeTemplate();
  }

  async writeTemplate() {
    let html = await this.templateCoreClass.html;
    if (!this.root || !this.root.contentWindow)
      return;

    this.root.contentWindow.document.open();
    this.root.contentWindow.document.write( html );
    this.root.contentWindow.document.close();

  }

}
