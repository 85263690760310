import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-tree-selector',
  templateUrl: './modal-selector.component.html',
  styleUrls: ['./modal-selector.component.scss'],
  providers: [],
})
export class ModalSelectorComponent {
  selectedFile: any;
  options: any;

  public activeTab = 'files';

  constructor(public activeModal: NgbActiveModal) {}

  setOptions(options: any) {
    this.options = Object.assign(
      {
        isModal: true,
      },
      options
    );
  }

  selectFile(input) {
    this.selectedFile = input;
  }

  closeAndSelect() {
    if (this.selectedFile) this.activeModal.close(this.selectedFile);
    else this.activeModal.dismiss();
  }

  selectedCount() {
    return this.selectedFile && Array.isArray(this.selectedFile.data)
      ? this.selectedFile.data.length
      : 0;
  }
}
