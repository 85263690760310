import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalL18nService } from '../../service/local-l18n/local-l18n.service';
import { SYSTEM_LANGUAGES } from '../../service/local-l18n/local-l18n.values';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  providers: [],
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() public showFullName = false;

  public LANGUAGES = {
    [SYSTEM_LANGUAGES.ru]: {
      name: 'Русский',
      flag: '&#127479;&#127482;',
    },
    [SYSTEM_LANGUAGES.en]: {
      name: 'English',
      flag: '&#127468;&#127463;',
    },
  };

  constructor(public localL18nService: LocalL18nService) {}

  ngOnInit() {}

  selectLanguage(id: SYSTEM_LANGUAGES) {
    this.localL18nService.setLanguage(id).then(language => {
      this.localL18nService.reloadPage();
    });
  }
}
