import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import {
  CatalogApiService as CatalogApiServiceGen,
  CatalogGetApi,
} from '../generated/Catalog';
import { PartnerApiService } from '../partner-api/partner-api.service';

@Injectable()
export class CatalogApiService {
  constructor(
    private http: HttpClient,
    private catalogApiService: CatalogApiServiceGen,
    private partnerApiService: PartnerApiService
  ) {}

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 256,
    },
  });

  create$(
    name: string,
    parentId?: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    let params: any = {
      partnerId: partnerId,
      name: name,
    };

    if (typeof parentId !== 'undefined') {
      params.parentCatalogId = parentId;
    }

    return this.catalogApiService
      .postPartnerPartneridCatalog({
        partnerId: partnerId,
        body: params,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@catalog-api_service.directory-creation-error:Ошибка создания каталога`
          );
          return throwError(error);
        })
      );
  }

  read$(
    catalogId?: number,
    body: CatalogGetApi = {},
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    const paramCatalogId = !catalogId ? '' : '/' + catalogId;

    return (
      !!catalogId
        ? this.catalogApiService.postPartnerPartneridCatalogId({
            partnerId: partnerId,
            id: catalogId,
            body,
          })
        : this.catalogApiService.postPartnerPartneridCatalogQuery({
            partnerId: partnerId,
            body,
          })
    ).pipe(
      catchError(error => {
        error.setError(
          $localize`:|@@catalog-api_service.error-getting-directory-contents:Ошибка получения содержимого каталога`
        );
        return throwError(error);
      })
    );
  }

  updateName$(
    catalogId: number,
    newName: string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.catalogApiService
      .postPartnerPartneridCatalogIdName({
        partnerId: partnerId,
        id: catalogId,
        body: Object.assign({
          newName: newName,
        }),
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@catalog-api_service.directory-update-error:Ошибка обновления каталога`
          );
          return throwError(error);
        })
      );
  }

  moveCatalog$(
    catalogId: number,
    newParentCatalogId?: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    let params: any = {};

    if (typeof newParentCatalogId !== 'undefined') {
      params.newParentCatalogId = newParentCatalogId;
    }

    return this.catalogApiService
      .postPartnerPartneridCatalogIdParentid({
        partnerId: partnerId,
        id: catalogId,
        body: params,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@catalog-api_service.directory-move-error:Ошибка перемещения каталога`
          );
          return throwError(error);
        })
      );
  }

  delete$(
    catalogId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.catalogApiService
      .deletePartnerPartneridCatalogId({
        partnerId: partnerId,
        id: catalogId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@catalog-api_service.directory-deletion-error:Ошибка удаления каталога`
          );
          return throwError(error);
        })
      );
  }

  checkSchedules$(
    catalogId: any,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.catalogApiService.getPartnerPartneridCatalogIdCheck({
      partnerId,
      id: catalogId,
    });
  }

  getCatalogLevel(
    catalogId?: number,
    body?: CatalogGetApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.read$(catalogId, body).toPromise();
  }
}
