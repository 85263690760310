import { Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StateService } from '@uirouter/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(public $state: StateService, public httpClient: HttpClient) {}

  public version = '';

  ngOnInit(): void {
    /*
    if (this.$state?.current?.name === 'root')
      setTimeout(() => {
        this.$state.go('root.dashboard');
      });
*/

    this.getVersion();
  }

  getVersion() {
    this.httpClient
      .get('/version.json')
      .pipe(
        catchError(error => {
          error && error?.stopPopupError();

          return of({
            version: '0.0',
            build: '0',
          });
        })
      )
      .subscribe((data: any) => {
        this.version = `${data?.version} ( Build ${data?.build} )`;
      });
  }
}
