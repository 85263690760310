import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import {
  ApiService,
  PartnerApi,
  PartnerApiService as PartnerApiServiceGen,
  PartnerCreateApi,
  PartnerSettingsSaveApi,
} from '../generated/Partner';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { LAST_PARTNER_ID } from '../../localStorage/local-storage.values';

@Injectable({
  providedIn: 'root',
})
export class PartnerApiService {
  private _currentParnter: PartnerApi;

  constructor(
    private http: HttpClient,
    private partnerAPIService: PartnerApiServiceGen,
    private defaultService: ApiService,
    private localStorage: LocalStorageService
  ) {}

  query$() {
    return this.partnerAPIService.getPartner();
  }

  create$(body: PartnerCreateApi) {
    return this.partnerAPIService.postPartner({
      body,
    });
  }

  get$(id: number) {
    return this.defaultService.getPartnerPartnerid({
      partnerId: id,
    });
  }

  stat$(id = this.currentPartnerId) {
    return this.defaultService.getPartnerPartneridStat({
      partnerId: id,
    });
  }

  get currentPartner() {
    return this._currentParnter;
  }

  get currentPartnerId() {
    return this.currentPartner ? this.currentPartner.id : -1;
  }

  get loggedPartner() {
    return !!this.currentPartner && this.currentPartnerId !== -1;
  }

  selectPartner$(partnerId: any) {
    return this.get$(partnerId).pipe(
      tap(
        partner => {
          this._currentParnter = partner;
        },
        error => {
          error.stopPopupError();
        }
      )
    );
  }

  getSettings$(partnerId = this.currentPartnerId) {
    return this.defaultService.getPartnerPartneridSettings({
      partnerId,
    });
  }

  setSettings$(
    body: PartnerSettingsSaveApi,
    partnerId = this.currentPartnerId
  ) {
    return this.defaultService.putPartnerPartneridSettings({
      partnerId,
      body,
    });
  }
}
