export enum TEMPLATE_STATUS {
  draft = 'draft',
  active = 'active',
  archive = 'archive',
}

export const TEMPLATE_STATUS_NAMES: { [id in TEMPLATE_STATUS]: string } = {
  [TEMPLATE_STATUS.draft]: $localize`:|@@variables_service.draft2:Черновик`,
  [TEMPLATE_STATUS.active]: $localize`:|@@variables_service.actively2:Активно`,
  [TEMPLATE_STATUS.archive]: $localize`:|@@variables_service.in-the-archive2:В архиве`,
};

export const TEMPLATE_STATUS_COLORS: { [id in TEMPLATE_STATUS]: string } = {
  [TEMPLATE_STATUS.draft]: 'warning',
  [TEMPLATE_STATUS.active]: 'success',
  [TEMPLATE_STATUS.archive]: 'danger',
};
