import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FilesSelectorService } from '../../../../../core/service/files-selector/files-selector.service';
import { FONT_SIZES } from '../../../../../modules/posters/template/template.const';
import { TemplateFieldClass } from 'ds-player/dist/components/templates/template-field.class';
import { FieldTypes } from '../../../../../../../../player/src/components/templates/template.interface';

type GroupField = {
  id: string;
  name: string;
  position: number;
  items: TemplateFieldClass[];
};

const DEFAULT_GROUP_ID = '$$default$$';

@Component({
  selector: 'template-fields',
  templateUrl: './template-fields.component.html',
  styleUrls: ['./template-fields.component.scss'],
  providers: [FilesSelectorService],
})
export class TemplateFieldsComponent implements OnChanges {
  @Input() fields: Array<TemplateFieldClass>;

  public fieldTypes = FieldTypes;
  public FONT_SIZES = FONT_SIZES;

  public fieldByGroups: GroupField[] = [];

  public prevItem;

  constructor(private filesSelectorService: FilesSelectorService) {}

  ngOnInit(): void {}

  get hasFields() {
    return Array.isArray(this.fields) && !!this.fields.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) this.updateByGroups();
  }

  uploadFile(field) {
    this.filesSelectorService
      .get({
        hideTemplates: true,
      })
      .toPromise()
      .then(
        result => {
          field.value = result.data.id;
        },
        () => {}
      );
  }

  updateByGroups() {
    this.fieldByGroups = [];
    if (!Array.isArray(this.fields)) {
      return;
    }

    this.fields.forEach(field => {
      const groupId = field.group || DEFAULT_GROUP_ID;
      let group = this.fieldByGroups.find(i => i.id === groupId);

      if (!group) {
        group = {
          id: groupId,
          name:
            field.group || $localize`:|@@template-fields_page.are-common:Общие`,
          position: 0,
          items: [],
        };

        this.fieldByGroups.push(group);
      }

      group.position += field.position || 0;
      group.items.push(field);
    });

    this.fieldByGroups = this.fieldByGroups.sort(
      (a, b) =>
        (this.isDefaultGroup(a.id) ? 999999 : a.position) -
        (this.isDefaultGroup(b.id) ? 999999 : b.position)
    );

    this.fieldByGroups.forEach(group => {
      group.items = group.items
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => {
          if (a.name !== b.name) return 0;

          return (
            this.fieldTypePosition(a.type) - this.fieldTypePosition(b.type)
          );
        });
    });
  }

  isDefaultGroup(groupKey) {
    return groupKey === DEFAULT_GROUP_ID;
  }

  fieldTypePosition(type: FieldTypes) {
    switch (type) {
      case FieldTypes.blockFont:
        return 100;
      case FieldTypes.string:
        return 200;
      case FieldTypes.url:
        return 300;
      case FieldTypes.font_size:
        return 400;
      case FieldTypes.color:
        return 500;

      default:
        return 600;
    }
  }

  activeGroup() {
    return this.fieldByGroups.length === 1 ? this.fieldByGroups[0].id : '';
  }

  hasItems(items: any[] = []) {
    return !!items.filter(i => i.type !== 'data').length;
  }

  getPrevName(items: any[] = [], item: any) {
    const index = items.indexOf(item);

    return index > 0 ? items[index - 1].name : '';
  }

  filterData(item) {
    return item && item.type !== 'data';
  }
}
