import { Injectable } from '@angular/core';

import {
  LocationsRequest,
  LocationApi,
  LocationApiService as LocationApiServiceGen,
  LocationCreateApi,
  LocationQueryApi,
} from '../generated/Location';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { isEmptyCheck } from '../tools.functions';
import { ApiRequestHandlerClass } from '../../../class/api-request-handler.class';

@Injectable()
export class LocationApiService extends ApiRequestHandlerClass {
  constructor(
    private locationAPIService: LocationApiServiceGen,
    private partnerApiService: PartnerApiService,
    localStorageService: LocalStorageService
  ) {
    super();

    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        filters: [
          {
            field: 'states',
            currentValue: undefined,
          }
        ],
      },
      localStorageService
    );

    this.locationsByGroupParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;
  public locationsByGroupParams: ParamsHandler;

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 256,
    },
    description: {
      required: true,
      maxlength: 256,
    },
    address: {
      required: false,
      maxlength: 256,
    },
    code: {
      required: true,
      maxlength: 100,
    },
  });

  query$(
    body?: LocationsRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (body && isEmptyCheck(body)) {
      return throwError({});
    }

    return this.locationAPIService
      .postPartnerPartneridLocationQuery({
        partnerId: partnerId,
        body: body || { paging: { offset: 0, limit: 99999 } },
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-getting-list-of-trading-objects:Ошибка получения списка торговых обьектов`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.locationAPIService
      .getPartnerPartneridLocationId({
        partnerId: partnerId,
        id: id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-getting-list-of-trading-objects2:Ошибка получения списка торговых обьектов`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: LocationCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.locationAPIService
      .postPartnerPartneridLocation({
        partnerId: partnerId,
        body: body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-creating-trade-objects:Ошибка создания торговых обьектов`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: LocationApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof body.locationGroupId !== 'number') {
      delete body.locationGroupId;
    }

    if (typeof body.id === 'undefined') return this.create$(body, partnerId);

    return this.locationAPIService
      .putPartnerPartneridLocationId({
        partnerId: partnerId,
        id: body.id,
        body: body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-saving-trade-objects:Ошибка сохранения торговых обьектов`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.locationAPIService
      .deletePartnerPartneridLocationId({
        partnerId: partnerId,
        id: id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-deleting-trade-objects:Ошибка удаления торговых обьектов`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '')
      return of({
        locationGroupId: '',
        address: '',
      });

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }

  getLocationsByGroup$(
    locationGroupId: number,
    body: LocationsRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ): Observable<LocationQueryApi[]> {
    return this.locationAPIService
      .postPartnerPartneridLocationLocationgroupLocationgroupid({
        partnerId: partnerId,
        locationGroupId: locationGroupId,
        body: body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-api_service.error-receiving-trade-objects:Ошибка получения торговых обьектов`
          );
          return throwError(error);
        })
      );
  }
}
