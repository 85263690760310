import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  showWarning(
    text: string,
    header: string = $localize`:|@@toast_components.attention:Внимание`,
    options: any = {}
  ) {
    this.show(
      text,
      Object.assign(
        {
          header: header,
          classname: 'bg-warning text-light',
        },
        options
      )
    );
  }

  showDanger(
    text: string,
    header: string = $localize`:|@@toast_components.error:Ошибка`,
    options: any = {}
  ) {
    this.show(
      text,
      Object.assign(
        {
          header: header,
          classname: 'bg-danger text-light',
        },
        options
      )
    );
  }

  showInfo(
    text: string,
    header: string = $localize`:|@@toast_components.attention2:Внимание`,
    options: any = {}
  ) {
    this.show(
      text,
      Object.assign(
        {
          header: header,
          classname: 'bg-info text-light',
        },
        options
      )
    );
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
