import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { RootModule, UIRouterModule } from '@uirouter/angular';
import { TransitionService } from '@uirouter/core';
import { UserApiService } from './core/service/api/user-api/user-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_ROUTES } from '../app-routes.variables';

let uService: UserApiService;

const routes: RootModule = {
  states: [
    {
      name: APP_ROUTES['root'].name,
      url: APP_ROUTES['root'].url,
      component: LayoutComponent,
      redirectTo: 'root.dashboard',
      params: {
        rootPartnerId: {
          value: '',
        },
      },
    },
  ],
  deferIntercept: true,
  useHash: true,
  otherwise: (inj, url, router) => {
    if (router?.stateService?.current?.url === APP_ROUTES['registration'].url) {
      return APP_ROUTES['registration'].url;
    }

    if (!uService.isLogged) {
      return APP_ROUTES['login'].url;
    }

    return APP_ROUTES['root'].url + APP_ROUTES['root.dashboard'].url;
  },
};

@NgModule({
  imports: [UIRouterModule.forRoot(routes)],
  exports: [UIRouterModule],
})
export class AppRoutingModule {
  constructor(
    private transitionService: TransitionService,
    private userApiService: UserApiService,
    private modalService: NgbModal
  ) {
    uService = this.userApiService;

    transitionService.onStart({}, transition => {
      this.modalService.dismissAll();
    });
  }
}
