import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError, concatAll, map, switchMap } from 'rxjs/operators';
import { concat, Observable, of, Subscriber, throwError } from 'rxjs';
import {
  LabelFinderService,
  AssignLabelToPlayerService,
  ApiService,
  LabelRequestApi,
  GetLabelService,
  CreateLabelService,
  LabelCreateApi,
  LabelUpdateApi,
} from '../generated/Label';
import { PlayerCreateApi, PlayerRequestApi } from '../generated/Player';
import { isEmptyCheck } from '../tools.functions';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { ApiRequestHandlerClass } from '../../../class/api-request-handler.class';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { ValidateFieldClass } from '../../../class/validate-field.class';

@Injectable({
  providedIn: 'root',
})
export class LabelApiService extends ApiRequestHandlerClass {
  constructor(
    private partnerApiService: PartnerApiService,
    private labelFinderService: LabelFinderService,
    private labelApiService: ApiService,
    private labelGetService: GetLabelService,
    private createLabelService: CreateLabelService,
    private assignLabelToPlayerService: AssignLabelToPlayerService,
    localStorageService: LocalStorageService
  ) {
    super();

    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );

    this.queryPlayersParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;

  public queryPlayersParams: ParamsHandler;

  public field = new ValidateFieldClass({
    labelName: {
      required: true,
      maxlength: 25,
    },
  });

  find$(query: string, partnerId = this.partnerApiService.currentPartnerId) {
    return this.labelFinderService.postPartnerPartneridLabel({
      partnerId,
      body: JSON.stringify(query),
    });
  }

  assignToPlayer$(
    tags: string[],
    playerId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.assignLabelToPlayerService
      .postPartnerPartneridLabelPlayerPlayerid({
        partnerId,
        playerId,
        body: tags,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.error-adding-labels:Ошибка добавления меток`
          );
          return throwError(error);
        })
      );
  }

  query$(
    body: LabelRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.labelApiService
      .postPartnerPartneridLabelQuery({
        partnerId,
        body,
      })
      .pipe(
        map(result => {
          result.items = result.items || [];

          result.items.forEach(i => {
            i.createdAt = i.createdAt.replace('T', ' ');
          });

          return result;
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.error-getting-list-of-labels:Ошибка получения списка меток`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.labelGetService
      .getPartnerPartneridLabelIdGet({
        id,
        partnerId,
      })
      .pipe(
        map(result => {
          result.createdAt = result.createdAt.replace('T', ' ');
          return result;
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.tag-not-found:Метка не найдена`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: LabelCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    body.playerIds = body.playerIds || [];

    return this.createLabelService
      .postPartnerPartneridLabelCreate({
        partnerId,
        body,
      })
      .pipe(
        switchMap(result => {
          return this.get$(result);
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.label-creation-error:Ошибка создания метки`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: LabelCreateApi | LabelUpdateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ): Observable<any> {
    if (
      typeof (<any>body)?.labelId === 'undefined' ||
      (<any>body)?.labelId === ''
    )
      return this.create$(<LabelCreateApi>body, partnerId);

    const id = this.cleanForUpdate(body);

    return this.labelApiService
      .postPartnerPartneridLabelIdUpdate({
        partnerId,
        id: <number>id,
        body: <LabelUpdateApi>body,
      })
      .pipe(
        switchMap(result => {
          return this.get$(id);
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.label-update-error:Ошибка обновления метки`
          );
          return throwError(error);
        })
      );
  }

  private cleanForUpdate(body: any) {
    body.playerIdsForAdd = body.playerIdsForAdd || [];
    body.playerIdsForRemove = body.playerIdsForRemove || [];

    const id = parseInt((<any>body).labelId);

    Object.keys(body)
      .filter(
        key =>
          ['labelName', 'playerIdsForRemove', 'playerIdsForAdd'].indexOf(key) <
          0
      )
      .forEach(key => delete body[key]);

    return id;
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.labelApiService
      .getPartnerPartneridLabelIdDelete({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.label-deletion-error:Ошибка удаления метки`
          );
          return throwError(error);
        })
      );
  }

  deleteIds$(
    ids: number[],
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    let subscriber: Subscriber<any>;
    let result = new Observable(sub => (subscriber = sub));

    concat(...ids.map(id => this.delete$(id, partnerId))).subscribe({
      complete: () => {
        subscriber.next();
        subscriber.complete();
      },
      error: () => {
        subscriber.error();
        subscriber.complete();
      },
    });

    return result;
  }

  queryPlayers$(
    body: PlayerRequestApi,
    id: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.labelApiService
      .postPartnerPartneridLabelIdPlayersQuery({
        id,
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@label-api_service.error-getting-list-of-players:Ошибка получения списка плееров`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '')
      return of({
        playerIds: [],
      });

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }
}
