import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import {
  DeviceTypeApiService as DeviceTypeApiServiceGen,
  DeviceTypeCreateApi,
  DeviceTypeRequestApi,
} from '../generated/DeviceType';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { isEmptyCheck } from '../tools.functions';
import { ApiRequestHandlerClass } from '../../../class/api-request-handler.class';

export * from './device-type-api.variables';

@Injectable()
export class DeviceTypeApiService extends ApiRequestHandlerClass {
  constructor(
    private deviceTypeApiService: DeviceTypeApiServiceGen,
    private partnerApiService: PartnerApiService,
    localStorageService: LocalStorageService
  ) {
    super();
    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;

  query$(
    body: DeviceTypeRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.deviceTypeApiService
      .postPartnerPartneridDevicetypeQuery({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@device-type-api_service.error-getting-list-of-device-types:Ошибка получения списка типов устройств`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: DeviceTypeCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.deviceTypeApiService
      .postPartnerPartneridDevicetype({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@device-type-api_service.error-creating-device-type:Ошибка создания типа устройства`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: DeviceTypeCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.deviceTypeApiService
      .putPartnerPartneridDevicetypeId({
        id: (<any>body).id,
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@device-type-api_service.error-creating-device-type2:Ошибка создания типа устройства`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.deviceTypeApiService
      .getPartnerPartneridDevicetypeId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@device-type-api_service.error-getting-device-type:Ошибка получения типа устройства`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.deviceTypeApiService
      .deletePartnerPartneridDevicetypeId({
        id,
        partnerId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@device-type-api_service.device-type-deletion-error:Ошибка удаления типа устройства`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '')
      return of({
        modes: [{}],
      });

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }

  $createOrUpdate(
    body: DeviceTypeCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof (<any>body)?.id === 'undefined')
      return this.create$(body, partnerId);

    return this.update$(body, partnerId);
  }
}
