<header class="app-header fixed-top">
  <div class="app-header-inner">
    <div class="container-fluid py-2">
      <div class="app-header-content">
        <div class="row">
          <div class="col-auto d-xl-none">
            <a
              #sidepanelToggler=""
              class="sidepanel-toggler d-inline-block"
              href="javascript:void(0)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                role="img">
                <title>Menu</title>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  d="M4 7h22M4 15h22M4 23h22"></path>
              </svg>
            </a>
          </div>
          <div class="col">
            <ng-content select="[header], header"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #appSidepanel="" class="app-sidepanel">
    <div #sidepanelDrop="" class="sidepanel-drop"></div>
    <div class="sidepanel-inner d-flex flex-column">
      <a href="#" #sidepanelClose="" class="sidepanel-close d-xl-none"
        ><i class="fa fa-times"></i> </a
      >
      <div class="app-branding">
        <ng-content select="[logo], logo"></ng-content>
      </div>

      <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
        <ng-content select="[nav], nav"></ng-content>
      </nav>

      <div class="app-sidepanel-footer">
        <nav class="app-nav app-nav-footer">
          <ng-content select="[nav-footer], nav-footer"></ng-content>
        </nav>
      </div>
    </div>
  </div>
</header>

<div class="app-wrapper">
  <div class="app-content">
    <ng-content></ng-content>
  </div>

  <footer class="app-footer">
    <ng-content select="[footer], footer"></ng-content>
  </footer>
</div>
