<div>
  <span *ngIf="!path"></span>

  <span *ngIf="path">
    <ng-container *ngFor="let item of path; last as last">
      <span class="item fw-bold" [innerText]="item"></span>
      <span class="delimiter" *ngIf="!last">
        <i class="fas fa-arrow-right fa-sm"></i>
      </span>
    </ng-container>
  </span>
</div>
