import { EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';

export class LoadableItemClass<T> {
  private _storage: T;
  private _isLoading = false;
  private _isEnded = false;

  public onChange = new EventEmitter();
  public onLoadStart = new EventEmitter();
  public onLoadEnd = new EventEmitter();

  get item() {
    return this._storage;
  }

  // для единственного значения
  set item(value) {
    this._storage = value;
    this.onChange.emit(this._storage);
  }

  // для единственного массивов
  get items() {
    return this._storage;
  }

  set items(value) {
    this._storage = value;
    this.isEnded = false;
    this.onChange.emit(this._storage);
  }

  get loadingOperator() {
    this._isLoading = true;
    this.onLoadStart.emit();
    return finalize<T>(() => {
      this._isLoading = false;
      this.onLoadEnd.emit();
    });
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get isEnded() {
    return this._isEnded;
  }

  set isEnded(value) {
    this._isEnded = value;
  }

  constructor() {}

  public addItems(addArray: T) {
    if (typeof this.items === 'undefined') this.items = <any>[];

    this.items = (<any>this.items).concat(addArray);
    this.isEnded = false;
  }
}
