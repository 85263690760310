import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CatalogApiService } from '../../service/api/catalog-api/catalog-api.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { HttpEventType } from '@angular/common/http';
import { concatAll } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUtilsClass } from '../../class/file-utils.class';
import { FileApiService } from '../../service/api/file-api/file-api.service';
import { firstValueFrom } from 'rxjs';
import GLightbox from 'glightbox';

enum LOAD_STATES {
  not_load,
  loading,
  loaded,
  error,
}

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  providers: [CatalogApiService, FileApiService],
})
export class FilePreviewComponent implements OnChanges, OnInit {
  @Input() public file;

  @Input() public id;
  @Input() public name;

  @Input() public disablePreview = false;

  public fileType = '';
  public fileItem;

  public isShowed = false;

  lightbox: any;

  @ViewChild('imgElement') imgElement: ElementRef<HTMLImageElement>;
  public LOAD_STATES = LOAD_STATES;
  currentState: LOAD_STATES = LOAD_STATES.loading;

  private paramsSalt;

  get filePreviewUrl() {
    if (!this.id) return '';

    return this.fileApiService.getPreview(this.id) + (this.paramsSalt || '');
  }

  get fileUrl() {
    return this.fileApiService.getUrl(this.id);
  }

  get isError() {
    return this.currentState === LOAD_STATES.error;
  }

  constructor(
    private catalogApiService: CatalogApiService,
    private fileApiService: FileApiService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.parseFile();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['file']) {
      this.parseFile();
    }
  }

  private parseFile() {
    if (typeof this.file === 'object') {
      this.name = this.name || this.file?.name;
      this.id = this.id || this.file?.id;

      this.checkFile(this.file?.mimeType);
    }
  }

  public setState(state: LOAD_STATES) {
    this.currentState = state;
  }

  public async open() {
    if (typeof this.id === 'undefined' || this.isError) return;

    if (!this.fileType) {
      try {
        let fileInfo: any = await firstValueFrom(
          this.fileApiService.getInfo$(this.id)
        );
        this.checkFile(fileInfo.mimeType);
      } catch (e) {
        return;
      }
    }

    const lightbox = GLightbox({
      elements: [
        {
          href: this.fileUrl,
          type: this.fileType,
          //      'title': this.name,
        },
      ],
      autoplayVideos: true,
    });

    lightbox.open();
  }

  checkFile(mimeType: string) {
    this.fileType = '';

    switch (true) {
      case FileUtilsClass.isImage(mimeType):
        this.fileType = 'image';
        return;

      case FileUtilsClass.isVideo(mimeType):
        this.fileType = 'video';
        return;
    }
  }

  previewFile() {
    if (this.disablePreview || this.isError) return;

    this.open();
  }

  reload() {
    this.paramsSalt = '?_t+' + new Date().getTime();
    this.setState(LOAD_STATES.loading);
  }
}
