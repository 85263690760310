import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  TagFinderService,
  AssignTagToFileService,
  AssignTagToTemplateService,
  GetTagByFileIdService,
  GetTagByTemplateIdService,
} from '../generated/Tag';

@Injectable({
  providedIn: 'root',
})
export class TagApiService {
  constructor(
    private partnerApiService: PartnerApiService,
    private tagFinderService: TagFinderService,
    private assignTagToFileService: AssignTagToFileService,
    private assignTagToTemplateService: AssignTagToTemplateService,
    private getTagByFileIdService: GetTagByFileIdService,
    private getTagByTemplateIdService: GetTagByTemplateIdService
  ) {}

  find$(query: string, partnerId = this.partnerApiService.currentPartnerId) {
    return this.tagFinderService.postPartnerPartneridTag({
      partnerId,
      body: JSON.stringify(query),
    });
  }

  assignToFile$(
    tags: string[],
    fileId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.assignTagToFileService
      .postPartnerPartneridTagFileFileid({
        partnerId,
        fileId,
        body: tags,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@tag-api_service.error-adding-tags:Ошибка добавления тегов`
          );
          return throwError(error);
        })
      );
  }

  assignToTemplate$(
    tags: string[],
    templateId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.assignTagToTemplateService
      .postPartnerPartneridTagTemplateTemplateid({
        partnerId,
        templateId,
        body: tags,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@tag-api_service.error-adding-tags2:Ошибка добавления тегов`
          );
          return throwError(error);
        })
      );
  }

  getFromFile$(
    fileId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.getTagByFileIdService
      .getPartnerPartneridTagFileFileid({
        partnerId,
        fileId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@tag-api_service.error-adding-tags3:Ошибка добавления тегов`
          );
          return throwError(error);
        })
      );
  }

  getFromTemplate$(
    templateId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.getTagByTemplateIdService
      .getPartnerPartneridTagTemplateTemplateid({
        partnerId,
        templateId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@tag-api_service.error-adding-tags4:Ошибка добавления тегов`
          );
          return throwError(error);
        })
      );
  }
}
