import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-panel',
  templateUrl: './app-top-panel.component.html',
  styleUrls: ['./app-top-panel.component.scss'],
})
export class AppTopPanelComponent implements OnInit {
  constructor() {}

  @Input() isIntegrated = false;

  ngOnInit(): void {}
}
