import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserApiService, UserRoleEnum } from '../api/user-api/user-api.service';
import { default_tree, role_tree } from './permissions.tree';
import { PERMISSION_TYPES } from './permissions.enum';
import { mergeDeep } from 'ds-player/dist/components/utils/ds-player.utils';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  get currentRole(): UserRoleEnum | undefined {
    return <UserRoleEnum | undefined>this.userApiService.currentUser?.role;
  }

  private urlPathList = {};

  constructor(private userApiService: UserApiService) {
    this.initDefaultTypesInRoles();
    this.createUrlPathList();
  }

  private initDefaultTypesInRoles() {
    Object.keys(role_tree).map(index => {
      role_tree[index] = mergeDeep({}, default_tree, role_tree[index]);
    });
  }

  private createUrlPathList() {
    let searchDeep = (level = {}, keyPath = '') => {
      Object.keys(level).forEach(key => {
        if (key === PERMISSION_TYPES.URL) {
          this.urlPathList[level[key]] = this.urlPathList[level[key]] || [];
          this.urlPathList[level[key]].push(keyPath);
          return;
        }

        if (typeof level[key] === 'object') {
          return searchDeep(level[key], keyPath + (!!keyPath ? '.' : '') + key);
        }
      });
    };

    searchDeep(default_tree);
  }

  public byPath(path: string) {
    if (this.userApiService.currentUser) {
      let result = this.searchInTree(
        path,
        role_tree[this.userApiService.currentUser.role]
      );
      if (result) return result;
    }

    return this.searchInTree(path, default_tree);
  }

  public enabledByPath(path: string) {
    return this.byType(path, PERMISSION_TYPES.ENABLED);
  }

  public byType(path: string, type: PERMISSION_TYPES | boolean) {
    let pathObj = this.byPath(path);

    if (typeof type === 'boolean') return type;

    if (!pathObj || typeof pathObj[type] === 'undefined') {
      // TODO на будущее. Дефолные значения
      switch (type) {
        case PERMISSION_TYPES.ENABLED:
          return false;

        default:
          return false;
      }
    }

    return pathObj[type];
  }

  private searchInTree(path: string, tree: any) {
    try {
      return path.split('.').reduce((res, prop) => res[prop], tree);
    } catch (e) {
      return undefined;
    }
  }

  public byUrlPath(path: any): boolean {
    if (!path) return true;

    let findedKey = Object.keys(this.urlPathList).find(key => {
      if (key.endsWith('.**')) {
        key = key.replace('.**', '');
      }

      return path.startsWith(key);
    });

    if (!findedKey) return true;

    return this.enabledByPath(this.urlPathList[findedKey][0]);
  }
}
