<app-layout>
  <div logo="" class="logo-placeholder">
    <app-logo [miniLogo]="true"></app-logo>
  </div>
  <div header="">
    <div class="row">
      <div class="col">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="col-auto">
        <app-utilites></app-utilites>
      </div>
    </div>
  </div>
  <div nav="">
    <app-nav></app-nav>
  </div>
  <!--  <div nav-footer>
    <app-nav-footer></app-nav-footer>
  </div>-->
  <div footer="" class="bg-light d-flex p-2 justify-content-between">
    <div class="">© 2023 Copyright.</div>
    <div>
      <div class="d-inline-block me-2" [innerText]="version"></div>

      <div class="d-inline-block">
        <language-selector [showFullName]="false"></language-selector>
      </div>
    </div>
  </div>

  <ui-view></ui-view>
</app-layout>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
