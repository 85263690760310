import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CatalogApiService } from '../../../core/service/api/catalog-api/catalog-api.service';
import { PartnerApiService } from '../../../core/service/api/partner-api/partner-api.service';
import { TemplateApi } from '../../../core/service/api/generated/Template';
import { FileTreeComponent } from './components/file-tree/file-tree.component';
import { FileTableViewComponent } from './components/file-table-view/file-table-view.component';
import {CatalogGetApi} from "../../../core/service/api/generated/Catalog";

@Component({
  selector: 'app-files-page',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.scss'],
  providers: [CatalogApiService],
})
export class FilesPageComponent implements OnInit {
  @Input() isForSelect = false;

  public buttonEvents = new EventEmitter();

  public partnerId: number = 1;
  public treeModel: any;

  public filterParams: any;

  @Input() enablePreview = false;
  @Input() options: {
    selectedTemplateId?: number;
    onlyActiveTemplates?: boolean;
    isModal?: boolean;
    multiselect?: boolean;
    filterByType?: string | ((item: any) => boolean);
  } = {};

  @Output() onChange = new EventEmitter();

  public selectedTemplate: TemplateApi | boolean = false;

  @ViewChild('fileTree') fileTree: FileTreeComponent;
  @ViewChild('fileTableView') fileTableView: FileTableViewComponent;

  constructor() {}

  ngOnInit(): void {}

  async onCreate() {
    await this.fileTree.createNewCatalog();
  }

  async onDelete() {
    await this.fileTree.deleteCatalog();
  }

  onImport() {
    this.fileTableView.openFileDialog();
  }

  async renameClick() {
    this.fileTree.editNode();
  }
}
