import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { UIRouterModule } from '@uirouter/angular';
import { CoreComponentsModule } from '../../../core/components/core.components.module';
import { FormsModule } from '@angular/forms';
import {
  UserApiService,
  UserRoleEnum,
} from '../../../core/service/api/user-api/user-api.service';
import { StateService } from '@uirouter/core';
import { APP_ROUTES } from '../../../../app-routes.variables';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: APP_ROUTES['login'].name,
          url: APP_ROUTES['login'].url,
          component: LoginPageComponent,
          params: {
            r: {
              value: null,
              squash: true,
            },
          },
          /*  resolve: [
            {
              token: 'testPreload',
              policy: {
                when: 'EAGER'
              },
              deps: [UserApiService, StateService],
              resolveFn: (
                userApiService: UserApiService,
                $state: StateService
              ) =>
                new Promise((resolve: any, reject: any) => {
debugger
                  userApiService.getData$().subscribe({
                    next: user => {
                      setTimeout(() => {
                        if (user.role === UserRoleEnum.superuser)
                          $state.go('partner');
                        else
                          $state.go('root');
                      });

                      reject();
                    },
                    error: err => {
                      err.stopPopupError();
                      resolve();
                    },
                  });
                }),
            },
          ],*/
        },
      ],
    }),
    CoreComponentsModule,
    FormsModule,
  ],
})
export class LoginPageModule {}
