<div class="modal-header bg-light ng-scope">
  <h5
    class="modal-title ng-binding"
    i18n="@@players-popup_service.player-selection">
    Выбор плеера
  </h5>
</div>
<div class="modal-body p-0 d-flex">
  <div class="col overflow-col">
    <div class="inner-block" id="playerPopupItemsContainer">
      <div class="col p-0">
        <app-top-panel>
          <div
            class="btn-group dropdown float-start me-2"
            ngbDropdown=""
            [autoClose]="'outside'"
            [placement]="'bottom-left'">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-default btn-addon btn-sm me-0"
                (click)="addPlatforms()">
                <i
                  class="fas fa-filter"
                  [class.text-danger]="
                    playerApiService.queryParams.getFilterValue('platforms')
                  "></i>
                <span i18n="@@players-popup_service.top-panel.button.platforms"
                  >Платформы</span
                >
              </button>

              <button
                type="button"
                class="btn btn-default btn-sm"
                ngbDropdownToggle=""
                *ngIf="
                  playerApiService.queryParams.getFilterValue('platforms')
                ">
                <span class="caret"></span>
              </button>

              <ul
                class="dropdown-menu"
                *ngIf="playerApiService.queryParams.getFilterValue('platforms')"
                ngbDropdownMenu="">
                <li
                  class="no-padder"
                  *ngFor="
                    let id of playerApiService.queryParams.getFilterValue(
                      'platforms'
                    ) || []
                  ">
                  <div class="dropdown-item d-flex justify-content-between">
                    <div>
                      <span [innerText]="platformNames.get(id)"></span>
                    </div>
                    <div class="ms-2">
                      <span
                        class="bg-danger text-white rounded-1 ps-2 pe-2 float-end"
                        (click)="removeFromFilter('platforms', id)">
                        <i class="fas fa-times fa-sm"></i>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <button
                type="button"
                class="btn btn-danger btn-sm"
                *ngIf="
                  !!playerApiService.queryParams.getFilterValue('platforms')
                "
                (click)="
                  playerApiService.queryParams.setFilterValue(
                    'platforms',
                    undefined
                  );
                  resetPages()
                ">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div
            class="btn-group dropdown float-start me-2"
            ngbDropdown=""
            [autoClose]="'outside'"
            [placement]="'bottom-right'"
            container="body">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-default btn-addon btn-sm me-0"
                (click)="addLocationsFilter()">
                <i
                  class="fas fa-filter"
                  [class.text-danger]="
                    playerApiService.queryParams.getFilterValue('locationIds')
                  "></i>
                <span
                  i18n="
                    @@players-popup_service.top-panel.button.shopping-facility"
                  >Торговый объект</span
                >
              </button>

              <button
                type="button"
                class="btn btn-default btn-sm"
                ngbDropdownToggle=""
                *ngIf="
                  playerApiService.queryParams.getFilterValue('locationIds')
                ">
                <span class="caret"></span>
              </button>

              <ul
                class="dropdown-menu"
                *ngIf="
                  playerApiService.queryParams.getFilterValue('locationIds')
                "
                ngbDropdownMenu="">
                <li
                  class="no-padder"
                  *ngFor="
                    let id of playerApiService.queryParams.getFilterValue(
                      'locationIds'
                    ) || []
                  ">
                  <div class="dropdown-item d-flex justify-content-between">
                    <div>
                      <span [innerText]="locationNames.get(id)"></span>
                    </div>
                    <div>
                      <span
                        class="bg-danger text-white rounded-1 ps-2 pe-2 float-end"
                        (click)="removeFromFilter('locationIds', id)">
                        <i class="fas fa-times fa-sm"></i>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <button
                type="button"
                class="btn btn-danger btn-sm"
                *ngIf="
                  !!playerApiService.queryParams.getFilterValue('locationIds')
                "
                (click)="
                  playerApiService.queryParams.setFilterValue(
                    'locationIds',
                    undefined
                  );
                  resetPages()
                ">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <checkbox-filter
            class="float-start me-2"
            name="Статус"
            [canSelectAll]="false"
            [inputValues]="PLAYER_STATUS_NAMES"
            [selectedValues]="
              playerApiService.queryParams.getFilterValue('states')
            "
            (selectedValuesChange)="
              playerApiService.queryParams.setFilterValue('states', $event);
              resetPages()
            "
            i18n-name="
              @@players-popup_service.top-panel.status"></checkbox-filter>

          <div class="btn-group btn-group-sm">
            <button
              class="btn btn-default btn-addon btn collapse-xs"
              (click)="addLabels()">
              <i
                class="fas fa-filter"
                [class.text-danger]="
                  playerApiService.queryParams.getFilterValue('labels')?.length
                "></i>
              <ng-container
                i18n="@@players-popup_service.top-panel.button.tags">
                Метки
              </ng-container>
            </button>

            <button
              type="button"
              class="btn btn-danger btn-sm"
              *ngIf="
                !!playerApiService.queryParams.getFilterValue('labels')?.length
              "
              (click)="
                playerApiService.queryParams.setFilterValue('labels', []);
                resetPages()
              ">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </app-top-panel>
      </div>

      <div class="col-sm-12 ps-2 pe-2">
        <app-search-input
          [model]="playerApiService.queryParams.getSearchValue()"
          (modelChange)="onSearchChange($event)"
          placeholder="ID/Наименование плеера/Платформы/ТО"
          i18n-placeholder="
            @@players-popup_service.id-name-of-the-platform-player-then"></app-search-input>
      </div>

      <div
        infiniteScroll=""
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'#playerPopupItemsContainer'"
        [fromRoot]="true"
        (scrolled)="nextPage()">
        <responsive-table [disabled]="true" >
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="width: 1px" class="ps-3 pe-0 text-center">
                <div class="form-check" *ngIf="!checkAllLoading">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    [checked]="isAllChecked()"
                    (change)="checkAll($event)" />
                </div>

                <span *ngIf="checkAllLoading">
                  <i class="fas fa-spin fa-spinner text-warning"></i>
                </span>
              </th>
              <th
                class="text-center"
                scope="col"
                i18n="@@players-popup_service.table.table-header.status">
                Статус
              </th>
              <th
                scope="col"
                i18n="@@players-popup_service.table.table-header.name">
                Наименование
              </th>
              <th
                scope="col"
                i18n="@@players-popup_service.table.table-header.platform">
                Платформа
              </th>
              <th
                class="text-center"
                scope="col"
                i18n="
                  @@players-popup_service.table.table-header.shopping-facility">
                Торговый объект
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of playerItems.items">
              <td class="ps-3 pe-0 text-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="selected.has(item.id)"
                    (change)="check(item.id)"
                    (click)="$event.stopPropagation()" />
                </div>
              </td>
              <td
                class="text-center"
                [innerText]="PLAYER_STATUS_NAMES[item.state]"></td>
              <td>
                <text-highlight
                  [search]="playerApiService.queryParams.getSearchValue()"
                  [text]="item.name"></text-highlight>

                <div
                  class="d-block"
                  *ngIf="item.labels && !!item.labels.length">
                  <app-tag-input
                    [model]="item.labels"
                    [onlyView]="true"></app-tag-input>
                </div>

                <div class="clearfix small text-muted">
                  ID:
                  <text-highlight
                    [search]="playerApiService.queryParams.getSearchValue()"
                    [text]="item.id || ''"></text-highlight>
                </div>
              </td>
              <td>
                <text-highlight
                  [search]="playerApiService.queryParams.getSearchValue()"
                  [text]="item.platform?.name"></text-highlight>
              </td>
              <td class="text-center">
                <text-highlight
                  [search]="playerApiService.queryParams.getSearchValue()"
                  [text]="item.location?.name"></text-highlight>
              </td>
            </tr>
          </tbody>
        </table>
        </responsive-table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer b-t bg-light dk">
  <button class="btn btn-success btn-addon" type="button" (click)="okEvent()">
    <i class="fas fa-check"></i>
    <span
      *ngIf="selected.size === 0"
      i18n="@@players-popup_service.button.choose">
      Выбрать
    </span>

    <span
      *ngIf="selected.size > 0"
      i18n="@@players-popup_service.button.selected-selected.size">
      Выбрано: {{ selected.size }}
    </span>
  </button>

  <button
    class="btn btn-danger btn-addon btn-danger"
    type="button"
    (click)="cancelEvent()">
    <i class="fas fa-times"></i>
    <ng-container i18n="@@players-popup_service.button.cancellation">
      Отмена
    </ng-container>
  </button>
</div>
