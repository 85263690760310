import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { FieldTypes } from 'ds-player/dist/components/templates/template.interface';
import { FilesSelectorService } from '../../../../../core/service/files-selector/files-selector.service';
import { FONT_SIZES } from '../../../../../modules/posters/template/template.const';
import { TemplateFieldClass } from 'ds-player/dist/components/templates/template-field.class';

export enum FILTER_TYPES {
  bold = 'bold',
  italic = 'italic',
  uppercase = 'uppercase',
  lineThrough = 'lineThrough',
  underline = 'underline',

  color = 'color',
  size = 'size',
}

export interface I_SWITCH_FILTER {
  parse: RegExp | Function;
  template: string;
  value: string;
  enabled: boolean;
}

export interface I_VALUE_FILTER {
  parse: RegExp | Function;
  template: string;
  value: string;
  enabled: boolean;
}

@Component({
  selector: 'block-font',
  templateUrl: './block-font.component.html',
  styleUrls: ['./block-font.component.scss'],
  providers: [FilesSelectorService],
})
export class BlockFontComponent implements OnChanges {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @Input() options: any = {};

  public FILTER_TYPES = FILTER_TYPES;

  public switch_filters: {
    [key: string]: I_SWITCH_FILTER;
  } = {
    [FILTER_TYPES.bold]: {
      parse: /font-weight:\s*[a-zA-Z]*/i,
      template: 'font-weight: bold',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.italic]: {
      parse: /font-style:\s*italic/i,
      template: 'font-style: italic',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.uppercase]: {
      parse: /text-transform:\s*uppercase/i,
      template: 'text-transform: uppercase',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.lineThrough]: {
      parse: /text-decoration:\s*line-through/i,
      template: 'text-decoration: line-through',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.underline]: {
      parse: /text-decoration:\s*underline/i,
      template: 'text-decoration: underline',
      value: '',
      enabled: false,
    },
  };

  public value_filters: {
    [key: string]: I_VALUE_FILTER;
  } = {
    [FILTER_TYPES.color]: {
      parse: input => {
        let res = /color:\s*([^;|^$]*)/i.exec(input);
        return res?.length == 2 ? res[1] : undefined;
      },
      template: 'color: {{value}}',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.size]: {
      parse: input => {
        let res = /font-size:\s*([^;|^$]*)/i.exec(input);
        return res?.length == 2 ? res[1] : undefined;
      },
      template: 'font-size: {{value}}',
      value: '',
      enabled: false,
    },
  };

  public SIZE_VALUES = {
    '0.5em': '-2',
    '0.7em': '-1',
    '1em': '0',
    '1.5em': '+1',
    '2em': '+2',
  };
  public SIZE_VALUES_SORTED = Object.keys(this.SIZE_VALUES)
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    .map(key => ({ key, value: this.SIZE_VALUES[key] }));

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) this.parse(changes['value'].currentValue);
  }

  parse(inputValue: string) {
    Object.values(this.switch_filters)
      .concat(Object.values(this.value_filters))
      .forEach(value => {
        let res;

        switch (true) {
          case value.parse instanceof RegExp:
            res = (<RegExp>value.parse).exec(inputValue);
            res = res && !!res.length ? res[0] : undefined;
            break;

          case typeof value.parse === 'function':
            res = (<any>value.parse)(inputValue);
            break;
        }

        if (!res || !res.length) {
          value.enabled = false;
          return;
        }

        value.value = res;
        value.enabled = true;
      });
  }

  emit() {
    let res = '';
    Object.values(this.switch_filters).forEach(value => {
      if (!value.value) return;

      res += ` ${value.value};`;
    });

    Object.values(this.value_filters).forEach(value => {
      if (!value.value) return;

      const val = value.template.replace('{{value}}', value.value);
      res += ` ${val};`;
    });

    this.valueChange.emit(res);
  }

  changeBoolFilter(filterId) {
    if (this.switch_filters[filterId]) {
      this.switch_filters[filterId].value = this.isFilterEnabled(filterId)
        ? ''
        : this.switch_filters[filterId].template;
    }

    this.emit();
  }

  changeValueFilter(filterId, value) {
    if (this.value_filters[filterId]) {
      this.value_filters[filterId].value = value;
      this.emit();
    }
  }

  isFilterEnabled(filterId) {
    if (this.switch_filters[filterId]) {
      return !!this.switch_filters[filterId].value;
    }

    return false;
  }
}
