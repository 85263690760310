import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './app-search-input.component.html',
})
export class AppSearchInputComponent implements OnInit, OnChanges {
  // Внутренние переменные
  public _searchString: string;
  set searchString(value) {
    this.changeModel(value, this._searchString);
  }

  get searchString() {
    return this._searchString;
  }

  get isSearchEnabled() {
    return !!this.searchString;
  }

  private changeOldVal;

  // Передаваемые параметры в компонент
  @Input() public model: any;
  @Output() public modelChange = new EventEmitter<string>();

  @Input() public placeholder: string;
  @Input() public change: any;
  @Input() public isEnabled: boolean;

  @Input() public isPending: boolean;
  @Input() public keyHook: any;

  @Input() public size = 'md';

  constructor() {}

  public ngOnInit() {
    if (typeof this.isEnabled === 'undefined') this.isEnabled = true;

    if (this.isEnabled) {
      this._searchString = this.model;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this._searchString = changes['model'].currentValue || '';
    }
  }

  public setSearch = () => {
    if (!this.searchString) {
      this.resetSearch();
    } else {
      this.model = this.searchString;
      this.modelChange.emit(this.model);
    }
  };

  public _keyHook = ($event: KeyboardEvent) => {
    if (this.keyHook) return this.keyHook($event);

    if (!$event) {
      $event = <any>window.event;
    }

    if ($event.keyCode == 13) {
      $event.preventDefault();
      this.setSearch();
    }
  };

  public resetSearch() {
    this.searchString = '';
    this.model = this.searchString;
    this.modelChange.emit(this.model);
    //    this.updateView();
  }

  /*   private updateView = () => {
        this.$scope.$apply();
    }*/

  private changeModel(currentVal: any, oldVal: any) {
    if (
      this.change &&
      typeof currentVal !== 'undefined' &&
      this.changeOldVal !== currentVal
    ) {
      this.changeOldVal = currentVal;
      this._searchString = this.change(currentVal, oldVal);
    } else {
      this._searchString = currentVal;
    }
  }
}
