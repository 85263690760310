import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import {
  ListWithTotalTranscodingByPlatformDetailsApi,
  PlatformApiService as PlatformApiServiceGen,
  PlatformTranscodingDetailsRequestApi,
  PlatformTranscodingRequestApi,
  TranscodingApi,
} from '../generated/Platform';
import { PlatformApi, PlatformRequestApi } from '../generated/Platform';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { isEmptyCheck } from '../tools.functions';

export * from './variables';

@Injectable()
export class PlatformApiService {
  constructor(
    private platformAPIService: PlatformApiServiceGen,
    private partnerApiService: PartnerApiService,
    localStorageService: LocalStorageService
  ) {
    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'platformType',
            values: {
              ukm4: 'ukm4',
              ukm5: 'ukm5',
            },
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );
    this.transcodingByPlatformParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );

    this.transcodingByFilesParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'dateFrom',
            currentValue: undefined,
          },
          {
            field: 'dateTo',
            currentValue: undefined,
          },
          {
            field: 'fileType',
            currentValue: undefined,
          },
          {
            field: 'fileIds',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;
  public transcodingByPlatformParams: ParamsHandler;
  public transcodingByFilesParams: ParamsHandler;

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 50,
    },
    description: {
      required: true,
      maxlength: 150,
    },
    resolutionX: {
      required: true,
      min: 1,
      max: 65535,
    },
    resolutionY: {
      required: true,
      min: 1,
      max: 65535,
    },
    orientation: {
      required: true,
    },
    codec: {
      required: true,
    },
    bitrateKbps: {
      required: true,
      min: 1,
      max: 4294967295,
    },
    container: {
      required: true,
      maxlength: 50,
    },
    spaceMb: {
      required: true,
    },
    platformType: {
      required: true,
    },
    videoCodec: {
      required: true,
    },
    audioCodec: {
      required: true,
    },
  });

  query$(
    body: PlatformRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.platformAPIService
      .postPartnerPartneridPlatformQuery({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.error-getting-list-of-platforms:Ошибка получения списка платформ`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.platformAPIService
      .getPartnerPartneridPlatformId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.platform-not-found:Платформа не найдена`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: PlatformApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.platformAPIService
      .postPartnerPartneridPlatform({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.platform-creation-error:Ошибка создания платформы`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: PlatformApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof body.id === 'undefined') return this.create$(body, partnerId);

    return this.platformAPIService
      .putPartnerPartneridPlatformId({
        partnerId,
        id: body.id,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.platform-saving-error:Ошибка сохранения платформы`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.platformAPIService
      .deletePartnerPartneridPlatformId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.platform-deletion-error:Ошибка удаления платформы`
          );
          return throwError(error);
        })
      );
  }

  transcodingByPlatform$(
    body: PlatformTranscodingRequestApi,
    id: number,
    partnerId = this.partnerApiService.currentPartnerId
  ): Observable<TranscodingApi[]> {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.platformAPIService
      .postPartnerPartneridPlatformIdTranscodingbyplatform({
        partnerId,
        id,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.error-getting-transcoding-list:Ошибка получения списка транскодинга`
          );
          return throwError(error);
        })
      );
  }

  transcodingByFiles$(
    body: PlatformTranscodingDetailsRequestApi,
    id: number,
    partnerId = this.partnerApiService.currentPartnerId
  ): Observable<ListWithTotalTranscodingByPlatformDetailsApi> {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.platformAPIService
      .postPartnerPartneridPlatformIdTranscodingdetails({
        partnerId,
        id,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@platform-api_service.error-getting-transcoding-list2:Ошибка получения списка транскодинга`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '')
      return of({
        canEdit: true,
        fps: 30,
        bitrateKbps: 8000,
      });

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }

  getFileUrlForPlatform(
    platformId: any,
    fileId: string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return ` /partner/${partnerId}/platform/${platformId}/file/${fileId}`;
  }
}
