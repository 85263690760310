import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
  UserApiService,
  UserRoleEnum,
} from '../../../core/service/api/user-api/user-api.service';
import { LoadableItemClass } from '../../../core/class/loadable-item.class';
import { LoginApi } from '../../../core/service/api/generated/Authorization';
import { PartnerApiService } from '../../../core/service/api/partner-api/partner-api.service';
import {ExternalApiService} from "../../../core/service/api/external-api/external-api.service";
import {debounceTime, Subject, Subscription, switchMap} from "rxjs";
import {LocationApiService} from "../../../core/service/api/location-api/location-api.service";
import {map} from "rxjs/operators";
import {fromBase64} from "js-base64";

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
  providers: [
    ExternalApiService
  ],
})

export class RegistrationPageComponent implements OnInit {
  public stage : 'create' | 'location' | 'ok' = 'create';

  public registerItem: any = {};

  public locationList: LoadableItemClass<any> = new LoadableItemClass();
  public deviceTypeList: LoadableItemClass<any> = new LoadableItemClass();

  public isLoading = false;
  public isLoadingLocation = false;
  public errorMessage: string = '';

  public locationParams = {
    query: "",
    offset: 0,
    limit: 50
  }
  private lastRequest: Subscription;

  public  lastRequestDeviceParams = {
    query: "",
  }
  private lastRequestDeviceType: Subscription;


  private lastSearchString = '';

  public searchChanged = new Subject<string>();

  public forRequiredHack;

  @ViewChild('passwordInputLink')
  public passwordInput: ElementRef<HTMLInputElement>;

  constructor(
    public $state: StateService,
    public externalApiService: ExternalApiService
  ) {}

  ngOnInit(): void {
    if (this.$state.params['api'])
      this.externalApiService.rootUrl = fromBase64(decodeURIComponent(this.$state.params['api']));

    if (this.$state.params['login'])
      this.registerItem.login = fromBase64(decodeURIComponent(this.$state.params['login']))
    else
      this.registerItem.login = 'LinuxTV';

    this.registerItem.playerPassword = '';
    this.registerItem.uuid = this.$state.params['uuid'];
    this.initSearchHandler();

  }

  selectLocation() {
    if (!this.isValidForLocation())
      return;

    this.isLoadingLocation = true;
    this.errorMessage = '';
    this.externalApiService.setAuth(this.registerItem.login, this.registerItem.playerPassword);
    this.externalApiService.queryLocations$({
      paging: {
        offset: 0,
        limit: 1
      }
    }, this.registerItem.partnerId).subscribe({
      next: (value) => {
        this.getDeviceTypes();
        this.isLoadingLocation = false;
        this.stage = "location";
        this.resetItems();
      },
      error: () => {
        this.isLoadingLocation = false;
        this.errorMessage = $localize`:|@@register-player-page.error-auth:Ошибка авторизации`
      }
    });
  }


  createPlayer() {

    this.isLoading = true;
    this.errorMessage = '';
    this.externalApiService.setAuth(this.registerItem.login, this.registerItem.playerPassword);
    this.externalApiService.createDevice$([{
      deviceTypeId: this.registerItem.deviceTypeId,
      name: this.registerItem.name,
      locationCode: this.registerItem.locationId,
      uuid: this.registerItem.uuid,
      code: ''
    }], this.registerItem.partnerId).subscribe({
      next: (value) => {
        this.isLoading = false;
        this.stage = "ok";
      },
      error: () => {
        this.isLoading = false;
        this.errorMessage = $localize`:|@@register-player-page.error-create:Ошибка регистрации плеера`
      }
    });
  }

  isValidForLocation() {
    return !['partnerId','login', 'playerPassword'].some( key => !this.registerItem[key] )
  }

  isValid() {
    return !['partnerId','deviceTypeId','name','login', 'playerPassword'].some( key => !this.registerItem[key] )
  }

  //----------------------


  getLocations() {
    if (this.locationList.isLoading) return;

    const isFirstRequest =
      this.locationParams.offset === 0;

    this.lastRequest = this.externalApiService
      .queryLocations$(
        {
          paging: {
            limit: this.locationParams.limit,
            offset: this.locationParams.offset
          },
          query: this.locationParams.query
        },
        this.registerItem.partnerId)
      .pipe(
        this.locationList.loadingOperator
      )
      .subscribe((result: any) => {
        this.locationList.addItems(result.items || result);
      });
  }

  onSearchChange($event) {
    this.locationParams.query = $event;

    this.resetItems();
  }

  resetItems() {
    this.locationList.items = [];
    this.locationParams.limit = 50;
    this.locationParams.offset = 0;

    this.lastRequest && this.lastRequest.unsubscribe();

    this.getLocations();
  }

  nextPage() {
    if (this.locationList.isLoading) return;

    this.locationParams.offset += this.locationParams.limit;

    this.getLocations();
  }

  onScroll($event: any = false) {
    if (this.locationList.isLoading) return;

    if (
      $event &&
      $event.end + this.locationParams?.limit >
      (this.locationList?.items?.length || 0)
    )
      this.nextPage();
  }

  onScrollToEnd() {
    this.nextPage();
  }

  initSearchHandler() {
    this.searchChanged.pipe(debounceTime(300)).subscribe(search => {
      if (!this.lastSearchString || !search) {
        this.locationList.isLoading = false;
        this.locationParams.query = ''
        this.resetItems();
        return;
      }

      this.locationList.isLoading = false;
      this.locationParams.query = search;
      this.resetItems();
    });
  }

  onSearch($event) {
    this.locationList.items = [];
    this.locationList.isLoading = true;
    this.lastSearchString = $event.term;
    this.searchChanged.next(this.lastSearchString);
  }

  clear() {
    this.locationList.items = [];
    this.lastSearchString = '';
    this.locationParams.query = '';
    this.resetItems();
  }

  close() {
/*    if (!!this.locationParams.query) {
      this.clear();
    }*/
  }

  compareFunction(item: any = {}, selected: any = {}) {
    return typeof selected === 'number'
      ? item?.id === selected
      : item?.id === selected?.id;
  }

  //----------

  getDeviceTypes() {
    if (this.deviceTypeList.isLoading) return;

    this.lastRequestDeviceType = this.externalApiService
      .queryDeviceType$(
        '',
        this.registerItem.partnerId
      )
      .pipe(
        this.deviceTypeList.loadingOperator
      )
      .subscribe((result: any) => {
        this.deviceTypeList.items = result;
        if (result?.length === 1) {
          this.registerItem.deviceTypeId = result[0]?.id;
        }
      });
  }

  onSearchDeviceType($event) {
    this.lastRequestDeviceParams.query = $event?.term || '';
  }

  clearDeviceType() {
    this.deviceTypeList.items = [];
    this.lastRequestDeviceParams.query = '';
    this.getDeviceTypes();
  }

  closeDeviceType() {
 /*   if (!!this.lastRequestDeviceParams.query) {
      this.clearDeviceType();
    }*/
  }

  filterDeviceBySearch = (term, item) => {

    if ( !term ) {
      return true;
    }

    let finded = this.deviceTypeList?.items?.find( i => i.id === item);

    return !finded ? false : finded?.name?.toLowerCase().includes(term?.toLowerCase()?.toString());

  }

  fixTypePassword() {
    // До лучших времён
    if (!this?.passwordInput?.nativeElement)
      return;

    this.passwordInput.nativeElement.type = 'password';
  }

}
