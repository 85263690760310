import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationGroupApiService } from '../api/location-group-api/location-group-api.service';
import { LoadableItemClass } from '../../class/loadable-item.class';


@Component({
  selector: 'ngbd-modal-content',
  styleUrls: ['./locationGroupPopup.service.scss'],
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5
        class="modal-title ng-binding"
        i18n="@@locationGroupPopup_service.location-selection">
        Выбор локации
      </h5>
    </div>
    <div class="modal-body overflow-wrap-break p-0 d-flex overflow-auto">
      <div class="col p-0">
<!--
        <div class=" d-block p-3 pt-2 pb-2 ">
          <app-search-input
            (modelChange)="onSearchChange($event)"
            placeholder="Введите наименование локации"
            size="sm"
            i18n-placeholder="
              @@locationGroupPopup_service.enter-the-name-of-the-platform"></app-search-input>
        </div>
-->

        <div
          infiniteScroll=""
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [fromRoot]="true"
          (scrolled)="nextPage()">
          <responsive-table [disabled]="true">
            <table class="table table-hover" *ngIf="!locationGroupsList.isLoading">
              <thead>
              <tr>
                <th style="width: 1px" class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      [checked]="isAllChecked()"
                      (change)="checkAll($event)" />
                  </div>
                </th>

                <th
                  scope="col"
                  i18n="@@locationGroupPopup.table.table-header.name">
                  Наименование
                </th>
                <th
                  scope="col"
                  i18n="
                  @@locationGroupPopup.table.table-header.description">
                  Описание
                </th>
                <th
                  scope="col"
                  i18n="@@locationGroupPopup.table.table-header.code">
                  Код
                </th>
                <th
                  class="text-center"
                  scope="col"
                  i18n="
                  @@locationGroupPopup.table.table-header.quantity-then">
                  Количество ТО
                </th>
                <th
                  class="text-center"
                  scope="col"
                  i18n="
                  @@locationGroupPopup.table.table-header.number-of-players">
                  Количество плееров
                </th>
                <th
                  class="text-center"
                  scope="col"
                  i18n="@@locationGroupPopup.table.table-header.user">
                  Пользователь
                </th>
                <th
                  class="text-end"
                  scope="col"
                  i18n="
                  @@locationGroupPopup.table.table-header.date-of-creation">
                  Дата создания
                </th>

              </tr>
              </thead>

              <tbody>
              <tr
                *ngFor="let item of locationGroupsList.items"
                (click)="check(item.id); $event.stopPropagation()">
                <td class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="selected.has(item.id)"
                      id="flexCheckDefault"
                      (change)="check(item.id)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </td>
                <td>
                  <div>
                    <text-highlight
                      [search]="
                      locationGroupApiService.queryParams.getSearchValue()
                    "
                      [text]="item?.name"></text-highlight>
                  </div>
                  <div class="clearfix small text-muted">
                    ID: <text-highlight [text]="item.id || ''"></text-highlight>
                  </div>
                </td>
                <td class="" [innerText]="item.description || ''"></td>
                <td [innerText]="item.code || 0"></td>
                <td
                  class="text-center"
                  [innerText]="item.locationCount || 0"></td>
                <td class="text-center" [innerText]="item.playerCount || 0"></td>
                <td class="text-center" [innerText]="item.userName || ''"></td>
                <td
                  class="text-end"
                  [innerText]="item.createdAt | dsDateTime"></td>
              </tr>
              </tbody>
            </table>
          </responsive-table>
        </div>

        <div class="text-center" *ngIf="locationGroupsList.isLoading">
          <i class="fas fa-spin fa-spinner mt-5  text-primary"></i>
        </div>
      </div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-addon "
        type="button"
        (click)="okEvent()"
        *ngIf="selected.size"
        ng-click="okEvent()">
        <i class="fas fa-check"></i
        ><ng-container
        i18n="@@locationGroupPopup_service.button.select-selected.size">
        Выбрать: {{ selected.size }}
      </ng-container>
      </button>

      <button
        class="btn btn-danger btn-addon btn-danger"
        type="button"
        (click)="cancelEvent()"
        ng-click="cancelEvent()">
        <i class="fas fa-times"></i
        ><ng-container i18n="@@locationGroupPopup_service.button.cancellation">
        Отмена
      </ng-container>
      </button>
    </div>
  `,
  providers: [ LocationGroupApiService],
})
export class NgbdModalDialogLocationGroupPopupComponent implements OnDestroy {

  public locationGroupsList: LoadableItemClass<any> = new LoadableItemClass();

  public selected = new Set();
  public cachedNames = new Map<any, any>();

  private total;

  constructor(
    public activeModal: NgbActiveModal,
    public locationGroupApiService: LocationGroupApiService
  ) {}

  public loadParams(params: any = {}) {
    if (Array.isArray(params.model)) {
      this.selected.clear();
      params.model.forEach(i => {
        switch (true) {
          case typeof i === 'number':
            this.selected.add(i);
            break;
          case typeof i === 'object' && typeof i.id === 'number':
            this.selected.add(i.id);
            break;
          case typeof i === 'object' && typeof i.id === 'string':
            this.selected.add(parseInt(i.id));
            break;
        }
      });
    }

    this.init();
  }

  public init() {
    this.locationGroupApiService.queryParams.setPageLimit(9999);
    this.resetItems();
  }

  getLocationGroups() {
    if (this.locationGroupsList.isLoading) return;

    let requester: any = this.locationGroupApiService.query$(
   //   this.locationGroupApiService.queryParams.params
    );

    requester.pipe(this.locationGroupsList.loadingOperator).subscribe(result => {
      this.locationGroupsList.addItems(result.items || result);
      this.total = result.total;

      this.locationGroupsList.items.forEach(i => {
        this.cachedNames.set(i.id, i.name);
      });
    });
  }

  isAllChecked() {
    if (
      this.selected.size === 0 ||
      this.selected.size < this.locationGroupsList.items.length
    )
      return false;

    return !this.locationGroupsList.items.some(i => !this.selected.has(i.id));
  }

  checkAll(value) {
    if (value.target.checked) {
      this.locationGroupsList.items.forEach(i => this.selected.add(i.id));
    } else {
      this.locationGroupsList.items.forEach(i => this.selected.delete(i.id));
    }
  }

  check(id) {
    if (!this.selected.has(id)) this.selected.add(id);
    else this.selected.delete(id);
  }

  okEvent() {
    this.activeModal.close(
      Array.from(this.selected).map(i => ({
        id: i,
        name: this.cachedNames.get(i),
      }))
    );
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  onSearchChange($event) {
    this.locationGroupApiService.queryParams.search($event);

    this.resetItems();
  }

  resetItems() {
    this.locationGroupsList.items = [];
    this.locationGroupApiService.queryParams.reset();

    this.getLocationGroups();
  }

  nextPage() {
/*
    if (this.locationGroupsList.isLoading) return;

    this.locationGroupApiService.queryParams.next();

    this.getLocationGroups();*/
  }

  ngOnDestroy() {
    this.selected.clear();
  }
}

@Injectable()
export class LocationGroupPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(
      NgbdModalDialogLocationGroupPopupComponent,
      {
        modalDialogClass: 'modal-dialog-location-group-popup',
        size: 'xl',
      }
    );
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
