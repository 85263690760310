const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof HTMLElement) );
}

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {
          [key]: {}
        });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const getByPath = (object, path) => {
  if (object == null) { // null or undefined
    return object;
  }
  const parts = path.split('.');
  return parts.reduce((object, key) => object?.[key], object);
};

export const setByPath = (path, value) => {
  let object  = {};
  const parts = path.split('.');
  parts.reduce((object, key, index ) => object[key] = index === parts.length-1 ? value : {}, object);
  return object;
};


export function IsPlaylistItemTemplate(item) {
  return item && typeof item.templateId !== "undefined"
}

export function hasPlayListTemplate(playlist) {
  return playlist &&
    Array.isArray(playlist.items)  &&
    playlist.items.some(i => IsPlaylistItemTemplate(i))
}

export function matchAll(pattern,haystack){
  var regex = new RegExp(pattern,"g")
  var matches = [];

  var match_result = haystack.match(regex);

  for (let index in match_result){
    var item = match_result[index];
    matches[index] = item.match(new RegExp(pattern));
  }
  return matches;
}
