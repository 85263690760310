<responsive-table [disabled]="true" >
<table class="table table-hover">
  <thead>
    <tr>
      <th
        scope="col"
        i18n="@@file-active-locations_page.table.table-header.that">
        ТО
      </th>
      <th
        scope="col"
        i18n="@@file-active-locations_page.table.table-header.location">
        Локация
      </th>
      <th
        scope="col"
        width="1px"
        i18n="@@file-active-locations_page.table.table-header.players">
        Плееры
      </th>
    </tr>
  </thead>

  <tbody>
    <ng-container
      *ngFor="
        let item of locationsItems.items
          | slice: (pageIndex - 1) * PAGE_COUNT:pageIndex * PAGE_COUNT;
        let index = index
      ">
      <tr>
        <td class="text-start">
          <span
            [innerText]="
              item.locationName || 'ТО с id:' + item.locationId
            "></span>
          <small class="d-block text-muted"> ID: {{ item.locationId }} </small>
        </td>

        <td class="text-start">
          <ng-container *ngIf="!!item.locationGroup">
            <span
              [innerText]="
                item.locationGroup.name ||
                'Локация с id:' + item.locationGroup.id
              "></span>
            <small class="d-block text-muted">
              ID: {{ item.locationGroup.id }}
            </small>
          </ng-container>
        </td>

        <td class="text-center pe-3">
          <div
            class="text-nowrap mb-0"
            i18n="
              @@file-active-locations_page.table.table-row.players-item.players.total-pcs.">
            Плееры: {{ item.players.total }} шт.
          </div>

          <a
            href="javascript:void(0)"
            *ngIf="item?.players?.total || 0 > 0"
            class="small text-nowrap d-inline-block mt-0"
            (click)="showPlayers(index)"
            ><ng-container
              i18n="@@file-active-locations_page.table.table-row.viewing">
              просмотр </ng-container
            ><i
              class="fas"
              [class.fa-caret-up]="showPlayersIndex === index"
              [class.fa-caret-down]="showPlayersIndex !== index"></i>
          </a>
        </td>
      </tr>

      <tr *ngIf="showPlayersIndex === index">
        <td colspan="9999">
          <div class="card">
            <div class="card-body">
              <responsive-table [disabled]="true" >
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      i18n="
                        @@file-active-locations_page.table.table-header.player">
                      Плеер
                    </th>
                    <th
                      scope="col"
                      i18n="
                        @@file-active-locations_page.table.table-header.schedule">
                      Расписание
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let subItem of item.players.items
                        | slice
                          : (pagePlayerIndex - 1) * PAGE_PLAYER_COUNT
                          : pagePlayerIndex * PAGE_PLAYER_COUNT;
                      let index = index
                    ">
                    <td>
                      <a
                        href="javascript:void(0)"
                        [dsLink]="'root.player.view'"
                        [dsLinkParams]="{ id: subItem.playerId }"
                        [innerText]="
                          subItem.playerName || 'Плеер с id:' + subItem.playerId
                        "></a>
                      <small class="d-block text-muted">
                        ID: {{ subItem.playerId }}
                      </small>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        [dsLink]="'root.schedule.view'"
                        [dsLinkParams]="{ id: subItem.scheduleId }"
                        [innerText]="
                          subItem.scheduleName ||
                          'Расписание с id:' + subItem.scheduleId
                        "></a>
                      <small class="d-block text-muted">
                        ID: {{ subItem.scheduleId }}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
              </responsive-table>

              <div
                *ngIf="!item?.players?.items?.length"
                class="text-center text-muted pt-2 pb-2">
                <h5
                  i18n="
                    @@file-active-locations_page.table.table-row.no-players">
                  Плееры отсутствуют
                </h5>
              </div>
            </div>
            <div
              class="card-footer text-center pb-0"
              *ngIf="(item?.players?.items?.length || 0) > PAGE_PLAYER_COUNT">
              <ngb-pagination
                class="d-flex justify-content-center mb-n2"
                [page]="pagePlayerIndex"
                (pageChange)="pagePlayerIndex = $event"
                [maxSize]="5"
                [rotate]="true"
                [pageSize]="PAGE_PLAYER_COUNT"
                [collectionSize]="
                  item?.players?.items?.length || 0
                "></ngb-pagination>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</responsive-table>

<div *ngIf="locationsItems.isLoading">
  <div class="text-center">
    <i class="fas fa-spin fa-3x fa-spinner text-warning"></i>
  </div>
</div>

<div
  *ngIf="!locationsItems.isLoading && !locationsItems.items?.length"
  class="text-center text-muted pt-2 pb-2">
  <h5 i18n="@@file-active-locations_page.no-retail-facilities">
    Торговые объекты отсутствуют
  </h5>
</div>

<div
  class="card-footer text-center ms-n3 me-n3 mb-n3"
  *ngIf="
    !locationsItems.isLoading &&
    (locationsItems.items?.length || 0) > PAGE_COUNT
  ">
  <ngb-pagination
    class="d-flex justify-content-center"
    [page]="pageIndex"
    (pageChange)="changePage($event)"
    [maxSize]="5"
    [rotate]="true"
    [pageSize]="PAGE_COUNT"
    [collectionSize]="locationsItems.items?.length || 0"></ngb-pagination>
</div>
