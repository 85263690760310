<button
  class="btn btn-sm me-2"
  (click)="changeBoolFilter(FILTER_TYPES.bold)"
  [ngClass]="{
    'btn-default': !isFilterEnabled(FILTER_TYPES.bold),
    'btn-success': isFilterEnabled(FILTER_TYPES.bold)
  }">
  <i class="fas fa-bold"></i>
</button>

<button
  class="btn btn-sm me-2"
  (click)="changeBoolFilter(FILTER_TYPES.italic)"
  [ngClass]="{
    'btn-default': !isFilterEnabled(FILTER_TYPES.italic),
    'btn-success': isFilterEnabled(FILTER_TYPES.italic)
  }">
  <i class="fas fa-italic"></i>
</button>

<button
  class="btn btn-sm me-2"
  (click)="changeBoolFilter(FILTER_TYPES.uppercase)"
  [ngClass]="{
    'btn-default': !isFilterEnabled(FILTER_TYPES.uppercase),
    'btn-success': isFilterEnabled(FILTER_TYPES.uppercase)
  }">
  <i class="fas fa-heading"></i>
</button>
<!--

<button
  class="btn btn-sm me-2"
  (click)="changeBoolFilter(FILTER_TYPES.lineThrough)"
  [ngClass]="{'btn-default': !isFilterEnabled(FILTER_TYPES.lineThrough), 'btn-success': isFilterEnabled(FILTER_TYPES.lineThrough) }"
>
  <i class="fas fa-strikethrough"></i>
</button>

-->

<button
  class="btn btn-sm me-2"
  (click)="changeBoolFilter(FILTER_TYPES.underline)"
  [ngClass]="{
    'btn-default': !isFilterEnabled(FILTER_TYPES.underline),
    'btn-success': isFilterEnabled(FILTER_TYPES.underline)
  }">
  <i class="fas fa-underline"></i>
</button>

<button
  class="btn btn-sm me-2 ms-3 btn-default"
  [colorPicker]="value_filters[FILTER_TYPES.color].value"
  (colorPickerChange)="changeValueFilter(FILTER_TYPES.color, $event)">
  <i class="fas fa-fill fa-lg"></i>
  <div
    class="d-inline-block ms-1"
    [style.color]="value_filters[FILTER_TYPES.color].value">
    <i class="icon-outfas fa-square-full"></i>
  </div>
</button>

<div class="btn-group me-2 ms-3">
  <button class="btn btn-sm btn-default">
    <i class="fas fa-font"></i>
  </button>

  <div class="btn-group" ngbDropdown="" role="group" [placement]="'bottom-end'">
    <button
      type="button"
      class="btn btn-sm btn-default"
      ngbDropdownToggle=""
      [innerText]="
        SIZE_VALUES[value_filters[FILTER_TYPES.size].value] || 0
      "></button>
    <div
      class="dropdown-menu"
      [style]="{ 'min-width': '3em' }"
      ngbDropdownMenu="">
      <ng-container *ngFor="let item of SIZE_VALUES_SORTED">
        <button
          ngbDropdownItem=""
          class="text-center"
          [class.active]="item.key === value_filters[FILTER_TYPES.size].value"
          (click)="changeValueFilter(FILTER_TYPES.size, item.key)"
          [innerText]="item.value"></button>
      </ng-container>
    </div>
  </div>
</div>
