import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FilesPageModule } from './files/files-page.module';
import { TemplatesListPageModule } from './templates/list-page/templates-list-page.module';
import { TemplatesViewPageModule } from './templates/view-page/templates-view-page.module';
import { APP_ROUTES } from '../../../app-routes.variables';
import { CoreDirectiveModule } from '../../core/directive/core-directive.module';

@Component({
  template: `<ui-view></ui-view>`,
})
class StoragePageComponent {}

@NgModule({
  declarations: [StoragePageComponent],
  imports: [
    CommonModule,
    FilesPageModule,
    TemplatesListPageModule,
    TemplatesViewPageModule,
    UIRouterModule.forChild({
      states: [
        {
          name: APP_ROUTES['root.storage'].name,
          url: APP_ROUTES['root.storage'].url,

          component: StoragePageComponent,
        },
        {
          name: APP_ROUTES['root.templates'].name,
          url: APP_ROUTES['root.templates'].url,

          component: StoragePageComponent,
        },
      ],
    }),
  ],
})
export class StoragePageModule {}
