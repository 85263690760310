import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DsDatePipe } from '../dsDate/ds-date.pipe';
import { DsTimePipe } from '../dsTime/ds-date-time.pipe';

@Pipe({
  name: 'dsDateTime',
})
export class DsDateTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  transform(value: string | undefined, args?: any): string {
    if (!value) return '';

    let datePipe: any = new DsDatePipe(this._locale);
    let timePipe: any = new DsTimePipe(this._locale);

    return datePipe.transform(value) + ' ' + timePipe.transform(value);
  }
}
