import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5 class="modal-title ng-binding" [innerHTML]="title"></h5>
    </div>
    <div class="modal-body overflow-wrap-break ">
      <div class="no-padder " [innerHTML]="message"></div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-sm "
        type="button"
        (click)="okEvent()"
        ng-click="okEvent()"
        [innerText]="ok"
        i18n="@@confirmAction_service.ok">
        Ок
      </button>
      <button
        class="btn btn-danger btn-sm"
        type="button"
        (click)="cancelEvent()"
        ng-click="cancelEvent()"
        [innerText]="cancel"
        i18n="@@confirmAction_service.cancellation">
        Отмена
      </button>
    </div>
  `,
})
export class NgbdModalContent {
  @Input()
  title = $localize`:|@@confirmAction_service.confirm-the-action:Подтвердите действие`;
  @Input() message;
  @Input() ok = $localize`:|@@confirmAction_service.ok2:Ок`;
  @Input() cancel = $localize`:|@@confirmAction_service.cancellation2:Отмена`;

  constructor(public activeModal: NgbActiveModal) {}

  okEvent() {
    this.activeModal.close('Ok');
  }

  cancelEvent() {
    this.activeModal.close('Cancel');
  }
}

@Injectable()
export class ConfirmActionService {
  constructor(private modalService: NgbModal) {}

  public confirm(question, callback, cancel: any = () => {}) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.message = question;
    modalRef.result.then(result => {
      if (result === 'Ok') {
        callback();
        return;
      }

      cancel();
    }, cancel);
  }
}
