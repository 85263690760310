import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { FilesSelectorService } from '../../../../../core/service/files-selector/files-selector.service';
import { I_SWITCH_FILTER } from '../block-font/block-font.component';

export enum FILTER_TYPES {
  color = 'color',
  image = 'image',

  bkgContain = 'bkgContain',
}

export interface I_VALUE_FILTER {
  parse: RegExp | Function;
  template: string;
  value: string;
  enabled: boolean;
}

@Component({
  selector: 'block-background',
  templateUrl: './block-background.component.html',
  styleUrls: ['./block-background.component.scss'],
  providers: [FilesSelectorService],
})
export class BlockBackgroundComponent implements OnChanges {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @Input() options: any = {};

  public FILTER_TYPES = FILTER_TYPES;

  public switch_filters: {
    [key: string]: I_SWITCH_FILTER;
  } = {
    [FILTER_TYPES.bkgContain]: {
      parse: /background-size\s*:\s*contain/i,
      template: 'background-size: contain',
      value: '',
      enabled: false,
    },
  };

  public value_filters: {
    [key: string]: I_VALUE_FILTER;
  } = {
    [FILTER_TYPES.color]: {
      parse: input => {
        let res = /background-color:\s*([^;|^$]*)/i.exec(input);
        return res?.length == 2 ? res[1] : undefined;
      },
      template: 'background-color: {{value}}',
      value: '',
      enabled: false,
    },

    [FILTER_TYPES.image]: {
      parse: input => {
        let res = /background-image\s*:\s*url\(['|"]([^\)]+)['|"]\)/i.exec(
          input
        );
        return res?.length == 2 ? res[1] : undefined;
      },
      template:
        "background-image: url('{{value}}'); background-repeat: no-repeat; background-position: center; background-size: cover;",
      value: '',
      enabled: false,
    },
  };

  constructor(private filesSelectorService: FilesSelectorService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) this.parse(changes['value'].currentValue);
  }

  parse(inputValue: string) {
    Object.values(this.switch_filters)
      .concat(Object.values(this.value_filters))
      .forEach(value => {
        let res;

        switch (true) {
          case value.parse instanceof RegExp:
            res = (<RegExp>value.parse).exec(inputValue);
            res = res && !!res.length ? res[0] : undefined;
            break;

          case typeof value.parse === 'function':
            res = (<any>value.parse)(inputValue);
            break;
        }

        if (!res || !res.length) {
          value.enabled = false;
          return;
        }

        value.value = res;
        value.enabled = true;
      });
  }

  emit() {
    let res = '';

    Object.values(this.value_filters).forEach(value => {
      if (!value.value) return;

      const val = value.template.replace('{{value}}', value.value);
      res += ` ${val};`;
    });

    Object.values(this.switch_filters).forEach(value => {
      if (!value.value) return;

      res += ` ${value.value};`;
    });

    this.valueChange.emit(res);
  }

  changeBoolFilter(filterId) {
    if (this.switch_filters[filterId]) {
      this.switch_filters[filterId].value = this.isFilterEnabled(filterId)
        ? ''
        : this.switch_filters[filterId].template;
    }

    this.emit();
  }

  changeValueFilter(filterId, value) {
    if (this.value_filters[filterId]) {
      this.value_filters[filterId].value = value;
      this.emit();
    }
  }

  uploadFile() {
    this.filesSelectorService
      .get({
        hideTemplates: true,
      })
      .toPromise()
      .then(
        result => {
          this.changeValueFilter(this.FILTER_TYPES.image, result.data.id);
        },
        () => {}
      );
  }

  isFilterEnabled(filterId) {
    if (this.value_filters[filterId]) {
      return !!this.value_filters[filterId].value;
    }

    if (this.switch_filters[filterId]) {
      return !!this.switch_filters[filterId].value;
    }

    return false;
  }
}
