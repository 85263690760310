export enum FieldTypes {
  'string' = 'string',
  'color' = 'color',
  'font_size' = 'font_size',
  'url' = 'url',
  'data' = 'data',
  'file' = 'file',

  'blockFont' = 'block.font',
  'blockBackground' = 'block.background',
}

export interface IFieldOptions {
  id: string,
  path: string,
  name: string,
  type: FieldTypes,
  position?: number,
  group?: string,
  options?: any,
  default?: string
}

export interface ITemplateOptions {
  updateFilePath?: (fileId: any) => Promise<any> | any
}
