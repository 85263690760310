import {
  Directive,
  Input,
  forwardRef,
  Attribute,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  Validators,
} from '@angular/forms';

@Directive({
  selector: '[min],[max]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxMinValidatorDirective,
      multi: true,
    },
  ],
})
export class MaxMinValidatorDirective implements Validator, OnChanges {
  @Input() public max: any;
  @Input() public min: any;

  constructor(private el: ElementRef) {}

  private isDate(value: any = '') {
    if (value instanceof Date) {
      return true;
    }

    if (typeof value === 'string' && /[\d]*\-[\d]*\-[\d]*/.test(value)) {
      return true;
    }

    return false;
  }

  private isTime(value = '') {
    if (typeof value === 'string' && /\d{1,2}:\d{1,2}:\d{1,2}/.test(value)) {
      return true;
    }

    return false;
  }

  private checkDate(type, value, validators) {
    switch (type) {
      case 'max':
        //     this.el.nativeElement.setAttribute('max', this.max);
        try {
          if (new Date(this.max).getTime() < new Date(value).getTime()) {
            Object.assign(validators, {
              maxDate: true,
            });
          }
        } catch (e) {}

        break;

      case 'min':
        //    this.el.nativeElement.setAttribute('min', this.min);
        try {
          if (new Date(this.min).getTime() > new Date(value).getTime()) {
            Object.assign(validators, {
              minDate: true,
            });
          }
        } catch (e) {}

        break;
    }
  }

  private checkTime(type, value, validators) {
    switch (type) {
      case 'max':
        let pasteMax = this.el.nativeElement.getAttribute('max');
        if (this.max !== pasteMax) {
          this.el.nativeElement.setAttribute('max', this.max);
        }

        try {
          if (
            new Date('1970-01-01T' + this.max).getTime() <
            new Date('1970-01-01T' + value).getTime()
          ) {
            Object.assign(validators, {
              maxDate: true,
            });
          }
        } catch (e) {}

        break;

      case 'min':
        let pasteMin = this.el.nativeElement.getAttribute('min');
        if (this.min !== pasteMin) {
          this.el.nativeElement.setAttribute('min', this.min);
        }

        try {
          if (
            new Date('1970-01-01T' + this.min).getTime() >
            new Date('1970-01-01T' + value).getTime()
          ) {
            Object.assign(validators, {
              minDate: true,
            });
          }
        } catch (e) {}

        break;
    }
  }

  validate(control: AbstractControl): { [key: string]: any } {
    let validators = {};

    if (
      typeof this.max === 'number' ||
      this.el?.nativeElement?.type === 'number'
    ) {
      Object.assign(validators, Validators.max(this.max)(control));
    }

    if (
      typeof this.min === 'number' ||
      this.el?.nativeElement?.type === 'number'
    )
      Object.assign(validators, Validators.min(this.min)(control));

    if (this.isDate(this.min) && control.value) {
      this.checkDate('min', control.value, validators);
    }

    if (this.isDate(this.max) && control.value) {
      this.checkDate('max', control.value, validators);
    }

    if (this.isTime(this.min) && control.value) {
      this.checkTime('min', control.value, validators);
    }

    if (this.isTime(this.max) && control.value) {
      this.checkTime('max', control.value, validators);
    }

    return validators;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['max'] && changes['max'].currentValue) {
      this.el.nativeElement.setAttribute(
        'max',
        changes['max'].currentValue.toString()
      );
    } else this.el.nativeElement.removeAttribute('max');

    if (changes['min'] && changes['min'].currentValue) {
      this.el.nativeElement.setAttribute(
        'min',
        changes['min'].currentValue.toString()
      );
    } else this.el.nativeElement.removeAttribute('min');
  }
}
