import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { LocationPopupService } from '../../../../core/service/locationPopup/locationPopup.service';
import { LocalStorageService } from '../../../../core/service/localStorage/local-storage.service';
import {
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_NAMES,
  TemplateApiService,
} from '../../../../core/service/api/template-api/template-api.service';
import { TemplateApi } from '../../../../core/service/api/generated/Template';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutApiService } from '../../../../core/service/api/layout-api/layout-api.service';
import { FileApiService } from '../../../../core/service/api/file-api/file-api.service';
import { FilesSelectorService } from '../../../../core/service/files-selector/files-selector.service';
import { FieldTypes } from '../../../../../../../player/src/components/templates/template.interface';
import { FONT_SIZES } from '../../../../modules/posters/template/template.const';
import { TemplateCoreClass } from '../../../../../../../player/src/components/templates/template-core.class';
import { StateService } from '@uirouter/core';
import { StatesChangerService } from '../../../../core/service/statesChanger/statesChanger.service';
import { ConfirmActionService } from '../../../../core/service/confirmAction/confirmAction.service';
import { TagApiService } from '../../../../core/service/api/tag-api/tag-api.service';
import { catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TemplateFieldClass } from 'ds-player/dist/components/templates/template-field.class';
import { LoadableItemClass } from '../../../../core/class/loadable-item.class';
import { PermissionsService } from '../../../../core/service/permissions/permissions.service';
import { PERMISSION_TYPES } from '../../../../core/service/permissions/permissions.enum';

const ID_STORAGE = 'scheduleListFilters';

@Component({
  selector: 'app-schedule-page',
  templateUrl: './templates-view-page.component.html',
  styleUrls: ['./templates-view-page.component.scss'],
  providers: [
    LocationPopupService,
    LocalStorageService,
    LayoutApiService,
    StatesChangerService,
    FilesSelectorService,
    TagApiService,
    PermissionsService,
  ],
})
export class TemplatesViewPageComponent implements OnInit {
  public PERMISSION_TYPES = PERMISSION_TYPES;

  public templateCore;

  public fieldTypes = FieldTypes;
  public FONT_SIZES = FONT_SIZES;

  public TEMPLATE_STATUS_NAMES = TEMPLATE_STATUS_NAMES;

  public selectedTemplate: TemplateApi | any = <any>{};
  public templateTags = [];

  @ViewChild('template') templateContainer: ElementRef;
  @Input() buttonEvents: EventEmitter<any>;

  public testedData = {
    index: -1,
    csv: false,
    items: [],
    errorsCount: 0,
  };

  public layoutItems = new LoadableItemClass<any>();

  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 3, md: 4, lg: 5, all: 0 },
    slide: 4,
    speed: 250,
    point: {
      visible: true,
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  public viewRatio = '16 / 9';

  public fileType = '';
  public fileItem;

  public templateData;
  public templateItem = {
    data: {},
  };

  public carouselIsFirst = true;
  public carouselIsLast = false;

  constructor(
    private modalService: NgbModal,
    private templateApiService: TemplateApiService,
    private layoutApiService: LayoutApiService,
    public fileApiService: FileApiService,
    private filesSelectorService: FilesSelectorService,
    public statesChangerService: StatesChangerService,
    public confirmActionService: ConfirmActionService,
    private tagApiService: TagApiService,
    public $state: StateService,
    public permissionsService: PermissionsService
  ) {
    this.templateCore = new TemplateCoreClass(
      ``,
      {},
      {
        updateFilePath: (id = '') => {
          id = this.fileApiService.fixPartnerUrl(id);

          if (id.toString().includes('/')) return id;

          return this.fileApiService.getUrl(id);
        },
      }
    );
  }

  ngOnInit() {
    this.getLayouts();
    this.subscribeOnDataChange();
    this.bindButtons();
    this.getTemplate(this.$state.params['id']);
  }

  createStates() {
    this.statesChangerService.createState(TEMPLATE_STATUS.draft);
    this.statesChangerService.createState(TEMPLATE_STATUS.active);
    this.statesChangerService.createState(TEMPLATE_STATUS.archive);
  }

  bindButtons() {
    this.statesChangerService.createButton(
      TEMPLATE_STATUS.draft,
      'edit',
      this.onEdit.bind(this)
    );
    this.statesChangerService.createButton(
      TEMPLATE_STATUS.draft,
      'delete',
      this.onDelete.bind(this)
    );
    this.statesChangerService.createButton(
      TEMPLATE_STATUS.active,
      'delete',
      this.onDelete.bind(this)
    );
    this.statesChangerService.createButton(
      'edit',
      'save',
      this.onSave.bind(this)
    );
    this.statesChangerService.createButton(
      'edit',
      'cancel',
      this.onCancel.bind(this)
    );

    this.statesChangerService.createButton(
      TEMPLATE_STATUS.draft,
      'active',
      () => this.changeState(TEMPLATE_STATUS.active)
    );
  }

  changeState(state: TEMPLATE_STATUS) {
    this.selectedTemplate.state = state;
    this.onSave();
  }

  onEdit() {
    this.statesChangerService.changeState('edit');
  }

  onDelete() {
    if (!this.selectedTemplate) return;

    this.templateApiService
      .checkSchedules$(this.selectedTemplate.id)
      .pipe(
        catchError(() => of([])),
        concatMap(
          (values: any) =>
            new Promise((resolve, reject) => {
              if (Array.isArray(values) && !!values.length) {
                values = (<any[]>values).join('</li><li>');
                values = `
            <div>Шаблон участвует в расписаниях:</div>
            <ul>
              <li>${values}</li>
            </ul>`;
              } else {
                values = '';
              }

              this.confirmActionService.confirm(
                `
            <div>Вы действительно хотите удалить шаблон '<span class="fw-bolder">${this.selectedTemplate.name}</span>'?</div>
            ${values}
          `,
                resolve,
                reject
              );
            })
        )
      )
      .subscribe(d => {
        this.templateApiService
          .delete$(this.selectedTemplate.id)
          .subscribe(() => {
            this.$state.go('^.list');
          });
      });
  }

  onCarouselMove(myCarousel: NguCarousel<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
  }

  subscribeOnDataChange() {
    this.templateCore.events.on('change', data => {
      this.selectedTemplate.params = <any>this.templateCore.dataFields;
      this.updateFiles();
    });
  }

  updateFiles() {
    this.selectedTemplate.files = [];
    (<TemplateCoreClass>this.templateCore).fieldsArray.forEach(field => {
      this.selectedTemplate.files = this.selectedTemplate.files.concat(
        this.getFilesForField(<any>field)
      );
    });

    this.selectedTemplate.files = [...new Set(this.selectedTemplate.files)];
  }

  getFilesForField(field: TemplateFieldClass) {
    if (typeof field.value === 'undefined' || field.value === '') return [];

    switch (field.type) {
      case FieldTypes.file:
        return field.value >= 0 ? ['' + field.value] : [];

      case FieldTypes.blockBackground:
        let res = [
          ...field.value.matchAll(/url\([\s|'|"]*(.*?)[\s|'|"]*\)/gim),
        ];
        return !res || !res.length ? [] : res.map(i => i[1]);
    }

    return [];
  }

  getLayouts() {
    this.layoutItems.items = [];
    this.layoutApiService
      .query$()
      .pipe(this.layoutItems.loadingOperator)
      .subscribe(i => {
        this.layoutItems.items = i;
      });
  }

  getTemplate(id: number) {
    this.templateApiService.getOrInit$(id).subscribe((i: any) => {
      this.selectedTemplate = i;

      if (typeof i.id === 'undefined') {
        this.statesChangerService.changeState('edit');
      } else {
        this.statesChangerService.changeState(i.state);
      }

      this.updateTemplateView();
    });
  }

  updateTemplateView() {
    this.coreViewUpdate();
  }

  coreViewUpdate() {
    this.templateCore.update(
      this.selectedTemplate.content,
      Object.assign({}, this.selectedTemplate.params)
    );
    this.updateFiles();
  }

  coreViewWithData() {
    this.testedData.index++;
    if (this.testedData.index >= this.testedData.items.length) {
      this.testedData.index = 0;
    }

    this.templateCore.update(
      this.selectedTemplate.content,
      Object.assign(
        {},
        this.selectedTemplate.params,
        this.testedData.items[this.testedData.index]
      )
    );
  }

  ngAfterViewInit() {
    this.addTemplateElement();
  }

  private addTemplateElement() {
    this.templateContainer.nativeElement.innerHTML = '';
    this.templateContainer.nativeElement.appendChild(this.templateCore.element);
  }

  public setLayout(item) {
    this.selectedTemplate.layoutId = item.id;
    this.layoutApiService.get$(this.selectedTemplate.layoutId).subscribe(i => {
      this.selectedTemplate.content = i.content;
      this.updateTemplateView();
    });
  }

  setViewRation(value) {
    this.viewRatio = value;
  }

  saveTemplate() {
    return this.templateApiService
      .update$(this.selectedTemplate)
      .toPromise()
      .then((result: any) => {
        this.selectedTemplate = result;
        this.$state.go('^.view', { id: result.id }, { reload: true });
      });
  }

  previewTestData() {
    if (!this.testedData.items.length)
      this.templateApiService.getTestdata$().subscribe((data: any) => {
        this.testedData.csv = false;
        this.testedData.items = data;
        this.testedData.errorsCount = 0;
        this.coreViewWithData();
      });
    else this.coreViewWithData();
  }

  previewCsvData() {
    this.fileApiService.uploadCsvToJson().then(
      result => {
        if (Array.isArray(result)) {
          this.testedData.errorsCount = result.filter(i => i.error)?.length;
          result = result.filter(i => !i.error);
        }

        this.testedData.items = <any>result;
        this.testedData.csv = true;
        this.coreViewWithData();
      },
      () => {}
    );
  }

  resetTestData() {
    this.testedData.index = -1;
    this.testedData.csv = false;
    this.testedData.items = [];
    this.testedData.errorsCount = 0;
    this.previewTestData();
  }

  uploadFile(field) {
    this.filesSelectorService
      .get({
        hideTemplates: true,
      })
      .toPromise()
      .then(
        result => {
          field.value = result.data.id;
        },
        () => {}
      );
  }

  saveTag = (id, tags): any => {
    return this.tagApiService
      .assignToTemplate$(tags || [], id)
      .subscribe(res => {});
  };

  getTag = (id): any => {
    return this.tagApiService.getFromTemplate$(id);
  };

  async onSave() {
    await this.saveTemplate();
  }

  onCancel() {
    this.$state.go('^.list');
  }
}
