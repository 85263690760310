import { Inject, Injectable, Injector } from '@angular/core';
import { UserApiService } from '../api/user-api/user-api.service';
import { createInstance } from 'localforage';
import sessionStorageWrapper from 'localforage-sessionstoragewrapper';
import { LAST_PARTNER_ID } from './local-storage.values';
import {PartnerApiService} from "../api/partner-api/partner-api.service";

const INSTANCE_PREFIX = 'ds_';
const DEFAULT_INSTANCE = 'db';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  localStorage: Storage;
  instances: { [key: string]: LocalForage } = {};
  instancesSession: { [key: string]: LocalForage } = {};

  private sessionFlag = false;
  get isSessionFlag() {
    if (this.sessionFlag) {
      this.sessionFlag = false;
      return true;
    }

    return false;
  }

  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private getKeyForUser(key) {
    const userService = this.injector.get(UserApiService);
    const partnerApiService = this.injector.get(PartnerApiService);

    if (key === LAST_PARTNER_ID)
      return `[${'partner'}_${userService.currentUser?.id}_${
        userService.currentUser?.email
      }]${key}`;
    else
      return `[${partnerApiService.currentPartnerId || 'root'}_${
        userService.currentUser?.id
      }_${userService.currentUser?.email}]${key}`;
  }

  private async getInstance(instanceName, isSessionStorage = false) {
    if (isSessionStorage) return this.getSessionInstance(instanceName);

    if (this.instances[instanceName]) return this.instances[instanceName];

    this.instances[instanceName] = createInstance({
      name: INSTANCE_PREFIX + instanceName,
    });

    return this.instances[instanceName];
  }

  private async getSessionInstance(instanceName) {
    if (this.instancesSession[instanceName])
      return this.instancesSession[instanceName];

    this.instancesSession[instanceName] = createInstance({
      name: INSTANCE_PREFIX + instanceName,
    });

    await this.instancesSession[instanceName].defineDriver(
      sessionStorageWrapper
    );
    await this.instancesSession[instanceName].setDriver(
      sessionStorageWrapper._driver
    );

    return this.instancesSession[instanceName];
  }

  session() {
    this.sessionFlag = true;
    return this;
  }

  async get(key: string, instanceName = DEFAULT_INSTANCE) {
    let instance = await this.getInstance(instanceName, this.isSessionFlag);

    try {
      return instance.getItem(this.getKeyForUser(key));
    } catch (e) {
      return undefined;
    }
  }

  async set(key: string, value: any, instanceName = DEFAULT_INSTANCE) {
    let instance = await this.getInstance(instanceName, this.isSessionFlag);

    return instance.setItem(this.getKeyForUser(key), value);
  }

  async remove(key: string, instanceName = DEFAULT_INSTANCE) {
    let instance = await this.getInstance(instanceName, this.isSessionFlag);

    return instance.removeItem(this.getKeyForUser(key));
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
