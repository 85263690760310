import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesViewPageComponent } from './templates-view-page.component';
import { UIRouterModule } from '@uirouter/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CoreComponentsModule } from '../../../../core/components/core.components.module';
import { CorePipesModule } from '../../../../core/pipe/core-pipes.module';
import { NguCarouselModule } from '@ngu/carousel';
import { TemplateFieldsComponent } from '../components/template-fields/template-fields.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BlockFontComponent } from '../components/block-font/block-font.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { BlockBackgroundComponent } from '../components/block-background/block-background.component';
import { APP_ROUTES } from '../../../../../app-routes.variables';
import { CoreDirectiveModule } from '../../../../core/directive/core-directive.module';
import { DsLinkModule } from '../../../../core/directive/ds-link/ds-link.module';

@NgModule({
  declarations: [
    TemplatesViewPageComponent,
    TemplateFieldsComponent,
    BlockFontComponent,
    BlockBackgroundComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: APP_ROUTES['root.templates.view'].name,
          url: APP_ROUTES['root.templates.view'].url,

          component: TemplatesViewPageComponent,
          params: {
            id: '',
          },
          data: {
            breadcrumbs: [$localize`:|@@templates-view-page.templates:Шаблоны`],
          },
        },
      ],
    }),
    CoreComponentsModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    FormsModule,
    NgbPopoverModule,
    CorePipesModule,
    NguCarouselModule,
    NgbAccordionModule,
    FontAwesomeModule,
    ColorPickerModule,
    CoreDirectiveModule,
    DsLinkModule,
  ],
})
export class TemplatesViewPageModule {}
