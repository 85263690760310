/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListWithTotalPlayerApi } from '../models/list-with-total-player-api';
import { ListWithTotalScheduleQueryResultApi } from '../models/list-with-total-schedule-query-result-api';
import { ListWithTotalTranscodingByPlatformDetailsApi } from '../models/list-with-total-transcoding-by-platform-details-api';
import { ListWithTotalTranscodingByPlayerApi } from '../models/list-with-total-transcoding-by-player-api';
import { PlayerFilterApi } from '../models/player-filter-api';
import { PlayerList } from '../models/player-list';
import { PlayerRequestApi } from '../models/player-request-api';
import { ScheduleApi } from '../models/schedule-api';
import { ScheduleCreateApi } from '../models/schedule-create-api';
import { ScheduleRequestApi } from '../models/schedule-request-api';
import { ScheduleStatApi } from '../models/schedule-stat-api';
import { TranscodingByPlatformApi } from '../models/transcoding-by-platform-api';
import { TranscodingByPlatformDetailsRequestApi } from '../models/transcoding-by-platform-details-request-api';
import { TranscodingByPlayerRequestApi } from '../models/transcoding-by-player-request-api';

@Injectable({
  providedIn: 'root',
})
export class ScheduleApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridSchedule
   */
  static readonly PostPartnerPartneridSchedulePath = '/partner/{partnerId}/schedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridSchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridSchedule$Response(params: {
    partnerId: number;
    body: ScheduleCreateApi
  }): Observable<StrictHttpResponse<ScheduleApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridSchedulePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridSchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridSchedule(params: {
    partnerId: number;
    body: ScheduleCreateApi
  }): Observable<ScheduleApi> {

    return this.postPartnerPartneridSchedule$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleApi>) => r.body as ScheduleApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleIdAutoadd
   */
  static readonly PostPartnerPartneridScheduleIdAutoaddPath = '/partner/{partnerId}/schedule/{id}/autoadd';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleIdAutoadd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdAutoadd$Response(params: {
    partnerId: number;
    id: number;
    body?: Array<PlayerFilterApi>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleIdAutoaddPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleIdAutoadd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdAutoadd(params: {
    partnerId: number;
    id: number;
    body?: Array<PlayerFilterApi>
  }): Observable<void> {

    return this.postPartnerPartneridScheduleIdAutoadd$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridScheduleIdClone
   */
  static readonly GetPartnerPartneridScheduleIdClonePath = '/partner/{partnerId}/schedule/{id}/clone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridScheduleIdClone()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdClone$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<ScheduleApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.GetPartnerPartneridScheduleIdClonePath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridScheduleIdClone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdClone(params: {
    partnerId: number;
    id: number;
  }): Observable<ScheduleApi> {

    return this.getPartnerPartneridScheduleIdClone$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleApi>) => r.body as ScheduleApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleQuery
   */
  static readonly PostPartnerPartneridScheduleQueryPath = '/partner/{partnerId}/schedule/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleQuery$Response(params: {
    partnerId: number;
    body: ScheduleRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalScheduleQueryResultApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalScheduleQueryResultApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleQuery(params: {
    partnerId: number;
    body: ScheduleRequestApi
  }): Observable<ListWithTotalScheduleQueryResultApi> {

    return this.postPartnerPartneridScheduleQuery$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalScheduleQueryResultApi>) => r.body as ListWithTotalScheduleQueryResultApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridScheduleId
   */
  static readonly GetPartnerPartneridScheduleIdPath = '/partner/{partnerId}/schedule/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridScheduleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<ScheduleApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.GetPartnerPartneridScheduleIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridScheduleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleId(params: {
    partnerId: number;
    id: number;
  }): Observable<ScheduleApi> {

    return this.getPartnerPartneridScheduleId$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleApi>) => r.body as ScheduleApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridScheduleId
   */
  static readonly PutPartnerPartneridScheduleIdPath = '/partner/{partnerId}/schedule/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridScheduleId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridScheduleId$Response(params: {
    partnerId: number;
    id: number;
    body: ScheduleCreateApi
  }): Observable<StrictHttpResponse<ScheduleApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PutPartnerPartneridScheduleIdPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridScheduleId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridScheduleId(params: {
    partnerId: number;
    id: number;
    body: ScheduleCreateApi
  }): Observable<ScheduleApi> {

    return this.putPartnerPartneridScheduleId$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleApi>) => r.body as ScheduleApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridScheduleId
   */
  static readonly DeletePartnerPartneridScheduleIdPath = '/partner/{partnerId}/schedule/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridScheduleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridScheduleId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.DeletePartnerPartneridScheduleIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridScheduleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridScheduleId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridScheduleId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation putPartnerPartneridScheduleIdState
   */
  static readonly PutPartnerPartneridScheduleIdStatePath = '/partner/{partnerId}/schedule/{id}/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridScheduleIdState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridScheduleIdState$Response(params: {
    partnerId: number;
    id: number;
    body: string
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PutPartnerPartneridScheduleIdStatePath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridScheduleIdState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridScheduleIdState(params: {
    partnerId: number;
    id: number;
    body: string
  }): Observable<void> {

    return this.putPartnerPartneridScheduleIdState$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridScheduleIdTranscodingState
   */
  static readonly GetPartnerPartneridScheduleIdTranscodingStatePath = '/partner/{partnerId}/schedule/{id}/transcoding/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridScheduleIdTranscodingState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdTranscodingState$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<'none' | 'running'>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.GetPartnerPartneridScheduleIdTranscodingStatePath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<'none' | 'running'>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridScheduleIdTranscodingState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdTranscodingState(params: {
    partnerId: number;
    id: number;
  }): Observable<'none' | 'running'> {

    return this.getPartnerPartneridScheduleIdTranscodingState$Response(params).pipe(
      map((r: StrictHttpResponse<'none' | 'running'>) => r.body as 'none' | 'running')
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleTranscodingbyplayer
   */
  static readonly PostPartnerPartneridScheduleTranscodingbyplayerPath = '/partner/{partnerId}/schedule/transcodingByPlayer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleTranscodingbyplayer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleTranscodingbyplayer$Response(params: {
    partnerId: number;
    body: TranscodingByPlayerRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalTranscodingByPlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleTranscodingbyplayerPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalTranscodingByPlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleTranscodingbyplayer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleTranscodingbyplayer(params: {
    partnerId: number;
    body: TranscodingByPlayerRequestApi
  }): Observable<ListWithTotalTranscodingByPlayerApi> {

    return this.postPartnerPartneridScheduleTranscodingbyplayer$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalTranscodingByPlayerApi>) => r.body as ListWithTotalTranscodingByPlayerApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridScheduleIdTranscodingbyplatform
   */
  static readonly GetPartnerPartneridScheduleIdTranscodingbyplatformPath = '/partner/{partnerId}/schedule/{id}/transcodingByPlatform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridScheduleIdTranscodingbyplatform()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdTranscodingbyplatform$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<Array<TranscodingByPlatformApi>>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.GetPartnerPartneridScheduleIdTranscodingbyplatformPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TranscodingByPlatformApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridScheduleIdTranscodingbyplatform$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleIdTranscodingbyplatform(params: {
    partnerId: number;
    id: number;
  }): Observable<Array<TranscodingByPlatformApi>> {

    return this.getPartnerPartneridScheduleIdTranscodingbyplatform$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TranscodingByPlatformApi>>) => r.body as Array<TranscodingByPlatformApi>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid
   */
  static readonly PostPartnerPartneridScheduleIdTranscodingbyplatformPlatformidPath = '/partner/{partnerId}/schedule/{id}/transcodingByPlatform/{platformId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid$Response(params: {
    partnerId: number;
    id: number;
    platformId: number;
    body: TranscodingByPlatformDetailsRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalTranscodingByPlatformDetailsApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleIdTranscodingbyplatformPlatformidPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.path('platformId', params.platformId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalTranscodingByPlatformDetailsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid(params: {
    partnerId: number;
    id: number;
    platformId: number;
    body: TranscodingByPlatformDetailsRequestApi
  }): Observable<ListWithTotalTranscodingByPlatformDetailsApi> {

    return this.postPartnerPartneridScheduleIdTranscodingbyplatformPlatformid$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalTranscodingByPlatformDetailsApi>) => r.body as ListWithTotalTranscodingByPlatformDetailsApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridScheduleStat
   */
  static readonly GetPartnerPartneridScheduleStatPath = '/partner/{partnerId}/schedule/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridScheduleStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleStat$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<ScheduleStatApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.GetPartnerPartneridScheduleStatPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleStatApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridScheduleStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridScheduleStat(params: {
    partnerId: number;
  }): Observable<ScheduleStatApi> {

    return this.getPartnerPartneridScheduleStat$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleStatApi>) => r.body as ScheduleStatApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleIdPlayers
   */
  static readonly PostPartnerPartneridScheduleIdPlayersPath = '/partner/{partnerId}/schedule/{id}/players';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleIdPlayers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdPlayers$Response(params: {
    partnerId: number;
    id: number;
    body: PlayerList
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleIdPlayersPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleIdPlayers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdPlayers(params: {
    partnerId: number;
    id: number;
    body: PlayerList
  }): Observable<void> {

    return this.postPartnerPartneridScheduleIdPlayers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postPartnerPartneridScheduleIdPlayersQuery
   */
  static readonly PostPartnerPartneridScheduleIdPlayersQueryPath = '/partner/{partnerId}/schedule/{id}/players/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridScheduleIdPlayersQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdPlayersQuery$Response(params: {
    partnerId: number;
    id: number;
    body: PlayerRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalPlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleApiService.PostPartnerPartneridScheduleIdPlayersQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalPlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridScheduleIdPlayersQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridScheduleIdPlayersQuery(params: {
    partnerId: number;
    id: number;
    body: PlayerRequestApi
  }): Observable<ListWithTotalPlayerApi> {

    return this.postPartnerPartneridScheduleIdPlayersQuery$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalPlayerApi>) => r.body as ListWithTotalPlayerApi)
    );
  }

}
