/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CatalogApi } from '../models/catalog-api';
import { CatalogCreateRequest } from '../models/catalog-create-request';
import { CatalogGetApi } from '../models/catalog-get-api';
import { CatalogQueryApi } from '../models/catalog-query-api';
import { CatalogUpdateRequest } from '../models/catalog-update-request';
import { CatalogUseApi } from '../models/catalog-use-api';

@Injectable({
  providedIn: 'root',
})
export class CatalogApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridCatalog
   */
  static readonly PostPartnerPartneridCatalogPath = '/partner/{partnerId}/catalog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCatalog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalog$Response(params: {
    partnerId: number;
    body: CatalogCreateRequest
  }): Observable<StrictHttpResponse<CatalogQueryApi>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.PostPartnerPartneridCatalogPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatalogQueryApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCatalog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalog(params: {
    partnerId: number;
    body: CatalogCreateRequest
  }): Observable<CatalogQueryApi> {

    return this.postPartnerPartneridCatalog$Response(params).pipe(
      map((r: StrictHttpResponse<CatalogQueryApi>) => r.body as CatalogQueryApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridCatalogQuery
   */
  static readonly PostPartnerPartneridCatalogQueryPath = '/partner/{partnerId}/catalog/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCatalogQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogQuery$Response(params: {
    partnerId: number;
    body: CatalogGetApi
  }): Observable<StrictHttpResponse<CatalogApi>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.PostPartnerPartneridCatalogQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatalogApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCatalogQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogQuery(params: {
    partnerId: number;
    body: CatalogGetApi
  }): Observable<CatalogApi> {

    return this.postPartnerPartneridCatalogQuery$Response(params).pipe(
      map((r: StrictHttpResponse<CatalogApi>) => r.body as CatalogApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridCatalogId
   */
  static readonly PostPartnerPartneridCatalogIdPath = '/partner/{partnerId}/catalog/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCatalogId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogId$Response(params: {
    partnerId: number;
    id: number;
    body: CatalogGetApi
  }): Observable<StrictHttpResponse<CatalogApi>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.PostPartnerPartneridCatalogIdPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatalogApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCatalogId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogId(params: {
    partnerId: number;
    id: number;
    body: CatalogGetApi
  }): Observable<CatalogApi> {

    return this.postPartnerPartneridCatalogId$Response(params).pipe(
      map((r: StrictHttpResponse<CatalogApi>) => r.body as CatalogApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridCatalogId
   */
  static readonly DeletePartnerPartneridCatalogIdPath = '/partner/{partnerId}/catalog/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridCatalogId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridCatalogId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.DeletePartnerPartneridCatalogIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridCatalogId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridCatalogId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridCatalogId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postPartnerPartneridCatalogIdName
   */
  static readonly PostPartnerPartneridCatalogIdNamePath = '/partner/{partnerId}/catalog/{id}/name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCatalogIdName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogIdName$Response(params: {
    partnerId: number;
    id: number;
    body: CatalogUpdateRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.PostPartnerPartneridCatalogIdNamePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCatalogIdName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogIdName(params: {
    partnerId: number;
    id: number;
    body: CatalogUpdateRequest
  }): Observable<void> {

    return this.postPartnerPartneridCatalogIdName$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postPartnerPartneridCatalogIdParentid
   */
  static readonly PostPartnerPartneridCatalogIdParentidPath = '/partner/{partnerId}/catalog/{id}/parentId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCatalogIdParentid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogIdParentid$Response(params: {
    partnerId: number;
    id: number;
    body: CatalogUpdateRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.PostPartnerPartneridCatalogIdParentidPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCatalogIdParentid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCatalogIdParentid(params: {
    partnerId: number;
    id: number;
    body: CatalogUpdateRequest
  }): Observable<void> {

    return this.postPartnerPartneridCatalogIdParentid$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridCatalogIdCheck
   */
  static readonly GetPartnerPartneridCatalogIdCheckPath = '/partner/{partnerId}/catalog/{id}/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridCatalogIdCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCatalogIdCheck$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<Array<CatalogUseApi>>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogApiService.GetPartnerPartneridCatalogIdCheckPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CatalogUseApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridCatalogIdCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCatalogIdCheck(params: {
    partnerId: number;
    id: number;
  }): Observable<Array<CatalogUseApi>> {

    return this.getPartnerPartneridCatalogIdCheck$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CatalogUseApi>>) => r.body as Array<CatalogUseApi>)
    );
  }

}
