import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { ApiService, FileUpdateApi } from '../generated/ContentFile';

@Injectable()
export class FileApiService {
  constructor(
    private http: HttpClient,
    private partnerApiService: PartnerApiService,
    private contentFileApi: ApiService
  ) {}

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 256,
    },
    description: {
      required: false,
      maxlength: 256,
    },
  });

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.http.get(`/partner/${partnerId}/file/${id}`, {
      headers: {
        Accept: 'application/json;q=0.9,text/plain',
      },
      responseType: 'json',
    });
  }

  getInfo$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.http.get(`/partner/${partnerId}/file/${id}/info`, {
      headers: {
        Accept: 'application/json;q=0.9,text/plain',
      },
      responseType: 'json',
    });
  }

  upload$(
    inputFile: any,
    catalogId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    const formData = new FormData();
    formData.append('file', inputFile);

    return this.http.put(
      `/partner/${partnerId}/file/catalogId/${catalogId}/name/${inputFile.name}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  update$(
    fileName,
    body: FileUpdateApi = {},
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.contentFileApi.postPartnerPartneridFileFilenameFilenameUpdate({
      fileName: fileName,
      partnerId: partnerId,
      body: body,
    });
  }

  delete$(fileName: any, partnerId = this.partnerApiService.currentPartnerId) {
    return this.http.delete(`/partner/${partnerId}/file/${fileName}/delete`);
  }

  checkSchedules$(
    fileId: any,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.http.get(`/partner/${partnerId}/fileId/${fileId}`);
  }

  getUrl(fileId: string, partnerId = this.partnerApiService.currentPartnerId) {
    return `/partner/${partnerId}/file/${fileId}`;
  }

  fixPartnerUrl(
    url: string = '',
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof url !== 'string') return url;

    return url.replace(/\/partner\/[1-9]+/i, `/partner/${partnerId}`);
  }

  getUrlByName(
    filename: string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return `/partner/${partnerId}/file/${filename}`;
  }

  getPreview(
    fileId: string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return `/partner/${partnerId}/file/previewfile/${fileId}`;
  }

  uploadCsvToJson(partnerId = this.partnerApiService.currentPartnerId) {
    let resolve, reject;

    let input: any = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = function () {
      let file = input.files[0];

      var formData = new FormData();
      formData.append('file', file, file.filename);

      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (xhr.status != 200) {
          reject(xhr.statusText);
        } else {
          try {
            resolve(JSON.parse(xhr.response));
          } catch (e) {
            reject(e);
          }
        }
      };

      xhr.onerror = function () {
        reject();
      };

      xhr.open('PUT', `/partner/${partnerId}/csvfile`);
      xhr.send(formData);
    };

    input.click();

    return new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
  }
}
