export class FileUtilsClass {
  static isImage(mimeType = '') {
    return mimeType.startsWith('image/');
    // ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
  }

  static isVideo(mimeType = '') {
    return mimeType.startsWith('video/');
    //((/\.(gmp4|mkv|wmv|mp4|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i).test(name))
  }
}
