import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { ApiService, PlayerRequestApi } from '../generated/ContentFile/index';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ContentFileApiService {
  constructor(
    private http: HttpClient,
    private partnerApiService: PartnerApiService,
    private contentFileApi: ApiService,
    localStorageService: LocalStorageService
  ) {
    this.queryPlayersParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'platformTypes',
            values: {
              ukm4: 'ukm4',
              ukm5: 'ukm5',
            },
            currentValue: undefined,
          },
          {
            field: 'locationIds',
            currentValue: undefined,
          },
          {
            field: 'platforms',
            currentValue: undefined,
          },
          {
            field: 'states',
            currentValue: undefined,
          },
          {
            field: 'labels',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );
  }

  public queryPlayersParams: ParamsHandler;

  savePlayersId$(
    fileId: number,
    body: Array<number> | undefined,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.contentFileApi.postPartnerPartneridFileFileidSettings({
      fileId,
      partnerId,
      body,
    });
  }

  getPlayersId$(
    fileId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.queryPlayers$(fileId, {
      paging: { offset: 0, limit: 1 },
    }).pipe(
      switchMap(result =>
        this.queryPlayers$(fileId, {
          paging: { offset: 0, limit: result.total },
        })
      ),
      map(result => result?.items?.map(i => i.id) || [])
    );
  }

  queryPlayers$(
    fileId: number,
    body: PlayerRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.contentFileApi.postPartnerPartneridFileFileidSettingsQuery({
      fileId,
      partnerId,
      body,
    });
  }

  queryLocations$(
    fileId: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.contentFileApi.getPartnerPartneridFileContentfileidActiveLocations(
      {
        partnerId,
        contentFileId: fileId,
      }
    );
  }
}
