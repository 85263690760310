<div
  class="dropdown d-inline-block"
  ngbDropdown
  #storeDropDown="ngbDropdown"
  container="body"
  [placement]="['bottom-end', 'top-end']"
  display="dynamic"
  [autoClose]="'outside'">
  <a href="javascript:void(0)" ngbDropdownToggle>
    <img
      [src]="
        'assets/images/flag/' + localL18nService.currentLanguage + '.svg'
      " />

    <div
      *ngIf="showFullName"
      class="ms-2 d-inline-block"
      [innerHTML]="LANGUAGES[localL18nService.currentLanguage].name"></div>
  </a>

  <ul class="list-unstyled card-option dropdown-menu" ngbDropdownMenu>
    <li
      class="dropdown-item h6 pt-2 pb-2"
      *ngFor="let key of localL18nService.supportedLanguages"
      (click)="selectLanguage(key); storeDropDown.close()">
      <a
        href="javascript:"
        [ngClass]="{
          'fw-bold  ': localL18nService.currentLanguage === key
        }">
        <img class="lg-flag h5" [src]="'assets/images/flag/' + key + '.svg'" />

        <span [innerHTML]="LANGUAGES[key].name"></span>
      </a>
    </li>
  </ul>
</div>
