import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import {
  SCHEDULE_PLAYER_ERRORS_NAMES,
  ScheduleApiService,
} from '../../../core/service/api/schedule-api/schedule-api.service';
import { LoadableItemClass } from '../../../core/class/loadable-item.class';
import { PLAYER_STATUS_NAMES } from '../../../core/service/api/player-api/variables';
import { TranscodingByPlatformApi } from '../../../core/service/api/generated/Schedule';
import Timeout = NodeJS.Timeout;
import { PlatformPopupService } from '../../../core/service/platformPopup/platformPopup.service';
import { LocationPopupService } from '../../../core/service/locationPopup/locationPopup.service';
import { LabelsBWPopupService } from '../../../core/service/labelsBWPopup/labelsBWPopup.service';
import { PlayerApiService } from '../../../core/service/api/player-api/player-api.service';
import { LocationApiService } from '../../../core/service/api/location-api/location-api.service';
import { StateService } from '@uirouter/core';
import { LabelApiService } from '../../../core/service/api/label-api/label-api.service';
import { PLATFORM_NAMES } from '../../../core/service/api/platform-api/variables';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

const ID_STORAGE = 'playersPlayersPopup';

@Component({
  selector: 'players-popup',
  templateUrl: './players-popup.component.html',
  styleUrls: ['./playersPopup.service.scss'],
  providers: [
    PlayerApiService,
    LocationApiService,
    LabelApiService,
    LocationPopupService,
    LabelsBWPopupService,
    PlatformPopupService,
  ],
})
export class PlayersPopupComponent implements OnInit {
  public playerItems: LoadableItemClass<any> = new LoadableItemClass();
  public locationsList: LoadableItemClass<any> = new LoadableItemClass();

  public totalItems = 0;

  public PLATFORM_NAMES = PLATFORM_NAMES;
  public PLAYER_STATUS_NAMES = PLAYER_STATUS_NAMES;

  public locationNames = new Map();
  public platformNames = new Map();

  public selected = new Set();

  public checkAllLoading = false;

  constructor(
    public playerApiService: PlayerApiService,
    private locationApiService: LocationApiService,
    public $state: StateService,
    public labelApiService: LabelApiService,
    public locationPopupService: LocationPopupService,
    public labelsBWPopupService: LabelsBWPopupService,
    public activeModal: NgbActiveModal,
    public platformPopupService: PlatformPopupService
  ) {}

  async ngOnInit() {}

  async loadParams(params) {
    this.playerItems.items = [];

    await this.loadPastParams();
    this.loadItems();
    this.getLocations();
  }

  async loadPastParams() {
    // await this.playerApiService.queryParams.loadFromStorage(ID_STORAGE);

    /*

    if ( !this.playerApiService.queryParams.getFilterValue('platformTypes') )
      this.playerApiService.queryParams.setFilterValue('platformTypes', Object.keys(PLATFORM_NAMES))
*/
    /*

    if ( typeof this.playerApiService.queryParams.getFilterValue('labels') === "undefined" )
      this.playerApiService.queryParams.setFilterValue('labels', [])
*/

    if (
      typeof this.playerApiService.queryParams.getFilterValue('states') ===
      'undefined'
    )
      this.playerApiService.queryParams.setFilterValue(
        'states',
        Object.keys(PLAYER_STATUS_NAMES)
      );

    this.playerApiService.queryParams.reset();
  }

  loadItems() {
    this.playerApiService
      .query$(this.playerApiService.queryParams.params)
      .pipe(this.playerItems.loadingOperator)
      .subscribe((result: any) => {
        this.totalItems = result.total;
        this.playerItems.addItems(result.items);
      });
  }

  onSearchChange($event) {
    this.playerApiService.queryParams.search($event);
    this.resetPages();
  }

  nextPage() {
    this.playerApiService.queryParams.next();
    this.loadItems();
  }

  resetPages() {
    this.playerApiService.queryParams.reset();
    //   this.playerApiService.queryParams.saveToStorage(ID_STORAGE);
    this.playerItems.items = [];
    this.loadItems();
  }

  getLocations() {
    this.locationApiService.query$().subscribe(result => {
      this.locationsList.addItems(result);
    });
  }

  findLabels = (tagsString): any => {
    return this.labelApiService.find$(tagsString);
  };

  addLocationsFilter() {
    this.locationPopupService.open().then(
      (result = []) => {
        let currentArray =
          this.playerApiService.queryParams.getFilterValue('locationIds') || [];
        result.forEach(i => {
          this.locationNames.set(i.id, i.name);
          if (currentArray.indexOf(i.id) === -1) currentArray.push(i.id);
        });

        this.playerApiService.queryParams.setFilterValue(
          'locationIds',
          currentArray.length ? currentArray : undefined
        );
        this.resetPages();
      },
      () => {}
    );
  }

  removeFromFilter(filterIdm, id, skipReset = false) {
    let currentArray =
      this.playerApiService.queryParams.getFilterValue(filterIdm);
    if (!currentArray) return;

    currentArray = currentArray.filter(i => i !== id);
    this.playerApiService.queryParams.setFilterValue(
      filterIdm,
      currentArray.length ? currentArray : undefined
    );

    if (!skipReset) this.resetPages();
  }

  public addLabels() {
    this.labelsBWPopupService
      .open({
        model: this.playerApiService.queryParams.getFilterValue('labels') || [],
        canEmptySelect: true,
      })
      .then(
        result => {
          if (!!result)
            this.playerApiService.queryParams.setFilterValue('labels', result);

          this.resetPages();
        },
        () => {}
      );
  }

  //---------------

  isAllChecked() {
    if (
      this.selected.size === 0 ||
      this.selected.size < this.playerItems.items.length
    )
      return false;

    return !this.playerItems.items.some(i => !this.selected.has(i.id));
  }

  checkAll(value) {
    this.checkAllLoading = true;

    this.playerApiService
      .query$(
        Object.assign({}, this.playerApiService.queryParams.params, {
          paging: {
            limit: this.totalItems,
            offset: 0,
          },
        })
      )
      .subscribe(
        result => {
          this.checkAllLoading = false;
          result.items?.forEach(i => {
            if (value.target.checked) {
              this.selected.add(i.id);
            } else {
              this.selected.delete(i.id);
            }
          });
        },
        error => (this.checkAllLoading = false)
      );
  }

  check(id) {
    if (!this.selected.has(id)) this.selected.add(id);
    else this.selected.delete(id);
  }

  okEvent() {
    this.activeModal.close(Array.from(this.selected));
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  public addPlatforms() {
    this.platformPopupService
      .open({
        model:
          this.playerApiService.queryParams.getFilterValue('platforms') || [],
      })
      .then(
        result => {
          if (typeof result === 'undefined') return;

          result.forEach(i => {
            this.platformNames.set(i.id, i.name);
          });

          this.playerApiService.queryParams.setFilterValue(
            'platforms',
            result.map(i => i.id)
          );

          this.resetPages();
        },
        () => {}
      );
  }

  //------------

  initPlayers(players = []) {
    if (!Array.isArray(players)) {
      return;
    }

    players.forEach((i: any) => {
      Number.isInteger(i) && this.selected.add(i);
      typeof i === 'object' && i.id >= 0 && this.selected.add(i.id);
    });
  }
}

@Injectable()
export class PlayersPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(PlayersPopupComponent, {
      modalDialogClass: 'modal-dialog-players-popup',
      size: 'xl',
    });
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
