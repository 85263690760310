import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { LayoutApiService as LayoutApiServiceGen } from '../generated/Layout';
import { ValidateFieldClass } from '../../../class/validate-field.class';

@Injectable()
export class LayoutApiService {
  constructor(
    private layoutAPIService: LayoutApiServiceGen,
    private partnerApiService: PartnerApiService
  ) {}

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 50,
    },
    fileName: {
      required: true,
      maxlength: 256,
    },
    content: {
      required: false,
    },
  });

  query$(partnerId = this.partnerApiService.currentPartnerId) {
    return this.layoutAPIService
      .getPartnerPartneridLayout({
        partnerId: partnerId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@layout-api_service.error-getting-layout-list:Ошибка получения списка Layout `
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.layoutAPIService
      .getPartnerPartneridLayoutId({
        partnerId: partnerId,
        id: id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@layout-api_service.layout-not-found:Layout не найден`
          );
          return throwError(error);
        })
      );
  }
}
