import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnChanges,
} from '@angular/core';
import { PermissionsService } from '../../service/permissions/permissions.service';
import { PERMISSION_TYPES } from '../../service/permissions/permissions.enum';

@Directive({
  selector: '[ifPermission]',
})
export class IfPermissionDirective<T = unknown> implements OnInit, OnChanges {
  private _thenTemplateRef: TemplateRef<NgIfContext<T>> | null = null;
  private path: string;
  private type: PERMISSION_TYPES;
  private invert = false;

  private lastPermission: boolean;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<T>>,
    private el: ElementRef,

    private permissionsService: PermissionsService
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set ifPermission(values: T) {
    switch (true) {
      case typeof values === 'string' || typeof values === 'boolean':
        this.path = <any>values;
        break;
      case typeof values === 'object':
        Object.assign(this, values);
        break;
    }

    this._updateView();
  }

  private _updateView() {
    let currentPermission;
    if (!this.type) {
      currentPermission = this.permissionsService.enabledByPath(this.path);
    } else {
      currentPermission = this.permissionsService.byType(this.path, this.type);
    }

    if (this.invert) {
      currentPermission = !currentPermission;
    }

    if (this.lastPermission === currentPermission) {
      return;
    }

    this.lastPermission = currentPermission;
    if (currentPermission && this._thenTemplateRef) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._thenTemplateRef);
    } else {
      this._viewContainer.clear();
    }
  }

  ngOnInit() {}

  ngOnChanges() {
    setTimeout(() => this._updateView(), 0);
  }
}

export class NgIfContext<T = unknown> {
  public $implicit: T = null!;
  public ngIf: T = null!;
}
