import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesPageComponent } from './files-page.component';
import { UIRouterModule } from '@uirouter/angular';
import { CoreComponentsModule } from '../../../core/components/core.components.module';
import { FileTreeComponent } from './components/file-tree/file-tree.component';
import { FileTableViewComponent } from './components/file-table-view/file-table-view.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TreeModule } from '@circlon/angular-tree-component';
import { CorePipesModule } from '../../../core/pipe/core-pipes.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopover,
  NgbPopoverModule,
  NgbProgressbarModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  FileSettingsComponent,
  FileSettingsPopupService,
} from './components/file-settings/file-settings.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileActiveLocationsComponent } from './components/file-active-locations/file-active-locations.component';
import { APP_ROUTES } from '../../../../app-routes.variables';
import { CoreDirectiveModule } from '../../../core/directive/core-directive.module';
import { DsLinkModule } from '../../../core/directive/ds-link/ds-link.module';
import {ResponsiveTableModule} from "../../../core/components/responsive-table/responsive-table.module";

@NgModule({
  declarations: [
    FilesPageComponent,
    FileTreeComponent,
    FileTableViewComponent,
    FileSettingsComponent,
    FileActiveLocationsComponent,
  ],
  providers: [FileSettingsPopupService],
    imports: [
        CommonModule,
        TreeModule,
        FormsModule,
        CorePipesModule,
        FontAwesomeModule,
        UIRouterModule.forChild({
            states: [
                {
                    name: APP_ROUTES['root.storage.files'].name,
                    url: APP_ROUTES['root.storage.files'].url,

                    component: FilesPageComponent,
                    data: {
                        breadcrumbs: [
                            $localize`:|@@files-page.content-management:Управление контентом`,
                            $localize`:|@@files-page.files:Файлы`,
                        ],
                    },
                },
            ],
        }),
        CoreComponentsModule,
        TreeModule,
        TreeModule,
        NgxFileDropModule,
        NgbProgressbarModule,
        NgbPopoverModule,
        InfiniteScrollModule,
        NgbDropdownModule,
        NgbPaginationModule,
        NgbNavModule,
        CoreDirectiveModule,
        DsLinkModule,
        ResponsiveTableModule,
    ],
  exports: [FileTreeComponent, FilesPageComponent],
})
export class FilesPageModule {}
