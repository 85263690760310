import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ApiModule as Location } from './Location';
import { ApiModule as LocationGroup } from './LocationGroup';
import { ApiModule as Platform } from './Platform';
import { ApiModule as Player } from './Player';
import { ApiModule as Schedule } from './Schedule';
import { ApiModule as Layout } from './Layout';
import { ApiModule as Template } from './Template';
import { ApiModule as Playlist } from './Playlist';
import { ApiModule as User } from './User';
import { ApiModule as Authorization } from './Authorization';
import { ApiModule as Partner } from './Partner';
import { ApiModule as Catalog } from './Catalog';
import { ApiModule as Tag } from './Tag';
import { ApiModule as Label } from './Label';
import { ApiModule as ContentFile } from './ContentFile';
import { ApiModule as Changelog } from './Changelog';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,

    Location,
    LocationGroup,
    Schedule,
    Layout,
    Template,
    Playlist,
    User,
    Authorization,
    Partner,
    Catalog,
    Tag,
    Label,
    ContentFile,
    Changelog,
  ],
  providers: [],
})
export class GeneratedApiModule {}
