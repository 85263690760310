import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DateFormatterDirective } from './date-formatter/date-formatter.directive';
import { IfPermissionDirective } from './if-permission/if-permission.directive';
import { MaxMinValidatorDirective } from './max-min-validator/max-min-validator.directive';
import { CustomValidatorDirective } from './custom-validator/custom-validator.directive';
import { UiSrefFixDirective } from './ui-sref-fix/ui-sref-fix.directive';

@NgModule({
  declarations: [
    DateFormatterDirective,
    IfPermissionDirective,
    MaxMinValidatorDirective,
    CustomValidatorDirective,
    UiSrefFixDirective,
  ],
  imports: [CommonModule],
  exports: [
    DateFormatterDirective,
    IfPermissionDirective,
    MaxMinValidatorDirective,
    CustomValidatorDirective,
    UiSrefFixDirective,
  ],
  providers: [
    DateFormatterDirective,
    IfPermissionDirective,
    MaxMinValidatorDirective,
    CustomValidatorDirective,
    UiSrefFixDirective,
  ],
})
export class CoreDirectiveModule {}
