<div class="modal-header bg-light ng-scope">
  <h5
    class="modal-title ng-binding"
    i18n="@@file-settings_page.settings-for-file-fileinfo.item.name">
    Настройки для файла {{ fileInfo.item?.name }}
  </h5>
  <button
    type="button"
    class="close btn btn-outline-danger"
    aria-label="Close"
    (click)="activeModal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>

<div class="modal-body overflow-wrap-break">
  <div class="col-sm-12">
    <h5 class="mb-0" i18n="@@file-settings_page.general-information">
      Общие сведения
    </h5>
    <hr class="solid mt-2 mb-2" />
  </div>

  <ng-container *ngIf="!!fileInfo.item">
    <div class="row mb-2">
      <label class="col-sm-4" i18n="@@file-settings_page.form-label.file-name"
        >Наименование файла</label
      >

      <div class="col-sm-8">
        <span
          *ngIf="!isNameEditing(fileInfo.item)"
          class="text-bold"
          [innerText]="fileInfo.item?.name"></span>

        <ng-container
          *ifPermission="{ path: 'storage.file.api', type: 'update' }">
          <div
            class="badge bg-warning text-white cursor-pointer ms-2"
            *ngIf="!isNameEditing(fileInfo.item)"
            (click)="switchEdit(fileInfo.item)">
            <i class="fas fa-pencil-alt"></i>
          </div>
        </ng-container>

        <div class="input-group" *ngIf="isNameEditing(fileInfo.item)">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="fileInfo.item.$editName"
            [max]="200"
            [disabled]="fileInfo.item?.$isUpdaiting" />

          <span class="input-group-text" *ngIf="fileInfo.item?.$isUpdaiting">
            <i class="fas fa-spin fa-spinner text-warning"></i>
          </span>

          <button
            class="btn btn-success m-n"
            type="button"
            [disabled]="
              !fileInfo.item?.$editName || fileInfo.item?.$isUpdaiting
            "
            (click)="saveName(fileInfo.item)">
            <i class="fas fa-check"></i>
          </button>

          <button
            class="btn btn-danger m-n"
            type="button"
            [disabled]="fileInfo.item.$isUpdaiting"
            (click)="cancelEditName(fileInfo.item)">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-4" i18n="@@file-settings_page.form-label.slide-type"
        >Тип слайда</label
      >

      <div class="col-sm-8">
        <ng-container
          [ngSwitch]="
            videoPlayerUtilsClass.getMediaType(fileInfo.item?.mimeType)
          ">
          <span
            *ngSwitchCase="videoPlayerUtilsClass.mediaTypes.IMAGE"
            i18n="@@file-settings_page.image"
            >Изображение</span
          >
          <span
            *ngSwitchCase="videoPlayerUtilsClass.mediaTypes.VIDEO"
            i18n="@@file-settings_page.video"
            >Видео</span
          >
        </ng-container>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-4" i18n="@@file-settings_page.form-label.file-size"
        >Размер файла</label
      >

      <div class="col-sm-8">
        <span [innerText]="fileInfo.item?.fileSize | fileSize"></span>
      </div>
    </div>

    <div class="row mb-2">
      <label
        class="col-sm-4"
        i18n="@@file-settings_page.form-label.file-resolution"
        >Разрешение файла</label
      >

      <div class="col-sm-8">
        <span [innerText]="fileInfo.item?.width"></span> x
        <span [innerText]="fileInfo.item?.height"></span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="fileInfo.isLoading">
    <div class="text-center">
      <i class="fas fa-spin fa-3x fa-spinner text-warning"></i>
    </div>
  </div>

  <ul ngbNav="" #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="'blackList'">
      <a ngbNavLink="" i18n="@@file-settings_page.black-list-of-players"
        >Черный список плееров</a
      >
      <ng-template ngbNavContent="">
        <div class="col-sm-12">
          <div class="row ms-n3 me-n3">
            <div class="col p-0 border-top border-1">
              <app-top-panel>
                <checkbox-filter
                  class="float-start me-2"
                  name="Тип платформы"
                  [canSelectAll]="false"
                  [inputValues]="PLATFORM_NAMES"
                  [selectedValues]="
                    contentFileApiService.queryPlayersParams.getFilterValue(
                      'platformTypes'
                    )
                  "
                  (selectedValuesChange)="
                    contentFileApiService.queryPlayersParams.setFilterValue(
                      'platformTypes',
                      $event
                    );
                    resetPages()
                  "
                  i18n-name="
                    @@file-settings_page.top-panel.platform-type"></checkbox-filter>

                <div
                  class="btn-group dropdown float-start me-2"
                  ngbDropdown=""
                  [autoClose]="'outside'"
                  [placement]="'bottom-right'">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-default btn-addon btn-sm me-0"
                      (click)="addLocationsFilter()">
                      <i
                        class="fas fa-filter"
                        [class.text-danger]="
                          contentFileApiService.queryPlayersParams.getFilterValue(
                            'locationIds'
                          )
                        "></i>
                      <span
                        i18n="
                          @@file-settings_page.top-panel.button.shopping-facility"
                        >Торговый объект</span
                      >
                    </button>

                    <button
                      type="button"
                      class="btn btn-default btn-sm"
                      ngbDropdownToggle=""
                      *ngIf="
                        contentFileApiService.queryPlayersParams.getFilterValue(
                          'locationIds'
                        )
                      ">
                      <span class="caret"></span>
                    </button>

                    <ul
                      class="dropdown-menu"
                      *ngIf="
                        contentFileApiService.queryPlayersParams.getFilterValue(
                          'locationIds'
                        )
                      "
                      ngbDropdownMenu="">
                      <li
                        class="no-padder"
                        *ngFor="
                          let id of contentFileApiService.queryPlayersParams.getFilterValue(
                            'locationIds'
                          ) || []
                        ">
                        <div
                          class="dropdown-item d-flex justify-content-between">
                          <div>
                            <span [innerText]="locationNames.get(id)"></span>
                          </div>
                          <div>
                            <span
                              class="bg-danger text-white rounded-1 ps-2 pe-2 float-end"
                              (click)="removeFromFilter('locationIds', id)">
                              <i class="fas fa-times fa-sm"></i>
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      *ngIf="
                        !!contentFileApiService.queryPlayersParams.getFilterValue(
                          'locationIds'
                        )
                      "
                      (click)="
                        contentFileApiService.queryPlayersParams.setFilterValue(
                          'locationIds',
                          undefined
                        );
                        resetPages()
                      ">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>

                <checkbox-filter
                  class="float-start me-2"
                  name="Статус"
                  [canSelectAll]="false"
                  [inputValues]="PLAYER_STATUS_NAMES"
                  [selectedValues]="
                    contentFileApiService.queryPlayersParams.getFilterValue(
                      'states'
                    )
                  "
                  (selectedValuesChange)="
                    contentFileApiService.queryPlayersParams.setFilterValue(
                      'states',
                      $event
                    );
                    resetPages()
                  "
                  i18n-name="
                    @@file-settings_page.top-panel.status"></checkbox-filter>

                <div class="btn-group btn-group-sm">
                  <button
                    class="btn btn-default btn-addon btn collapse-xs"
                    (click)="addLabels()">
                    <i
                      class="fas fa-filter"
                      [class.text-danger]="
                        contentFileApiService.queryPlayersParams.getFilterValue(
                          'labels'
                        )?.length
                      "></i>
                    <ng-container
                      i18n="@@file-settings_page.top-panel.button.tags">
                      Метки
                    </ng-container>
                  </button>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    *ngIf="
                      !!contentFileApiService.queryPlayersParams.getFilterValue(
                        'labels'
                      )?.length
                    "
                    (click)="
                      contentFileApiService.queryPlayersParams.setFilterValue(
                        'labels',
                        []
                      );
                      resetPages()
                    ">
                    <i class="fas fa-times"></i>
                  </button>
                </div>

                <ng-container
                  *ifPermission="{ path: 'storage.file.api', type: 'update' }">
                  <button
                    *ngIf="!!deletedIds.size"
                    (click)="confirmDelete()"
                    class="btn btn-danger btn-sm btn-addon float-end">
                    <i class="fas fa-times"></i>
                    <ng-container
                      i18n="
                        @@file-settings_page.top-panel.button.delete-deletedids.size">
                      Удалить: {{ deletedIds.size }}
                    </ng-container>
                  </button>
                </ng-container>

                <button
                  class="btn btn-sm btn-addon float-end btn-success"
                  (click)="addPlayers()"
                  *ifPermission="{ path: 'storage.file.api', type: 'update' }">
                  <i class="fas fa-plus"></i>
                  <ng-container
                    i18n="@@file-settings_page.top-panel.button.add-player">
                    Добавить плеер
                  </ng-container>
                </button>
              </app-top-panel>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-12">
              <app-search-input
                [model]="
                  contentFileApiService.queryPlayersParams.getSearchValue()
                "
                (modelChange)="onSearchChange($event)"
                placeholder="ID/Наименование плеера/Платформы/ТО"
                i18n-placeholder="
                  @@file-settings_page.id-name-of-the-platform-player-then"></app-search-input>
            </div>
          </div>

          <div>
            <responsive-table [disabled]="true" >
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th
                    class="text-center"
                    scope="col"
                    i18n="@@file-settings_page.table.table-header.status">
                    Статус
                  </th>
                  <th
                    scope="col"
                    i18n="@@file-settings_page.table.table-header.name">
                    Наименование
                  </th>
                  <th
                    scope="col"
                    i18n="@@file-settings_page.table.table-header.platform">
                    Платформа
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    i18n="
                      @@file-settings_page.table.table-header.shopping-facility">
                    Торговый объект
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of playerItems.items">
                  <td>
                    <ng-container
                      *ifPermission="{
                        path: 'storage.file.api',
                        type: 'update'
                      }">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="deletedIds.has(item.id)"
                          (change)="changeSelected($event, item.id)"
                          (click)="$event.stopPropagation()" />
                      </div>
                    </ng-container>
                  </td>
                  <td
                    class="text-center"
                    [innerText]="PLAYER_STATUS_NAMES[item.state]"></td>
                  <td>
                    <a
                      class=""
                      href="javascript:void(0)"
                      [dsLink]="'root.player.view'"
                      [dsLinkParams]="{ id: item.id }">
                      <text-highlight
                        [search]="
                          contentFileApiService.queryPlayersParams.getSearchValue()
                        "
                        [text]="item.name"></text-highlight>
                    </a>
                    <div
                      class="d-block"
                      *ngIf="item.labels && !!item.labels.length">
                      <app-tag-input
                        [model]="item.labels"
                        [onlyView]="true"></app-tag-input>
                    </div>
                    <div class="clearfix small text-muted">
                      ID:
                      <text-highlight
                        [search]="
                          contentFileApiService.queryPlayersParams.getSearchValue()
                        "
                        [text]="item.id || ''"></text-highlight>
                    </div>
                  </td>
                  <td>
                    <text-highlight
                      [search]="
                        contentFileApiService.queryPlayersParams.getSearchValue()
                      "
                      [text]="item.platform?.name"></text-highlight>
                  </td>
                  <td class="text-center">
                    <text-highlight
                      [search]="
                        contentFileApiService.queryPlayersParams.getSearchValue()
                      "
                      [text]="item.location?.name"></text-highlight>
                  </td>
                </tr>
              </tbody>
            </table>
            </responsive-table>

            <div *ngIf="playerItems.isLoading">
              <div class="text-center">
                <i class="fas fa-spin fa-3x fa-spinner text-warning"></i>
              </div>
            </div>

            <ng-container
              *ngIf="!playerItems.isLoading && !playerItems.items?.length">
              <div class="d-block text-center text-muted mt-4 mb-4">
                <h4 i18n="@@file-settings_page.no-players-specified">
                  Плееры не заданы
                </h4>
              </div>
            </ng-container>

            <ngb-pagination
              class="d-flex justify-content-center"
              *ngIf="totalItems > PAGE_ITEMS_COUNT"
              [page]="pageIndex"
              (pageChange)="setPage($event)"
              [pageSize]="PAGE_ITEMS_COUNT"
              [collectionSize]="totalItems"></ngb-pagination>
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="'locations'">
      <a ngbNavLink="" i18n="@@file-settings_page.playing-back"
        >Проигрывание в ТО</a
      >
      <ng-template ngbNavContent="">
        <file-active-locations [fileId]="fileId"></file-active-locations>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>
