export const APP_ROUTES = {
  root: {
    name: 'root',
    url: '/:rootPartnerId/',
  },

  login: {
    name: 'login',
    url: '/login?r',
  },

  registration: {
    name: 'registration',
    url: '/registration?uuid&api&login',
  },

  partner: {
    name: 'partner',
    url: '/login/partner',
  },

  link: {
    name: 'link',
    url: '/login/link',
  },

  'root.dashboard.**': {
    name: 'root.dashboard.**',
    url: 'dashboard',
  },

  'root.player.**': {
    name: 'root.player.**',
    url: 'player',
  },

  'root.device.**': {
    name: 'root.device.**',
    url: 'device',
  },

  'root.device-type.**': {
    name: 'root.device-type.**',
    url: 'device-type',
  },

  'root.users.**': {
    name: 'root.users.**',
    url: 'directory/users',
  },

  'root.playlist.**': {
    name: 'root.playlist.**',
    url: 'playlist',
  },

  'partner.**': {
    name: 'partner.**',
    url: '/login/partner',
  },

  'link.**': {
    name: 'link.**',
    url: '/login/link',
  },

  'root.schedule.**': {
    name: 'root.schedule.**',
    url: 'schedule',
  },

  'root.information.**': {
    name: 'root.information.**',
    url: 'information',
  },

  'root.locationsGroup.**': {
    name: 'root.locationsGroup.**',
    url: 'locations/locationsGroup',
  },

  'root.locations.**': {
    name: 'root.locations.**',
    url: 'locations/locations',
  },

  'root.playlist.list.**': {
    name: 'root.playlist.list.**',
    url: '/list',
  },

  'root.platform.**': {
    name: 'root.platform.**',
    url: 'platform',
  },

  'root.label.**': {
    name: 'root.label.**',
    url: 'label',
  },

  'root.reports.**': {
    name: 'root.reports.**',
    url: 'reports',
  },

  'root.journal.**': {
    name: 'root.journal.**',
    url: 'journal',
  },

  'root.settings.**': {
    name: 'root.settings.**',
    url: 'settings',
  },

  'root.dashboard': {
    name: 'root.dashboard',
    url: 'dashboard',
  },

  'root.users': {
    name: 'root.users',
    url: 'directory/users?id',
  },

  'root.information': {
    name: 'root.information',
    url: 'information',
  },

  'root.journal': {
    name: 'root.journal',
    url: 'journal',
  },

  'root.journal.changelog': {
    name: 'root.journal.changelog',
    url: '/changelog',
  },

  'root.locationsGroup': {
    name: 'root.locationsGroup',
    url: 'locations/locationsGroup',
  },

  'root.locationsGroup.list': {
    name: 'root.locationsGroup.list',
    url: 'list',
  },

  'root.locationsGroup.view': {
    name: 'root.locationsGroup.view',
    url: 'view/:id',
  },

  'root.locations': {
    name: 'root.locations',
    url: 'locations/',
  },

  'root.locations.list': {
    name: 'root.locations.list',
    url: 'list',
  },

  'root.locations.view': {
    name: 'root.locations.view',
    url: 'view/:id',
  },
  'root.platform': {
    name: 'root.platform',
    url: 'platform',
  },

  'root.platform.list': {
    name: 'root.platform.list',
    url: '/list',
  },

  'root.platform.view': {
    name: 'root.platform.view',
    url: '/view/:id',
  },

  'root.player': {
    name: 'root.player',
    url: 'player',
  },

  'root.player.list.**': {
    name: 'root.player.list.**',
    url: '/list',
  },

  'root.player.view.**': {
    name: 'root.player.view.**',
    url: '/view/:id',
  },

  'root.player.list': {
    name: 'root.player.list',
    url: '/list?status',
  },

  'root.player.view': {
    name: 'root.player.view',
    url: '/view/:id',
  },

  'root.playlist': {
    name: 'root.playlist',
    url: 'playlist',
  },

  'root.playlist.list': {
    name: 'root.playlist.list',
    url: '/list',
  },

  'root.playlist.view': {
    name: 'root.playlist.view',
    url: '/view/:id?{isEdit}',
  },

  'root.label': {
    name: 'root.label',
    url: 'label',
  },

  'root.label.list': {
    name: 'root.label.list',
    url: '/list',
  },

  'root.label.view': {
    name: 'root.label.view',
    url: '/view/:id',
  },

  'root.reports': {
    name: 'root.reports',
    url: 'reports',
  },

  'root.reports.statMedia': {
    name: 'root.reports.statMedia',
    url: '/statmedia',
  },

  'root.schedule': {
    name: 'root.schedule',
    url: 'schedule',
  },

  'root.schedule.list': {
    name: 'root.schedule.list',
    url: '/list?states',
  },

  'root.schedule.view': {
    name: 'root.schedule.view',
    url: '/view/:id',
  },

  'root.settings': {
    name: 'root.settings',
    url: 'settings',
  },

  'root.settings.partner': {
    name: 'root.settings.partner',
    url: '/partner',
  },

  'root.storage': {
    name: 'root.storage',
    url: 'storage',
  },

  'root.templates': {
    name: 'root.templates',
    url: 'templates',
  },

  'root.storage.files': {
    name: 'root.storage.files',
    url: '/files',
  },

  'root.templates.list': {
    name: 'root.templates.list',
    url: '/list',
  },

  'root.templates.view': {
    name: 'root.templates.view',
    url: '/view/:id',
  },

  'root.device': {
    name: 'root.device',
    url: 'device',
  },

  'root.device.list.**': {
    name: 'root.device.list.**',
    url: '/list',
  },

  'root.device.view.**': {
    name: 'root.device.view.**',
    url: '/view/:id',
  },

  'root.device.list': {
    name: 'root.device.list',
    url: '/list',
  },

  'root.device.view': {
    name: 'root.device.view',
    url: '/view/:id',
  },

  'root.device-type': {
    name: 'root.device-type',
    url: 'device-type',
  },

  'root.device-type.list.**': {
    name: 'root.device-type.list.**',
    url: '/list',
  },

  'root.device-type.view.**': {
    name: 'root.device-type.view.**',
    url: '/view/:id',
  },

  'root.device-type.list': {
    name: 'root.device-type.list',
    url: '/list',
  },

  'root.device-type.view': {
    name: 'root.device-type.view',
    url: '/view/:id',
  },
};
