import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  PlayListApi,
  PlaylistApiService as PlaylistApiServiceGen,
} from '../generated/Playlist';
import { ValidateFieldClass } from '../../../class/validate-field.class';

const createUpdateFilter = function (item: any = {}) {
  if (item.template && !item.template.params) item.template.params = {};

  if (item.template && typeof item.template.params === 'object')
    item.template.params = JSON.stringify(item.template.params);
};

@Injectable()
export class PlaylistApiService {
  constructor(
    private http: HttpClient,
    private partnerApiService: PartnerApiService,
    private playlistAPIService: PlaylistApiServiceGen
  ) {}

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 256,
    },
    description: {
      required: true,
      maxlength: 256,
    },
  });

  get initItem() {
    return {
      items: [],
    };
  }

  create$(body: any, partnerId = this.partnerApiService.currentPartnerId) {
    createUpdateFilter(body);
    return this.playlistAPIService
      .postPartnerPartneridPlaylist({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@playlist-api_service.playlist-creation-error:Ошибка создания плейлиста`
          );
          return throwError(error);
        })
      );
  }

  get$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined') {
      return throwError({});
    }

    return this.playlistAPIService
      .getPartnerPartneridPlaylistId({
        partnerId,
        id,
      })
      .pipe(
        map((item: any) => {
          if (Array.isArray(item)) {
            return item[0];
          }
          return item;
        }),
        map((item: PlayListApi) => {
          if (
            item &&
            item.template &&
            typeof item.template.params === 'string'
          ) {
            try {
              item.template.params = JSON.parse(item.template.params);
            } catch (e) {}
          }

          return item;
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@playlist-api_service.playlist-not-found:Плейлист не найден`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '')
      return of(<any>{
        items: [],
      });

    return this.get$(id, partnerId);
  }

  query$(partnerId = this.partnerApiService.currentPartnerId) {
    return this.playlistAPIService
      .getPartnerPartneridPlaylist({
        partnerId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@playlist-api_service.error-getting-playlists:Ошибка получения плейлистов`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: any,
    id?: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof id === 'undefined') {
      return this.create$(body, partnerId);
    } else {
      createUpdateFilter(body);
      return this.playlistAPIService
        .putPartnerPartneridPlaylistId({
          partnerId,
          id,
          body,
        })
        .pipe(
          catchError(error => {
            error.setError(
              $localize`:|@@playlist-api_service.error-saving-playlist:Ошибка сохранения плейлиста`
            );
            return throwError(error);
          })
        );
    }
  }

  $delete(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.playlistAPIService
      .deletePartnerPartneridPlaylistId({ partnerId, id })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@playlist-api_service.playlist-deletion-error:Ошибка удления плейлиста`
          );
          return throwError(error);
        })
      );
  }
}
