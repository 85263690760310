import Mustache from "mustache";
import {matchAll, mergeDeep, setByPath} from "./../utils/ds-player.utils";

import {TemplateFieldClass} from "./template-field.class";
import {TemplateViewClass} from "./template-view.class";
import {FieldTypes, ITemplateOptions} from "./template.interface";
import {EventEmitter} from "events";

export class TemplateCoreClass {

  private originalTemplate: string = '';
  private preparedTemplate: string = '';

  private _view: TemplateViewClass;

  public fields = new Map<string,TemplateFieldClass>();
  private _data: any = {};
  private _disableUpdateEvent = false;
  private _fieldsArray: TemplateFieldClass[] = [];

  public events: any = new EventEmitter();



  constructor(inTemplate = '', data ={}, public options: ITemplateOptions = {}) {
    this._view = new TemplateViewClass(this);
    this.update(inTemplate, data);
  }

  get html() {
    return this.prepareDataToRender()
        .then( data => {
          Mustache.escape = (text) =>text;
          return Mustache.render(this.preparedTemplate, data );
        } )
  }

  get data() {
    return this._data;
  }

  set data(value: any) {
    mergeDeep(this._data, value);
    if (!this._disableUpdateEvent)
      this.events.emit('change', this.data);
  }

  get dataFields() {
    let resultObj = {};
    this.fields.forEach( field => {
      if (field.type === FieldTypes.data)
        return;

      mergeDeep( resultObj, setByPath(field.fieldOptions.path, field.value) );
    });

    return resultObj;
  }

  get element() {
    return this._view.root;
  }

  get fieldsArray(): Array<TemplateFieldClass> {
    return this._fieldsArray;
  }

  public async update(inTemplate = '', data ={}) {
    this.originalTemplate = inTemplate;
    this._data = data;
    this.fields.clear();
    this.updateFieldsArray(true);
    this.prepareTemplate();
    await this._view.onChange();
  }

  public getField(id):TemplateFieldClass {
    return <any>this.fields.get(id);
  }

  private prepareTemplate() {

    this._disableUpdateEvent = true;

    for (const match of matchAll(TemplateFieldClass.FIELD_REGEXP, this.originalTemplate) ) {
      this.prepareField(match);
    }

    this.prepareOriginalTemplate();

    this._disableUpdateEvent = false;
  }

  private prepareField(match: RegExpMatchArray) {
    let fieldObj = new TemplateFieldClass( match, this.onFieldsChange.bind(this), this._data );
    if ( fieldObj.id === 'none' ) {
      return
    }

    this.fields.set(fieldObj.id, fieldObj);
    this.updateFieldsArray();
  }

  private prepareOriginalTemplate() {
    this.preparedTemplate = this.originalTemplate;

    this.fields.forEach( field => {
      this.preparedTemplate = this.preparedTemplate.replace( field.templateField, `{{${field.path}}}`)
    })

  }

  private onFieldsChange(id, path, value) {

    let obj = {};
    let pathArray = path.split('.');
    let deepCounter = 0;

    if (!pathArray)
      pathArray = [path];

      pathArray.reduce((o,i)=> {
        o[i] = (++deepCounter === pathArray.length) ? value : {};
        return o[i];
      }, obj);

    this.data = obj;
  }

  private  async prepareDataToRender() {
    let data = this.data;

    let arrayFields : any = []; // для асинхронщины
    this.fields.forEach(i => arrayFields.push( i ));

    for  (let i of arrayFields) {
      if (
          i.type !== FieldTypes.blockBackground &&
          i.type !== FieldTypes.file &&
          !( i.type === FieldTypes.data && i.path === 'url' ) )
        continue;

      if ( this.options.updateFilePath && !i.isDefault) {

        let value;
        if (i.type === FieldTypes.blockBackground) {

          const pattern = /background-image\s*:\s*url\(['|"]([^\)]+)['|"]\)/i;

          value = i.value;
          let parsed = pattern.exec(value);
          if (parsed && parsed.length === 2) {
            const newPath  = await this.options.updateFilePath(parsed[1]);
            value = value.replace(parsed[0], 'background-image: url("'+newPath+'")')
          }

        } else {
          value = await this.options.updateFilePath(i.value);
        }

        let path = setByPath(i.path, value);
        mergeDeep(data, path);

      }

    }

    return data;
  }

  private updateFieldsArray( updateArray = false ) {

    if ( updateArray )
      this._fieldsArray = [];
    else
      this._fieldsArray.splice(0, this._fieldsArray.length);

    Array.from( this.fields ).map(i =>  this._fieldsArray.push(i[1]) );
  }

  public destroy() {
    this._view.root = <any>null;
    this.events.removeAllListeners();
    this.fields.clear();
    this.updateFieldsArray();
  }

}
