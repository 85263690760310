import { Component, OnInit } from '@angular/core';
import {
  StateDeclaration,
  StateService,
  Transition,
  TransitionService,
} from '@uirouter/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './app-breadcrumbs.component.html',
  styleUrls: ['./app-breadcrumbs.component.scss'],
})
export class AppBreadcrumbsComponent implements OnInit {
  public path: any;

  constructor(private trans: TransitionService, private $state: StateService) {}

  ngOnInit(): void {
    this.trans.onSuccess({}, trans => {
      this.getBreadcrumbs(trans.to());
    });

    this.getBreadcrumbs(this.$state.current);
  }

  getBreadcrumbs(tr: StateDeclaration) {
    if (tr.data && tr.data.breadcrumbs) {
      this.path = tr.data.breadcrumbs.slice();
    } else {
      this.path = false;
    }
  }
}
