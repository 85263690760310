import { NgModule } from '@angular/core';
import {ResponsiveTableComponent} from './responsive-table.component'

@NgModule({
  declarations: [
    ResponsiveTableComponent,
  ],
  imports: [

  ],
  providers: [],
  exports: [
    ResponsiveTableComponent
  ],
})
export class ResponsiveTableModule {}
