<div class="modal-header pb-0">
  <button
    type="button"
    class="close btn btn-outline-danger"
    aria-label="Close"
    (click)="activeModal.dismiss()">
    <i class="fas fa-times"></i>
  </button>

  <ul
    ngbNav=""
    #nav="ngbNav"
    [activeId]="activeTab"
    (activeIdChange)="activeTab = $event; selectedFile = undefined"
    class="nav-tabs file-selector-tabs">
    <li [ngbNavItem]="'files'">
      <a ngbNavLink="" i18n="@@modal-selector_service.select-files"
        >Выбрать файлы</a
      >
      <ng-template ngbNavContent="">
        <app-files-page
          [isForSelect]="true"
          [options]="options"
          (onChange)="selectedFile = $event"></app-files-page>
      </ng-template>
    </li>

    <li [ngbNavItem]="'templates'" *ngIf="!options.hideTemplates">
      <a ngbNavLink="" i18n="@@modal-selector_service.select-templates"
        >Выбрать шаблоны</a
      >
      <ng-template ngbNavContent="">
        <app-template-list
          class="d-block overflow-auto pt-4"
          [isForSelect]="true"
          (onChange)="selectedFile = $event"
          [options]="options">
        </app-template-list>
      </ng-template>
    </li>
  </ul>
</div>
<div class="modal-body p-0">
  <div class="card-body p-0 d-flex flex-column">
    <div class="row m-0" style="height: 70vh">
      <div class="col p-0" [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger btn-addon mr-2" (click)="activeModal.dismiss()">
    <i class="fas fa-times"></i>
    <ng-container i18n="@@modal-selector_service.button.cancellation">
      Отмена
    </ng-container>
  </button>

  <button
    class="btn btn-success btn-addon"
    (click)="closeAndSelect()"
    [disabled]="!selectedFile">
    <i class="fas fa-check"></i>
    <ng-container i18n="@@modal-selector_service.button.choose">
      Выбрать </ng-container
    ><span *ngIf="selectedCount() > 1">: {{ selectedCount() }}</span>
  </button>
</div>
