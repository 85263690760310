import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'sort-view',
  template: `
    <div
      class="cursor-pointer d-inline-flex justify-content-center align-items-center"
      (click)="changeSort()"
      [ngClass]="{ pointer: enabled }">
      <div>
        <ng-content></ng-content>
      </div>
      <div *ngIf="!!sortIcon" class="ms-1">
        <i class="fas" [ngClass]="'fa-' + sortIcon"></i>
      </div>
    </div>
  `,
})
export class AppSortViewComponent implements OnInit {
  @Input() column: any;
  @Input() params: any;

  @Output() onChange = new EventEmitter();
  constructor() {}

  get enabled() {
    return !!this.params._params.sort;
  }

  get sortIcon(): any {
    switch (true) {
      case this.isCurrentField && this.isUp:
        return 'sort-up';
      case this.isCurrentField && this.isDown:
        return 'sort-down';
      case this.enabled:
        return 'sort';
    }
  }

  get isCurrentField() {
    return this.enabled && this.params._params.sort.sortValue === this.column;
  }

  get isUp() {
    return (
      this.enabled &&
      this.params._params.sort.orderValue.toLowerCase() === 'asc'
    );
  }

  get isDown() {
    return (
      this.enabled &&
      this.params._params.sort.orderValue.toLowerCase() === 'desc'
    );
  }

  public ngOnInit() {}

  public changeSort() {
    if (!this.enabled) return;

    if (!this.isCurrentField) this.params.setSort(this.column, 'asc');
    else {
      if (this.isUp) {
        this.params.setSort(this.column, 'desc');
      } else {
        this.params.setSort(this.column);
      }
    }

    this.onChange.emit();
  }
}
