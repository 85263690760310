import { Injectable, OnInit } from '@angular/core';
import { SYSTEM_LANGUAGES } from './local-l18n.values';

const COOKIE_ID = 'ds_lg';

@Injectable({
  providedIn: 'root',
})
export class LocalL18nService {
  constructor() {
    const cookiesLang = this.getFromCookies();

    if (!!cookiesLang && Object.values(SYSTEM_LANGUAGES).includes(cookiesLang))
      this.curLg = cookiesLang;
  }

  private curLg = SYSTEM_LANGUAGES.ru;

  get currentLanguage() {
    return this.curLg;
  }

  get supportedLanguages() {
    return [SYSTEM_LANGUAGES.ru, SYSTEM_LANGUAGES.en];
  }

  setLanguage(id: SYSTEM_LANGUAGES): Promise<SYSTEM_LANGUAGES> {
    return new Promise(r => {
      this.curLg = id;
      this.setToCookies();
      r(this.currentLanguage);
    });
  }

  setToCookies() {
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      COOKIE_ID + '=' + this.currentLanguage + ';' + expires + ';path=/';
  }

  getFromCookies() {
    let lg: SYSTEM_LANGUAGES | string = SYSTEM_LANGUAGES.ru;
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${COOKIE_ID}=`);
    if (parts?.length === 2) {
      lg = Array.isArray(parts) ? parts.pop().split(';').shift() : 'ru';
      if (!SYSTEM_LANGUAGES[lg]) return false;
    } else {
      return false;
    }

    return <SYSTEM_LANGUAGES>lg;
  }

  isValidLang(inLang: string): SYSTEM_LANGUAGES | false {
    return this.supportedLanguages.includes(<any>inLang)
      ? <SYSTEM_LANGUAGES>inLang
      : false;
  }

  reloadPage() {
    window.location.reload();
  }
}
