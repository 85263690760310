<div class="container-fluid p-0 d-flex flex-column">
  <app-top-panel [isIntegrated]="true">
    <ng-container *ifPermission="{ path: 'templates.api', type: 'update' }">
      <button
        class="btn btn-default btn-addon btn-sm float-end"
        *ngIf="statesChangerService.hasButton('save')"
        (click)="statesChangerService.click('save')"
        [disabled]="inputForm.invalid">
        <i class="fas fa-check text-success"></i>
        <ng-container i18n="@@templates-view-page.top-panel.button.save">
          Сохранить
        </ng-container>
      </button>

      <button
        class="btn btn-default btn-addon btn-sm float-end"
        *ngIf="statesChangerService.hasButton('cancel')"
        (click)="statesChangerService.click('cancel')">
        <i class="fas fa-times text-danger"></i>
        <ng-container i18n="@@templates-view-page.top-panel.button.cancel">
          Отменить
        </ng-container>
      </button>
    </ng-container>

    <ng-container *ifPermission="{ path: 'templates.api', type: 'delete' }">
      <button
        class="btn btn-danger btn-addon btn-sm float-start"
        *ngIf="statesChangerService.hasButton('delete')"
        (click)="statesChangerService.click('delete')">
        <i class="fas fa-times"></i>
        <ng-container i18n="@@templates-view-page.top-panel.button.delete">
          Удалить
        </ng-container>
      </button>
    </ng-container>

    <ng-container *ifPermission="{ path: 'templates.api', type: 'update' }">
      <button
        class="btn btn-success btn-sm float-end"
        *ngIf="statesChangerService.hasButton('active')"
        (click)="statesChangerService.click('active')">
        <i class="fas fa-play"></i>
      </button>

      <button
        class="btn btn-danger btn-sm float-end"
        *ngIf="statesChangerService.hasButton('archive')"
        (click)="statesChangerService.click('archive')">
        <i class="fas fa-archive"></i>
      </button>

      <button
        class="btn btn-success btn-sm btn-addon float-end"
        *ngIf="statesChangerService.hasButton('edit')"
        (click)="statesChangerService.click('edit')">
        <i class="fas fa-edit"></i>
        <ng-container i18n="@@templates-view-page.top-panel.button.edit">
          Редактировать
        </ng-container>
      </button>
    </ng-container>

    <button
      class="btn btn-success btn-addon btn-sm float-end"
      *ngIf="!statesChangerService.isActive"
      [dsLink]="'^.list'">
      <i class="fas fa-arrow-left"></i>
      <ng-container i18n="@@templates-view-page.top-panel.button.to-the-list">
        К списку
      </ng-container>
    </button>
  </app-top-panel>

  <form #inputForm="ngForm" style="flex: 1; display: contents" class="h-100">
    <div
      class="d-flex flex-column justify-content-between p-3 align-items-stretch">
      <div class="row">
        <div class="col-xl-6" *ngIf="statesChangerService.isActive">
          <div class="mb-3">
            <label
              class="form-label"
              i18n="@@templates-view-page.form-label.template-name"
              >Наименование шаблона</label
            >
            <input
              type="text"
              class="form-control form-control-sm"
              name="name"
              [(ngModel)]="selectedTemplate.name"
              [required]="true"
              maxlength="100" />
          </div>
          <div class="mb-3">
            <label
              class="form-label"
              i18n="@@templates-view-page.form-label.description"
              >Описание</label
            >
            <textarea
              class="form-control form-control-sm"
              rows="3"
              name="description"
              [(ngModel)]="selectedTemplate.description"
              [required]="true"
              maxlength="255"></textarea>
          </div>

          <input
            [required]="true"
            name="layoutId"
            [(ngModel)]="selectedTemplate.layoutId"
            type="hidden" />
        </div>

        <div class="col-xl-6" *ngIf="!statesChangerService.isActive">
          <div class="row">
            <div class="col-sm-4" i18n="@@templates-view-page.template-name">
              Наименование шаблона
            </div>
            <div class="col-sm-8" [innerHTML]="selectedTemplate.name"></div>

            <div class="col-sm-4" i18n="@@templates-view-page.description">
              Описание
            </div>
            <div
              class="col-sm-8"
              [innerHTML]="selectedTemplate.description || ''"></div>

            <div class="col-sm-4" i18n="@@templates-view-page.status">
              Статус
            </div>
            <div
              class="col-sm-8"
              [innerHTML]="TEMPLATE_STATUS_NAMES[selectedTemplate.state]"></div>

            <ng-container *ngIf="selectedTemplate && selectedTemplate.id >= 0">
              <div class="col-sm-4" i18n="@@templates-view-page.tags">Теги</div>
              <div class="col-sm-8">
                <app-tag-input
                  [loadFunction]="getTag(selectedTemplate.id)"
                  (onChange)="saveTag(selectedTemplate.id, $event)"
                  [onlyView]="
                    !permissionsService.byType(
                      'templates.api',
                      PERMISSION_TYPES.UPDATE
                    )
                  "></app-tag-input>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="statesChangerService.isActive">
        <div class="col-xl-12" *ngIf="!!layoutItems.items?.length">
          <ngu-carousel
            #carouselLayout=""
            [inputs]="carouselConfig"
            [dataSource]="layoutItems.items"
            (onMove)="onCarouselMove($event)">
            <ngu-tile
              *nguCarouselDef="let item; let i = index"
              class="h-100 text-center">
              <a
                href="javascript:void(0)"
                (click)="setLayout(item)"
                [class.table-row-active]="
                  item.id === selectedTemplate?.layoutId
                "
                class="h-100 w-100 d-flex flex-column layout-preview">
                <div
                  class="layout-image"
                  [style]="{
                    'background-image':
                      'url(' + fileApiService.getUrlByName(item.fileName) + ')'
                  }"></div>
                <div class="p-2 layout-name">
                  <div [innerText]="item.name"></div>
                </div>
              </a>
            </ngu-tile>

            <button
              NguCarouselPrev=""
              class="btn btn-success leftRs"
              [style.opacity]="carouselIsFirst ? 0.5 : 1">
              &amp;lt;
            </button>
            <button
              NguCarouselNext=""
              class="btn btn-success rightRs"
              [style.opacity]="carouselIsLast ? 0.5 : 1">
              &amp;gt;
            </button>
          </ngu-carousel>
        </div>

        <div class="col-xl-12 text-center" *ngIf="!!layoutItems.isLoading">
          <i class="fas fa-spin fa-3x mt-3 mb-3 fa-spinner text-warning"></i>
        </div>
      </div>

      <div class="row flex-fill mt-3">
        <div class="col-xl-8 position-relative">
          <div class="top-0 start-0 w-100 h-100">
            <div
              class="card card mx-auto h-100"
              [style]="
                ' max-width: 100%; max-height: 80vh; aspect-ratio:' + viewRatio
              "
              [class.w-100]="viewRatio.startsWith('16')"
              [class.h-100]="
                viewRatio.startsWith('9') ||
                viewRatio.startsWith('3') ||
                viewRatio.startsWith('4')
              ">
              <div class="card-body p-0" #template=""></div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <template-fields
            *ngIf="statesChangerService.isActive"
            [fields]="templateCore.fieldsArray">
          </template-fields>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label
                    class="form-label"
                    i18n="@@templates-view-page.form-label.preview-on-test-data"
                    >Предпросмотр на тестовых данных</label
                  >
                </div>
              </div>

              <div class="row">
                <div class="col col-auto pe-0 me-3">
                  <button class="btn btn-success" (click)="previewTestData()">
                    <i class="fas fa-step-forward"></i>
                  </button>
                </div>

                <div class="col col-auto ps-0">
                  <div class="btn-group">
                    <button
                      class="btn btn-success btn-addon"
                      (click)="previewCsvData()">
                      <i class="fas fa-upload"></i>
                      <ng-container
                        i18n="@@templates-view-page.button.upload-csv">
                        Загрузить CSV
                      </ng-container>
                    </button>
                    <button
                      *ngIf="
                        this.testedData.csv &&
                        !(
                          (testedData?.errorsCount || 0) > 0 &&
                          testedData.items.length === 0
                        )
                      "
                      class="btn btn-danger"
                      (click)="resetTestData()">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>

                  <div
                    class="text-danger mt-2"
                    *ngIf="
                      (testedData?.errorsCount || 0) > 0 &&
                      testedData.items.length > 0
                    ">
                    <i class="fas fa-exclamation-triangle"></i>
                    <ng-container
                      i18n="
                        @@templates-view-page.error-loading-testeddata.errorscount-positions">
                      Ошибка загрузки {{ testedData?.errorsCount }} позиций
                    </ng-container>
                  </div>

                  <div
                    class="text-danger mt-2"
                    *ngIf="
                      (testedData?.errorsCount || 0) > 0 &&
                      testedData.items.length === 0
                    ">
                    <i class="fas fa-exclamation-triangle"></i>
                    <ng-container
                      i18n="@@templates-view-page.error-loading-entire-file">
                      Ошибка загрузки всего файла
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body pb-1">
              <div class="row">
                <div class="col">
                  <label
                    class="form-label"
                    i18n="
                      @@templates-view-page.form-label.preview-at-different-resolutions"
                    >Предпросмотр на разных разрешениях</label
                  >
                </div>
              </div>

              <div class="w-100 d-flex flex-wrap justify-content-around">
                <button
                  *ngFor="let ratio of ['16 / 9', '9 / 16', '4 / 3', '3 / 4']"
                  class="btn btn-outline-primary ms-1 mb-3 me-1"
                  (click)="setViewRation(ratio)"
                  [ngClass]="{
                    'btn-outline-primary': ratio !== viewRatio,
                    'btn-primary': ratio === viewRatio
                  }"
                  i18n="@@templates-view-page.template-ratio.replace-,">
                  Шаблон {{ ratio.replace(' / ', ':') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
