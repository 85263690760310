export enum MODE_TYPES {
  STAND_BY = 'StandBy',
  RECEIPT = 'Receipt',
  CUSTOMER_DISPLAY_RECEIPT = 'CustomerDisplayReceipt',
  CUSTOMER_DISPLAY_EMPTY_RECEIPT = 'CustomerDisplayEmptyReceipt',
}

export const MODE_TYPES_NAMES = {
  [MODE_TYPES.STAND_BY]: $localize`:|@@device-type-api_service.full-screen:Полный экран`,
  [MODE_TYPES.RECEIPT]: $localize`:|@@device-type-api_service.check:Чек`,
  [MODE_TYPES.CUSTOMER_DISPLAY_RECEIPT]: $localize`:|@@device-type-api_service.buyer-display.-check:Дисплей покупателя. Чек`,
  [MODE_TYPES.CUSTOMER_DISPLAY_EMPTY_RECEIPT]: $localize`:|@@device-type-api_service.buyer-display.-full-screen:Дисплей покупателя. Полный экран`,
};
