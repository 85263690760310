import {
  Component,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CatalogApiService } from '../../../../../core/service/api/catalog-api/catalog-api.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { HttpEventType } from '@angular/common/http';
import { concatAll } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUtilsClass } from '../../../../../core/class/file-utils.class';
import { FileApiService } from '../../../../../core/service/api/file-api/file-api.service';
import { NgbdModalDialogPlatformPopupComponent } from '../../../../../core/service/platformPopup/platformPopup.service';
import { LoadableItemClass } from '../../../../../core/class/loadable-item.class';
import { VideoPlayerUtilsClass } from '../../../../../modules/player/video-core/class/video-player-utils.class';
import { ContentFileApiService } from '../../../../../core/service/api/content-file-api/content-file-api.service';
import { PlayersPopupService } from '../../../../../core/service/playersPopup/playersPopup.service';
import { LocationApiService } from '../../../../../core/service/api/location-api/location-api.service';
import { LabelApiService } from '../../../../../core/service/api/label-api/label-api.service';
import { LocationPopupService } from '../../../../../core/service/locationPopup/locationPopup.service';
import { LabelsBWPopupService } from '../../../../../core/service/labelsBWPopup/labelsBWPopup.service';
import { PLATFORM_NAMES } from '../../../../../core/service/api/platform-api/variables';
import { PLAYER_STATUS_NAMES } from '../../../../../core/service/api/player-api/variables';
import { ConfirmActionService } from '../../../../../core/service/confirmAction/confirmAction.service';

@Component({
  selector: 'app-file-settings',
  templateUrl: './file-settings.component.html',
  styleUrls: ['./file-settings.component.scss'],
  providers: [
    CatalogApiService,
    FileApiService,
    ContentFileApiService,
    PlayersPopupService,
    LocationApiService,
    LabelApiService,
    LocationPopupService,
    LabelsBWPopupService,
    ConfirmActionService,
  ],
})
export class FileSettingsComponent {
  public PAGE_ITEMS_COUNT = 20;
  public pageIndex = 1;
  public totalItems = 0;

  private model: any;
  public fileInfo: LoadableItemClass<any> = new LoadableItemClass();
  public locationsList: LoadableItemClass<any> = new LoadableItemClass();

  public locationNames = new Map();

  public videoPlayerUtilsClass = VideoPlayerUtilsClass;

  public selectedIds: Set<number> = new Set();
  public deletedIds: Set<number> = new Set();
  public fileId;

  public PLATFORM_NAMES = PLATFORM_NAMES;
  public PLAYER_STATUS_NAMES = PLAYER_STATUS_NAMES;

  public playerItems: LoadableItemClass<any> = new LoadableItemClass();

  constructor(
    public activeModal: NgbActiveModal,

    private catalogApiService: CatalogApiService,
    private fileApiService: FileApiService,
    public contentFileApiService: ContentFileApiService,
    private playersPopupService: PlayersPopupService,
    private confirmActionService: ConfirmActionService,

    private locationApiService: LocationApiService,
    private labelApiService: LabelApiService,
    private locationPopupService: LocationPopupService,
    private labelsBWPopupService: LabelsBWPopupService
  ) {}

  public loadParams(params) {
    if (typeof params.id !== 'undefined') {
      this.loadFile(params.id || params.model);
    }

    if (typeof params.model !== 'undefined') {
      this.model = params.model;
    }

    this.loadDefaultParams();
    this.getPlayers();
    this.getLocations();
  }

  loadFile(id) {
    this.fileId = id;
    this.getInfo();
  }

  getInfo() {
    this.fileApiService
      .getInfo$(this.fileId)
      .pipe(this.fileInfo.loadingOperator)
      .subscribe(result => {
        this.fileInfo.item = result;
      });
  }

  getPlayers() {
    this.playerItems.isLoading = true;
    this.contentFileApiService
      .getPlayersId$(this.fileId)
      .subscribe((result: any[]) => {
        this.selectedIds = new Set(result);
        this.resetPages();
      });
  }

  addPlayers() {
    this.playersPopupService.open({}).then(
      (ids = []) => {
        ids.forEach(i => this.selectedIds.add(i));
        this.savePlayers();
      },
      () => {}
    );
  }

  savePlayers() {
    this.contentFileApiService
      .savePlayersId$(this.fileId, Array.from(this.selectedIds))
      .subscribe(
        r => {
          this.resetPages();
        },
        error => {}
      );
  }

  changeSelected($event, id) {
    !!(<HTMLInputElement>$event.target).checked
      ? this.deletedIds.add(id)
      : this.deletedIds.delete(id);
  }

  confirmDelete() {
    if (!this.deletedIds.size) return;

    this.confirmActionService.confirm(
      $localize`:|@@file-settings_page.are-you-sure-you-want-to-exclude:Вы уверены, что хотите исключить ` +
        this.deletedIds.size +
        $localize`:|@@file-settings_page.players: плеера(ов)?`,
      () => {
        this.deletedIds.forEach(id => {
          this.selectedIds.delete(id);
        });

        this.deletedIds.clear();
        this.savePlayers();
      }
    );
  }

  //-----------------------

  isNameEditing(fileItem) {
    return fileItem && fileItem?.$isEditName;
  }

  switchEdit(fileItem) {
    fileItem.$isEditName = true;
    fileItem.$editName = fileItem.name;
  }

  saveName(fileItem) {
    const savedName = fileItem.$editName;
    fileItem.$isUpdaiting = true;
    this.fileApiService
      .update$(fileItem.fileName, {
        name: fileItem.$editName,
      })
      .subscribe(
        result => {
          fileItem.name = savedName;

          if (typeof this.model !== 'undefined') {
            this.model.name = fileItem.name;
          }

          delete fileItem.$isUpdaiting;
          this.cancelEditName(fileItem);
        },
        () => {
          this.cancelEditName(fileItem);
          delete fileItem.$isUpdaiting;
        }
      );
  }

  cancelEditName(fileItem) {
    delete fileItem.$isEditName;
    delete fileItem.$editName;
  }

  //---------------------------------------

  setPage(page) {
    this.pageIndex = page;
    this.contentFileApiService.queryPlayersParams.setPage(this.pageIndex - 1);
    this.loadItems();
  }

  loadDefaultParams() {
    this.contentFileApiService.queryPlayersParams.setFilterValue(
      'platformTypes',
      Object.keys(PLATFORM_NAMES)
    );
    this.contentFileApiService.queryPlayersParams.setFilterValue(
      'labels',
      undefined
    );
    this.contentFileApiService.queryPlayersParams.setFilterValue(
      'states',
      Object.keys(PLAYER_STATUS_NAMES)
    );

    this.contentFileApiService.queryPlayersParams.setPageLimit(
      this.PAGE_ITEMS_COUNT
    );
    this.pageIndex = 1;
    this.contentFileApiService.queryPlayersParams.setPage(1);
  }

  loadItems() {
    this.playerItems.items = [];

    this.contentFileApiService
      .queryPlayers$(
        this.fileId,
        this.contentFileApiService.queryPlayersParams.params
      )
      .pipe(this.playerItems.loadingOperator)
      .subscribe(
        (result: any) => {
          this.playerItems.addItems(result.items);
          this.totalItems = result.total;
        },
        error => {
          this.playerItems.items = [];
          this.totalItems = 0;
        }
      );
  }

  onSearchChange($event) {
    this.contentFileApiService.queryPlayersParams.search($event);
    this.resetPages();
  }

  nextPage() {
    this.contentFileApiService.queryPlayersParams.next();
    this.loadItems();
  }

  resetPages() {
    this.contentFileApiService.queryPlayersParams.reset();
    this.playerItems.items = [];
    this.setPage(1);
  }

  getLocations() {
    this.locationApiService.query$().subscribe(result => {
      this.locationsList.addItems(result);
    });
  }

  findLabels = (tagsString): any => {
    return this.labelApiService.find$(tagsString);
  };

  addLocationsFilter() {
    this.locationPopupService.open().then(
      (result = []) => {
        let currentArray =
          this.contentFileApiService.queryPlayersParams.getFilterValue(
            'locationIds'
          ) || [];
        result.forEach(i => {
          this.locationNames.set(i.id, i.name);
          if (currentArray.indexOf(i.id) === -1) currentArray.push(i.id);
        });

        this.contentFileApiService.queryPlayersParams.setFilterValue(
          'locationIds',
          currentArray.length ? currentArray : undefined
        );
        this.resetPages();
      },
      () => {}
    );
  }

  removeFromFilter(filterIdm, id, skipReset = false) {
    let currentArray =
      this.contentFileApiService.queryPlayersParams.getFilterValue(filterIdm);
    if (!currentArray) return;

    currentArray = currentArray.filter(i => i !== id);
    this.contentFileApiService.queryPlayersParams.setFilterValue(
      filterIdm,
      currentArray.length ? currentArray : undefined
    );

    if (!skipReset) this.resetPages();
  }

  public addLabels() {
    this.labelsBWPopupService
      .open({
        model:
          this.contentFileApiService.queryPlayersParams.getFilterValue(
            'labels'
          ) || [],
      })
      .then(
        result => {
          if (!!result)
            this.contentFileApiService.queryPlayersParams.setFilterValue(
              'labels',
              result
            );

          this.resetPages();
        },
        () => {}
      );
  }
}

@Injectable()
export class FileSettingsPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(FileSettingsComponent, {
      modalDialogClass: 'modal-dialog-platform-popup',
      size: 'xl',
    });
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
