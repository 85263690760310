import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app',
  template: '<ui-view></ui-view>',
  providers: [],
})
export class AppComponent implements OnInit {
  constructor(public $state: StateService) {}

  ngOnInit() {
    /*    setTimeout(() => {


      let index = 0;
      let roots =  ['root.player.list', 'root.platform.list', 'root.schedule.list'];

      let run = () => {
        index++;
        if (index >= roots.length)
          index = 0;

        this.$state.go(roots[index])
          .then(() => {
            setTimeout( () => run(), 500);
          });
      }

      run();
    }, 2000);*/
  }
}
