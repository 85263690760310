import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationApiService } from '../api/location-api/location-api.service';
import { LocationGroupApiService } from '../api/location-group-api/location-group-api.service';
import { LoadableItemClass } from '../../class/loadable-item.class';
import { tap } from 'rxjs';

@Component({
  selector: 'ngbd-modal-content',
  styleUrls: ['./locationPopup.service.scss'],
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5
        class="modal-title ng-binding"
        i18n="@@locationPopup_service.selection-of-a-retail-facility">
        Выбор торгового объекта
      </h5>
    </div>
    <div class="modal-body   p-0 d-flex">
      <div class="col-sm-3 bg-light border-end d-flex flex-column overflow-col">
        <div class="inner-block">
          <div class="p-2">
            <app-search-input
              [model]="searchGroupString"
              (modelChange)="searchGroupString = $event"
              placeholder="Поиск локаций"
              size="sm"
              i18n-placeholder="
                @@locationPopup_service.location-search"></app-search-input>
          </div>

          <div class="p-2">
            <ul class="list-group mb-2">
              <li
                class="list-group-item list-group-item-action"
                [class.active]="activeLocationGroup === -1"
                (click)="selectLocationGroup(-1)">
                <span i18n="@@locationPopup_service.all">ВСЕ</span>
              </li>
            </ul>
            <div class="mb-1">
              <small
                class="text-muted"
                i18n="@@locationPopup_service.separately-by-location"
                >Отдельно по локациям:</small
              >
            </div>
            <ul class="list-group">
              <li
                *ngFor="
                  let item of locationGroupsList.items
                    | callback: filterBySearch.bind(this)
                "
                class="list-group-item list-group-item-action"
                [class.active]="activeLocationGroup === item.id"
                (click)="selectLocationGroup(item.id)">
                <text-highlight [text]="item.name" [search]="searchGroupString">
                </text-highlight>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col overflow-col">
        <div class="inner-block" id="locationPopupItemsContainer">
          <div class="p-2 ">
            <app-search-input
              (modelChange)="onSearchChange($event)"
              placeholder="Введите наименование торгового объекта"
              size="sm"
              i18n-placeholder="
                @@locationPopup_service.enter-the-name-of-the-retail-facility"></app-search-input>
          </div>

          <div
            infiniteScroll=""
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            [fromRoot]="true"
            (scrolled)="nextPage()"
            [infiniteScrollContainer]="'#locationPopupItemsContainer'">
            <responsive-table [disabled]="true" >
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width:1px; text-align: center">
                    <div class="form-check ms-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        [checked]="isAllChecked()"
                        (change)="checkAll($event)" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    i18n="@@locationPopup_service.table.table-header.name">
                    Наименование
                  </th>
                  <th
                    scope="col"
                    i18n="@@locationPopup_service.table.table-header.code">
                    Код
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let item of locationList.items"
                  (click)="check(item.id); $event.stopPropagation()">
                  <td style="text-align: center">
                    <div class="form-check ms-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="selected.has(item.id)"
                        id="flexCheckDefault"
                        (change)="check(item.id)"
                        (click)="$event.stopPropagation()" />
                    </div>
                  </td>
                  <td>
                    <text-highlight
                      [text]="item.name"
                      [search]="
                        this.locationApiService?.queryParams?.getSearchValue()
                      ">
                    </text-highlight>
                    <div class="clearfix small text-muted">
                      ID: {{ item.id }}
                    </div>
                  </td>
                  <td [innerText]="item.code"></td>
                </tr>
              </tbody>
            </table>
            </responsive-table>
          </div>

          <div class="text-center" *ngIf="locationList.isLoading">
            <i
              class="fas fa-spin fa-3x fa-spinner  text-primary"
              [class.mt-5]="
                !locationList.items || !locationList.items.length
              "></i>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-addon "
        type="button"
        (click)="okEvent()"
        *ngIf="selected.size">
        <i class="fas fa-check"></i
        ><ng-container
          i18n="@@locationPopup_service.button.select-selected.size">
          Выбрать: {{ selected.size }}
        </ng-container>
      </button>

      <button
        class="btn btn-danger btn-addon btn-danger"
        type="button"
        (click)="cancelEvent()">
        <i class="fas fa-times"></i
        ><ng-container i18n="@@locationPopup_service.button.cancellation">
          Отмена
        </ng-container>
      </button>
    </div>
  `,
  providers: [LocationApiService, LocationGroupApiService],
})
export class NgbdModalDialogLocationPopupComponent implements OnDestroy {
  public searchGroupString = '';

  public activeLocationGroup = -1;

  public locationGroupsList: LoadableItemClass<any> = new LoadableItemClass();
  public locationList: LoadableItemClass<any> = new LoadableItemClass();

  public selected = new Set();
  public cachedNames = new Map<any, any>();

  constructor(
    public activeModal: NgbActiveModal,
    public locationApiService: LocationApiService,
    public locationGroupApiService: LocationGroupApiService
  ) {}

  public loadParams(params: any = {}) {
    if (Array.isArray(params.model)) {
      this.selected.clear();
      params.model.forEach(i => {
        switch (true) {
          case typeof i === 'number':
            this.selected.add(i);
            break;
          case typeof i === 'object' && typeof i.id === 'number':
            this.selected.add(i.id);
            break;
          case typeof i === 'object' && typeof i.id === 'string':
            this.selected.add(parseInt(i.id));
            break;
        }
      });
    }

    this.init();
  }

  public init() {
    this.getlocationGroups();
    this.selectLocationGroup(-1);
  }

  getlocationGroups() {
    this.locationGroupApiService
      .query$()
      .pipe(this.locationGroupsList.loadingOperator)
      .subscribe(result => {
        this.locationGroupsList.addItems(result);
      });
  }

  selectLocationGroup(id) {
    this.activeLocationGroup = id;
    this.resetItems();
  }

  getRequester(getAll?) {
    let params =
      this.activeLocationGroup === -1
        ? this.locationApiService.queryParams.params
        : this.locationApiService.locationsByGroupParams.params;

    if (getAll) {
      params['paging'] = {
        offset: 0,
        limit: 999999,
      };
    }

    return (
      this.activeLocationGroup === -1
        ? this.locationApiService.query$(params)
        : this.locationApiService.getLocationsByGroup$(
            this.activeLocationGroup,
            params
          )
    ).pipe(
      tap(i => {
        if (Array.isArray(i)) {
          i.forEach((location: any) => {
            if (!!location?.name && !!location?.id)
              this.cachedNames.set(location?.id, location?.name);
          });
        }
      })
    );
  }

  getLocations() {
    if (this.locationList.isLoading) return;

    this.getRequester()
      .pipe(this.locationList.loadingOperator)
      .subscribe(result => {
        this.locationList.addItems(result.items || result);
        this.locationList.items.forEach(i => {
          this.cachedNames.set(i.id, i.name);
        });
      });
  }

  isAllChecked() {
    if (
      this.selected.size === 0 ||
      this.selected.size < this.locationList.items.length
    )
      return false;

    return !this.locationList.items.some(i => !this.selected.has(i.id));
  }

  checkAll(value) {
    this.getRequester(true).subscribe(result => {
      result?.forEach(i => {
        if (value.target.checked) {
          this.selected.add(i.id);
        } else {
          this.selected.delete(i.id);
        }
      });
    });
  }

  check(id) {
    if (!this.selected.has(id)) this.selected.add(id);
    else this.selected.delete(id);
  }

  okEvent() {
    this.activeModal.close(
      Array.from(this.selected).map(i => ({
        id: i,
        name: this.cachedNames.get(i),
      }))
    );
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  filterBySearch(item) {
    if (!this.searchGroupString) return true;

    return item.name
      .toLowerCase()
      .includes(this.searchGroupString.toLowerCase());
  }

  onSearchChange($event) {
    this.locationApiService.queryParams.search($event);
    this.locationApiService.locationsByGroupParams.search($event);

    this.resetItems();
  }

  resetItems() {
    this.locationList.items = [];
    this.locationApiService.queryParams.reset();
    this.locationApiService.locationsByGroupParams.reset();

    this.getLocations();
  }

  nextPage() {
    if (this.locationList.isLoading) return;

    this.locationApiService.queryParams.next();
    this.locationApiService.locationsByGroupParams.next();

    this.getLocations();
  }

  ngOnDestroy() {
    this.selected.clear();
  }
}

@Injectable()
export class LocationPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(
      NgbdModalDialogLocationPopupComponent,
      {
        modalDialogClass: 'modal-dialog-location-popup',
        size: 'xl',
      }
    );
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
