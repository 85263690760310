import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import {
  FileStatisticRequest,
  HistoryDownloadBarChartRequest,
  HistoryDownloadRequest,
  HistoryRequest,
  PlayerApiService as PlayerApiServiceGen,
  PlayerCreateApi,
  PlayerDownloadRequestStatApi,
  PlayerRequestApi,
  PlayerSchedulesRequest,
  ScheduleHistoryBarChartRequest,
  ScheduleHistoryRequest,
  StatisticRequest,
} from '../generated/Player';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { isEmptyCheck } from '../tools.functions';
import { ApiRequestHandlerClass } from '../../../class/api-request-handler.class';
import { PLAYER_STATUS } from './variables';

export * from './variables';

@Injectable()
export class PlayerApiService extends ApiRequestHandlerClass {
  constructor(
    private playerAPIService: PlayerApiServiceGen,
    private partnerApiService: PartnerApiService,
    localStorageService: LocalStorageService
  ) {
    super();
    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'platformTypes',
            values: {
              ukm4: 'ukm4',
              ukm5: 'ukm5',
            },
            currentValue: undefined,
          },
          {
            field: 'locationIds',
            currentValue: undefined,
          },
          {
            field: 'deviceTypeIds',
            currentValue: undefined,
          },
          {
            field: 'deviceId',
            currentValue: undefined,
          },
          {
            field: 'platforms',
            currentValue: undefined,
          },
          {
            field: 'states',
            currentValue: undefined,
          },
          {
            field: 'labels',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );

    this.schedulesParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        search: {
          field: 'query',
        },
        filters: [
          {
            field: 'dateFrom',
            currentValue: undefined,
          },
          {
            field: 'dateTo',
            currentValue: undefined,
          },
          {
            field: 'states',
            currentValue: undefined,
          },
          {
            field: 'scheduleStates',
            currentValue: undefined,
          },
          {
            field: 'playerId',
            currentValue: undefined,
          },
          {
            field: 'id',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );

    this.schedulesBarChart = new ParamsHandler(
      {
        filters: [
          {
            field: 'fromDate',
            currentValue: undefined,
          },
          {
            field: 'toDate',
            currentValue: undefined,
          },
          {
            field: 'chartOps',
            currentValue: undefined,
          },
          {
            field: 'playerId',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );

    this.historyParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'fromDate',
            currentValue: undefined,
          },
          {
            field: 'toDate',
            currentValue: undefined,
          },
          {
            field: 'playerId',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );

    this.downloadHistoryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'fromDate',
            currentValue: undefined,
          },
          {
            field: 'toDate',
            currentValue: undefined,
          },
          {
            field: 'playerId',
            currentValue: undefined,
          },
          {
            field: 'state',
            currentValue: undefined,
          },
          {
            field: 'fileTypes',
            currentValue: undefined,
          },
          {
            field: 'tags',
            currentValue: undefined,
            type: 'array',
          },
        ],
      },
      localStorageService
    );

    this.playerStatDownloadParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'dateFrom',
            currentValue: undefined,
          },
          {
            field: 'dateTo',
            currentValue: undefined,
          },
          {
            field: 'state',
            currentValue: undefined,
          },
        ],
      },
      localStorageService
    );

    this.playerStatParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        filters: [
          {
            field: 'fileType',
            currentValue: undefined,
          },
          {
            field: 'fromDate',
            currentValue: undefined,
          },
          {
            field: 'toDate',
            currentValue: undefined,
          },
          {
            field: 'tags',
            currentValue: undefined,
            type: 'array',
          },
        ],
      },
      localStorageService
    );

    this.downloadHistoryBarChartParams = new ParamsHandler(
      {
        filters: [
          {
            field: 'playerId',
            currentValue: undefined,
          },
          {
            field: 'fileTypes',
            currentValue: undefined,
          },
          {
            field: 'fromDate',
            currentValue: undefined,
          },
          {
            field: 'toDate',
            currentValue: undefined,
          },
          {
            field: 'chartOps',
            currentValue: undefined,
          },
          {
            field: 'tags',
            currentValue: undefined,
            type: 'array',
          },
        ],
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;
  public schedulesParams: ParamsHandler;
  public schedulesBarChart: ParamsHandler;

  public historyParams: ParamsHandler;
  public downloadHistoryParams: ParamsHandler;
  public downloadHistoryBarChartParams: ParamsHandler;
  public playerStatDownloadParams: ParamsHandler;

  public playerStatParams: ParamsHandler;

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 50,
    },
    status: {
      required: true,
    },
    platform: {
      required: true,
    },
    location: {
      required: true,
    },
  });

  private updateIdFields(body): any {
    let tmp = Object.assign(
      {
        platformId: body.platform?.id,
        locationId: body.location?.id,
      },
      body
    );
    delete tmp.platform;
    delete tmp.location;

    return tmp;
  }

  query$(
    body: PlayerRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService
      .postPartnerPartneridPlayerQuery({
        partnerId,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@player-api_service.error-getting-list-of-players:Ошибка получения списка плееров`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.playerAPIService
      .getPartnerPartneridPlayerId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@player-api_service.player-not-found:Плеер не найден`
          );
          return throwError(error);
        })
      );
  }

  getByIds$(
    ids: number[],
    body: PlayerRequestApi =  {
      paging: {},
      platforms: [],
      locationIds: [],
      labels: [],
      states: []
    },
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.query$(
      Object.assign(body, { ids}),
      partnerId
    );
  }

  create$(
    body: PlayerCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    body = this.updateIdFields(body);
    return of({}); // ЗАглушка
  }

  update$(body: any, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof body.id === 'undefined') return this.create$(body, partnerId);

    body = this.updateIdFields(body);
    return this.playerAPIService
      .putPartnerPartneridPlayerId({
        partnerId,
        id: <any>body.id,
        body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@player-api_service.error-saving-player:Ошибка сохранения плеера`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.playerAPIService
      .deletePartnerPartneridPlayerId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@player-api_service.player-deletion-error:Ошибка удаления плеера`
          );
          return throwError(error);
        })
      );
  }

  stat$(id = this.partnerApiService.currentPartnerId) {
    return this.playerAPIService.getPartnerPartneridPlayerStat({
      partnerId: id,
    });
  }

  schedules$(
    body: PlayerSchedulesRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerSchedules({
      partnerId,
      body,
    });
  }

  schedulesHistory$(
    body: ScheduleHistoryRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerSchedulehistory({
      partnerId,
      body,
    });
  }

  schedulesBarChart$(
    body: ScheduleHistoryBarChartRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerSchedulehistorybarchart(
      {
        partnerId,
        body,
      }
    );
  }

  history$(
    body: HistoryRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerHistory({
      partnerId,
      body,
    });
  }

  downloadHistory$(
    body: HistoryDownloadRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerDownloadhistory({
      partnerId,
      body,
    });
  }

  downloadHistoryBarChart$(
    body: HistoryDownloadBarChartRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerDownloadhistorybarchart(
      {
        partnerId,
        body,
      }
    );
  }

  playerStat$(
    playerId: number,
    body: StatisticRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerIdStat({
      id: playerId,
      partnerId,
      body,
    });
  }

  playerStatDetails$(
    id: number,
    body: FileStatisticRequest,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerIdStatDetails({
      id,
      partnerId,
      body,
    });
  }

  playerStatDownload$(
    id: number,
    body: PlayerDownloadRequestStatApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.playerAPIService.postPartnerPartneridPlayerIdStatDownload({
      id,
      partnerId,
      body,
    });
  }

  getOrInit$(
    id?: number | string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof id === 'undefined' || <any>id === '')
      return of({
        status: PLAYER_STATUS.active,
        canEdit: true,
        platform: {},
        location: {},
        labels: [],
      });

    return this.get$(parseInt(id.toString()), partnerId).pipe(
      catchError(error => of({}))
    );
  }

  getApiUrl(id: string, partnerId = this.partnerApiService.currentPartnerId) {
    return `/partner/${partnerId}/player/${id}/info`;
  }
}
