<div class="clearfix" *ngIf="isEnabled">
  <div class="row">
    <div class="col">
      <div class="input-group" [class.input-group-sm]="size === 'sm'">
        <input
          type="text"
          class="form-control"
          [title]="placeholder || ''"
          [(ngModel)]="_searchString"
          (keydown)="_keyHook($event)"
          [placeholder]="placeholder || ''"
          [disabled]="isPending"
        />

 <!--       <span class="input-group-addon m-l-n-xxs" *ngIf="isPending">
          <i class="fas fa-spin fa-spinner"></i>
        </span>
-->
        <button
          *ngIf="isSearchEnabled"
          class="btn btn-danger"
          [class.btn-sm]="size === 'sm'"
          type="button"
          (click)="resetSearch()"
          [disabled]="isPending"
        >
          <i class="fas fa-times"></i>
        </button>

        <button
          id="db-search"
          class="btn btn-default"
          type="button"
          [class.btn-sm]="size === 'sm'"
          (click)="setSearch()"
          [disabled]="isPending"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</div>
