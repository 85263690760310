import { Injectable } from '@angular/core';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import {
  TemplateApiService as TemplateApiServiceGen,
  TemplateCreateApi,
  TemplateRequestApi,
} from '../generated/Template';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { isEmptyCheck } from '../tools.functions';
export * from './variables';

const updateBody = function (tpmObj: any = {}) {
  if (!tpmObj.params) tpmObj.params = {};

  if (typeof tpmObj.params === 'object')
    tpmObj.params = JSON.stringify(tpmObj.params);
};

@Injectable()
export class TemplateApiService {
  constructor(
    private templateAPIService: TemplateApiServiceGen,
    private partnerApiService: PartnerApiService,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {
    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
        search: {
          field: 'query',
        },
        sort: {
          type: 'object',
          sortField: 'sorting.column',
          orderField: 'sorting.dir',
        },
        filters: [
          {
            field: 'states',
            currentValue: undefined,
          },
          {
            field: 'tags',
            currentValue: undefined,
            type: 'array',
          },
        ],
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 50,
    },

    description: {
      required: true,
      maxlength: 50,
    },

    logoFileName: {
      required: false,
      maxlength: 256,
    },

    params: {
      required: false,
      maxlength: 4294967295,
    },

    content: {
      required: true,
    },

    createdAt: {
      required: true,
    },

    state: {
      required: true,
    },
  });

  query$(
    body: TemplateRequestApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (isEmptyCheck(body)) {
      return throwError({});
    }

    return this.templateAPIService
      .postPartnerPartneridTemplateQuery({ body, partnerId })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.error-receiving-templates:Ошибка получания шаблонов`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.templateAPIService
      .getPartnerPartneridTemplateId({
        partnerId,
        id,
      })
      .pipe(
        map(i => {
          if (typeof i.params === 'string') {
            try {
              i.params = JSON.parse(i.params);
            } catch (e) {
              i.params = <any>{};
            }
          }
          return i;
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.template-request-error:Ошибка запроса шаблона`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: TemplateCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    let tpmObj: any = Object.assign({}, body);

    updateBody(tpmObj);

    return this.templateAPIService
      .postPartnerPartneridTemplate({
        partnerId,
        body: tpmObj,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.template-creation-error:Ошибка создания шаблона`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: TemplateCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    let tpmObj: any = Object.assign({}, body);

    updateBody(tpmObj);

    if (typeof tpmObj.id === 'undefined')
      return this.create$(<any>tpmObj, partnerId);

    return this.templateAPIService
      .postPartnerPartneridTemplateId({
        partnerId,
        id: tpmObj.id,
        body: <any>tpmObj,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.template-update-error:Ошибка обновления шаблона`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.templateAPIService
      .deletePartnerPartneridTemplateId({ partnerId, id })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.template-deletion-error:Ошибка удаления шаблона`
          );
          return throwError(error);
        })
      );
  }

  checkSchedules$(
    id: any,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.templateAPIService
      .getPartnerPartneridTemplateIdCheck({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.template-deletion-error2:Ошибка удаления шаблона`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '') return of({});

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }

  getTestdata$(partnerId = this.partnerApiService.currentPartnerId) {
    return this.templateAPIService
      .getPartnerPartneridTemplateTestdata({ partnerId })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@template-api_service.error-receiving-test-data:Ошибка получения тестовых данных`
          );
          return throwError(error);
        })
      );
  }
}
