import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadableItemClass } from '../../class/loadable-item.class';
import {
  PLATFORM_AUDIO_CODEC_NAMES,
  PLATFORM_CONTAINER_NAMES,
  PLATFORM_NAMES,
  PLATFORM_ORIENTATION_NAMES,
  PLATFORM_VIDEO_CODEC_NAMES,
  PlatformApiService,
} from '../api/platform-api/platform-api.service';

@Component({
  selector: 'ngbd-modal-platformPopup',
  styleUrls: ['./platformPopup.service.scss'],
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5
        class="modal-title ng-binding"
        i18n="@@platformPopup_service.platform-selection">
        Выбор платформы
      </h5>
    </div>
    <div class="modal-body overflow-wrap-break p-0 d-flex overflow-auto">
      <div class="col p-0">
        <div class=" d-block p-3 pt-2 pb-2 ">
          <app-search-input
            (modelChange)="onSearchChange($event)"
            placeholder="Введите наименование платформы"
            size="sm"
            i18n-placeholder="
              @@platformPopup_service.enter-the-name-of-the-platform"></app-search-input>
        </div>

        <div
          infiniteScroll=""
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [fromRoot]="true"
          (scrolled)="nextPage()">
          <responsive-table [disabled]="true">
          <table class="table table-hover" *ngIf="!platformList.isLoading">
            <thead>
              <tr>
                <th style="width: 1px" class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      [checked]="isAllChecked()"
                      (change)="checkAll($event)" />
                  </div>
                </th>
                <th scope="col">
                  <sort-view
                    column="name"
                    [params]="platformApiService.queryParams"
                    (onChange)="resetItems()"
                    i18n="@@platformPopup_service.table.table-header.name">
                    Наименование
                  </sort-view>
                </th>
                <th
                  scope="col"
                  i18n="@@platformPopup_service.table.table-header.type">
                  Тип
                </th>
                <th
                  scope="col"
                  i18n="@@platformPopup_service.table.table-header.permission">
                  Разрешение
                </th>
                <th
                  scope="col"
                  i18n="
                    @@platformPopup_service.table.table-header.video-settings">
                  Настройки видео
                </th>
                <th
                  scope="col"
                  i18n="
                    @@platformPopup_service.table.table-header.audio-settings">
                  Настройки аудио
                </th>
                <th
                  scope="col"
                  i18n="@@platformPopup_service.table.table-header.container">
                  Контейнер
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let item of platformList.items"
                (click)="check(item.id); $event.stopPropagation()">
                <td class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="selected.has(item.id)"
                      id="flexCheckDefault"
                      (change)="check(item.id)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </td>
                <td>
                  <text-highlight
                    [search]="platformApiService.queryParams.getSearchValue()"
                    [text]="item.name"></text-highlight>

                  <!--
                                <div class="clearfix"
                                >
                                  <small class="text-success"> <i class="fas fa-cogs fa-sm"></i> Стандартная платформа</small>
                                </div>

                -->
                </td>
                <td [innerText]="PLATFORM_NAMES[item.platformType]"></td>
                <td>
                  <div>{{ item.resolutionX }}x{{ item.resolutionY }}</div>
                  <small
                    [innerText]="
                      PLATFORM_ORIENTATION_NAMES[item.orientation]
                    "></small>
                </td>
                <td>
                  <div>
                    <ng-container
                      i18n="@@platformPopup_service.table.table-row.codec">
                      Кодек: </ng-container
                    ><span
                      [innerText]="
                        PLATFORM_VIDEO_CODEC_NAMES[item.videoCodec]
                      "></span>
                  </div>
                  <small
                    ><ng-container
                      i18n="@@platformPopup_service.table.table-row.bitrate">
                      Битрейт: </ng-container
                    ><span [innerText]="item.bitrateKbps"></span> Kb/s
                  </small>
                </td>
                <td>
                  <div>
                    <ng-container
                      i18n="@@platformPopup_service.table.table-row.codec2">
                      Кодек: </ng-container
                    ><span
                      [innerText]="
                        PLATFORM_AUDIO_CODEC_NAMES[item.audioCodec]
                      "></span>
                  </div>
                </td>

                <td>
                  <span
                    [innerText]="
                      PLATFORM_CONTAINER_NAMES[item.container]
                    "></span>
                </td>
              </tr>
            </tbody>
          </table>
          </responsive-table>
        </div>

        <div class="text-center" *ngIf="platformList.isLoading">
          <i class="fas fa-spin fa-spinner mt-5  text-primary"></i>
        </div>
      </div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-addon "
        type="button"
        (click)="okEvent()"
        *ngIf="selected.size"
        ng-click="okEvent()">
        <i class="fas fa-check"></i
        ><ng-container
          i18n="@@platformPopup_service.button.select-selected.size">
          Выбрать: {{ selected.size }}
        </ng-container>
      </button>

      <button
        class="btn btn-danger btn-addon btn-danger"
        type="button"
        (click)="cancelEvent()"
        ng-click="cancelEvent()">
        <i class="fas fa-times"></i
        ><ng-container i18n="@@platformPopup_service.button.cancellation">
          Отмена
        </ng-container>
      </button>
    </div>
  `,
  providers: [PlatformApiService],
})
export class NgbdModalDialogPlatformPopupComponent implements OnDestroy {
  public searchGroupString = '';

  public activePlatform = -1;

  public locationGroupsList: LoadableItemClass<any> = new LoadableItemClass();
  public platformList: LoadableItemClass<any> = new LoadableItemClass();

  public selected = new Set();
  public cachedNames = new Map<any, any>();

  private total;

  public PLATFORM_NAMES = PLATFORM_NAMES;
  public PLATFORM_CONTAINER_NAMES = PLATFORM_CONTAINER_NAMES;
  public PLATFORM_AUDIO_CODEC_NAMES = PLATFORM_AUDIO_CODEC_NAMES;
  public PLATFORM_VIDEO_CODEC_NAMES = PLATFORM_VIDEO_CODEC_NAMES;
  public PLATFORM_ORIENTATION_NAMES = PLATFORM_ORIENTATION_NAMES;

  constructor(
    public activeModal: NgbActiveModal,
    public platformApiService: PlatformApiService
  ) {}

  public loadParams(params: any = {}) {
    if (Array.isArray(params.model)) {
      this.selected.clear();
      params.model.forEach(i => {
        switch (true) {
          case typeof i === 'number':
            this.selected.add(i);
            break;
          case typeof i === 'object' && typeof i.id === 'number':
            this.selected.add(i.id);
            break;
          case typeof i === 'object' && typeof i.id === 'string':
            this.selected.add(parseInt(i.id));
            break;
        }
      });
    }

    this.init();
  }

  public init() {
    this.platformApiService.queryParams.setPageLimit(9999);
    this.resetItems();
  }

  getPlatforms() {
    if (this.platformList.isLoading) return;

    let requester: any = this.platformApiService.query$(
      this.platformApiService.queryParams.params
    );

    requester.pipe(this.platformList.loadingOperator).subscribe(result => {
      this.platformList.addItems(result.items || result);
      this.total = result.total;

      this.platformList.items.forEach(i => {
        this.cachedNames.set(i.id, i.name);
      });
    });
  }

  isAllChecked() {
    if (
      this.selected.size === 0 ||
      this.selected.size < this.platformList.items.length
    )
      return false;

    return !this.platformList.items.some(i => !this.selected.has(i.id));
  }

  checkAll(value) {
    if (value.target.checked) {
      this.platformList.items.forEach(i => this.selected.add(i.id));
    } else {
      this.platformList.items.forEach(i => this.selected.delete(i.id));
    }
  }

  check(id) {
    if (!this.selected.has(id)) this.selected.add(id);
    else this.selected.delete(id);
  }

  okEvent() {
    this.activeModal.close(
      Array.from(this.selected).map(i => ({
        id: i,
        name: this.cachedNames.get(i),
      }))
    );
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  onSearchChange($event) {
    this.platformApiService.queryParams.search($event);

    this.resetItems();
  }

  resetItems() {
    this.platformList.items = [];
    this.platformApiService.queryParams.reset();

    this.getPlatforms();
  }

  nextPage() {
    if (this.platformList.isLoading) return;

    this.platformApiService.queryParams.next();

    this.getPlatforms();
  }

  ngOnDestroy() {
    this.selected.clear();
  }
}

@Injectable()
export class PlatformPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(
      NgbdModalDialogPlatformPopupComponent,
      {
        modalDialogClass: 'modal-dialog-platform-popup',
        size: 'xl',
      }
    );
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
