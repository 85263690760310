<div class="w-100 mt-2 mb-1">
  <div class="d-flex w-100">
    <div class="col ps-2 pe-2 overflow-hidden">
      <app-search-input
        [model]="searchQuery"
        placeholder="ID/Имя файла/каталога"
        (modelChange)="searchQuery = $event; changeFilter()"
        size="sm"
        i18n-placeholder="@@file-tree_page.directory-file-name">
      </app-search-input>
    </div>

    <div class="col-auto pe-2">
      <button
        class="btn btn-default btn-sm"
        ngbPopover="Поиск по тегам"
        triggers="mouseenter:mouseleave"
        (click)="switchTagActive()"
        [class.text-primary]="
          !(!!filterParams && !!filterParams.tags && !!filterParams.tags.length)
        "
        [class.text-success]="
          !!filterParams && !!filterParams.tags && !!filterParams.tags.length
        "
        i18n-ngbPopover="@@file-tree_page.search-by-tags">
        <i class="fas fa-tags"></i>
      </button>
    </div>
  </div>

  <div class="clearfix"></div>
</div>

<div
  class="border-top border-bottom w-100 mt-2 pt-1 mb-1"
  *ngIf="activeTagInput">
  <div class="d-inline-block w-100 ps-2 pe-2 overflow-hidden">
    <app-tag-input
      [model]="searchTags"
      [onlyInput]="true"
      [onlyExisting]="true"
      (onChange)="searchTags = $event; changeFilter()"
      size="sm"></app-tag-input>
  </div>

  <div class="clearfix"></div>
</div>

<div class="ps-2 pe-2">
  <tree-root
    id="treeCatalog"
    #tree=""
    class="tree mt-3"
    *ngIf="treeArray && treeArray.length"
    [focused]="true"
    [nodes]="treeArray"
    [options]="treeOptions"
    (blur)="deactivate($event)"
    (moveNode)="moveNode($event)"
    (dragend)="dragEnd($event)">
    <ng-template #treeNodeTemplate="" let-node="" let-index="index">
      <div class="d-inline-block position-relative">
        <i
          class="fa-lg"
          [class.fas]="!!node.data.hasChildren"
          [class.far]="!node.data.hasChildren"
          [class.fa-folder]="!!node.isCollapsed"
          [class.fa-folder-open]="!node.isCollapsed"></i>
        <span
          class="position-absolute translate-middle tree-node-template_badge badge rounded-pill bg-info"
          *ngIf="!!node.data.filesCount">
          <span [innerText]="node.data.filesCount"></span>
        </span>
      </div>

      <span
        *ngIf="!node.data.$isEditing"
        [class]="node.data.className"
        [class.title]="true">
        <text-highlight
          [search]="filterParams?.query || ''"
          [text]="node.data.name"></text-highlight>
      </span>

      <div
        class="d-inline-block position-relative"
        *ngIf="node.data.$isEditing">
        <input
          [(ngModel)]="node.data.name"
          #editNodeInput=""
          class="form input form-input"
          (keydown)="changeNodeKeyDown($event, node)"
          (blur)="updateNodeName(node)" />
      </div>
    </ng-template>

    <ng-template #loadingTemplate="" let-node="">
      <div class="clearfix text-center">
        <i class="fas fa-spinner fa-spin text-warning"></i>
      </div>
    </ng-template>
  </tree-root>
</div>
