import {
  Component,
  OnChanges,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  Input,
} from '@angular/core';
import { CatalogApiService } from '../../../../../core/service/api/catalog-api/catalog-api.service';

import { FileApiService } from '../../../../../core/service/api/file-api/file-api.service';

import { ConfirmActionService } from '../../../../../core/service/confirmAction/confirmAction.service';
import { ContentFileApiService } from '../../../../../core/service/api/content-file-api/content-file-api.service';
import { LoadableItemClass } from '../../../../../core/class/loadable-item.class';
import { FileActiveLocationsApi } from '../../../../../core/service/api/generated/ContentFile';
import { ListWithTotalFileActiveLocationsApi } from '../../../../../core/service/api/generated/ContentFile/models/list-with-total-file-active-locations-api';

@Component({
  selector: 'file-active-locations',
  templateUrl: './file-active-locations.component.html',
  styleUrls: ['./file-active-locations.component.scss'],
  providers: [ContentFileApiService],
})
export class FileActiveLocationsComponent implements OnInit, OnDestroy {
  @Input() fileId;

  public locationsItems: LoadableItemClass<FileActiveLocationsApi[]> =
    new LoadableItemClass();

  public PAGE_COUNT = 10;
  public pageIndex = 1;

  public PAGE_PLAYER_COUNT = 10;
  public pagePlayerIndex = 1;

  public showPlayersIndex = -1;

  constructor(private contentFileApiService: ContentFileApiService) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage() {
    this.contentFileApiService
      .queryLocations$(this.fileId)
      .pipe(<any>this.locationsItems.loadingOperator)
      .subscribe((result: any) => {
        this.locationsItems.addItems(result.items);
        this.pageIndex = 1;
      });
  }

  changePage(index) {
    this.pageIndex = index;
    this.pagePlayerIndex = 1;
    this.showPlayersIndex = -1;
  }

  showPlayers(index) {
    this.showPlayersIndex = this.showPlayersIndex === index ? -1 : index;
    this.pagePlayerIndex = 1;
  }

  ngOnDestroy() {}
}
