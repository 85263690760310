/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PartnerApi } from '../models/partner-api';
import { PartnerCreateApi } from '../models/partner-create-api';
import { PartnerSettingsApi } from '../models/partner-settings-api';
import { PartnerSettingsSaveApi } from '../models/partner-settings-save-api';
import { PartnerStatApi } from '../models/partner-stat-api';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPartnerPartnerid
   */
  static readonly GetPartnerPartneridPath = '/partner/{partnerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartnerid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartnerid$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<PartnerApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartnerid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartnerid(params: {
    partnerId: number;
  }): Observable<PartnerApi> {

    return this.getPartnerPartnerid$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerApi>) => r.body as PartnerApi)
    );
  }

  /**
   * Path part for operation postPartnerPartnerid
   */
  static readonly PostPartnerPartneridPath = '/partner/{partnerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartnerid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartnerid$Response(params: {
    partnerId: number;
    body: PartnerCreateApi
  }): Observable<StrictHttpResponse<PartnerApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostPartnerPartneridPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartnerid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartnerid(params: {
    partnerId: number;
    body: PartnerCreateApi
  }): Observable<PartnerApi> {

    return this.postPartnerPartnerid$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerApi>) => r.body as PartnerApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridStat
   */
  static readonly GetPartnerPartneridStatPath = '/partner/{partnerId}/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridStat$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<PartnerStatApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridStatPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerStatApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridStat(params: {
    partnerId: number;
  }): Observable<PartnerStatApi> {

    return this.getPartnerPartneridStat$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerStatApi>) => r.body as PartnerStatApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridSettings
   */
  static readonly GetPartnerPartneridSettingsPath = '/partner/{partnerId}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridSettings$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<PartnerSettingsApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridSettingsPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerSettingsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridSettings(params: {
    partnerId: number;
  }): Observable<PartnerSettingsApi> {

    return this.getPartnerPartneridSettings$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerSettingsApi>) => r.body as PartnerSettingsApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridSettings
   */
  static readonly PutPartnerPartneridSettingsPath = '/partner/{partnerId}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridSettings$Response(params: {
    partnerId: number;
    body: PartnerSettingsSaveApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PutPartnerPartneridSettingsPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridSettings(params: {
    partnerId: number;
    body: PartnerSettingsSaveApi
  }): Observable<void> {

    return this.putPartnerPartneridSettings$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
