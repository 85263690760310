<a
  class="app-logo d-flex"
  [dsLink]="notClicable ? '' : 'root.dashboard'"
  href="javasciprt:void(0)">
  <div class="logo-icon me-2" [class.mini-logo]="miniLogo"></div>
  <div class="visually-hidden">
    <div class="logo-text-top" i18n="@@app-logo_components.supermagician">
      СУПЕРМАГ
    </div>
    <div class="logo-text">DS</div>
  </div>
</a>
