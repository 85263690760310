import { APP_INITIALIZER, Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { CoreServicesModule } from './core/service/core-services.module';
import { PagesModule } from './pages/pages.module';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { default as fas } from '@fortawesome/fontawesome-free';
import { default as far } from '@fortawesome/fontawesome-free-regular';
import { default as fass } from '@fortawesome/fontawesome-free-solid';
import { default as fab } from '@fortawesome/fontawesome-free-brands';
import { CorePipesModule } from './core/pipe/core-pipes.module';
import { PlayerModule } from './modules/player/player.module';
import { CoreComponentsModule } from './core/components/core.components.module';
import { CoreDirectiveModule } from './core/directive/core-directive.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorCatchingInterceptor } from './core/interceptors/http-error-catching.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import {
  UserApiService,
  UserRoleEnum,
} from './core/service/api/user-api/user-api.service';
import { DateFormatterDirective } from './core/directive/date-formatter/date-formatter.directive';

import { CommonModule } from '@angular/common';
import { TransitionService, UIRouter } from '@uirouter/core';
import { AuthModule } from './modules/auth/auth.module';
import { PartnerApiService } from './core/service/api/partner-api/partner-api.service';
import { firstValueFrom } from 'rxjs';
import { DsLinkModule } from './core/directive/ds-link/ds-link.module';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    CoreServicesModule,
    CoreComponentsModule,
    CorePipesModule,
    CoreDirectiveModule,
    DsLinkModule,
    PagesModule,
    FontAwesomeModule,
    AppRoutingModule,
    CommonModule,
    AuthModule,
    DsLinkModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorCatchingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
    public transitionService: TransitionService,
    public userApiService: UserApiService,
    public partnerApiService: PartnerApiService
  ) {
    library.addIconPacks(<any>fas, <any>far, <any>fass, <any>fab);
    this.subscribeOnStateParams();
  }

  subscribeOnStateParams() {
    this.transitionService.onCreate({ to: 'root.**' }, transition => {
      if (this.isNeedChangePartner(transition)) {
        return this.selectNewPartner(transition).catch(() => {
          this.setRootPartnerForTransition(transition);
        });
      }

      if (this.isNeedSetPartner(transition)) {
        this.setRootPartnerForTransition(transition);
      }

      return;
    });
  }

  private setRootPartnerForTransition(transition: any) {
    try {
      let param = transition?._treeChanges?.to?.find(
        i => i.paramSchema[0]?.id === 'rootPartnerId'
      );
      if (param) {
        param.paramValues.rootPartnerId =
          this.partnerApiService?.currentPartnerId;
      }
    } catch (e) {}
  }

  private selectNewPartner(transition: any) {
    return firstValueFrom(
      this.partnerApiService.selectPartner$(
        parseInt(transition?.params()['rootPartnerId'])
      )
    ).catch(e => {
      return Promise.reject();
    });
  }

  private isNeedSetPartner(transition: any) {
    return (
      !transition?.params()['rootPartnerId'] ||
      transition?.params()['rootPartnerId'] !==
        this.partnerApiService?.currentPartnerId?.toString()
    );
  }

  private isNeedChangePartner(transition: any) {
    return (
      !!transition?.params()['rootPartnerId'] &&
      transition?.params()['rootPartnerId'] !==
        this.partnerApiService?.currentPartnerId?.toString() &&
      this.userApiService?.currentUser?.role === UserRoleEnum.superuser
    );
  }
}
