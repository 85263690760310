import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UserApiService } from '../../service/api/user-api/user-api.service';
import { StateService } from '@uirouter/core';
import { PartnerApiService } from '../../service/api/partner-api/partner-api.service';
import {
  SCHEDULE_STATUS,
  SCHEDULE_STATUS_COLORS,
  SCHEDULE_STATUS_NAMES,
} from '../../service/api/schedule-api/variables';

@Component({
  selector: 'checkbox-filter',
  template: `
    <div
      class="input-group input-group-sm dropdown "
      ngbDropdown=""
      [autoClose]="'outside'"
      container="body">
      <button
        type="button"
        class="btn btn-default btn-addon btn-sm"
        ngbDropdownToggle="">
        <i
          *ngIf="showIcon"
          class="fas fa-filter"
          [class.text-danger]="!selectedAll()"></i>

        <span [innerText]="name"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" ngbDropdownMenu="">
        <li class="ps-2 pe-2 pt-1 pb-1 border-bottom" *ngIf="canSelectAll">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="selectedAll()"
              (change)="selectAllChange($event)"
              [id]="id + 'all'" />
            <label
              class="form-check-label"
              [for]="id + 'all'"
              i18n="@@checkbox-filter_components.form-label.choose-all">
              Выбрать все
            </label>
          </div>
        </li>

        <li
          class="ps-2 pe-2 pt-1 pb-1"
          *ngFor="let item of preparedInputValues; let i = index">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="inFilter(item.id)"
              (change)="switchFilter($event, item.id)"
              [id]="id + 'filer' + i" />
            <label
              class="form-check-label"
              [for]="id + 'filer' + i"
              [innerText]="item.value">
            </label>
          </div>
        </li>
      </ul>
      <button
        type="button"
        class="btn btn-danger btn-sm"
        *ngIf="!selectedAll()"
        (click)="selectAll(true)">
        <i class="fas fa-times"></i>
      </button>
    </div>
  `,
  styleUrls: ['./checkbox-filter.component.scss'],
  providers: [],
})
export class CheckboxFilterComponent implements OnInit, OnChanges {
  @Input() canSelectAll = false;
  @Input() name = $localize`:|@@checkbox-filter_components.filter:Фильтр`;
  @Input() inputValues: {};
  @Input() selectedValues: Array<any>;

  @Input() showIcon = true;

  public _selectedValues = new Set();
  public id = '';

  public preparedInputValues: any = [];

  @Output() selectedValuesChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    this.prepareInputValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['selectedValues'] &&
      Array.isArray(changes['selectedValues'].currentValue) &&
      (this._selectedValues.size !==
        changes['selectedValues'].currentValue.length ||
        changes['selectedValues'].currentValue.some(
          i => !this._selectedValues.has(i)
        ))
    ) {
      this._selectedValues.clear();
      changes['selectedValues'].currentValue.forEach(
        this._selectedValues.add,
        this._selectedValues
      );
      this.emitValues();
    }

    if (changes['inputValues'] && !changes['inputValues'].firstChange)
      this.prepareInputValues();
  }

  prepareInputValues() {
    this.preparedInputValues = [];

    for (let key in this.inputValues) {
      let id: any = key;

      if (/^\d+$/.test(id) && !!this.inputValues[parseInt(id)])
        id = parseInt(id);

      this.preparedInputValues.push({
        id: id,
        value: this.inputValues[key],
      });
    }
  }

  emitValues() {
    setTimeout(() => {
      let emitValues = Array.from(this._selectedValues.values());

      if (!Array.isArray(this.selectedValues)) return;

      if (
        JSON.stringify([...this.selectedValues].sort()) !==
        JSON.stringify(emitValues.sort())
      )
        this.selectedValuesChange.emit(emitValues);
    });
  }

  selectedAll() {
    return this._selectedValues.size === this.preparedInputValues.length;
    this.emitValues();
  }

  selectAll(checked) {
    setTimeout(() => {
      if (checked) {
        this._selectedValues.clear();
        this.preparedInputValues.forEach(i => {
          this._selectedValues.add(i.id);
        });
      } else {
        this._selectedValues.clear();
      }
      this.emitValues();
    });
  }

  selectAllChange($event: Event) {
    const checked = (<any>$event?.target)?.checked;

    this.selectAll(checked);
  }

  inFilter(id) {
    return this._selectedValues.has(id);
  }

  switchFilter($event: Event, key) {
    const checked = (<any>$event?.target)?.checked;

    setTimeout(() => {
      if (checked) {
        this._selectedValues.add(key);
      } else {
        this._selectedValues.delete(key);
      }

      this.emitValues();
    });
  }
}
