<div class="container-fluid p-0 d-flex flex-column">
  <app-top-panel *ngIf="!isForSelect" [isIntegrated]="true">
    <div class="d-flex">
      <div class="col-sm-4 col-xl-3">
        <ng-container
          *ifPermission="{ path: 'storage.catalog.api', type: 'create' }">
          <button
            class="btn btn-success btn-sm ms-2 btn-addon"
            (click)="onCreate()"
            *ngIf="!options.isModal">
            <i class="fas fa-plus"></i>
            <ng-container i18n="@@files-page.top-panel.button.create">
              Создать
            </ng-container>
          </button>
        </ng-container>

        <ng-container
          *ifPermission="{ path: 'storage.catalog.api', type: 'delete' }">
          <button
            class="btn btn-danger btn-sm float-end"
            (click)="onDelete()"
            ngbPopover="Удалить каталог"
            triggers="mouseenter:mouseleave"
            *ngIf="treeModel?.hasSelectedCatalog()"
            i18n-ngbPopover="@@files-page.top-panel.delete-directory">
            <i class="fas fa-trash"></i>
          </button>
        </ng-container>

        <ng-container
          *ifPermission="{ path: 'storage.catalog.api', type: 'update' }">
          <button
            class="btn btn-warning btn-sm me-2 float-end"
            (click)="renameClick()"
            ngbPopover="Переименовать каталог"
            triggers="mouseenter:mouseleave"
            *ngIf="treeModel?.hasSelectedCatalog()"
            i18n-ngbPopover="@@files-page.top-panel.rename-directory">
            <i class="fas fa-i-cursor ms-1 me-1"></i>
          </button>
        </ng-container>
      </div>

      <ng-container
        *ifPermission="{ path: 'storage.file.api', type: 'create' }">
        <div class="col text-end" *ngIf="treeModel?.hasSelectedCatalog()">
          <button class="btn btn-success btn-addon btn-sm" (click)="onImport()">
            <i class="fas fa-download"></i>
            <ng-container i18n="@@files-page.top-panel.button.import-file">
              Импорт файла
            </ng-container>
          </button>
        </div>
      </ng-container>
    </div>
  </app-top-panel>

  <div class="flex-fill d-flex flex-column">
    <div class="row m-0 h-100" style="flex: 1">
      <div class="col-sm-4 col-xl-3 bg-light pb-2 border-end overflow-auto">
        <div class="row">
          <div class="col p-0 position-relative">
            <file-tree
              #fileTree=""
              (treeModelChange)="treeModel = $event"
              [(filterParams)]="filterParams"></file-tree>
          </div>
        </div>
      </div>

      <div class="col p-0 overflow-auto">
        <file-table-view
          #fileTableView=""
          [partnerId]="partnerId"
          [enablePreview]="enablePreview"
          [parentId]="treeModel?.focusedNode?.data.id"
          (selectedFileChange)="
            onChange.emit(!!$event ? { type: 'file', data: $event } : $event)
          "
          [treeModel]="treeModel"
          [multiselect]="!!options.multiselect"
          [filterParams]="filterParams"
          [filterByType]="options?.filterByType"

        >
        </file-table-view>
      </div>
    </div>
  </div>
</div>
