import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  isNullOrUndefined,
  StateService,
  TransitionOptions,
  UIRouter,
} from '@uirouter/core';
import { ParentUIViewInject, UISref, UIView } from '@uirouter/angular';
import { PermissionsService } from '../../service/permissions/permissions.service';
import {APP_ROUTES} from "../../../../app-routes.variables";

@Directive({
  selector: 'a[dsLink]',
})
export class DsLinkAnchorDirective<T = unknown> implements OnInit, OnChanges {
  constructor(
    public $state: StateService,
    public _el: ElementRef,
    public _renderer: Renderer2,
    public permissionsService: PermissionsService
  ) {}

  @Input()
  public dsLink: T;

  @Input()
  public dsLinkParams: any;

  public url;

  ngOnChanges(changes: SimpleChanges) {
   //   this.buildUrl();
  }

  public buildUrl() {

    let state = (<any>this.$state).router.stateRegistry.matcher.find(this.dsLink,'root');

    if (state && state?.name?.endsWith('*') ) {
/*      if (<any>this.dsLink === 'root.schedule.view')
        debugger*/
     // state.lazyLoad();
    }

    let result = this.$state.href(this.dsLink, this.dsLinkParams, {
      inherit: true
    });
  }

  public disableCss() {
    const el: HTMLElement = this._el.nativeElement;
    this._renderer.removeAttribute(el, 'href');
    el.style.color = 'inherit';
    el.style.cursor = 'default';
  }

  public enableCss() {
    const el: HTMLElement = this._el.nativeElement;
    el.style.color = '';
    el.style.cursor = '';
  }

  openInNewTab() {
    // хак для UISref
    if (!this.permissionsService.byUrlPath(this.dsLink)) {
      return true;
    }

    return this._el.nativeElement.target === '_blank';
  }

  update(href: string) {

    if (!this.permissionsService.byUrlPath(this.dsLink)) {
      this.disableCss();
      return;
    }

    href = this.fixHref(href);

    this.enableCss();

    if (!isNullOrUndefined(href)) {
      this._renderer.setProperty(this._el.nativeElement, 'href', href);
    } else {
      this._renderer.removeAttribute(this._el.nativeElement, 'href');
    }
  }

  fixHref(href: string) {

    let appRoute = APP_ROUTES?.[ String( this.dsLink ) ]?.url;
    if (!appRoute)
      return href;

    if (this.dsLinkParams && Object.keys(this.dsLinkParams).length) {
      Object.keys(this.dsLinkParams).forEach(key => {
        appRoute = appRoute.replace(`:${key}`, typeof this.dsLinkParams[key] !== "undefined" && this.dsLinkParams[key] !== null ? this.dsLinkParams[key]?.toString() : '');
      });
    }

    if (href.includes(appRoute)) {
      return href;
    }

    let hrefArray = href.split('/');
    let appRouteArray = appRoute.split('/');

    for (let i = 0; i < hrefArray.length && i < appRouteArray.length; i++) {
      if (hrefArray?.[hrefArray?.length - 1 - i]?.toLowerCase() === appRouteArray?.[i]?.toLowerCase()) {
        delete hrefArray[hrefArray?.length - 1 - i];
      } else {
        break;
      }
    }

    href = '/' + hrefArray.join('/') + ( appRouteArray.join('/') );


    return href;
  }

  ngOnInit() {}
}

@Directive({
  selector: '[dsLink]',
  exportAs: 'dsLink',
})
export class DsLinkDirective extends UISref {
  @Input('dsLink')
  set dsState(value: string) {
    this.state = value;
  }

  @Input('dsLinkParams')
  set dsParams(value: any) {
    this.params = value;
  }

  @Input('dsLinkOptions')
  set dsOptions(value: TransitionOptions) {
    this.options = value;
  }

  constructor(
    _router: UIRouter,
    @Optional() _anchorUISref: DsLinkAnchorDirective,
    @Inject(UIView.PARENT_INJECT) parent: ParentUIViewInject
  ) {
    super(_router, _anchorUISref, parent);
  }
}
