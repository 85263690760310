/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExternalInfo } from '../models/external-info';
import { FileStatisticRequest } from '../models/file-statistic-request';
import { HistoryDownloadBarChartRequest } from '../models/history-download-bar-chart-request';
import { HistoryDownloadBarChartResponse } from '../models/history-download-bar-chart-response';
import { HistoryDownloadRequest } from '../models/history-download-request';
import { HistoryRequest } from '../models/history-request';
import { ListWithTotalFileStatInfoApi } from '../models/list-with-total-file-stat-info-api';
import { ListWithTotalHistoryDownloadResponse } from '../models/list-with-total-history-download-response';
import { ListWithTotalHistoryResponse } from '../models/list-with-total-history-response';
import { ListWithTotalPlayerApi } from '../models/list-with-total-player-api';
import { ListWithTotalPlayerDownloadResponseStatApi } from '../models/list-with-total-player-download-response-stat-api';
import { ListWithTotalPlayerSchedules } from '../models/list-with-total-player-schedules';
import { ListWithTotalScheduleHistoryResponse } from '../models/list-with-total-schedule-history-response';
import { PlayerApi } from '../models/player-api';
import { PlayerCreateApi } from '../models/player-create-api';
import { PlayerDownloadRequestStatApi } from '../models/player-download-request-stat-api';
import { PlayerRequestApi } from '../models/player-request-api';
import { PlayerSchedulesRequest } from '../models/player-schedules-request';
import { PlayerStatApi } from '../models/player-stat-api';
import { ScheduleHistoryBarChartRequest } from '../models/schedule-history-bar-chart-request';
import { ScheduleHistoryBarChartResponse } from '../models/schedule-history-bar-chart-response';
import { ScheduleHistoryRequest } from '../models/schedule-history-request';
import { StatisticRequest } from '../models/statistic-request';
import { StatisticResponse } from '../models/statistic-response';

@Injectable({
  providedIn: 'root',
})
export class PlayerApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridPlayerQuery
   */
  static readonly PostPartnerPartneridPlayerQueryPath = '/partner/{partnerId}/player/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerQuery$Response(params: {
    partnerId: number;
    body: PlayerRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalPlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalPlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerQuery(params: {
    partnerId: number;
    body: PlayerRequestApi
  }): Observable<ListWithTotalPlayerApi> {

    return this.postPartnerPartneridPlayerQuery$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalPlayerApi>) => r.body as ListWithTotalPlayerApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridPlayerId
   */
  static readonly GetPartnerPartneridPlayerIdPath = '/partner/{partnerId}/player/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridPlayerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<PlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.GetPartnerPartneridPlayerIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridPlayerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerId(params: {
    partnerId: number;
    id: number;
  }): Observable<PlayerApi> {

    return this.getPartnerPartneridPlayerId$Response(params).pipe(
      map((r: StrictHttpResponse<PlayerApi>) => r.body as PlayerApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridPlayerId
   */
  static readonly PutPartnerPartneridPlayerIdPath = '/partner/{partnerId}/player/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridPlayerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridPlayerId$Response(params: {
    partnerId: number;
    id: number;
    body: PlayerCreateApi
  }): Observable<StrictHttpResponse<PlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PutPartnerPartneridPlayerIdPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridPlayerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridPlayerId(params: {
    partnerId: number;
    id: number;
    body: PlayerCreateApi
  }): Observable<PlayerApi> {

    return this.putPartnerPartneridPlayerId$Response(params).pipe(
      map((r: StrictHttpResponse<PlayerApi>) => r.body as PlayerApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridPlayerId
   */
  static readonly DeletePartnerPartneridPlayerIdPath = '/partner/{partnerId}/player/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridPlayerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridPlayerId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.DeletePartnerPartneridPlayerIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridPlayerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridPlayerId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridPlayerId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridPlayerIdInfo
   */
  static readonly GetPartnerPartneridPlayerIdInfoPath = '/partner/{partnerId}/player/{id}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridPlayerIdInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerIdInfo$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<ExternalInfo>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.GetPartnerPartneridPlayerIdInfoPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExternalInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridPlayerIdInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerIdInfo(params: {
    partnerId: number;
    id: number;
  }): Observable<ExternalInfo> {

    return this.getPartnerPartneridPlayerIdInfo$Response(params).pipe(
      map((r: StrictHttpResponse<ExternalInfo>) => r.body as ExternalInfo)
    );
  }

  /**
   * Path part for operation getPartnerPartneridPlayerStat
   */
  static readonly GetPartnerPartneridPlayerStatPath = '/partner/{partnerId}/player/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridPlayerStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerStat$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<PlayerStatApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.GetPartnerPartneridPlayerStatPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlayerStatApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridPlayerStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlayerStat(params: {
    partnerId: number;
  }): Observable<PlayerStatApi> {

    return this.getPartnerPartneridPlayerStat$Response(params).pipe(
      map((r: StrictHttpResponse<PlayerStatApi>) => r.body as PlayerStatApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerSchedules
   */
  static readonly PostPartnerPartneridPlayerSchedulesPath = '/partner/{partnerId}/player/schedules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerSchedules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedules$Response(params: {
    partnerId: number;
    body: PlayerSchedulesRequest
  }): Observable<StrictHttpResponse<ListWithTotalPlayerSchedules>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerSchedulesPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalPlayerSchedules>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerSchedules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedules(params: {
    partnerId: number;
    body: PlayerSchedulesRequest
  }): Observable<ListWithTotalPlayerSchedules> {

    return this.postPartnerPartneridPlayerSchedules$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalPlayerSchedules>) => r.body as ListWithTotalPlayerSchedules)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerHistory
   */
  static readonly PostPartnerPartneridPlayerHistoryPath = '/partner/{partnerId}/player/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerHistory$Response(params: {
    partnerId: number;
    body: HistoryRequest
  }): Observable<StrictHttpResponse<ListWithTotalHistoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerHistoryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalHistoryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerHistory(params: {
    partnerId: number;
    body: HistoryRequest
  }): Observable<ListWithTotalHistoryResponse> {

    return this.postPartnerPartneridPlayerHistory$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalHistoryResponse>) => r.body as ListWithTotalHistoryResponse)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerDownloadhistory
   */
  static readonly PostPartnerPartneridPlayerDownloadhistoryPath = '/partner/{partnerId}/player/downloadHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerDownloadhistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerDownloadhistory$Response(params: {
    partnerId: number;
    body: HistoryDownloadRequest
  }): Observable<StrictHttpResponse<ListWithTotalHistoryDownloadResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerDownloadhistoryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalHistoryDownloadResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerDownloadhistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerDownloadhistory(params: {
    partnerId: number;
    body: HistoryDownloadRequest
  }): Observable<ListWithTotalHistoryDownloadResponse> {

    return this.postPartnerPartneridPlayerDownloadhistory$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalHistoryDownloadResponse>) => r.body as ListWithTotalHistoryDownloadResponse)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerSchedulehistory
   */
  static readonly PostPartnerPartneridPlayerSchedulehistoryPath = '/partner/{partnerId}/player/scheduleHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerSchedulehistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedulehistory$Response(params: {
    partnerId: number;
    body: ScheduleHistoryRequest
  }): Observable<StrictHttpResponse<ListWithTotalScheduleHistoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerSchedulehistoryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalScheduleHistoryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerSchedulehistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedulehistory(params: {
    partnerId: number;
    body: ScheduleHistoryRequest
  }): Observable<ListWithTotalScheduleHistoryResponse> {

    return this.postPartnerPartneridPlayerSchedulehistory$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalScheduleHistoryResponse>) => r.body as ListWithTotalScheduleHistoryResponse)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerDownloadhistorybarchart
   */
  static readonly PostPartnerPartneridPlayerDownloadhistorybarchartPath = '/partner/{partnerId}/player/downloadHistoryBarChart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerDownloadhistorybarchart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerDownloadhistorybarchart$Response(params: {
    partnerId: number;
    body: HistoryDownloadBarChartRequest
  }): Observable<StrictHttpResponse<Array<HistoryDownloadBarChartResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerDownloadhistorybarchartPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HistoryDownloadBarChartResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerDownloadhistorybarchart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerDownloadhistorybarchart(params: {
    partnerId: number;
    body: HistoryDownloadBarChartRequest
  }): Observable<Array<HistoryDownloadBarChartResponse>> {

    return this.postPartnerPartneridPlayerDownloadhistorybarchart$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HistoryDownloadBarChartResponse>>) => r.body as Array<HistoryDownloadBarChartResponse>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerSchedulehistorybarchart
   */
  static readonly PostPartnerPartneridPlayerSchedulehistorybarchartPath = '/partner/{partnerId}/player/scheduleHistoryBarChart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerSchedulehistorybarchart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedulehistorybarchart$Response(params: {
    partnerId: number;
    body: ScheduleHistoryBarChartRequest
  }): Observable<StrictHttpResponse<Array<ScheduleHistoryBarChartResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerSchedulehistorybarchartPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScheduleHistoryBarChartResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerSchedulehistorybarchart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerSchedulehistorybarchart(params: {
    partnerId: number;
    body: ScheduleHistoryBarChartRequest
  }): Observable<Array<ScheduleHistoryBarChartResponse>> {

    return this.postPartnerPartneridPlayerSchedulehistorybarchart$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ScheduleHistoryBarChartResponse>>) => r.body as Array<ScheduleHistoryBarChartResponse>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerIdStat
   */
  static readonly PostPartnerPartneridPlayerIdStatPath = '/partner/{partnerId}/player/{id}/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerIdStat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStat$Response(params: {
    partnerId: number;
    id: number;
    body: StatisticRequest
  }): Observable<StrictHttpResponse<Array<StatisticResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerIdStatPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerIdStat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStat(params: {
    partnerId: number;
    id: number;
    body: StatisticRequest
  }): Observable<Array<StatisticResponse>> {

    return this.postPartnerPartneridPlayerIdStat$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticResponse>>) => r.body as Array<StatisticResponse>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerIdStatDetails
   */
  static readonly PostPartnerPartneridPlayerIdStatDetailsPath = '/partner/{partnerId}/player/{id}/stat/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerIdStatDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStatDetails$Response(params: {
    partnerId: number;
    id: number;
    body: FileStatisticRequest
  }): Observable<StrictHttpResponse<ListWithTotalFileStatInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerIdStatDetailsPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalFileStatInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerIdStatDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStatDetails(params: {
    partnerId: number;
    id: number;
    body: FileStatisticRequest
  }): Observable<ListWithTotalFileStatInfoApi> {

    return this.postPartnerPartneridPlayerIdStatDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalFileStatInfoApi>) => r.body as ListWithTotalFileStatInfoApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridPlayerIdStatDownload
   */
  static readonly PostPartnerPartneridPlayerIdStatDownloadPath = '/partner/{partnerId}/player/{id}/stat/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridPlayerIdStatDownload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStatDownload$Response(params: {
    partnerId: number;
    id: number;
    body: PlayerDownloadRequestStatApi
  }): Observable<StrictHttpResponse<ListWithTotalPlayerDownloadResponseStatApi>> {

    const rb = new RequestBuilder(this.rootUrl, PlayerApiService.PostPartnerPartneridPlayerIdStatDownloadPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalPlayerDownloadResponseStatApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridPlayerIdStatDownload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridPlayerIdStatDownload(params: {
    partnerId: number;
    id: number;
    body: PlayerDownloadRequestStatApi
  }): Observable<ListWithTotalPlayerDownloadResponseStatApi> {

    return this.postPartnerPartneridPlayerIdStatDownload$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalPlayerDownloadResponseStatApi>) => r.body as ListWithTotalPlayerDownloadResponseStatApi)
    );
  }

}
