import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, of, throwError } from 'rxjs';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import {
  ApiService,
  UserApi,
  UserApiService as UserApiServiceGen,
  UserCreateApi, UserRequestApi,
} from '../generated/User';
import {
  AuthorizationApiService as AuthorizationApiServiceGen,
  LoginApi,
} from '../generated/Authorization';
import { StateService, TransitionService, UIRouter } from '@uirouter/core';


import { sha256 } from 'js-sha256';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { LAST_PARTNER_ID } from '../../localStorage/local-storage.values';
import { SaltApi } from '../generated/Authorization/models';
import { fromBase64, toBase64 } from 'js-base64';
import {APP_ROUTES} from "../../../../../app-routes.variables";

export enum UserRoleEnum {
  superuser = 'superuser',
  admin = 'admin',
  user = 'user',
  observer = 'observer',
  external = 'external',
}

export const UserRoleNames = {
  [UserRoleEnum.superuser]: $localize`:|@@user-api_service.rs-employee:Работник РС`,
  [UserRoleEnum.admin]: $localize`:|@@user-api_service.administrator:Администратор`,
  [UserRoleEnum.user]: $localize`:|@@user-api_service.user:Пользователь`,
  [UserRoleEnum.observer]: $localize`:|@@user-api_service.observer:Наблюдатель`,
  [UserRoleEnum.external]: $localize`:|@@user-api_service.external-system:Внешняя система`,
};

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private partnerApiService: PartnerApiService,
    private userAPIService: UserApiServiceGen,
    private userDefaultService: ApiService,
    private authorizationApiService: AuthorizationApiServiceGen,
    private localStorage: LocalStorageService,

    private $state: StateService,
    private $transition: TransitionService,
  ) {}

  private _currentUser: UserApi | undefined;

  get canEdit() {
    return (
      [UserRoleEnum.superuser, UserRoleEnum.admin].indexOf(
        <any>this.currentUser?.role
      ) >= 0
    );
  }

  get currentUser() {
    return Object.assign({}, this._currentUser);
  }

  get isLogged() {
    return !!this._currentUser;
  }

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 50,
    },
    email: {
      required: true,
      maxlength: 100,
    },
    password: {
      required: true,
      maxlength: 256,
    },
    role: {
      required: true,
    },
  });

  query$(body: UserRequestApi, partnerId = this.partnerApiService.currentPartnerId) {
    return this.userAPIService.postPartnerPartneridUserQuery({
      partnerId,
      body
    }).pipe(
      map(items => {
        if (this.currentUser.role !== UserRoleEnum.superuser) {
          items = items.filter(i => i.role !== UserRoleEnum.superuser);
        }
        return items;
      }),
      catchError(error => {
        error.setError(
          $localize`:|@@user-api_service.error-getting-list-of-users:Ошибка получения списка пользователей`
        );
        return throwError(error);
      })
    );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.userAPIService
      .getPartnerPartneridUserId({ partnerId, id })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@user-api_service.player-not-found:Плеер не найден`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: UserCreateApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    body = Object.assign({}, body);

    body.partnerId = body.partnerId || partnerId;
    let originalPassword = body.password;
    body.password = sha256(body.password);

    return this.userAPIService
      .postPartnerPartneridUser({ partnerId, body })
      .pipe(
        switchMap(result => {
          if (result.role !== UserRoleEnum.external) return of(result);

          return this.userAPIService
            .postPartnerPartneridUserIdPrompt({
              partnerId: <number>result?.partnerId,
              id: result.id,
              body: {
                prompt: toBase64(originalPassword),
              },
            })
            .pipe(
              map((item: any) => {
                return result;
              })
            );
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@user-api_service.user-creation-error:Ошибка создания пользователя`
          );
          return throwError(error);
        })
      );
  }

  update$(body: UserApi, partnerId = this.partnerApiService.currentPartnerId) {
    body = Object.assign({}, body);

    if (typeof body.id === 'undefined') return this.create$(body, partnerId);

    body.partnerId = body.partnerId || partnerId;

    let originalPassword: string | boolean = false;

    return this.get$(body.id, partnerId).pipe(
      map(
        originalUser => {
          if (
            body.password &&
            (originalUser.password !== body.password ||
              body.password.length !== 64)
          ) {
            originalPassword = body.password;
            body.password = sha256(body.password);
          }
        },
        error => {
          error.stopPopupError();
          return of({});
        }
      ),
      switchMap(() => {
        return this.userAPIService
          .putPartnerPartneridUserId({
            partnerId,
            id: body.id,
            body,
          })
          .pipe(
            catchError(error => {
              error.setError(
                $localize`:|@@user-api_service.error-saving-player:Ошибка сохранения плеера`
              );
              return throwError(error);
            })
          );
      }),
      switchMap(result => {
        if (result.role !== UserRoleEnum.external || originalPassword === false)
          return of(result);

        return this.userAPIService
          .postPartnerPartneridUserIdPrompt({
            partnerId: <number>result?.partnerId,
            id: result.id,
            body: {
              prompt: toBase64(<string>originalPassword),
            },
          })
          .pipe(
            map((item: any) => {
              return result;
            })
          );
      })
    );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.userAPIService
      .deletePartnerPartneridUserId({ partnerId, id })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@user-api_service.player-deletion-error:Ошибка удаления плеера`
          );
          return throwError(error);
        })
      );
  }

  stat$(id = this.partnerApiService.currentPartnerId) {
    return this.userAPIService.getPartnerPartneridUserStat({
      partnerId: id,
    });
  }

  getPassword$(
    id: number,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.userAPIService
      .getPartnerPartneridUserIdPrompt({ partnerId, id })
      .pipe(
        map(result => {
          if (!!result?.prompt) {
            try {
              result.prompt = fromBase64(result?.prompt);
            } catch (e) {
              result.prompt = '';
            }
          }

          return !!result?.prompt ? result.prompt : '';
        }),
        catchError(error => {
          error.setError(
            $localize`:|@@user-api_service.password-not-found:Пароль не найден`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(
    id?: number | string,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof id === 'undefined' || id === '') return of(<UserApi>{});

    return this.get$(parseInt(id.toString()), partnerId).pipe(
      catchError(error => of({}))
    );
  }

  getAvailableRoles$() {
    let roles = [
      UserRoleEnum.superuser,
      UserRoleEnum.admin,
      UserRoleEnum.user,
      UserRoleEnum.observer,
      UserRoleEnum.external,
    ];

    if (this.currentUser?.role !== UserRoleEnum.superuser) {
      roles = [
        UserRoleEnum.admin,
        UserRoleEnum.user,
        UserRoleEnum.observer,
        UserRoleEnum.external,
      ];
    }

    return of(roles);
  }

  login$(body: LoginApi) {
    return this.authorizationApiService.getLoginSalt().pipe(
      switchMap((saltResult: SaltApi = { salt: '' }) =>
        !!saltResult?.salt
          ? this.authorizationApiService.postLogin({
              body: {
                email: body.email,
                password: sha256(
                  sha256(body.password) + saltResult?.salt || ''
                ),
              },
            })
          : throwError(() => new Error('Salt is empty'))
      ),
      map(user => {
        this._currentUser = user;
        return user;
      }),
      catchError(error => {
        this._currentUser = undefined;
        return throwError(error);
      })
    );
  }

  logout$() {
    return this.authorizationApiService.getLogout().pipe(
      tap(() => {
        delete this._currentUser;
      })
    );
  }

  getData$(stopLoginRedirect?) {
    let res = this.userDefaultService.getUserData().pipe(
      catchError(error => {
        if (stopLoginRedirect) (<any>error).stopLoginRedirect = true;

        throw error;
      }),
      tap(user => {
        this._currentUser = user;
      })
    );

    return res;
  }

  async testLogged(router: UIRouter) {

    let user;
    let goto = path => {
      let target;
      try {
        target = this.$state?.transition.targetState()?.name();
      } catch (e) {
        target = '';
      }

      if (target !== path) this.$state?.transition?.abort();

      return new Promise(r => {
        this.$state
          .transitionTo(path, undefined, {
            supercede: false,
          })
          .catch(() => Promise.resolve())
          .then(r);
      });
    };

    if (window.location?.href?.includes('/login') || window.location?.href?.includes('/registration') ) {
      return Promise.resolve();
    }

    try {
      user = await firstValueFrom(this.getData$(true));
    } catch (error: any) {
      error.stopPopupError && error.stopPopupError();
      return goto('login');
    }

    if (this.partnerApiService.currentPartnerId === -1) {
      if (user?.role == UserRoleEnum.superuser) {
        const url = location.href.replace(location.origin, '');
        const urlMatch = url.replace(/^\/?[#,!]+\//, '').match(/^(\d+)/);

        let lastPartnerId;

        if (urlMatch && !!urlMatch.length && !isNaN(parseInt(urlMatch[0]))) {
          lastPartnerId = parseInt(urlMatch[0]);
        } else {
          return goto('partner');
        }

        user.partnerId = lastPartnerId;
      }

      (<any>router).$currentPartner = user.partnerId;

      return firstValueFrom(
        this.partnerApiService.selectPartner$(user?.partnerId)
      );
    }

    return Promise.resolve(true);
  }
}
