import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgModel,
  Validator,
} from '@angular/forms';

@Directive({
  selector: `[ngModel][customValidator]`,
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: CustomValidatorDirective,
      multi: true,
    },
  ],
})
export class CustomValidatorDirective implements Validator {
  @Input('customValidator') functionForValidate;

  constructor(private el: ElementRef) {}

  validate(control: AbstractControl): { [key: string]: any } {
    return new Promise(resolve => {
      setTimeout(() => {
        if (!this.functionForValidate) return resolve(null);

        let res;

        try {
          res = this.functionForValidate(control.value);
        } catch (e) {
          console.error(e);
          return resolve(null);
        }

        if (typeof res === 'boolean')
          return resolve(res ? null : { customError: true });

        return resolve(res);
      });
    });
  }
}
