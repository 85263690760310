/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserApi } from '../models/user-api';
import { UserCreateApi } from '../models/user-create-api';
import { UserPromptApi } from '../models/user-prompt-api';
import { UserRequestApi } from '../models/user-request-api';
import { UserStatApi } from '../models/user-stat-api';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridUser
   */
  static readonly PostPartnerPartneridUserPath = '/partner/{partnerId}/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUser$Response(params: {
    partnerId: number;
    body: UserCreateApi
  }): Observable<StrictHttpResponse<UserApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.PostPartnerPartneridUserPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUser(params: {
    partnerId: number;
    body: UserCreateApi
  }): Observable<UserApi> {

    return this.postPartnerPartneridUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserApi>) => r.body as UserApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridUserQuery
   */
  static readonly PostPartnerPartneridUserQueryPath = '/partner/{partnerId}/user/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridUserQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUserQuery$Response(params: {
    partnerId: number;
    body: UserRequestApi
  }): Observable<StrictHttpResponse<Array<UserApi>>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.PostPartnerPartneridUserQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridUserQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUserQuery(params: {
    partnerId: number;
    body: UserRequestApi
  }): Observable<Array<UserApi>> {

    return this.postPartnerPartneridUserQuery$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserApi>>) => r.body as Array<UserApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridUserId
   */
  static readonly GetPartnerPartneridUserIdPath = '/partner/{partnerId}/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<UserApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.GetPartnerPartneridUserIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserId(params: {
    partnerId: number;
    id: number;
  }): Observable<UserApi> {

    return this.getPartnerPartneridUserId$Response(params).pipe(
      map((r: StrictHttpResponse<UserApi>) => r.body as UserApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridUserId
   */
  static readonly PutPartnerPartneridUserIdPath = '/partner/{partnerId}/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridUserId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridUserId$Response(params: {
    partnerId: number;
    id: number;
    body: UserCreateApi
  }): Observable<StrictHttpResponse<UserApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.PutPartnerPartneridUserIdPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridUserId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridUserId(params: {
    partnerId: number;
    id: number;
    body: UserCreateApi
  }): Observable<UserApi> {

    return this.putPartnerPartneridUserId$Response(params).pipe(
      map((r: StrictHttpResponse<UserApi>) => r.body as UserApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridUserId
   */
  static readonly DeletePartnerPartneridUserIdPath = '/partner/{partnerId}/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridUserId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.DeletePartnerPartneridUserIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridUserId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridUserId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridUserStat
   */
  static readonly GetPartnerPartneridUserStatPath = '/partner/{partnerId}/user/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridUserStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserStat$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<UserStatApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.GetPartnerPartneridUserStatPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserStatApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridUserStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserStat(params: {
    partnerId: number;
  }): Observable<UserStatApi> {

    return this.getPartnerPartneridUserStat$Response(params).pipe(
      map((r: StrictHttpResponse<UserStatApi>) => r.body as UserStatApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridUserIdPrompt
   */
  static readonly GetPartnerPartneridUserIdPromptPath = '/partner/{partnerId}/user/{id}/prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridUserIdPrompt()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserIdPrompt$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<UserPromptApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.GetPartnerPartneridUserIdPromptPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPromptApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridUserIdPrompt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridUserIdPrompt(params: {
    partnerId: number;
    id: number;
  }): Observable<UserPromptApi> {

    return this.getPartnerPartneridUserIdPrompt$Response(params).pipe(
      map((r: StrictHttpResponse<UserPromptApi>) => r.body as UserPromptApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridUserIdPrompt
   */
  static readonly PostPartnerPartneridUserIdPromptPath = '/partner/{partnerId}/user/{id}/prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridUserIdPrompt()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUserIdPrompt$Response(params: {
    partnerId: number;
    id: number;
    body: UserPromptApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiService.PostPartnerPartneridUserIdPromptPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridUserIdPrompt$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridUserIdPrompt(params: {
    partnerId: number;
    id: number;
    body: UserPromptApi
  }): Observable<void> {

    return this.postPartnerPartneridUserIdPrompt$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
