export enum PLATFORM_TYPES {
  ukm4 = 'ukm4',
  ukm4Kso = 'ukm4Kso',
  ukm5 = 'ukm5',
  ukm5Kso = 'ukm5Kso',
}

export const PLATFORM_NAMES: { [id in PLATFORM_TYPES]: string } = {
  [PLATFORM_TYPES.ukm4]: $localize`:|@@variables_service.ukm-4:УКМ 4`,
  [PLATFORM_TYPES.ukm4Kso]: $localize`:|@@variables_service.ukm-4-xo:УКМ 4 КСО`,
  [PLATFORM_TYPES.ukm5]: $localize`:|@@variables_service.ukm-5:УКМ 5`,
  [PLATFORM_TYPES.ukm5Kso]: $localize`:|@@variables_service.ukm-5-kso:УКМ 5 КСО`,
};

//---------------------

export enum PLATFORM_ORIENTATION {
  ordinary = 'ordinary',
  left90 = 'left90',
  reversed = 'reversed',
  right90 = 'right90',
}

export const PLATFORM_ORIENTATION_NAMES: {
  [id in PLATFORM_ORIENTATION]: string;
} = {
  [PLATFORM_ORIENTATION.ordinary]: $localize`:|@@variables_service.without-turning:Без поворота`,
  [PLATFORM_ORIENTATION.right90]: $localize`:|@@variables_service.turn-90°:Поворот на 90°`,
  [PLATFORM_ORIENTATION.reversed]: $localize`:|@@variables_service.turn-180°:Поворот на 180°`,
  [PLATFORM_ORIENTATION.left90]: $localize`:|@@variables_service.270°-rotation:Поворот на 270°`,
};

//------------

export enum PLATFORM_VIDEO_CODEC {
  h264 = 'h264',
  libx265 = 'libx265',
  libtheora = 'libtheora',
  mpeg2video = 'mpeg2video',
  mpeg4 = 'mpeg4',
}

export const PLATFORM_VIDEO_CODEC_NAMES: {
  [id in PLATFORM_VIDEO_CODEC]: string;
} = {
  [PLATFORM_VIDEO_CODEC.h264]: 'H264',
  [PLATFORM_VIDEO_CODEC.libx265]: 'H265',
  [PLATFORM_VIDEO_CODEC.libtheora]: 'Theora',
  [PLATFORM_VIDEO_CODEC.mpeg2video]: 'Mpeg2',
  [PLATFORM_VIDEO_CODEC.mpeg4]: 'Mpeg4',
};

//------------

export enum PLATFORM_AUDIO_CODEC {
  mp3 = 'mp3',
  aac = 'aac',
  wav = 'pcm_u8',
  libvorbis = 'libvorbis',
}

export const PLATFORM_AUDIO_CODEC_NAMES: {
  [id in PLATFORM_AUDIO_CODEC]: string;
} = {
  [PLATFORM_AUDIO_CODEC.mp3]: 'Mp3',
  [PLATFORM_AUDIO_CODEC.aac]: 'Aac',
  [PLATFORM_AUDIO_CODEC.wav]: 'Wav',
  [PLATFORM_AUDIO_CODEC.libvorbis]: 'Vorbis',
};

//--------------

export enum PLATFORM_CONTAINER {
  avi = 'avi',
  mkv = 'mkv',
  mpeg = 'mpeg',
  mp4 = 'mp4',
  mov = 'mov',
  ogg = 'ogg',
}

export const PLATFORM_CONTAINER_NAMES: { [id in PLATFORM_CONTAINER]: string } =
  {
    [PLATFORM_CONTAINER.avi]: 'AVI',
    [PLATFORM_CONTAINER.mkv]: 'MKV',
    [PLATFORM_CONTAINER.mov]: 'MOV',
    [PLATFORM_CONTAINER.mp4]: 'MP4',
    [PLATFORM_CONTAINER.mpeg]: 'MPEG',
    [PLATFORM_CONTAINER.ogg]: 'OGG',
  };
