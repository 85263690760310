import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsLinkAnchorDirective, DsLinkDirective } from './ds-link.directive';

@NgModule({
  declarations: [DsLinkDirective, DsLinkAnchorDirective],
  imports: [CommonModule],
  exports: [DsLinkDirective, DsLinkAnchorDirective],
  providers: [DsLinkDirective, DsLinkAnchorDirective],
})
export class DsLinkModule {}
