import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesListPageComponent } from './templates-list-page.component';
import { UIRouterModule } from '@uirouter/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CoreComponentsModule } from '../../../../core/components/core.components.module';
import { CorePipesModule } from '../../../../core/pipe/core-pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { APP_ROUTES } from '../../../../../app-routes.variables';
import { CoreDirectiveModule } from '../../../../core/directive/core-directive.module';
import { DsLinkModule } from '../../../../core/directive/ds-link/ds-link.module';
import {ResponsiveTableModule} from "../../../../core/components/responsive-table/responsive-table.module";

@NgModule({
  declarations: [TemplatesListPageComponent],
    imports: [
        CommonModule,
        UIRouterModule.forChild({
            states: [
                {
                    name: APP_ROUTES['root.templates.list'].name,
                    url: APP_ROUTES['root.templates.list'].url,

                    component: TemplatesListPageComponent,
                    data: {
                        breadcrumbs: [$localize`:|@@templates-list-page.templates:Шаблоны`],
                    },
                },
            ],
        }),
        CoreComponentsModule,
        InfiniteScrollModule,
        NgbDropdownModule,
        FormsModule,
        NgbPopoverModule,
        CorePipesModule,
        FontAwesomeModule,
        CoreDirectiveModule,
        DsLinkModule,
        ResponsiveTableModule,
    ],
  exports: [TemplatesListPageComponent],
})
export class TemplatesListPageModule {}
