<div class="container-fluid d-flex">
  <div class="row flex-fill">
    <div class="col-sm-12">
      <div class="row" *ngIf="!isForSelect">
        <div class="col p-0">
          <app-top-panel>
            <checkbox-filter
              class="float-start me-2"
              name="Статус"
              [canSelectAll]="true"
              [inputValues]="TEMPLATE_STATUS_NAMES"
              [selectedValues]="
                templateApiService.queryParams.getFilterValue('states')
              "
              (selectedValuesChange)="updateFilterValues('states', $event)"
              i18n-name="
                @@templates-list-page.top-panel.status"></checkbox-filter>

            <div class="btn-group float-start" ngbDropdown="">
              <button
                class="btn btn-default btn-addon btn-sm btn-addon collapse-xs"
                ngbDropdownToggle="">
                <i
                  class="fas fa-filter"
                  [class.text-danger]="
                    !!templateApiService.queryParams.getFilterValue('tags')
                      ?.length
                  "></i>
                <ng-container
                  i18n="@@templates-list-page.top-panel.button.tags">
                  Теги
                </ng-container>
              </button>

              <div
                class="scrollable p-3"
                style="z-index: 9999; min-width: 300px"
                onclick="event.stopPropagation()"
                ngbDropdownMenu="">
                <app-tag-input
                  [model]="
                    templateApiService.queryParams.getFilterValue('tags')
                  "
                  [onlyInput]="true"
                  [onlyExisting]="true"
                  (onChange)="updateFilterValues('tags', $event)"
                  size="sm"></app-tag-input>
              </div>

              <button
                type="button"
                class="btn btn-danger btn-sm"
                *ngIf="
                  !!templateApiService.queryParams.getFilterValue('tags')
                    ?.length
                "
                (click)="updateFilterValues('tags', undefined)">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <ng-container
              *ifPermission="{ path: 'templates.api', type: 'create' }">
              <button
                class="btn btn-success btn-addon btn-sm float-end"
                (click)="onAdd()">
                <i class="fas fa-plus"></i>
                <ng-container
                  i18n="@@templates-list-page.top-panel.button.create">
                  Создать
                </ng-container>
              </button>
            </ng-container>
          </app-top-panel>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <app-search-input
            [model]="templateApiService.queryParams.getSearchValue()"
            (modelChange)="onSearchChange($event)"
            placeholder="Введите наименование шаблона"
            i18n-placeholder="
              @@templates-list-page.enter-the-name-of-the-template"></app-search-input>
        </div>
      </div>

      <div
        infiniteScroll=""
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [fromRoot]="true"
        (scrolled)="nextPage()">
        <responsive-table  >
        <table class="table table-hover">
          <thead>
            <tr>
              <th
                scope="col"
                i18n="@@templates-list-page.table.table-header.status"
                class="table-row-xs"
              >
                Статус
              </th>
              <th
                scope="col"
                i18n="@@templates-list-page.table.table-header.name"
                class="table-row-xs"
              >
                Наименование
              </th>
              <th
                scope="col"
                i18n="@@templates-list-page.table.table-header.description"
                class="table-row-sm table-row-xs"
              >
                Описание
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let item of templateItems.items"
              (click)="onClickItem(item)"
              [class.table-row-active]="selectedTemplate?.id === item.id">
              <td class="text-nowrap pe-4">
                <span *ngIf="item.state === 'draft'">
                  <i
                    [class.check-circle]="selectedTemplate?.id === item.id"
                    [class.circle]="selectedTemplate?.id !== item.id"
                    class="fas me-1 text-warning"></i>
                  <ng-container
                    i18n="@@templates-list-page.table.table-row.draft">
                    Черновик
                  </ng-container></span
                >
                <span *ngIf="item.state === 'active'">
                  <i
                    [class.check-circle]="selectedTemplate?.id === item.id"
                    [class.circle]="selectedTemplate?.id !== item.id"
                    class="fas me-1 text-success"></i>
                  <ng-container
                    i18n="@@templates-list-page.table.table-row.active">
                    Активный
                  </ng-container></span
                >
                <span *ngIf="item.state === 'archive'">
                  <i
                    [class.check-circle]="selectedTemplate?.id === item.id"
                    [class.circle]="selectedTemplate?.id !== item.id"
                    class="fas me-1 text-danger"></i>
                  <ng-container
                    i18n="@@templates-list-page.table.table-row.archival">
                    Архивный
                  </ng-container></span
                >
              </td>
              <td>
                <text-highlight
                  [search]="templateApiService.queryParams.getSearchValue()"
                  [text]="item.name || ''"></text-highlight>

                <div class="clearfix">
                  <app-tag-input
                    [model]="item.tags"
                    [size]="'sm'"
                    [onlyView]="true"></app-tag-input>
                </div>
              </td>
              <td [innerText]="item.description || ''"></td>
            </tr>
          </tbody>
        </table>
        </responsive-table>
      </div>
    </div>
  </div>
</div>
