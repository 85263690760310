import {Component, ElementRef, Input, OnInit, AfterContentInit, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'responsive-table',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./responsive-table.component.scss'],

})
export class ResponsiveTableComponent implements OnInit, AfterContentInit, OnChanges {

    @Input() disabled = false
    @Input() disabledGrid = false

    private table: HTMLTableElement;
    private counters: {
      [key: string]: Set<number>;
    } = {};

    constructor(
      private el: ElementRef,
    ) {
    }

    public ngOnInit() {

    }

    public ngAfterContentInit() {
        this.findTable();
        if (!this.table)
            return;

        if (!this.disabled) {
          this.initSizeCounters();
         // this.createCssVariables();
          this.createClasses();
          if (!this.disabledGrid)
            this.el.nativeElement.classList.add('enabled-grid');
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
      if ( changes['disabled']) {
        if ( changes['disabled'].currentValue )
          this.el?.nativeElement?.classList?.add('responsive-disabled');
      } else {
        this.el?.nativeElement?.classList?.remove('responsive-disabled');
      }
    }

    private findTable() {
      this.table = this.el.nativeElement.querySelector('table');
    }


    private initSizeCounters() {

      let firstRow = this.table.querySelectorAll('tr:first-child th');

      if (!firstRow.length) {
        firstRow = this.table.querySelectorAll('tr:first-child td');
        if (!firstRow.length)
          return
      }

      this.counters[''] = new Set();
      let clientWidthArray: number[] = [];
      firstRow.forEach( (node, index) => {

        clientWidthArray[index] = node.clientWidth;

        index+=1;
        this.counters[''].add(index);
        ( node.className.match(/table-row-[a-zA-Z]*/ig) || [] ).forEach( className => {
          className = className.replace('table-row-','');
            this.counters[className] = this.counters[className] || new Set();
            this.counters[ className].add(index);
        });

        if ( node.tagName.toLowerCase() === 'th' ) {

          let rowName =  node.hasAttribute('data-responsive-name') ?
            node.getAttribute('data-responsive-name') :
            node.textContent;

          this.table.style.setProperty('--header-row-'+( index ), `'${rowName}:'` );
        }

      })

      let max = Math.max(...clientWidthArray);
      let min = Math.min(...clientWidthArray);
      let middle = clientWidthArray.reduce( (acc, val) => acc + val, 0 ) / clientWidthArray.length;
      let sizesTemplate = clientWidthArray
            .reduce(
              (acc, val, index) => {

                if (firstRow.item(index)?.classList.contains('table-row-0')) {
                  acc.push('0fr ');
                  return acc;
                }


                if (val <=middle) {
                  acc.push( val <= (middle - min) ? '1fr ' : '2fr ' );
                } else {
                  acc.push(val - middle <= (max - middle) ? '4fr ' : '5fr ');
                }
                return acc;
              }, <any[]>[]);

      for( let size of ['', 'xs','sm','md','lg','xl'] ) {
        this.table.style.setProperty(
          '--grid-rows'+(size === '' ? '': '-' ) + size,
            !size ?  sizesTemplate.join(' ') :
            sizesTemplate
              .filter( (val, index) => !this.counters[size]?.has(index + 1) )
              .join(' ')
        );
      }

    }

    private createCssVariables() {

      if (!this.table)
        return;

      for( let size of ['', 'xs','sm','md','lg','xl'] ) {
        this.table.style.setProperty('--grid-rows'+(size === '' ? '': '-' ) + size, size === '' ? this.counters[size].size.toString() : ( this.counters[''].size - (size in this.counters ? this.counters[size].size : 0 ) ) .toString() );
      }

    }

    private createClasses() {

      if (!this.table)
        return;


      for( let size in this.counters ) {

        if (size === '') continue;

        this.counters[size].forEach(id => {
          this.table.classList.add(`row-${size}-${id}`);
        });

      }

    }
}
