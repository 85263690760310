import { Injectable } from '@angular/core';

import {
  LocationGroupApi,
  LocationgroupApiService as LocationgroupApiServiceGen,
} from '../generated/LocationGroup';
import { PartnerApiService } from '../partner-api/partner-api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ValidateFieldClass } from '../../../class/validate-field.class';
import { ParamsHandler } from '../../../class/ParamsHandler/Params.handler';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { ApiRequestHandlerClass } from '../../../class/api-request-handler.class';

@Injectable()
export class LocationGroupApiService extends ApiRequestHandlerClass {
  constructor(
    private locationgroupAPIService: LocationgroupApiServiceGen,
    private partnerApiService: PartnerApiService,
    localStorageService: LocalStorageService
  ) {
    super();

    this.queryParams = new ParamsHandler({}, localStorageService);
  }

  public queryParams: ParamsHandler;

  public field = new ValidateFieldClass({
    name: {
      required: true,
      maxlength: 256,
    },
    description: {
      required: true,
      maxlength: 256,
    },
    code: {
      required: false,
      maxlength: 100,
    },
  });

  query$(partnerId = this.partnerApiService.currentPartnerId) {
    return this.locationgroupAPIService
      .getPartnerPartneridLocationgroup({
        partnerId: partnerId,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-group-api_service.error-getting-list-of-locations:Ошибка получения списка локаций`
          );
          return throwError(error);
        })
      );
  }

  get$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.locationgroupAPIService
      .getPartnerPartneridLocationgroupId({
        partnerId: partnerId,
        id: id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-group-api_service.location-not-found:Локация не найдена`
          );
          return throwError(error);
        })
      );
  }

  create$(
    body: LocationGroupApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    return this.locationgroupAPIService
      .postPartnerPartneridLocationgroup({
        partnerId: partnerId,
        body: body,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-group-api_service.location-creation-error:Ошибка создания локации`
          );
          return throwError(error);
        })
      );
  }

  update$(
    body: LocationGroupApi,
    partnerId = this.partnerApiService.currentPartnerId
  ) {
    if (typeof body.id === 'undefined') return this.create$(body, partnerId);

    return this.locationgroupAPIService
      .putPartnerPartneridLocationgroupId({
        partnerId: partnerId,
        body: body,
        id: body.id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-group-api_service.location-creation-error2:Ошибка создания локации`
          );
          return throwError(error);
        })
      );
  }

  delete$(id: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.locationgroupAPIService
      .deletePartnerPartneridLocationgroupId({
        partnerId,
        id,
      })
      .pipe(
        catchError(error => {
          error.setError(
            $localize`:|@@location-group-api_service.location-deletion-error:Ошибка удаления локации`
          );
          return throwError(error);
        })
      );
  }

  getOrInit$(id?: number, partnerId = this.partnerApiService.currentPartnerId) {
    if (typeof id === 'undefined' || <any>id === '') return of({});

    return this.get$(id, partnerId).pipe(catchError(error => of({})));
  }
}
