<div class="d-flex flex-column h-100" *ngIf="hasSelectedCatalog()">
  <div class="mb-2 mt-1 pb-1 border-bottom">
    <ng-container
      *ngFor="let id of treeModel?.focusedNode?.path; let last = last">
      <button
        class="btn btn-sm me-2"
        (click)="treeModel.getNodeById(id).focus()">
        <i class="fas fa-folder fa-sm me-1 text-warning"></i>
        <small
          class="text-muted"
          [innerText]="treeModel.getNodeById(id).data.name"></small>
      </button>

      <i *ngIf="!last" class="fas fa-angle-right fa-sm me-1 text-muted"></i>
    </ng-container>
  </div>
  <div class="position-relative flex-fill">
    <div class="h-100 overflow-auto"
         infiniteScroll=""
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="150"
         [infiniteScrollContainer]="'.ngx-file-drop__content'"
         (scrolled)="nextPage()"
    >
      <ngx-file-drop
        dropZoneLabel="Перетащите файлы"
        #ngxFileDrop=""
        (onFileDrop)="dropped($event)"
        [dropZoneClassName]="'drop-zone'"
        i18n-dropZoneLabel="@@file-table-view_page.drag-and-drop-files">
        <ng-template
          ngx-file-drop-content-tmp=""
          let-openFileSelector="openFileSelector"
        >
          <responsive-table [disabled]="true" >
          <table class="table mb-0"  >
            <thead>
              <th *ngIf="multiselect" class="text-center align-middle">
                <input
                  class="form-check-input form-check-"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  [checked]="isAllChecked()"
                  (change)="checkAll($event)" />
              </th>
              <th></th>
              <th i18n="@@file-table-view_page.table.table-header.name">Имя</th>
              <th i18n="@@file-table-view_page.table.table-header.type">Тип</th>
              <th i18n="@@file-table-view_page.table.table-header.file-size">
                Размер файла
              </th>
              <th i18n="@@file-table-view_page.table.table-header.permission">
                Разрешение
              </th>
              <th></th>
            </thead>
            <tbody>
              <tr
                *ngFor="let fileItem of filesArray | callback:filterItems; let index = index"
                [ngClass]="{
                  'text-muted bg-light': !!fileItem.percentLoading
                }"
                [class.table-row-active]="isSelected(fileItem)"
                class="pointer-event"
                (click)="
                  !fileItem.percentLoading && selectFile(fileItem);
                  $event.preventDefault()
                "
                (dblclick)="
                  !fileItem.percentLoading && showFile(fileItem, index);
                  $event.preventDefault()
                "
                (dragstart)="starDrug($event, fileItem)"
                (mousedown)="mouseDownRow($event)"
                (mouseup)="mouseUpRow($event)">
                <th *ngIf="multiselect" class="align-middle text-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="isSelected(fileItem)"
                    (change)="selectFile(fileItem)"
                    (click)="$event.stopPropagation()" />
                </th>

                <td class="text-center preview">
                  <app-file-preview
                    *ngIf="!fileItem.percentLoading"
                    [file]="fileItem"
                    [disablePreview]="true"></app-file-preview>
                </td>
                <td
                  class="pointer-event align-middle name-col"
                  *ngIf="!isNameEditing(fileItem)">
                  <text-highlight
                    [search]="filterParams?.query || ''"
                    [text]="fileItem.name"></text-highlight>

                  <ng-container
                    *ifPermission="{
                      path: 'storage.file.api',
                      type: 'update'
                    }">
                    <span
                      class="badge bg-warning text-white cursor-pointer edit-name-button ms-2"
                      *ngIf="!fileItem.percentLoading"
                      (click)="
                        $event.preventDefault();
                        !fileItem.percentLoading && switchEdit(fileItem)
                      ">
                      <i class="fas fa-pencil-alt"></i>
                    </span>
                  </ng-container>


                  <div class="clearfix small text-muted">
                    ID: <text-highlight
                    [search]="filterParams?.query || ''"
                    [text]="fileItem.id || ''"></text-highlight>
                  </div>

                  <div class="clearfix" *ngIf="fileItem.percentLoading">
                    <ngb-progressbar
                      [value]="fileItem.percentLoading"
                      [showValue]="true"
                      [type]="'success'"></ngb-progressbar>
                  </div>

                  <div class="mt-1">
                   <app-tag-input
                      [loadFunction]="getTags(fileItem.id, fileItem?.tags )"
                      (onChange)="saveTag(fileItem.id, $event)"
                      [highlight]="filterParams?.tags"
                      [onlyView]="
                        !permissionsService.byType(
                          'storage.file.api',
                          PERMISSION_TYPES.UPDATE
                        )
                      "></app-tag-input>
                  </div>
                </td>

                <td
                  class="pointer-event align-middle"
                  *ngIf="isNameEditing(fileItem)">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="fileItem.$editName"
                      [max]="200"
                      [disabled]="fileItem.$isUpdaiting" />

                    <span
                      class="input-group-text"
                      *ngIf="fileItem.$isUpdaiting">
                      <i class="fas fa-spin fa-spinner text-warning"></i>
                    </span>

                    <button
                      class="btn btn-success m-n"
                      type="button"
                      [disabled]="!fileItem.$editName || fileItem.$isUpdaiting"
                      (click)="saveName(fileItem)">
                      <i class="fas fa-check"></i>
                    </button>

                    <button
                      class="btn btn-danger m-n"
                      type="button"
                      [disabled]="fileItem.$isUpdaiting"
                      (click)="cancelEditName(fileItem)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </td>

                <td class="align-middle">
                  <span
                    [innerHTML]="
                      isVideo(fileItem.mimeType)
                        ? 'Видео'
                        : isImage(fileItem.mimeType)
                        ? 'Изображение'
                        : 'Не определён'
                    "></span>
                </td>
                <td class="align-middle text-nowrap">
                  <span [innerHTML]="fileItem?.size | fileSize"></span>
                </td>
                <td class="align-middle">
                  <span *ngIf="fileItem?.width && fileItem?.height">
                    <span [innerHTML]="fileItem?.width"></span>
                    X
                    <span [innerHTML]="fileItem?.height"></span>
                  </span>
                </td>
                <td class="text-end align-middle text-nowrap">
                  <button
                    class="btn btn-sm btn-outline-success me-2"
                    *ngIf="!fileItem.percentLoading"
                    (click)="
                      $event.preventDefault();
                      !fileItem.percentLoading && showFile(fileItem, index)
                    "
                    [disabled]="isNameEditing(fileItem)">
                    <i class="fas fa-eye"></i>
                  </button>
                  <!--

                    <button
                      class="btn btn-sm btn-outline-warning me-2"
                      *ngIf="!fileItem.percentLoading"
                      (click)="$event.preventDefault(); !fileItem.percentLoading && switchEdit(fileItem); "
                      [disabled]="isNameEditing(fileItem)"
                    >
                      <i class="fas fa-pencil-alt"
                      ></i>
                    </button>
-->

                  <button
                    class="btn btn-sm btn-outline-warning me-2"
                    *ngIf="!fileItem.percentLoading"
                    (click)="
                      $event.preventDefault();
                      !fileItem.percentLoading && configPopup(fileItem)
                    "
                    [disabled]="isNameEditing(fileItem)">
                    <i class="fas fa-cog"></i>
                  </button>

                  <ng-container
                    *ifPermission="{
                      path: 'storage.file.api',
                      type: 'delete'
                    }">
                    <button
                      class="btn btn-sm btn-outline-danger me-2"
                      *ngIf="!fileItem.percentLoading"
                      (click)="deleteFile(fileItem); $event.stopPropagation()"
                      [disabled]="isNameEditing(fileItem)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
          </responsive-table>
        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
</div>

<div
  class="h-100 d-flex justify-content-center align-items-center"
  *ngIf="!hasSelectedCatalog()">
  <h3 class="text-muted" i18n="@@file-table-view_page.select-directory">
    Выберите каталог
  </h3>
</div>
