import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FileSizePipe } from './fileSize/fileSize.pipe';
import { CallbackPipe } from './callback/callback.pipe';
import { SecToTimePipe } from './secToTime/secToTime.pipe';
import { DsDatePipe } from './dsDate/ds-date.pipe';
import { DsDateTimePipe } from './dsDateTime/ds-date-time.pipe';
import { DsTimePipe } from './dsTime/ds-date-time.pipe';
import { TimeToSecPipe } from './secToTime/timeToSec.pipe';
import { DsNumberPipe } from './dsNumber/ds-number.pipe';

@NgModule({
  declarations: [
    CallbackPipe,
    SecToTimePipe,
    TimeToSecPipe,
    DsDatePipe,
    DsDateTimePipe,
    DsTimePipe,
    FileSizePipe,
    DsNumberPipe,
  ],
  imports: [CommonModule],
  exports: [
    CallbackPipe,
    SecToTimePipe,
    TimeToSecPipe,
    DsDatePipe,
    DsDateTimePipe,
    DsTimePipe,
    FileSizePipe,
    DsNumberPipe,
  ],
  providers: [FileSizePipe],
})
export class CorePipesModule {}
