import { LocalStorageService } from '../../service/localStorage/local-storage.service';
import { mergeDeep } from 'ds-player/dist/components/utils/ds-player.utils';

const PARAMS_INSTANCE = 'serv_params';

const setValueByPath = function (obj, path, value) {
  var a = path.split('.');
  var o = obj;
  while (a.length - 1) {
    var n = a.shift();
    if (!(n in o)) o[n] = {};
    o = o[n];
  }
  o[a[0]] = value;
};

export interface InitParamsType {
  pager?: {
    type?: string;
    limit?: number;
    dropField: string;
    limitField: string;
  };
  search?: {
    field: string;
  };
  sort?: {
    type?: string;
    sortField: string;
    orderField: string;
  };
  filters?: Array<{
    id?: string;
    type?: string;
    field: string;
    values?: { [id: string]: string };
    currentValue: any;
  }>;
}

export class ParamsHandler {
  private _params: any = {};

  get params() {
    return Object.assign(
      {},
      this.getParamsPager(),
      this.getParamsSearch(),
      this.getParamsSort(),
      this.getParamsFilters()
    );
  }

  constructor(
    private initConfig: InitParamsType = {},
    private localStorageService: LocalStorageService
  ) {
    this.parseConfigPager();
    this.parseConfigSearch();
    this.parseConfigSort();
    this.parseConfigFilter();
  }

  public saveToStorage(id: string) {
    return this.localStorageService.set(id, this._params, PARAMS_INSTANCE);
  }

  public async loadFromStorage(id: string) {
    this._params = {};

    let resultFromDb = await this.localStorageService.get(id, PARAMS_INSTANCE);

    try {
      this._params = mergeDeep(this._params, resultFromDb || {});
    } catch (e) {
      this._params = {};
    }

    // Фиксы последней надежды
    await new Promise(r => setTimeout(r));

    this.parseConfigPager();
    this.parseConfigSearch();
    this.parseConfigSort();
    this.parseConfigFilter();

    // Фиксы последней надежды
    return new Promise(r => setTimeout(r));
  }

  //----------

  private parseConfigPager() {
    if (!this.initConfig.pager) {
      this._params && delete this._params.pager;
      return;
    }

    if (!this._params.pager)
      this._params.pager = {
        drop: 0,
        limit: this.initConfig.pager.limit || 20,
      };
  }

  private getParamsPager() {
    if (!this._params.pager) {
      return {};
    }

    switch (this.initConfig.pager?.type) {
      case 'string':
        return {
          [this.initConfig.pager?.dropField || 'pager.drop']:
            this._params.pager.drop,
          [this.initConfig.pager?.limitField || 'pager.limit']:
            this._params.pager.limit,
        };
      case 'object':
        let retObj = {};
        setValueByPath(
          retObj,
          this.initConfig.pager?.dropField || 'pager.drop',
          this._params.pager.drop
        );
        setValueByPath(
          retObj,
          this.initConfig.pager?.limitField || 'pager.limit',
          this._params.pager.limit
        );
        return retObj;
    }

    return {};
  }

  public setPage(page) {
    if (this._params.pager) {
      this._params.pager.drop = this._params.pager.limit * page;
    }
  }

  public getPageLimit() {
    if (this._params.pager) {
      return this._params.pager.limit;
    }

    return 0;
  }

  public getPageDrop() {
    if (this._params.pager.drop) {
      return this._params.pager.drop;
    }

    return 0;
  }

  public setPageLimit(value) {
    if (this._params.pager) {
      this._params.pager.limit = value;
    }
  }

  //-----------

  private parseConfigSearch() {
    if (!this.initConfig.search) {
      this._params && delete this._params.search;
      return;
    }

    if (!this._params.search)
      this._params.search = {
        field: this.initConfig.search.field,
      };
  }

  private getParamsSearch() {
    if (!this._params.search || !this._params.search.value) {
      return {};
    }

    return {
      [this._params.search.field]: this._params.search.value,
    };
  }

  public search(value: string) {
    if (!this._params.search) return false;

    this._params.search.value = value;
    this.reset();
    return true;
  }

  public getSearchValue() {
    if (!this._params.search) return '';

    return this._params.search?.value;
  }

  //-----------

  private parseConfigSort() {
    if (!this.initConfig.sort) {
      this._params && delete this._params.sort;
      return;
    }

    if (!this._params.sort)
      this._params.sort = {
        type: this.initConfig.sort.type,
        orderField: this.initConfig.sort.orderField,
        sortField: this.initConfig.sort.sortField,
      };
  }

  private getParamsSort() {
    if (!this._params.sort || !this._params.sort.sortValue) {
      return {};
    }

    if (this._params.sort?.type === 'object') {
      let retObj = {};
      setValueByPath(
        retObj,
        this._params.sort.orderField,
        this._params.sort.orderValue
      );
      setValueByPath(
        retObj,
        this._params.sort.sortField,
        this._params.sort.sortValue
      );
      return retObj;
    }

    return {
      [this._params.sort.orderField]: this._params.sort.orderValue,
      [this._params.sort.sortField]: this._params.sort.sortValue,
    };
  }

  public setSort(field: string, value?: string) {
    if (!this._params.sort) return false;

    if (value) {
      this._params.sort.orderValue = value;
      this._params.sort.sortValue = field;
    } else {
      this._params.sort.orderValue = undefined;
      this._params.sort.sortValue = undefined;
    }
    this.reset();
    return true;
  }

  //------------

  private parseConfigFilter() {
    if (!this.initConfig.filters || !Array.isArray(this.initConfig.filters)) {
      delete this._params.filters;
      return;
    }

    this._params.filters = this._params.filters || [];
    this.initConfig.filters.forEach(filter => {
      if (!!this.getFilterItemById(filter.id || filter.field || '')) {
        return;
      }

      this._params.filters.push(
        Object.assign(
          {
            currentValue: undefined,
          },
          filter
        )
      );
    });
  }

  private getParamsFilters() {
    if (!this._params.filters) {
      return {};
    }

    let result = {};

    this._params.filters.forEach(filter => {
      Object.assign(result, {
        [filter.field]: filter.currentValue,
      });
    });

    return result;
  }

  private getFilterItemById(id: string) {
    if (!this._params.filters) return;

    return this._params.filters.find(
      i => (typeof i.id !== 'undefined' && i.id === id) || i.field === id
    );
  }

  public getFilterValues(id: string) {
    let finded = this.getFilterItemById(id);

    if (finded) {
      return finded.values;
    }
    return;
  }

  public getFilterValue(id: string) {
    let finded = this.getFilterItemById(id);

    if (finded) {
      return finded.currentValue;
    }
    return;
  }

  public setFilterValue(id: string, value: any) {
    if (!this._params.filters) return;

    let finded = this._params.filters.find(
      i => (typeof i.id !== 'undefined' && i.id === id) || i.field === id
    );

    if (finded) finded.currentValue = value;
  }

  //-----------

  public next() {
    if (this._params.pager) {
      this._params.pager.drop += this._params.pager.limit;
    }
  }

  public prev() {
    if (this._params.pager) {
      this._params.pager.drop -= this._params.pager.limit;
      this._params.pager.drop =
        this._params.pager.drop < 0 ? 0 : this._params.pager.drop;
    }
  }

  public reset() {
    if (this._params.pager) {
      this._params.pager.drop = 0;
    }
  }
}
