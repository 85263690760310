/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationGroupApi } from '../models/location-group-api';
import { LocationGroupCreateApi } from '../models/location-group-create-api';
import { LocationGroupQueryApi } from '../models/location-group-query-api';

@Injectable({
  providedIn: 'root',
})
export class LocationgroupApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPartnerPartneridLocationgroup
   */
  static readonly GetPartnerPartneridLocationgroupPath = '/partner/{partnerId}/locationgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridLocationgroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationgroup$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<Array<LocationGroupQueryApi>>> {

    const rb = new RequestBuilder(this.rootUrl, LocationgroupApiService.GetPartnerPartneridLocationgroupPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationGroupQueryApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridLocationgroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationgroup(params: {
    partnerId: number;
  }): Observable<Array<LocationGroupQueryApi>> {

    return this.getPartnerPartneridLocationgroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationGroupQueryApi>>) => r.body as Array<LocationGroupQueryApi>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridLocationgroup
   */
  static readonly PostPartnerPartneridLocationgroupPath = '/partner/{partnerId}/locationgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridLocationgroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationgroup$Response(params: {
    partnerId: number;
    body: LocationGroupCreateApi
  }): Observable<StrictHttpResponse<LocationGroupApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationgroupApiService.PostPartnerPartneridLocationgroupPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationGroupApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridLocationgroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationgroup(params: {
    partnerId: number;
    body: LocationGroupCreateApi
  }): Observable<LocationGroupApi> {

    return this.postPartnerPartneridLocationgroup$Response(params).pipe(
      map((r: StrictHttpResponse<LocationGroupApi>) => r.body as LocationGroupApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridLocationgroupId
   */
  static readonly GetPartnerPartneridLocationgroupIdPath = '/partner/{partnerId}/locationgroup/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridLocationgroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationgroupId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<LocationGroupApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationgroupApiService.GetPartnerPartneridLocationgroupIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationGroupApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridLocationgroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationgroupId(params: {
    partnerId: number;
    id: number;
  }): Observable<LocationGroupApi> {

    return this.getPartnerPartneridLocationgroupId$Response(params).pipe(
      map((r: StrictHttpResponse<LocationGroupApi>) => r.body as LocationGroupApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridLocationgroupId
   */
  static readonly PutPartnerPartneridLocationgroupIdPath = '/partner/{partnerId}/locationgroup/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridLocationgroupId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridLocationgroupId$Response(params: {
    partnerId: number;
    id: number;
    body: LocationGroupCreateApi
  }): Observable<StrictHttpResponse<LocationGroupApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationgroupApiService.PutPartnerPartneridLocationgroupIdPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationGroupApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridLocationgroupId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridLocationgroupId(params: {
    partnerId: number;
    id: number;
    body: LocationGroupCreateApi
  }): Observable<LocationGroupApi> {

    return this.putPartnerPartneridLocationgroupId$Response(params).pipe(
      map((r: StrictHttpResponse<LocationGroupApi>) => r.body as LocationGroupApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridLocationgroupId
   */
  static readonly DeletePartnerPartneridLocationgroupIdPath = '/partner/{partnerId}/locationgroup/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridLocationgroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridLocationgroupId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationgroupApiService.DeletePartnerPartneridLocationgroupIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridLocationgroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridLocationgroupId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridLocationgroupId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
