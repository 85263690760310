import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum PLAYER_STATUS {
  active = 'active',
  inactive = 'inactive',
}

export const PLAYER_STATUS_NAMES: { [id in PLAYER_STATUS]: string } = {
  [PLAYER_STATUS.active]: $localize`:|@@variables_service.active:Активный`,
  [PLAYER_STATUS.inactive]: $localize`:|@@variables_service.not-active:Не активный`,
};

export enum PLAYER_SCHEDULE_STATUS {
  create = 'create',
  valid = 'valid',
  error = 'error',
  run = 'run',
  transcoding = 'transcoding',
  archive = 'archive',
}

export const PLAYER_SCHEDULE_STATUS_NAMES: {
  [id in PLAYER_SCHEDULE_STATUS]: string;
} = {
  [PLAYER_SCHEDULE_STATUS.create]: $localize`:|@@variables_service.in-preparation:В подготовке`,
  [PLAYER_SCHEDULE_STATUS.valid]: $localize`:|@@variables_service.successful-preparation-of-the-schedule:Успешная подготовка расписания`,
  [PLAYER_SCHEDULE_STATUS.error]: $localize`:|@@variables_service.an-error-occurred-while-preparing-the-schedule:При подготовке расписания возникла ошибка`,
  [PLAYER_SCHEDULE_STATUS.run]: $localize`:|@@variables_service.playback-started:Запущено проигрывание`,
  [PLAYER_SCHEDULE_STATUS.transcoding]: $localize`:|@@variables_service.transcoding-started:Запущено транскодирование`,
  [PLAYER_SCHEDULE_STATUS.archive]: $localize`:|@@variables_service.archived-schedule-playback-time-has-expired:Расписание в архиве/время проигрывания истекло`,
};

export const FILE_TYPES_NAMES = {
  image: $localize`:|@@variables_service.image:Изображение`,
  video: $localize`:|@@variables_service.video:Видео`,
};

export const FILE_TYPES = {
  image: {
    name: $localize`:|@@variables_service.image2:Изображение`,
    icon: <IconProp>'file-image',
  },
  video: {
    name: $localize`:|@@variables_service.video2:Видео`,
    icon: <IconProp>'file-video',
  },
};
