<ul class="app-menu list-unstyled accordion" id="menu-accordion">
  <ng-container *ngFor="let navItem of navTree">
    <ng-container *ifPermission="navItem?.permission || { type: true }">
      <li class="nav-item">
        <a
          class="nav-link"
          href="javascript: void(0)"
          [dsLink]="navItem.uiPath"
          [class.submenu-toggle]="!!navItem.children"
          [class.active]="
            isActivePath(navItem.activePath) ||
            hasActiveChild(navItem?.children)
          ">
          <span class="nav-icon">
            <i [className]="navItem.icon"></i>
          </span>
          <span class="nav-link-text" [innerText]="navItem.name"> </span>

          <span class="submenu-arrow" *ngIf="!!navItem.children">
            <i class="fas fa-angle-down"></i>
          </span>
        </a>

        <div *ngIf="!!navItem.children" class="submenu submenu-1">
          <ul class="submenu-list list-unstyled">
            <ng-container *ngFor="let subItem of navItem.children">
              <ng-container
                *ifPermission="subItem?.permission || { type: true }">
                <li class="submenu-item">
                  <a
                    class="submenu-link"
                    href="javascript: void(0)"
                    [dsLink]="subItem.uiPath"
                    [class.active]="isActivePath(subItem.activePath)"
                    [innerText]="subItem.name"></a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </li>
    </ng-container>
  </ng-container>
</ul>
