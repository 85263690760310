/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TemplateApi } from '../models/template-api';
import { TemplateCreateApi } from '../models/template-create-api';
import { TemplateListApi } from '../models/template-list-api';
import { TemplateRequestApi } from '../models/template-request-api';
import { TemplateUpdateApi } from '../models/template-update-api';

@Injectable({
  providedIn: 'root',
})
export class TemplateApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridTemplateQuery
   */
  static readonly PostPartnerPartneridTemplateQueryPath = '/partner/{partnerId}/template/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridTemplateQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplateQuery$Response(params: {
    partnerId: number;
    body: TemplateRequestApi
  }): Observable<StrictHttpResponse<Array<TemplateListApi>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.PostPartnerPartneridTemplateQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateListApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridTemplateQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplateQuery(params: {
    partnerId: number;
    body: TemplateRequestApi
  }): Observable<Array<TemplateListApi>> {

    return this.postPartnerPartneridTemplateQuery$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateListApi>>) => r.body as Array<TemplateListApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridTemplateId
   */
  static readonly GetPartnerPartneridTemplateIdPath = '/partner/{partnerId}/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridTemplateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<TemplateApi>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.GetPartnerPartneridTemplateIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridTemplateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateId(params: {
    partnerId: number;
    id: number;
  }): Observable<TemplateApi> {

    return this.getPartnerPartneridTemplateId$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateApi>) => r.body as TemplateApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridTemplateId
   */
  static readonly PostPartnerPartneridTemplateIdPath = '/partner/{partnerId}/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridTemplateId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplateId$Response(params: {
    partnerId: number;
    id: number;
    body: TemplateUpdateApi
  }): Observable<StrictHttpResponse<TemplateApi>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.PostPartnerPartneridTemplateIdPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridTemplateId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplateId(params: {
    partnerId: number;
    id: number;
    body: TemplateUpdateApi
  }): Observable<TemplateApi> {

    return this.postPartnerPartneridTemplateId$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateApi>) => r.body as TemplateApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridTemplateId
   */
  static readonly DeletePartnerPartneridTemplateIdPath = '/partner/{partnerId}/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridTemplateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridTemplateId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.DeletePartnerPartneridTemplateIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridTemplateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridTemplateId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridTemplateId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postPartnerPartneridTemplate
   */
  static readonly PostPartnerPartneridTemplatePath = '/partner/{partnerId}/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplate$Response(params: {
    partnerId: number;
    body: TemplateCreateApi
  }): Observable<StrictHttpResponse<TemplateApi>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.PostPartnerPartneridTemplatePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridTemplate(params: {
    partnerId: number;
    body: TemplateCreateApi
  }): Observable<TemplateApi> {

    return this.postPartnerPartneridTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateApi>) => r.body as TemplateApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridTemplateTestdata
   */
  static readonly GetPartnerPartneridTemplateTestdataPath = '/partner/{partnerId}/template/testData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridTemplateTestdata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateTestdata$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.GetPartnerPartneridTemplateTestdataPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridTemplateTestdata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateTestdata(params: {
    partnerId: number;
  }): Observable<any> {

    return this.getPartnerPartneridTemplateTestdata$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getPartnerPartneridTemplateIdCheck
   */
  static readonly GetPartnerPartneridTemplateIdCheckPath = '/partner/{partnerId}/template/{id}/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridTemplateIdCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateIdCheck$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateApiService.GetPartnerPartneridTemplateIdCheckPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridTemplateIdCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridTemplateIdCheck(params: {
    partnerId: number;
    id: number;
  }): Observable<Array<string>> {

    return this.getPartnerPartneridTemplateIdCheck$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
