import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { APP_ROUTES } from '../../../../app-routes.variables';
import { forEach } from '@uirouter/core';

@Directive({
  selector: 'a[uiSref]',
  providers: [],
})
export class UiSrefFixDirective implements OnInit, OnChanges {
  @Input('uiSref') url;
  @Input('uiParams') params;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      let buildedPath = this.buildUrl();
      let uiPath = this.el.nativeElement.getAttribute('href') || '';

      if (!buildedPath) return;

      if (buildedPath.indexOf(uiPath) === 0 && buildedPath !== uiPath) {
        this.el.nativeElement.setAttribute('href', encodeURI(buildedPath));
      }
    });
  }

  buildPath() {
    if (!this.url) return;

    let path = this.url.split('.');
    let url = '#';
    let item;

    for (let i = 0; i < path.length; i++) {
      item = APP_ROUTES[path.slice(0, i + 1).join('.')];

      if (!item) return;

      url += '/' + item.url;
    }

    url = url.replace(/\/+/gi, '/');

    return url;
  }

  buildUrl() {
    let path = this.buildPath();

    if (!path) return;

    path = path.replace(/\?.*$/gi, '');

    if (typeof this.params !== 'object') return path;

    for (let key in this.params) {
      switch (true) {
        case path.includes(':' + key):
          path = path.replace(':' + key, this.params[key].toString());
          break;

        default:
          path +=
            (path.includes('?') ? '&' : '?') +
            `${key}=${this.params[key].toString()}`;
      }
    }

    return path;
  }
}
