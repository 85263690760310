import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ITreeOptions,
  KEYS,
  TREE_ACTIONS,
  TreeComponent,
  TreeNode,
} from '@circlon/angular-tree-component';
import { CatalogApiService } from '../../../../../core/service/api/catalog-api/catalog-api.service';
import { FileTableViewComponent } from '../file-table-view/file-table-view.component';
import { catchError, concatMap, takeUntil, takeWhile } from 'rxjs/operators';
import { CatalogGetApi } from '../../../../../core/service/api/generated/Catalog';
import { of } from 'rxjs';
import { ConfirmActionService } from '../../../../../core/service/confirmAction/confirmAction.service';
import { FileApiService } from '../../../../../core/service/api/file-api/file-api.service';

@Component({
  selector: 'file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss'],
  providers: [CatalogApiService, ConfirmActionService],
})
export class FileTreeComponent implements OnInit, OnDestroy {
  public partnerId: number = 1;
  public treeArray: any = [];

  public activeTab;

  private _tree: TreeComponent | undefined;
  private subs: any = [];

  public activeTagInput;

  get tree() {
    return this._tree;
  }

  @Input() filterParams: CatalogGetApi = {};
  @Output() filterParamsChange = new EventEmitter();

  public searchQuery;
  public searchTags;

  @ViewChild(TreeComponent) set tree(value) {
    this._tree = value;
    setTimeout(() => {
      if (this._tree && this._tree.treeModel)
        (<any>this._tree.treeModel).hasSelectedCatalog = () =>
          !!this._tree?.treeModel?.focusedNode &&
          this._tree?.treeModel?.focusedNode.id !== -1;

      this.treeModelChange.emit(this._tree?.treeModel);
    });
  }

  @Input() enablePreview = false;

  @Output() treeModelChange = new EventEmitter();

  @ViewChild('editNodeInput')
  set editNodeInput(value: ElementRef) {
    if (value) {
      setTimeout(() => {
        value.nativeElement.focus();
        value.nativeElement.select();
      }, 10);
    }
  }

  public treeOptions: ITreeOptions = {
    //  allowDragoverStyling: false,
    getChildren: node => {
      return this.getLevelForCatalog(node.data.id, node.data.partnerId);
    },
    allowDrag: node => {
      return !node.data || !node.data.$isEditing;
    },
    allowDrop: (nodeFrom, nodeTo, $event) => {
      if ($event?.type === 'drop') {
        $event?.target
          ?.closest('.is-dragging-over')
          .classList?.remove('is-dragging-over');
        if (!nodeFrom) {
          this.dragFile(nodeFrom, nodeTo, $event);
          return false;
        }
      }

      if (nodeTo?.parent?.data?.id === -1 || nodeTo?.parent?.data?.virtual)
        return false;

      if (
        $event?.target &&
        $event?.target?.classList?.contains('is-dragging-over') &&
        !$event?.target?.childNodes?.length
      ) {
        $event?.target?.classList?.remove('is-dragging-over');
        return false;
      }

      return !nodeFrom || !nodeFrom.data || !nodeFrom.data.$isEditing;
    },
    actionMapping: {
      mouse: {
        click: (tree: any, node: TreeNode, $event: any) => {
          if (node.data.$isEditing) return;

          if (node.isFocused) {
            node.blur();
            node.collapse();
          } else {
            node.focus();
            if (node.isCollapsed) {
              node.expand();
            }
          }
        },
        dblClick: (tree: any, node: any, $event: any) => {
          node.focus();
          node.expand();
          node.data.$isEditing = true;
        },
      },
      keys: {
        [KEYS.ENTER]: (tree: any, node: any, $event: any) => {
          node.expandAll();
        },
      },
    },
  };

  constructor(
    private catalogApiService: CatalogApiService,
    private elRef: ElementRef,
    public confirmActionService: ConfirmActionService,
    private fileApiService: FileApiService
  ) {}

  ngOnInit(): void {
    this.tree?.treeModel?.collapseAll();
    this.getLevelForCatalog()
      .then(result => {
        this.treeArray = result;
      })
      .then(() => {
        setInterval(() => {
          const someNode = this.tree?.treeModel.getNodeById(-1);
          someNode && someNode.expand();
        }, 0);
      });
  }

  getLevelForCatalog(catalogId?: number, partnerId = this.partnerId) {
    return this.catalogApiService
      .getCatalogLevel(catalogId, this.filterParams)
      .then((result: any = []) => {
        result = result.catalogs.map((i: any) =>
          Object.assign(
            {
              hasChildren: !!i.childFolderCount,
            },
            i
          )
        );

        if (typeof catalogId === 'undefined') {
          result.forEach(i => {
            i.parentCatalogId = -1;
          });

          result = [
            {
              childFolderCount: result.length,
              filesCount: 0,
              hasChildren: true,
              id: -1,
              name: $localize`:|@@file-tree_page.catalogs:Каталоги`,
              partnerId: partnerId,
              children: result,
            },
          ];
        }

        return result;
      });
  }

  moveNode($event) {
    return this.catalogApiService
      .moveCatalog$(
        $event.node.id,
        $event.to.parent.virtual ? undefined : $event.to.parent?.id
      )
      .toPromise();
  }

  public async createNewCatalog() {
    let arrayToAdd, parentArray, partnerId, parentId, node;

    if (!this.tree && !this.treeArray.length) return;

    if (!this.tree?.treeModel.getFocusedNode()) {
      this.tree?.treeModel.getNodeById(-1)?.focus();
    }

    node = this.tree?.treeModel.getFocusedNode();
    parentId = node.data.id;
    if (parentId !== -1) await node.loadNodeChildren();

    node.data.children = node.data.children || [];
    arrayToAdd = node.data.children;
    partnerId = node.data.partnerId;
    parentArray = node.data.children;
    node.data.childFolderCount = node.data.childFolderCount || 0;
    node.data.childFolderCount++;

    const newFolderCounter = parentArray.reduce((acc, i) => {
      if (
        !i.name
          .toLowerCase()
          .startsWith($localize`:|@@file-tree_page.new-folder:новая папка`)
      )
        return acc;

      if (
        i.name.toLowerCase().trim() ===
        $localize`:|@@file-tree_page.new-folder2:новая папка`
      )
        return 1 > acc ? 1 : 0;

      let finded = /\((\d+)\)/gi.exec(i.name.toLowerCase());
      return finded && finded.length === 2
        ? parseInt(finded[1]) > acc
          ? parseInt(finded[1])
          : acc
        : acc;
    }, 0);

    const addedNode: any = await this.catalogApiService
      .create$(
        !newFolderCounter
          ? $localize`:|@@file-tree_page.new-folder3:Новая папка`
          : `Новая папка (${newFolderCounter + 1})`,
        parentId === -1 ? undefined : parentId
      )
      .toPromise();

    if (parentId === -1) {
      addedNode.parentId = parentId;
    }

    arrayToAdd.push(Object.assign({ $isEditing: true }, addedNode));

    if (this.tree) {
      this.tree?.treeModel.update();

      if (node) node = this.tree?.treeModel.getNodeById(node.data.id);
    }

    if (node) {
      let parentNodes = node.parent.children || this.tree?.treeModel.roots;
      let finded = parentNodes.find(i => i.data.id === node.data.id);
      if (finded) finded.expand();
    }

    if (this.tree && this.tree.treeModel.getNodeById(addedNode.id))
      this.tree.treeModel.getNodeById(addedNode.id).focus();
  }

  public async deleteCatalog() {
    if (!this.tree?.treeModel.getFocusedNode()) return;

    let node = this.tree?.treeModel.getFocusedNode();

    try {
      await this.catalogApiService
        .checkSchedules$(node.data.id)
        .pipe(
          catchError(() => of([])),
          concatMap(
            (values: any) =>
              new Promise((resolve, reject) => {
                if (Array.isArray(values) && !!values.length) {
                  values = (<any[]>values)
                    .map(
                      i =>
                        i.fileName +
                        '<ul><li>' +
                        i.info.map(v => v.scheduleName).join('</li><li>') +
                        '</li></ul>'
                    )
                    .join('</li><li>');

                  values = `
                <div">Файлы участвуют в расписаниях:</div>
                <ul>
                  <li>${values}</li>
                </ul>`;
                } else {
                  values = '';
                }

                this.confirmActionService.confirm(
                  `
            <div>Вы действительно хотите удалить каталог '<span class="fw-bold">${node.data.name}</span>'?</div>
            ${values}
          `,
                  resolve,
                  reject
                );
              })
          )
        )
        .toPromise()
        .then(d => {
          this.catalogApiService.delete$(node.data.id).toPromise();
        });
    } catch (e) {
      return;
    }

    let parentNodes =
      (node.parent && node.parent.data.children) || this.tree?.treeModel.nodes;
    let findedIndex = parentNodes.findIndex(i => i.id === node.data.id);
    parentNodes.splice(findedIndex, 1);

    if (node.parent && node.parent.data.childFolderCount) {
      node.parent.data.childFolderCount--;
    }

    if (
      node.parent &&
      node.parent.data.childFolderCount <= 0 &&
      node.parent.isExpanded
    ) {
      node.parent.collapse();
    }

    if (this.tree) this.tree.treeModel.update();
  }

  changeNodeKeyDown($event: KeyboardEvent, node) {
    switch (true) {
      case $event.keyCode === 13:
        $event.preventDefault();
        return this.updateNodeName(node);
      case $event.keyCode === 27:
        $event.preventDefault();
        return this.resetNode(node);
    }

    return;
  }

  updateNodeName(node) {
    return this.catalogApiService
      .updateName$(node.data.id, node.data.name)
      .toPromise()
      .then(() => {
        delete node.data.$isEditing;
      });
  }

  resetNode(node) {
    const nodeId = node.data.id;
    return this.catalogApiService
      .read$(node.parent.data.virtual ? undefined : node.parent.data.id)
      .toPromise()
      .then((result: any) => {
        const finded = result.find(i => i.id === nodeId);
        if (finded) {
          Object.assign(node.data, finded);
        }

        delete node.data.$isEditing;
      });
  }

  onFileChange($event) {
    if (!this.tree) return;

    let node = this.tree?.treeModel.getFocusedNode();

    switch ($event.type) {
      case 'delete':
        node.data.filesCount = node.data.filesCount || 0;
        node.data.filesCount--;
        if (node.data.filesCount < 0) {
          node.data.filesCount = 0;
        }
        break;
      case 'add':
        node.data.filesCount = node.data.filesCount || 0;
        node.data.filesCount++;
        break;
    }
  }

  public editNode() {
    let node = this.tree?.treeModel.getFocusedNode();

    if (!node || node?.data?.$isEditing) return;

    node.focus();
    node.expand();
    node.data.$isEditing = true;
  }

  changeFilter() {
    this.filterParams = {};

    if (!!this.searchQuery) {
      this.filterParams.query = this.searchQuery;
    }

    if (!!this.searchTags) {
      this.filterParams.tags = this.searchTags;
    }

    this.filterParamsChange.emit(this.filterParams);
    this.ngOnInit();
  }

  public deactivate($event) {
    if (!$event?.treeModel?.focusedNode) {
      $event?.node?.focus();
    }
  }

  public switchTagActive() {
    this.activeTagInput = !this.activeTagInput;
    if (!this.activeTagInput) {
      setTimeout(() => {
        this.elRef.nativeElement
          .querySelector('app-search-input input')
          ?.focus();
      });
    }
  }

  public dragEnd($event) {
    setTimeout(() => this.tree?.treeModel?.update());
  }

  public dragFile(nodeFrom, nodeTo, $event) {
    let data = $event?.dataTransfer?.getData('file-item');
    if (!data) return;

    try {
      data = JSON.parse(data);
    } catch (e) {
      return;
    }

    if (
      !data.filename ||
      typeof nodeTo?.parent?.data?.id === 'undefined' ||
      nodeTo?.parent?.data?.id === -1
    )
      return;

    this.fileApiService
      .update$(data.filename, {
        newCatalogId: nodeTo?.parent?.data?.id,
      })
      .subscribe(result => {
        nodeTo.parent.data.filesCount++;

        let pastNode = this.tree?.treeModel.getNodeById(data.catalogId);
        pastNode.data.filesCount--;

        if (pastNode.data.filesCount < 0) pastNode.data.filesCount = 0;

        nodeTo?.parent?.focus();
      });
  }

  ngOnDestroy() {
    this.subs.forEach(i => i.unsubscribe());
  }
}
