/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceApi } from '../models/device-api';
import { DeviceCreateApi } from '../models/device-create-api';

@Injectable({
  providedIn: 'root',
})
export class Service extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridApi20Device
   */
  static readonly PostPartnerPartneridApi20DevicePath = '/partner/{partnerId}/api/2.0/device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridApi20Device()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridApi20Device$Response(params: {
    partnerId: number;

    /**
     * Данные устройства
     */
    body?: Array<DeviceCreateApi>
  }): Observable<StrictHttpResponse<Array<DeviceApi>>> {

    const rb = new RequestBuilder(this.rootUrl, Service.PostPartnerPartneridApi20DevicePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridApi20Device$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridApi20Device(params: {
    partnerId: number;

    /**
     * Данные устройства
     */
    body?: Array<DeviceCreateApi>
  }): Observable<Array<DeviceApi>> {

    return this.postPartnerPartneridApi20Device$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceApi>>) => r.body as Array<DeviceApi>)
    );
  }

}
