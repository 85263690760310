<div class="form-registration-container">
  <div class="text-center form-signin">
    <form autocomplete=”off” #inputForm="ngForm">
      <div class="logo-icon logo-container">
        <app-logo [notClicable]="true"></app-logo>
      </div>

      <div *ngIf="!isLoading && ( stage === 'create' || stage === 'location' )">
        <h1 class="h4 mb-3 fw-normal" i18n="@@register-player-page.login">
          Регистрация плеера
        </h1>

        <div class="form-floating mb-4">
          <input
            autocomplete=”off”
            type="text"
            class="form-control"
            id="playerID"
            name="playerID"
            pattern="^[0-9]*$"
            [(ngModel)]="registerItem.partnerId"
            (ngModelChange)="stage = 'create'"
          />
          <label for="playerID" i18n="@@register-player-page.form-label.partner-id"
          >ID партнёра</label
          >
        </div>

        <div class="form-floating mb-4">
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            [(ngModel)]="registerItem.name" />
          <label for="name" i18n="@@register-player-page.form-label.name"
          >Название устройства</label
          >
        </div>

        <div class="form-floating mb-4">
          <input
            type="text"
            class="form-control"
            id="login"
            name="login"
            [(ngModel)]="registerItem.login"
            (ngModelChange)="stage = 'create'"
          />
          <label for="login" i18n="@@register-player-page.form-label.login">
            Login
          </label>
        </div>

        <div class="form-floating mb-4">
          <input
            type="password"

            class="form-control"
            id="playerPassword"
            name="playerPassword"
            placeholder="Пароль"
            autocomplete=”off”
            [(ngModel)]="registerItem.playerPassword"
            (ngModelChange)="stage = 'create'"
            i18n-placeholder="@@register-player-page.playerPassword"
            #passwordInputLink
            (focus)="fixTypePassword()"
            (change)="fixTypePassword()"
            (mousedown)="fixTypePassword()"
          />
          <label for="playerPassword" i18n="@@register-player-page.form-label.playerPassword"
          >Пароль</label
          >
        </div>

        <ng-container *ngIf="stage === 'create'">
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="errorMessage"
              [innerText]="errorMessage"></div>


            <button
              class="w-100 btn btn-lg btn-primary"
              *ngIf="!isLoadingLocation"
              (click)="selectLocation()"
              [disabled]="!isValidForLocation() || inputForm.invalid"
              i18n="@@register-player-page.to-come-in">
              Далее
            </button>
        </ng-container>
        <ng-container
          *ngIf="stage === 'location' && !isLoadingLocation"
        >
          <div class="form-floating mb-4 ">
            <ng-select
              [required]="true"
              [(ngModel)]="registerItem.locationId"
              [loading]="locationList.isLoading"
              (scroll)="onScroll($event)"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event)"
              [virtualScroll]="true"
              (clear)="clear()"
              (close)="close()"
              [compareWith]="compareFunction"
              loadingText="Поиск ТО"
              placeholder="Выбор ТО"
              [name]="'locationId'"
              class="big-ng-select"
              i18n-loadingText="@@register-player-page.search-then"
              i18n-placeholder="@@register-player-page.choice-then">
              <ng-option *ngFor="let item of locationList.items" [value]="item.code">
                <div class="d-flex w-100">
                  <div class="col col-auto text-start">
                    <text-highlight
                      [search]="locationParams.query"
                      [text]="item.name"></text-highlight>
                  </div>

                  <div class="col  text-end">
                    <small class="text-muted float-end ms-2"
                    >
                      <ng-container i18n="@@select-location_components.code">
                        ( Код:
                      </ng-container>
                      <text-highlight
                        [search]="locationParams.query"
                        [text]="item.code"></text-highlight>
                      )
                    </small>
                  </div>
                </div>
              </ng-option>
            </ng-select>
          </div>


          <div class="form-floating mb-4"
               *ngIf="deviceTypeList?.items?.length !== 1"
          >

            <ng-select
              [required]="true"
              [(ngModel)]="registerItem.deviceTypeId"
              [loading]="deviceTypeList.isLoading"
              [clearable]="false"
              (close)="closeDeviceType()"
              (search)="onSearchDeviceType($event)"
              [searchFn]="filterDeviceBySearch"
              loadingText="Поиск типа устройства"
              placeholder="Выбор типа устройства"
              name="deviceTypeId"
              class="big-ng-select"
              i18n-loadingText="@@register-player-page.device-type-then"
              i18n-placeholder="@@register-player-page.device-type">
              <ng-option *ngFor="let item of deviceTypeList.items " [value]="item?.id">
                <div class="text-start w-100">
                 {{ item.name }}
                </div>
              </ng-option>
            </ng-select>

          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="errorMessage"
            [innerText]="errorMessage"></div>

          <button
            class="w-100 btn btn-lg btn-primary"
            (click)="createPlayer()"
            [disabled]="!isValid() || inputForm.invalid"
            i18n="@@register-player-page.registration">
            Регистрация плеера
          </button>
        </ng-container>

        <div class="mt-5" *ngIf="isLoadingLocation">
          <i class="fas fa-spinner fa-spin fa-3x text-warning"></i>
        </div>

      </div>

      <div class="mt-5" *ngIf="isLoading">
        <i class="fas fa-spinner fa-spin fa-3x text-warning"></i>
      </div>

      <div *ngIf="stage === 'ok'">
        <div
          class="alert alert-success h4 pt-4 pb-4 text-center mb-0"
          role="alert"
        >
          <i class="fa fa-check-circle text-success me-2"></i> Плеер зарегистрирован
        </div>

      </div>

      <div class="card text-center mt-5">
        <div class="card-body p-2">
          <div
            class="d-inline-block me-2"
            i18n="
              vis-signin|Авторизация.Вход в систему.@@vis-signin.language-select">
            Выбор языка:
          </div>

          <language-selector [showFullName]="true"></language-selector>
        </div>
      </div>

      <p class="mt-5 mb-3 text-muted">&copy; 2024</p>
    </form>
  </div>
</div>
