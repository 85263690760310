import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { ModalSelectorComponent } from './modal-selector/modal-selector.component';

@Injectable()
export class FilesSelectorService {
  constructor(private modalService: NgbModal) {}

  get(options: any = {}): Observable<any> {
    const modalRef = this.modalService.open(ModalSelectorComponent, {
      size: 'xl',
    });

    modalRef.componentInstance.setOptions(options);

    return from(modalRef.result);
  }
}
