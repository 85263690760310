import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { CatalogApiService } from '../../../../../core/service/api/catalog-api/catalog-api.service';
import {
  FileSystemFileEntry,
  NgxFileDropComponent,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { HttpEventType } from '@angular/common/http';
import { FilePreviewComponent } from '../../../../../core/components/file-preview/file-preview.component';
import { FileUtilsClass } from '../../../../../core/class/file-utils.class';
import { FileApiService } from '../../../../../core/service/api/file-api/file-api.service';
import { ToastService } from '../../../../../core/components/toasts-container/toast.service';
import { TagApiService } from '../../../../../core/service/api/tag-api/tag-api.service';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CatalogGetApi } from '../../../../../core/service/api/generated/Catalog';
import { ConfirmActionService } from '../../../../../core/service/confirmAction/confirmAction.service';
import { of } from 'rxjs';
import { FileSettingsPopupService } from '../file-settings/file-settings.component';
import { PartnerApiService } from '../../../../../core/service/api/partner-api/partner-api.service';
import { PermissionsService } from '../../../../../core/service/permissions/permissions.service';
import { PERMISSION_TYPES } from '../../../../../core/service/permissions/permissions.enum';

@Component({
  selector: 'file-table-view',
  templateUrl: './file-table-view.component.html',
  styleUrls: ['./file-table-view.component.scss'],
  providers: [
    CatalogApiService,
    FileApiService,
    ConfirmActionService,
    PermissionsService,
  ],
})
export class FileTableViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public partnerId;
  @Input() public parentId;
  @Input() public enablePreview;
  @Input() public multiselect = false;

  @Input() public filterParams: CatalogGetApi = {};
  @Output() public filterParamsChange = new EventEmitter();

  @Input() public filterByType: undefined | string | ((item: any) => boolean);

  @Input() public treeModel;

  @Output() public selectedFileChange = new EventEmitter();

  public filesArrayCached: any = [];
  public filesArray: any = [];
  public PAGE_LIMIT=30;

  public selectedList: any[] = [];

  @ViewChildren(FilePreviewComponent)
  public filePreview!: QueryList<FilePreviewComponent>;

  @ViewChild('ngxFileDrop')
  public ngxFileDrop: NgxFileDropComponent;

  public savedNode: any;
  public maxFileSize = 5368709120;

  public PERMISSION_TYPES = PERMISSION_TYPES;

  public imageDrag;

  bindedKeys: any;
  constructor(
    private catalogApiService: CatalogApiService,
    private fileApiService: FileApiService,
    private toastService: ToastService,
    private tagApiService: TagApiService,
    private confirmActionService: ConfirmActionService,
    private fileSettingsPopupService: FileSettingsPopupService,
    public partnerApiService: PartnerApiService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.bindedKeys = this.bindKeys.bind(this);
    this.getPartnerSettings();
    window.addEventListener('keydown', this.bindedKeys, false);

    this.imageDrag = new Image();
    this.imageDrag.src = `/assets/images/fileIcon.png`;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedList = [];
    this.selectedFileChange.emit(false);

    if (changes['parentId']) {
      this.getListByParentId(changes['parentId'].currentValue);
      return;
    }

    if (changes['filterParams'] && !changes['filterParams'].firstChange) {
      this.getListByParentId(this.parentId);
    }
  }

  public getPartnerSettings() {
    this.partnerApiService.getSettings$().subscribe(result => {
      this.maxFileSize =
        (result?.maxFileSize || 0) * 1024 * 1024 || this.maxFileSize;
    });
  }

  getListByParentId(parentId?: number) {
    if (parentId === -1) parentId = undefined;

    return this.catalogApiService
      .getCatalogLevel(parentId, this.filterParams)
      .then((result: any = []) => {
        this.filesArray = [];
        this.filesArrayCached = result.files.map((i: any) =>
          Object.assign(
            {
              hasChildren: !!i.hasChildren,
            },
            i
          )
        );


        this.nextPage();
        return this.filesArray;
      });
  }

  showFile(fileItem, index: boolean | number = false) {
    if (index !== false) this.selectFile(fileItem, <number>index);

    this.filePreview?.get(<number>index)?.open();
  }

  selectFile(fileItem, forceAddOrDelete?) {
    const deleteItem = () =>
      (this.selectedList = this.selectedList.filter(i => i.id !== fileItem.id));
    const addItem = () => {
      if (!this.multiselect) {
        this.selectedList = [fileItem];
      } else {
        this.selectedList.push(fileItem);
      }
    };

    this.selectedList = this.selectedList || [];

    switch (true) {
      case forceAddOrDelete === true:
        addItem();
        break;
      case forceAddOrDelete === false:
        deleteItem();
        break;

      default:
        this.isSelected(fileItem) ? deleteItem() : addItem();
    }

    this.selectedFileChange.emit(
      this.selectedList.length === 0
        ? false
        : this.multiselect
        ? this.selectedList
        : this.selectedList[0]
    );
  }

  isSelected(fileItem) {
    this.selectedList = this.selectedList || [];
    return this.selectedList.some((i: any) => i.id === fileItem.id);
  }

  uploadFile(file: File) {
    if (file.size > this.maxFileSize) {
      this.toastService.showDanger(
        `Размер файла ${file.name} превышает допустимые ${(
          this.maxFileSize /
          1024 /
          1024
        ).toFixed(2)}MB`,
        $localize`:|@@file-table-view_page.attention:Внимание`
      );
      return;
    }

    if (!(<any>file)?.name && !(<any>file)?.fileName && !file.size) return;

    let unitForAdd: any = {
      name: (<any>file)?.name,
      filename: (<any>file)?.fileName,
      percentLoading: 1,
    };

    this.filesArray.unshift(unitForAdd);
    let savedNode = this.treeModel.getFocusedNode();
    this.fileApiService.upload$(file, this.parentId).subscribe(
      result => {
        if (result.type === HttpEventType.UploadProgress) {
          try {
            unitForAdd.percentLoading = Math.round(
              (100 * result.loaded) / <any>result.total
            );
          } catch (e) {}

          return;
        }

        if (
          result.type === HttpEventType.Response &&
          result.ok &&
          result.body
        ) {
          delete unitForAdd.percentLoading;
          Object.assign(unitForAdd, result.body);
          unitForAdd.filename = unitForAdd.fileName;
          unitForAdd.size = unitForAdd.fileSize;
          this.onFileChange({ type: 'add' }, savedNode);
        }
      },
      error => {
        if (
          error &&
          error.error &&
          error.error.toLowerCase().includes('undefined file format')
        ) {
          error.stopPopupError();
          this.toastService.showDanger(
            `Формат файла ${file.name} не поддерживается`,
            $localize`:|@@file-table-view_page.attention2:Внимание`
          );
        }

        this.filesArray = this.filesArray.filter(i => i !== unitForAdd);
      }
    );
  }

  public isDropDisabled = false;
  public dropped(files: NgxFileDropEntry[] = []) {
    files.forEach(droppedFile => {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.uploadFile(file);
      });
    });

    this.ngxFileDrop.ngOnDestroy();
  }

  public openFileDialog() {
    this.ngxFileDrop?.fileSelector?.nativeElement?.click();
  }

  isImage = FileUtilsClass.isImage;
  isVideo = FileUtilsClass.isVideo;

  getFileUrl = name => this.fileApiService.getUrlByName(name);

  deleteFile(file) {
    this.fileApiService
      .checkSchedules$(file.id)
      .pipe(
        catchError(() => of([])),
        concatMap(
          values =>
            new Promise((resolve, reject) => {
              if (Array.isArray(values) && !!values.length) {
                values = (<any[]>values).join('</li><li>');
                values = `
            <div>Файл участвует в расписаниях:</div>
            <ul>
              <li>${values}</li>
            </ul>`;
              } else {
                values = '';
              }

              this.confirmActionService.confirm(
                `
            <div>Вы действительно хотите удалить файл '${file.name}'?</div>
            ${values}
          `,
                resolve,
                reject
              );
            })
        )
      )
      .subscribe(
        d => {
          this.selectedList = [];
          this.selectedFileChange.emit(false);
          let savedNode = this.treeModel.getFocusedNode();
          this.fileApiService
            .delete$(file.filename || file.fileName)
            .toPromise()
            .then(() => {
              this.onFileChange({ type: 'delete' }, savedNode);
              this.filesArray = this.filesArray.filter(i => i !== file);
            });
        },
        () => {
          // не подтвердили
        }
      );
  }

  bindKeys($event: KeyboardEvent) {
    if (!this.multiselect || this.selectedList.length <= 0) {
      return;
    }

    if (
      ['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(
        $event.code
      ) > -1
    ) {
      $event.preventDefault();
    }
  }

  onFileChange($event, inNode) {
    let node = inNode || this.treeModel.getFocusedNode();

    switch ($event.type) {
      case 'delete':
        node.data.filesCount = node.data.filesCount || 0;
        node.data.filesCount--;
        if (node.data.filesCount < 0) {
          node.data.filesCount = 0;
        }
        break;
      case 'add':
        node.data.filesCount = node.data.filesCount || 0;
        node.data.filesCount++;
        break;
    }
  }

  isAllChecked() {
    if (
      this.selectedList.length === 0 ||
      this.selectedList.length < this.filesArray.length
    )
      return false;

    return !this.filesArray.some(i => !this.isSelected(i));
  }

  checkAll(value) {
    if (value.target.checked) {
      this.filesArray.forEach(i => this.selectFile(i, true));
    } else {
      this.filesArray.forEach(i => this.selectFile(i, false));
    }
  }

  saveTag = (id, tags): any => {
    return this.tagApiService
      .assignToFile$(tags || [], id)
      .subscribe(res => {});
  };

  getTags = (id, initArray): any => {

    if (!!initArray) {
      return of( Array.isArray(initArray) ? initArray : [] );
    }

    return this.tagApiService.getFromFile$(id);
  };

  isNameEditing(fileItem) {
    return fileItem && fileItem?.$isEditName;
  }

  switchEdit(fileItem) {
    this.filesArray.forEach(i => {
      this.cancelEditName(i);
    });

    fileItem.$isEditName = true;
    fileItem.$editName = fileItem.name;
  }

  configPopup(fileItem) {
    this.filesArray.forEach(i => {
      this.cancelEditName(i);
    });

    this.fileSettingsPopupService.open({
      id: fileItem.id,
      model: fileItem,
    });
  }

  saveName(fileItem) {
    const savedName = fileItem.$editName;

    fileItem.$isUpdaiting = true;
    this.fileApiService
      .update$(fileItem.filename, {
        name: fileItem.$editName,
      })
      .subscribe(
        result => {
          fileItem.name = savedName;
          delete fileItem.$isUpdaiting;
          this.cancelEditName(fileItem);
        },
        () => {
          this.cancelEditName(fileItem);
          delete fileItem.$isUpdaiting;
        }
      );
  }

  cancelEditName(fileItem) {
    delete fileItem.$isEditName;
    delete fileItem.$editName;
  }

  hasSelectedCatalog() {
    return !!this.treeModel?.hasSelectedCatalog() || !!this.filesArray?.length;
  }

  mouseDownRow($event: MouseEvent) {
    (<HTMLElement>$event?.target)?.setAttribute('draggable', 'true');
  }

  mouseUpRow($event) {
    (<HTMLElement>$event?.target)?.removeAttribute('draggable');
  }

  starDrug($event, fileItem) {
    $event.dataTransfer.clearData();

    $event.dataTransfer.dropEffect = 'copy';
    $event.dataTransfer.setDragImage(this.imageDrag, 32, 32);

    $event.dataTransfer.setData(
      'file-item',
      JSON.stringify({
        filename: fileItem.filename,
        id: fileItem.id,
        catalogId: this.parentId,
      })
    );
  }

  nextPage() {
    this.filesArray = this.filesArray.concat(
      this.filesArrayCached.splice(0, this.PAGE_LIMIT)
    );
  }

  filterItems = (item) => {

    if (!this.filterByType)
      return true;

    if (typeof this.filterByType === "string")
      return item?.mimeType?.includes(this.filterByType)

    if (typeof this.filterByType === "function")
      return this.filterByType(item);

    return true;
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.bindedKeys);
  }
}
