<div class="form-signin-container">
  <div class="text-center form-signin">
    <form>
      <div class="logo-icon logo-container">
        <app-logo [notClicable]="true"></app-logo>
      </div>

      <div *ngIf="!loadingItem.isLoading">
        <h1 class="h4 mb-3 fw-normal" i18n="@@login-page.login">
          Вход в систему
        </h1>

        <div class="form-floating">
          <input
            type="email"
            class="form-control"
            id="floatingInput"
            name="floatingInput"
            placeholder="name@example.com"
            [(ngModel)]="loginItem.email" />
          <label for="floatingInput" i18n="@@login-page.form-label.user-email"
            >Email пользователя</label
          >
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            name="floatingPassword"
            placeholder="Пароль"
            [(ngModel)]="loginItem.password"
            i18n-placeholder="@@login-page.password" />
          <label for="floatingPassword" i18n="@@login-page.form-label.password"
            >Пароль</label
          >
        </div>

        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="errorMessage"
          [innerText]="errorMessage"></div>

        <div class="checkbox mb-3">
          <!--     <label>
            <input type="checkbox" value="remember-me" /> Запомнить меня
          </label>-->
        </div>
        <button
          class="w-100 btn btn-lg btn-primary"
          (click)="login()"
          i18n="@@login-page.to-come-in">
          Войти
        </button>

        <div
          class="mt-2 text-muted text-sm text-center"
          i18n="@@login-page.or-login-with">
          или войти с помощью
        </div>

        <a
          class="w-100 mt-2 btn btn-lg btn-default"
          href="/login/oauth2/ya/redirect"
          (click)="loadingItem.isLoading = true">
          <i
            class="fab fa-yandex float-start fa-lg mt-1 ms-2"
            style="color: #fc3f1d"></i>
          <ng-container i18n="@@login-page.login-with-yandex-id">
            Войти через Яндекс ID
          </ng-container>
        </a>

        <a
          class="w-100 mt-2 btn btn-lg btn-default"
          href="/login/oauth2/vk/redirect"
          (click)="loadingItem.isLoading = true">
          <i
            class="fab fa-vk float-start fa-lg mt-1"
            style="color: #1976d2"></i>
          <ng-container i18n="@@login-page.login-with-vk-id">
            Войти через VK ID
          </ng-container>
        </a>
      </div>

      <div class="mt-5" *ngIf="loadingItem.isLoading">
        <i class="fas fa-spinner fa-spin fa-3x text-warning"></i>
      </div>

      <div class="card text-center mt-5">
        <div class="card-body p-2">
          <div
            class="d-inline-block me-2"
            i18n="
              vis-signin|Авторизация.Вход в систему.@@vis-signin.language-select">
            Выбор языка:
          </div>

          <language-selector [showFullName]="true"></language-selector>
        </div>
      </div>

      <p class="mt-5 mb-3 text-muted">&copy; 2024</p>
    </form>
  </div>
</div>
