import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TagApiService } from '../../service/api/tag-api/tag-api.service';
import { Observable, of, tap } from 'rxjs';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { isArray } from '@uirouter/core';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-tag-input',
  templateUrl: './app-tag-input.component.html',
  styleUrls: ['./app-tag-input.component.scss'],
  providers: [TagApiService],
})
export class AppTagInputComponent implements OnInit {
  @Input() onlyInput = false;
  @Input() onlyView = false;

  @Input() model: string[] = [];

  @Input() highlight: string[] | undefined = [];

  @Input() onlyExisting = false;

  @Input() loadFunction: Observable<string[]>;
  @Input() findFunction: (string) => Observable<string[]>;

  @Input() size = 'md';

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() isEdit = false;

  @Input()
  placeholder = $localize`:|@@app-tag-input_components.add-a-tag:Добавьте тег`;
  @Input()
  addName = $localize`:|@@app-tag-input_components.add-a-tag2:Добавьте тег`;
  @Input()
  editName = $localize`:|@@app-tag-input_components.enter-tag:Введите тег`;
  @Input()
  buttonName = $localize`:|@@app-tag-input_components.add-tags:Добавить теги`;

  public autocompleteItems: any = false;

  public isLoading = false;

  constructor(private tagApiService: TagApiService, private eRef: ElementRef) {}

  ngOnInit() {
    if (!!this.loadFunction) {
      this.isLoading = true;

      this.loadFunction?.subscribe(
        result => {
          this.model = result;
          this.isLoading = false;
        },
        error => {
          error.stopPopupError();
          this.isLoading = false;
        }
      );
    }

    if (this.onlyInput) {
      this.isEdit = true;
      this.setFocus();
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.isEdit || !event.target) return;

    if (this.onlyInput) {
      this.isEdit = true;
      return;
    }

    if (this.eRef.nativeElement.contains(event.target)) {
      return;
    }

    if (
      event.target &&
      typeof event.target.className === 'string' &&
      event.target.className?.includes('ng2-')
    ) {
      return;
    }

    this.switchToView();
  }

  findTag = (text: string): any => {
    this.autocompleteItems = false;
    if (!this.findFunction)
      this.findFunction = <any>(
        this.tagApiService.find$.bind(this.tagApiService)
      );

    return this.findFunction(text).pipe(
      tap(val => (this.autocompleteItems = val))
    );
  };

  switchToView() {
    if (this.onlyInput) return;

    setTimeout(() => (this.isEdit = false), 0);
  }

  switchToEdit() {
    setTimeout(() => (this.isEdit = true), 0);
  }

  lengthValidator: ValidatorFn = (control: AbstractControl) => {
    if (control?.value?.length > 25) {
      return <ValidationErrors>{
        maxLength: true,
      };
    }

    return null;
  };

  public isHighlighted(tag) {
    if (!Array.isArray(this.highlight) || !this.highlight.length) return false;

    return this.highlight.map(i => i.toLowerCase()).includes(tag.toLowerCase());
  }

  public validators = [this.lengthValidator];

  setFocus() {
    if (this.isEdit)
      setTimeout(() => {
        this.eRef.nativeElement
          .querySelector('.ng2-tag-input__text-input')
          ?.focus();
      });
  }

  onAdding(tag: any) {
    if (!this.onlyExisting) return of(tag);

    if (!Array.isArray(this.autocompleteItems)) return of('');

    return of(
      this.autocompleteItems.some(i =>
        typeof tag === 'string' ? i === tag : i === tag.value
      )
        ? tag
        : ''
    );
  }
}
