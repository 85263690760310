<div class="btn-group me-2">
  <button
    class="btn btn-sm btn-default"
    [colorPicker]="value_filters[FILTER_TYPES.color].value"
    (colorPickerChange)="changeValueFilter(FILTER_TYPES.color, $event)"
    ngbPopover="Цвет фона"
    [triggers]="'mouseenter:mouseleave'"
    i18n-ngbPopover="@@block-background_page.background-color">
    <i class="fas fa-fill fa-lg"></i>
    <div
      class="d-inline-block ms-1"
      [style.color]="value_filters[FILTER_TYPES.color].value">
      <i
        class="icon-out fas fa-square-full"
        *ngIf="isFilterEnabled(FILTER_TYPES.color)"></i>
    </div>
  </button>

  <button
    class="btn btn-sm btn-danger"
    *ngIf="isFilterEnabled(FILTER_TYPES.color)"
    (click)="changeValueFilter(FILTER_TYPES.color, undefined)">
    <i class="fas fa-times"></i>
  </button>
</div>

<div class="btn-group me-2" *ngIf="!options?.noImage">
  <button
    class="btn btn-sm btn-default"
    [class.text-success]="isFilterEnabled(FILTER_TYPES.image)"
    (click)="uploadFile()"
    ngbPopover="Изображение фона"
    [triggers]="'mouseenter:mouseleave'"
    i18n-ngbPopover="@@block-background_page.background-image">
    <i class="fas fas-image fa-lg"></i>
  </button>

  <button
    class="btn btn-sm btn-danger"
    *ngIf="isFilterEnabled(FILTER_TYPES.image)"
    (click)="changeValueFilter(FILTER_TYPES.image, undefined)">
    <i class="fas fa-times"></i>
  </button>
</div>

<button
  class="btn btn-sm me-2"
  *ngIf="isFilterEnabled(FILTER_TYPES.image)"
  (click)="changeBoolFilter(FILTER_TYPES.bkgContain)"
  [ngClass]="{
    'btn-default': !isFilterEnabled(FILTER_TYPES.bkgContain),
    'btn-success': isFilterEnabled(FILTER_TYPES.bkgContain)
  }">
  <i class="fas fa-compress-arrows-alt"></i>
</button>
