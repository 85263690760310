<div *ngIf="isEdit" [class.tag-sm]="size === 'sm'">
  <tag-input
    theme="bootstrap"
    [(ngModel)]="model"
    (onAdd)="onChange.emit(model)"
    (onRemove)="onChange.emit(model)"
    [onAdding]="onAdding.bind(this)"
    [modelAsStrings]="true"
    [placeholder]="placeholder"
    [secondaryPlaceholder]="onlyInput ? editName : addName"
    [validators]="validators">
    <tag-input-dropdown
      [zIndex]="20000"
      [minimumTextLength]="2"
      [autocompleteObservable]="findTag"
      [autocompleteItems]="autocompleteItems">
    </tag-input-dropdown>
  </tag-input>
</div>

<div *ngIf="!isEdit">
  <div *ngIf="isLoading">
    <i class="fas fa-spin fa-spinner text-warning"></i>
  </div>

  <ng-container *ngIf="!isLoading">
    <span
      class="badge me-2"
      [class.bg-danger]="isHighlighted(tag)"
      [class.bg-primary]="!isHighlighted(tag)"
      *ngFor="let tag of model"
      >{{ tag }}</span
    >

    <a
      href="javascript:void(0)"
      *ngIf="!onlyView"
      (click)="switchToEdit()"
      class="badge bg-warning text-white">
      <span class="me-1 d-inline-block me-1" *ngIf="!model || !model.length">{{
        buttonName
      }}</span>
      <i class="fas fa-pencil-alt"></i>
    </a>
  </ng-container>
</div>
