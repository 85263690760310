import { IPlayerTypes, IPlayItem } from '../video-core.interfaces';

export class VideoPlayerUtilsClass {
  constructor() {}

  static mediaTypes = IPlayerTypes;
  static getMediaType(type?: IPlayItem['metaType']) {
    switch (true) {
      case typeof type === 'undefined':
        return IPlayerTypes.NONE;
      case type?.toLowerCase().startsWith('video') ||
        type?.toLowerCase().startsWith('quicktime'):
        return IPlayerTypes.VIDEO;
      case type?.toLowerCase().startsWith('image') ||
        type?.toLowerCase().startsWith('jpeg'):
        return IPlayerTypes.IMAGE;
      case type?.toLowerCase().startsWith('template'):
        return IPlayerTypes.TEMPLATE;
    }

    return IPlayerTypes.NONE;
  }
}
