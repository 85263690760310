<div class="app-utility">
  <div
    class="app-utility-item app-user-dropdown dropdown"
    ngbDropdown=""
    [placement]="'bottom-right'">
    <a
      class="dropdown-toggle"
      id="user-dropdown-toggle"
      data-bs-toggle="dropdown"
      href="javascript:void(0)"
      role="button"
      aria-expanded="false"
      ngbDropdownToggle="">
      <div class="row d-inline-flex me-0">
        <div class="col-auto d-flex align-items-center">
          <i class="fas fa-user-circle text-info small fa-2x"></i>
          <!--<img src="assets/images/user.png" alt="user profile" />-->
        </div>
        <div class="col-auto ps-0">
          <div
            class="user-name"
            [innerText]="userApiService.currentUser?.name || ''"></div>
          <div
            class="user-partner text-muted"
            [innerText]="partnerApiService.currentPartner?.name || ''"></div>
        </div>
      </div>
    </a>
    <ul
      class="dropdown-menu"
      aria-labelledby="user-dropdown-toggle"
      ngbDropdownMenu="">
      <ng-container *ifPermission="'information'">
        <li ngbDropdownItem="">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            [dsLink]="'root.information'"
            i18n="@@app-utilites_components.information"
            >Информация</a
          >
        </li>
      </ng-container>
      <ng-container *ngIf="canChangePartner">
        <li ngbDropdownItem="">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            [dsLink]="'partner'"
            i18n="@@app-utilites_components.change-partner"
            >Сменить партнёра</a
          >
        </li>
      </ng-container>
      <li ngbDropdownItem="">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="logout()"
          i18n="@@app-utilites_components.exit"
          >Выход</a
        >
      </li>
    </ul>
  </div>
</div>
