export interface ValidateFieldOptions {
  [key: string]: {
    required?: boolean;
    maxlength?: number;
    max?: number;
    min?: number;
  };
}

export class ValidateFieldClass {
  constructor(private options: ValidateFieldOptions) {}

  public key(keyId: string) {
    return this.options[keyId] || {};
  }

  public required(keyId: string) {
    let key = this.key(keyId) || {};

    return key.required || false;
  }

  public maxLength(keyId: string) {
    let key = this.key(keyId) || {};

    return typeof key.maxlength === 'undefined' ? null : key.maxlength;
  }

  public min(keyId: string) {
    let key = this.key(keyId) || {};

    return typeof key.min === 'undefined' ? null : <number>key.min;
  }

  public max(keyId: string) {
    let key = this.key(keyId) || {};

    return typeof key.max === 'undefined' ? null : <number>key.max;
  }
}
