import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadableItemClass } from '../../class/loadable-item.class';
import {
  PLATFORM_AUDIO_CODEC_NAMES,
  PLATFORM_CONTAINER_NAMES,
  PLATFORM_NAMES,
  PLATFORM_ORIENTATION_NAMES,
  PLATFORM_VIDEO_CODEC_NAMES,
  PlatformApiService,
} from '../api/platform-api/platform-api.service';
import { LabelApiService } from '../api/label-api/label-api.service';

@Component({
  selector: 'ngbd-modal-labelsBWPopup',
  styleUrls: ['./labelsBWPopup.service.scss'],
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5
        class="modal-title ng-binding"
        i18n="@@labelsBWPopup_service.selecting-labels">
        Выбор меток
      </h5>
    </div>
    <div class="modal-body overflow-wrap-break p-0 d-flex overflow-auto">
      <div class="col p-0">
        <ng-container *ngFor="let item of labelsList; let index = index">
          <div class="d-flex border-bottom">
            <div
              class="col align-self-center text-start text-nowrap ps-3 "
              i18n="@@labelsBWPopup_service.label-group-index-1">
              Группа меток {{ index + 1 }}
            </div>

            <div class="col pb-2">
              <div class="d-block text-muted">
                <small i18n="@@labelsBWPopup_service.white-list"
                  >Белый список</small
                >
              </div>

              <app-tag-input
                [model]="item.whiteList"
                [onlyExisting]="true"
                [findFunction]="findLabels"
                placeholder="Выбрать метку"
                addName="Выбрать метку"
                editName="Выбрать метку"
                buttonName="Выбрать метку"
                (onChange)="item.whiteList = $event"
                size="sm"
                i18n-placeholder="@@labelsBWPopup_service.select-label"
                i18n-addName="@@labelsBWPopup_service.select-label2"
                i18n-editName="@@labelsBWPopup_service.select-label3"
                i18n-buttonName="
                  @@labelsBWPopup_service.select-label4"></app-tag-input>
            </div>

            <div class="col pb-2">
              <div class="d-block text-muted">
                <small i18n="@@labelsBWPopup_service.black-list"
                  >Чёрный список</small
                >
              </div>

              <app-tag-input
                [model]="item.blackList"
                [onlyExisting]="true"
                [findFunction]="findLabels"
                placeholder="Выбрать метку"
                addName="Выбрать метку"
                editName="Выбрать метку"
                buttonName="Исключить метки"
                (onChange)="item.blackList = $event"
                size="sm"
                i18n-placeholder="@@labelsBWPopup_service.select-label5"
                i18n-addName="@@labelsBWPopup_service.select-label6"
                i18n-editName="@@labelsBWPopup_service.select-label7"
                i18n-buttonName="
                  @@labelsBWPopup_service.exclude-labels"></app-tag-input>
            </div>

            <div class="col-auto pe-3 align-self-center text-end">
              <button class="btn btn-sm btn-danger" (click)="deleteItem(item)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <div class="mt-5 text-center text-muted" *ngIf="isEmpty">
          <h4 i18n="@@labelsBWPopup_service.players-without-tags">
            Плееры без меток
          </h4>
        </div>

        <div class="d-flex mt-3">
          <div class="col pe-3 text-end ">
            <button
              class="btn btn-addon btn-sm btn-success me-2"
              (click)="addItem()">
              <i class="fas fa-plus"></i>
              <span
                class="btn-name"
                i18n="@@labelsBWPopup_service.button.add-new-group">
                Добавить новую группу
              </span>
            </button>

            <button
              class="btn btn-addon btn-sm btn-danger"
              *ngIf="canEmptySelect && !isEmpty"
              (click)="deleteAll()">
              <i class="fas fa-trash"></i>
              <span
                class="btn-name"
                i18n="@@labelsBWPopup_service.button.no-tags">
                Без меток
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-addon "
        type="button"
        (click)="okEvent()"
        ng-click="okEvent()">
        <i class="fas fa-check"></i
        ><ng-container i18n="@@labelsBWPopup_service.button.choose">
          Выбрать
        </ng-container>
      </button>

      <button
        class="btn btn-danger btn-addon btn-danger"
        type="button"
        (click)="cancelEvent()"
        ng-click="cancelEvent()">
        <i class="fas fa-times"></i
        ><ng-container i18n="@@labelsBWPopup_service.button.cancellation">
          Отмена
        </ng-container>
      </button>
    </div>
  `,
  providers: [PlatformApiService],
})
export class NgbdModalDialoglabelsBWPopupPopupComponent implements OnDestroy {
  public labelsList: any[] = [];
  public canEmptySelect = false;

  constructor(
    public activeModal: NgbActiveModal,
    public platformApiService: PlatformApiService,
    public labelApiService: LabelApiService
  ) {}

  get isEmpty() {
    return !(this.labelsList && !!this.labelsList?.length);
  }

  public loadParams(params: any = {}) {
    if (Array.isArray(params.model)) {
      this.labelsList = params.model.slice();
    }

    if (typeof params?.canEmptySelect !== 'undefined')
      this.canEmptySelect = !!params.canEmptySelect;

    this.init();
  }

  public init() {
    if (!this.labelsList?.length) this.addItem();
  }

  okEvent() {
    this.activeModal.close(
      this.labelsList.filter(
        i => !!i.whiteList?.length || !!i.blackList?.length
      )
    );
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  addItem() {
    this.labelsList = this.labelsList || [];
    this.labelsList.push({
      whiteList: [],
      blackList: [],
    });
  }

  findLabels = (tagsString): any => {
    return this.labelApiService.find$(tagsString);
  };

  deleteItem(item) {
    this.labelsList = this.labelsList.filter(i => i != item);
    if (!this.labelsList?.length && !this.canEmptySelect) this.addItem();
  }

  deleteAll() {
    this.labelsList = [];
  }

  ngOnDestroy() {}
}

@Injectable()
export class LabelsBWPopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(
      NgbdModalDialoglabelsBWPopupPopupComponent,
      {
        modalDialogClass: 'modal-dialog-labelsbw-popup',
        size: 'lg',
      }
    );
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
