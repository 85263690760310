import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: `[ngModel][date-formatter]`,
  providers: [NgModel],
  /*  host: {
    '(ngModelChange)' : 'onInputChange($event)'
  }*/
})
export class DateFormatterDirective implements OnInit {
  constructor(private element: ElementRef, private ngModel: NgModel) {}

  private inputToModel(value: string) {
    if (!value || !/\d\d\d\d-\d\d-\d\d/.test(value)) {
      return value;
    }
    let tmp = value.split('-');
    return `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
  }

  onInputChange(event) {
    //    this.ngModel?.valueAccessor?.writeValue(this.inputToModel(event));
    //  debugger
    //  this.ngModel?.valueAccessor?.writeValue("2022-02-02");
    //  this.ngModel.update.emit("2022-02-02");
  }

  /*

  ngOnInit () {
    const initialOnChange = (this.ngModel.valueAccessor as any).onChange;

    (this.ngModel.valueAccessor as any).onChange = (value) => initialOnChange(this.processInput(value));

    const savedViewToModelUpdate = this.ngModel.viewToModelUpdate;

    this.ngModel.viewToModelUpdate = (value) => savedViewToModelUpdate(this.processInput2(value))




  }

  processInput2(value: any) {
    debugger
    return "03-02-2022"; // value.toUpperCase();
  }

  processInput(value: any) {
    return value; // value.toUpperCase();
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    debugger
    this.ngModel.valueAccessor?.writeValue(this.processInput(value));
  }
*/

  ngOnInit() {
    let that = this;

    this.ngModel.valueAccessor?.registerOnChange(event => {
      this.ngModel.update.emit(event);
      //    this.ngModel.viewModel = event;
    });

    this.ngModel?.valueChanges?.subscribe(function (value) {
      //$(that.element.nativeElement).data("newValue",value);
    });

    /*

       const ctrl = this.ngModel.control;

       ctrl.valueChanges
         .subscribe(v => {
           debugger
       });
   */

    /*
       /!* Inform ng model for any new change happened *!/
       this.element.nativeElement.addEventListener("change",(event: Event) => {
         let tmpValue = (<any>event).target.value;

         this.ngModel.update.emit('2323');
       });
     }
   */
  }
}
