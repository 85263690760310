import {FieldTypes, IFieldOptions} from "./template.interface";
import {getByPath} from "./../utils/ds-player.utils";
import {TemplateCoreClass} from "./template-core.class";

export class TemplateFieldClass {
  static FIELD_REGEXP = /\{\{.*?\}\}/igm

  public templateField: string;
  public templateIndex: number | undefined;

  public fieldOptions: IFieldOptions;
  private _value: any;


  get id() {
    return this.fieldOptions.id;
  }

  get path() {
    return this.fieldOptions.path;
  }

  get name() {
    return this.fieldOptions.name;
  }

  get type() {
    return this.fieldOptions.type;
  }

  get group() {
    return this.fieldOptions.group;
  }

  get position() {
    return this.fieldOptions.position || 0;
  }

  get options() {
    return this.fieldOptions?.options;
  }

  get value() {
    return this._value;
  }

  get isDefault() {
    return this.value === this.fieldOptions.default;
  }

  set value(value: any) {
    this._value = value;
    this.callbackChange && this.callbackChange(this.id, this.path, this.value);
  }

  constructor(match: RegExpMatchArray, private callbackChange?: Function, data = {} ) {

    this.templateField = match[0];
    this.templateIndex = match.index;
    this.prepareJson();
    this.setDefault( getByPath( data, this.path) );
  }

  private prepareJson() {

    try {

      this.fieldOptions = JSON.parse( this.templateField.slice(1, -1) );

    } catch (e) {
      this.fieldOptions = {
        id: 'none',
        type: FieldTypes.string,
        path: '',
        name: '',
      };
    }

  }

  private setDefault(value) {

    if ( typeof value !== "undefined") {
      this._value = value
      return;
    }

    if (this.fieldOptions.default)
      this.value = this.fieldOptions.default;
  }

}
