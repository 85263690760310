import { Component, OnInit } from '@angular/core';
import {
  UserApiService,
  UserRoleEnum,
} from '../../service/api/user-api/user-api.service';
import { StateService } from '@uirouter/core';
import { PartnerApiService } from '../../service/api/partner-api/partner-api.service';

@Component({
  selector: 'app-utilites',
  templateUrl: './app-utilites.component.html',
  styleUrls: ['./app-utilites.component.scss'],
  providers: [],
})
export class AppUtilitesComponent implements OnInit {
  constructor(
    public userApiService: UserApiService,
    public partnerApiService: PartnerApiService,
    public $state: StateService
  ) {}

  get canChangePartner() {
    return this.userApiService.currentUser?.role === UserRoleEnum.superuser;
  }

  ngOnInit(): void {}

  logout() {
    this.userApiService.logout$().subscribe({
      next: () => {
        this.$state.go('login');
      },
    });
  }
}
