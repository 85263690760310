/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LabelCreateApi } from '../models/label-create-api';

@Injectable({
  providedIn: 'root',
})
export class CreateLabelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridLabelCreate
   */
  static readonly PostPartnerPartneridLabelCreatePath = '/partner/{partnerId}/label/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridLabelCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLabelCreate$Response(params: {
    partnerId: number;
    body: LabelCreateApi
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CreateLabelService.PostPartnerPartneridLabelCreatePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridLabelCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLabelCreate(params: {
    partnerId: number;
    body: LabelCreateApi
  }): Observable<number> {

    return this.postPartnerPartneridLabelCreate$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
