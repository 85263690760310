import { ElementRef } from '@angular/core';

export interface IPlayItem {
  url?: string;
  metaType: string;
  meta?: any;
}

export interface ICoreEvents {
  type: ICoreEventType;
  data?: any;
}

export enum ICoreEventType {
  QUEUE_ADD,
  QUEUE_TAKEN_TO_PLAYER,
  QUEUE_CLEANED,
  QUEUE_ENDED,
  PLAY_START,
  PLAY_ERROR,
  PLAY_PAUSE,
  PLAY_ENDED,
  PLAY_STOP,
  VIDEO_SEEK,
  VIDEO_PLAYING,
  VIDEO_LOADED,
  VIDEO_WAITING,
  VIDEO_CANPLAY,
  VIDEO_TIME_UPDATE,
}

export enum IPlayerTypes {
  VIDEO,
  IMAGE,
  TEMPLATE,
  NONE,
}

export interface IPlayerStruct {
  nativeElement: HTMLVideoElement | HTMLImageElement | HTMLDivElement;
  type: IPlayerTypes;
  container: ElementRef<HTMLDivElement>;
  item?: IPlayItem;
}

export enum IPlayerStates {
  PLAYING,
  PAUSED,
  STOPED,
}
