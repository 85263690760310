/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationApi } from '../models/location-api';
import { LocationCreateApi } from '../models/location-create-api';
import { LocationQueryApi } from '../models/location-query-api';
import { LocationsRequest } from '../models/locations-request';

@Injectable({
  providedIn: 'root',
})
export class LocationApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridLocationLocationgroupLocationgroupid
   */
  static readonly PostPartnerPartneridLocationLocationgroupLocationgroupidPath = '/partner/{partnerId}/location/locationgroup/{locationGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridLocationLocationgroupLocationgroupid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationLocationgroupLocationgroupid$Response(params: {
    partnerId: number;
    locationGroupId: number;
    body: LocationsRequest
  }): Observable<StrictHttpResponse<Array<LocationQueryApi>>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.PostPartnerPartneridLocationLocationgroupLocationgroupidPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('locationGroupId', params.locationGroupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationQueryApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridLocationLocationgroupLocationgroupid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationLocationgroupLocationgroupid(params: {
    partnerId: number;
    locationGroupId: number;
    body: LocationsRequest
  }): Observable<Array<LocationQueryApi>> {

    return this.postPartnerPartneridLocationLocationgroupLocationgroupid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationQueryApi>>) => r.body as Array<LocationQueryApi>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridLocationQuery
   */
  static readonly PostPartnerPartneridLocationQueryPath = '/partner/{partnerId}/location/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridLocationQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationQuery$Response(params: {
    partnerId: number;
    body: LocationsRequest
  }): Observable<StrictHttpResponse<Array<LocationQueryApi>>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.PostPartnerPartneridLocationQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationQueryApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridLocationQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocationQuery(params: {
    partnerId: number;
    body: LocationsRequest
  }): Observable<Array<LocationQueryApi>> {

    return this.postPartnerPartneridLocationQuery$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationQueryApi>>) => r.body as Array<LocationQueryApi>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridLocation
   */
  static readonly PostPartnerPartneridLocationPath = '/partner/{partnerId}/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocation$Response(params: {
    partnerId: number;
    body: LocationCreateApi
  }): Observable<StrictHttpResponse<LocationApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.PostPartnerPartneridLocationPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridLocation(params: {
    partnerId: number;
    body: LocationCreateApi
  }): Observable<LocationApi> {

    return this.postPartnerPartneridLocation$Response(params).pipe(
      map((r: StrictHttpResponse<LocationApi>) => r.body as LocationApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridLocationId
   */
  static readonly GetPartnerPartneridLocationIdPath = '/partner/{partnerId}/location/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridLocationId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<LocationApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.GetPartnerPartneridLocationIdPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridLocationId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridLocationId(params: {
    partnerId: number;
    id: number;
  }): Observable<LocationApi> {

    return this.getPartnerPartneridLocationId$Response(params).pipe(
      map((r: StrictHttpResponse<LocationApi>) => r.body as LocationApi)
    );
  }

  /**
   * Path part for operation putPartnerPartneridLocationId
   */
  static readonly PutPartnerPartneridLocationIdPath = '/partner/{partnerId}/location/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridLocationId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridLocationId$Response(params: {
    partnerId: number;
    id: number;
    body: LocationCreateApi
  }): Observable<StrictHttpResponse<LocationApi>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.PutPartnerPartneridLocationIdPath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridLocationId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridLocationId(params: {
    partnerId: number;
    id: number;
    body: LocationCreateApi
  }): Observable<LocationApi> {

    return this.putPartnerPartneridLocationId$Response(params).pipe(
      map((r: StrictHttpResponse<LocationApi>) => r.body as LocationApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridLocationId
   */
  static readonly DeletePartnerPartneridLocationIdPath = '/partner/{partnerId}/location/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridLocationId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridLocationId$Response(params: {
    partnerId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationApiService.DeletePartnerPartneridLocationIdPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridLocationId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridLocationId(params: {
    partnerId: number;
    id: number;
  }): Observable<void> {

    return this.deletePartnerPartneridLocationId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
