var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var isObject = function (item) {
    return (item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof HTMLElement));
};
export var mergeDeep = function (target) {
    var _a, _b;
    var sources = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sources[_i - 1] = arguments[_i];
    }
    if (!sources.length)
        return target;
    var source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (var key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, (_a = {},
                        _a[key] = {},
                        _a));
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, (_b = {},
                    _b[key] = source[key],
                    _b));
            }
        }
    }
    return mergeDeep.apply(void 0, __spreadArray([target], sources, false));
};
export var getByPath = function (object, path) {
    if (object == null) { // null or undefined
        return object;
    }
    var parts = path.split('.');
    return parts.reduce(function (object, key) { return object === null || object === void 0 ? void 0 : object[key]; }, object);
};
export var setByPath = function (path, value) {
    var object = {};
    var parts = path.split('.');
    parts.reduce(function (object, key, index) { return object[key] = index === parts.length - 1 ? value : {}; }, object);
    return object;
};
export function IsPlaylistItemTemplate(item) {
    return item && typeof item.templateId !== "undefined";
}
export function hasPlayListTemplate(playlist) {
    return playlist &&
        Array.isArray(playlist.items) &&
        playlist.items.some(function (i) { return IsPlaylistItemTemplate(i); });
}
export function matchAll(pattern, haystack) {
    var regex = new RegExp(pattern, "g");
    var matches = [];
    var match_result = haystack.match(regex);
    for (var index in match_result) {
        var item = match_result[index];
        matches[index] = item.match(new RegExp(pattern));
    }
    return matches;
}
