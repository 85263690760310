/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceTypeApi } from '../models/device-type-api';

@Injectable({
  providedIn: 'root',
})
export class Service extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridApi20Devicetype
   */
  static readonly PostPartnerPartneridApi20DevicetypePath = '/partner/{partnerId}/api/2.0/deviceType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridApi20Devicetype()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPartnerPartneridApi20Devicetype$Response(params: {
    partnerId: number;
    type: string;
  }): Observable<StrictHttpResponse<Array<DeviceTypeApi>>> {

    const rb = new RequestBuilder(this.rootUrl, Service.PostPartnerPartneridApi20DevicetypePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceTypeApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridApi20Devicetype$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPartnerPartneridApi20Devicetype(params: {
    partnerId: number;
    type: string;
  }): Observable<Array<DeviceTypeApi>> {

    return this.postPartnerPartneridApi20Devicetype$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceTypeApi>>) => r.body as Array<DeviceTypeApi>)
    );
  }

}
