import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadableItemClass } from '../../class/loadable-item.class';
import {
  DeviceTypeApiService,
  MODE_TYPES_NAMES,
} from '../api/device-type-api/device-type-api.service';

@Component({
  selector: 'ngbd-modal-platformPopup',
  styleUrls: ['./deviceTypePopup.service.scss'],
  template: `
    <div class="modal-header  bg-light ng-scope">
      <h5
        class="modal-title ng-binding"
        i18n="@@deviceTypePopup_service.device-type-selection">
        Выбор типа устройства
      </h5>
    </div>
    <div class="modal-body overflow-wrap-break p-0 d-flex overflow-auto">
      <div class="col p-0">
        <div class=" d-block p-3 pt-2 pb-2 ">
          <app-search-input
            (modelChange)="onSearchChange($event)"
            placeholder="Введите наименование типа устройства"
            size="sm"
            i18n-placeholder="
              @@deviceTypePopup_service.enter-the-name-of-the-device-type"></app-search-input>
        </div>

        <div
          infiniteScroll=""
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [fromRoot]="true"
          (scrolled)="nextPage()">
          <responsive-table [disabled]="true" >
          <table class="table table-hover" *ngIf="!deviceTypeList.isLoading">
            <thead>
              <tr>
                <th style="width: 1px" class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      [checked]="isAllChecked()"
                      (change)="checkAll($event)" />
                  </div>
                </th>
                <th
                  scope="col"
                  i18n="@@deviceTypePopup_service.table.table-header.name">
                  Наименование
                </th>
                <th
                  scope="col"
                  i18n="@@deviceTypePopup_service.table.table-header.modes">
                  Режимы
                </th>
                <th
                  scope="col"
                  i18n="@@deviceTypePopup_service.table.table-header.user">
                  Пользователь
                </th>
                <th
                  scope="col"
                  i18n="
                    @@deviceTypePopup_service.table.table-header.date-of-creation">
                  Дата создания
                </th>
                <th scope="col">ID</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let item of deviceTypeList.items"
                (click)="check(item.id); $event.stopPropagation()">
                <td class="ps-3 pe-0 text-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="selected.has(item.id)"
                      id="flexCheckDefault"
                      (change)="check(item.id)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </td>
                <td>
                  <text-highlight
                    [search]="deviceTypeApiService.queryParams.getSearchValue()"
                    [text]="item.name"></text-highlight>
                </td>
                <td>
                  <div *ngFor="let mode of item?.modes || []">
                    {{ MODE_TYPES_NAMES[mode?.mode] || '' }}:
                    <span class="fw-bold">{{ mode?.platformName }}</span>
                  </div>
                </td>
                <td class="text-end" [innerText]="item.userName"></td>
                <td
                  class="text-end"
                  [innerText]="item.createdAt | dsDateTime"></td>
                <td class="text-end" [innerText]="item.id || ''"></td>
              </tr>
            </tbody>
          </table>
          </responsive-table>
        </div>

        <div class="text-center" *ngIf="deviceTypeList.isLoading">
          <i class="fas fa-spin fa-spinner mt-5  text-primary"></i>
        </div>
      </div>
    </div>
    <div class="modal-footer b-t bg-light dk ">
      <button
        class="btn btn-success btn-addon "
        type="button"
        (click)="okEvent()"
        *ngIf="selected.size"
        ng-click="okEvent()">
        <i class="fas fa-check"></i
        ><ng-container
          i18n="@@deviceTypePopup_service.button.select-selected.size">
          Выбрать: {{ selected.size }}
        </ng-container>
      </button>

      <button
        class="btn btn-danger btn-addon btn-danger"
        type="button"
        (click)="cancelEvent()"
        ng-click="cancelEvent()">
        <i class="fas fa-times"></i
        ><ng-container i18n="@@deviceTypePopup_service.button.cancellation">
          Отмена
        </ng-container>
      </button>
    </div>
  `,
  providers: [DeviceTypeApiService],
})
export class NgbdModalDialogDeviceTypePopupComponent implements OnDestroy {
  public deviceTypeList: LoadableItemClass<any> = new LoadableItemClass();

  public selected = new Set();
  public cachedNames = new Map<any, any>();

  public MODE_TYPES_NAMES = MODE_TYPES_NAMES;

  private total;

  constructor(
    public activeModal: NgbActiveModal,
    public deviceTypeApiService: DeviceTypeApiService
  ) {}

  public loadParams(params: any = {}) {
    if (Array.isArray(params.model)) {
      this.selected.clear();
      params.model.forEach(i => {
        switch (true) {
          case typeof i === 'number':
            this.selected.add(i);
            break;
          case typeof i === 'object' && typeof i.id === 'number':
            this.selected.add(i.id);
            break;
          case typeof i === 'object' && typeof i.id === 'string':
            this.selected.add(parseInt(i.id));
            break;
        }
      });
    }

    this.init();
  }

  public init() {
    this.deviceTypeApiService.queryParams.setPageLimit(9999);
    this.resetItems();
  }

  getDeviceTypes() {
    if (this.deviceTypeList.isLoading) return;

    let requester: any = this.deviceTypeApiService.query$(
      this.deviceTypeApiService.queryParams.params
    );

    requester.pipe(this.deviceTypeList.loadingOperator).subscribe(result => {
      this.deviceTypeList.addItems(result.items || result);
      this.total = result.total;

      this.deviceTypeList.items.forEach(i => {
        this.cachedNames.set(i.id, i.name);
      });
    });
  }

  isAllChecked() {
    if (
      this.selected.size === 0 ||
      this.selected.size < this.deviceTypeList.items.length
    )
      return false;

    return !this.deviceTypeList.items.some(i => !this.selected.has(i.id));
  }

  checkAll(value) {
    if (value.target.checked) {
      this.deviceTypeList.items.forEach(i => this.selected.add(i.id));
    } else {
      this.deviceTypeList.items.forEach(i => this.selected.delete(i.id));
    }
  }

  check(id) {
    if (!this.selected.has(id)) this.selected.add(id);
    else this.selected.delete(id);
  }

  okEvent() {
    this.activeModal.close(
      Array.from(this.selected).map(i => ({
        id: i,
        name: this.cachedNames.get(i),
      }))
    );
  }

  cancelEvent() {
    this.activeModal.close(undefined);
  }

  onSearchChange($event) {
    this.deviceTypeApiService.queryParams.search($event);

    this.resetItems();
  }

  resetItems() {
    this.deviceTypeList.items = [];
    this.deviceTypeApiService.queryParams.reset();

    this.getDeviceTypes();
  }

  nextPage() {
    if (this.deviceTypeList.isLoading) return;

    this.deviceTypeApiService.queryParams.next();

    this.getDeviceTypes();
  }

  ngOnDestroy() {
    this.selected.clear();
  }
}

@Injectable()
export class DeviceTypePopupService {
  constructor(private modalService: NgbModal) {}

  public open(params = {}) {
    const modalRef = this.modalService.open(
      NgbdModalDialogDeviceTypePopupComponent,
      {
        modalDialogClass: 'modal-dialog-device-type-popup',
        size: 'xl',
      }
    );
    modalRef.componentInstance.loadParams(params);

    return modalRef.result;
  }
}
