import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import {
  UserApiService,
  UserRoleEnum,
} from '../../../core/service/api/user-api/user-api.service';
import { LoadableItemClass } from '../../../core/class/loadable-item.class';
import { LoginApi } from '../../../core/service/api/generated/Authorization';
import { PartnerApiService } from '../../../core/service/api/partner-api/partner-api.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [],
})
export class LoginPageComponent implements OnInit {
  public loadingItem = new LoadableItemClass<any>();

  public loginItem: LoginApi = <any>{};
  public errorMessage: string = '';

  constructor(
    public $state: StateService,
    public userApiService: UserApiService,
    public partnerApiService: PartnerApiService
  ) {}

  ngOnInit(): void {}

  login() {
    this.errorMessage = '';

    if (!this.loginItem.email || !this.loginItem.password) return;

    this.loadingItem.isLoading = true;
    this.userApiService.login$(this.loginItem).subscribe(
      () => {
        if (this.userApiService.currentUser) {
          if (this.userApiService.currentUser.role === UserRoleEnum.superuser) {
            this.$state.go('partner');
            return;
          }
        }

        if (typeof this.userApiService.currentUser?.partnerId !== 'undefined') {
          this.loadingItem.isLoading = true;
          this.partnerApiService
            .selectPartner$(this.userApiService.currentUser.partnerId)
            .subscribe({
              complete: () => this.$state.go('root'),
              error: () => (this.loadingItem.isLoading = false),
            });
        }
      },
      error => {
        this.loadingItem.isLoading = false;
        error.stopPopupError();
        this.errorMessage = $localize`:|@@login-page.login-failed:Ошибка входа`;
      }
    );
  }
}
