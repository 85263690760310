import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../components/toasts-container/toast.service';

interface HttpErrorResponseWithPopup extends HttpErrorResponse {
  stopPopupError: () => boolean;
  setError: (text?, header?) => boolean;
}

@Injectable()
export class HttpErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponseWithPopup) => {
        let errorText, errorHeader;
        let timeoutId = setTimeout(() => {
          let resultText =
            errorText || error.error || error.message || error.statusText;

          if (
            typeof error?.error === 'string' &&
            error?.error?.toLowerCase() !== 'internal server error'
          )
            resultText = error.error;

          this.toastService.showDanger(resultText, errorHeader);
        }, 20);

        error.stopPopupError = () => {
          timeoutId && clearTimeout(timeoutId);
          return true;
        };

        error.setError = (text?, header?) => {
          errorText = text;
          errorHeader =
            header ||
            $localize`:|@@http-error-catching_interceptors.error:Ошибка`;
          return true;
        };

        return throwError(error);
      })
    );
  }
}
