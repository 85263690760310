import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('sidepanelToggler') sidePanelToggler: ElementRef;
  @ViewChild('appSidepanel') sidePanel: ElementRef;
  @ViewChild('sidepanelDrop') sidePanelDrop: ElementRef;
  @ViewChild('sidepanelClose') sidePanelClose: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    document.body.style.paddingTop = '56px';
  }

  ngAfterViewInit(): void {
    this.addListeners();
  }

  addListeners() {
    this.renderer.listen(window, 'load', this.responsiveSidePanel.bind(this));
    this.renderer.listen(window, 'resize', this.responsiveSidePanel.bind(this));

    this.renderer.listen(
      this.sidePanelToggler.nativeElement,
      'click',
      this.sidePanelTogglerClick.bind(this)
    );

    this.renderer.listen(this.sidePanelClose.nativeElement, 'click', event => {
      event.preventDefault();
      this.sidePanelToggler.nativeElement.click();
    });

    this.renderer.listen(this.sidePanelDrop.nativeElement, 'click', event => {
      this.sidePanelToggler.nativeElement.click();
    });
  }

  responsiveSidePanel() {
    let w = window.innerWidth;
    if (w >= 1200) {
      // if larger
      //console.log('larger');
      this.renderer.removeClass(
        this.sidePanel.nativeElement,
        'sidepanel-hidden'
      );
      this.renderer.addClass(this.sidePanel.nativeElement, 'sidepanel-visible');
    } else {
      // if smaller
      //console.log('smaller');
      this.renderer.removeClass(
        this.sidePanel.nativeElement,
        'sidepanel-visible'
      );
      this.renderer.addClass(this.sidePanel.nativeElement, 'sidepanel-hidden');
    }
  }

  sidePanelTogglerClick() {
    if (this.sidePanel.nativeElement.classList.contains('sidepanel-visible')) {
      this.renderer.removeClass(
        this.sidePanel.nativeElement,
        'sidepanel-visible'
      );
      this.renderer.addClass(this.sidePanel.nativeElement, 'sidepanel-hidden');
    } else {
      this.renderer.removeClass(
        this.sidePanel.nativeElement,
        'sidepanel-hidden'
      );
      this.renderer.addClass(this.sidePanel.nativeElement, 'sidepanel-visible');
    }
  }
}
