import { Component, DoCheck, Input } from '@angular/core';

const sphinxSpecChars = ['?', '%', '*', '+'];

@Component({
  selector: 'text-highlight',
  templateUrl: './text-highlight.component.html',
  styleUrls: ['./text-highlight.component.scss'],
})
export class TextHighlightComponent implements DoCheck {
  _localEnabled = false;
  // Флаги
  get isEnabled() {
    return !!this.search && this._localEnabled;
  }

  // Разбиение на подсвеченное
  public prefix: string;
  public highlighten: string;
  public postfix: string;

  // Передаваемые параметры в компонент
  @Input() public search: string;
  @Input() public text: string;

  public get searchPhrase() {
    return this.search;
  }

  constructor() {}

  public $onInit() {}

  ngDoCheck() {
    this.splitHighlight(this.search);
  }

  private splitHighlight = (phase: any) => {
    this._localEnabled = true;
    if (!this.isEnabled || !phase || !this.text) {
      return;
    }

    let text = this.text.toString();

    let from = text.toLowerCase().indexOf(this.searchPhrase.toLowerCase());
    let count = this.searchPhrase.length;

    if (from < 0) {
      this._localEnabled = false;
      return;
    }

    this.prefix = text.substring(0, from);
    this.highlighten = text.substring(from, from + count);
    this.postfix = text.substring(from + count);
  };
}
