<img
  *ngIf="!!filePreviewUrl"
  #imgElement=""
  class="preview"
  loading="lazy"
  [style.visibility]="isError ? 'hidden' : 'visible'"
  [class.cursor-pointer]="!disablePreview"
  [src]="filePreviewUrl"
  (click)="previewFile()"
  (load)="setState(LOAD_STATES.loaded)"
  (error)="setState(LOAD_STATES.error)"
  (abort)="setState(LOAD_STATES.error)" />
<div
  class="prev-img-loading prev-top-block"
  *ngIf="currentState === LOAD_STATES.loading">
  <i class="text-warning fas fa-spinner fa-spin fa-lg"></i>
</div>

<div
  class="prev-img-not-loading prev-top-block"
  *ngIf="currentState === LOAD_STATES.not_load">
  <i class="text-successfas fa-image fa-2x"></i>
</div>

<div
  class="prev-img-error prev-top-block cursor-pointer"
  *ngIf="isError"
  (click)="reload()">
  <i class="text-dangerfas fa-times fa-2x"></i>
</div>
