export class ApiRequestHandlerClass {
  private prevSubscribes = new Map();

  constructor() {}

  public cancelRequest(name?: string) {
    if (!!name) {
      this.unsubscribeIfIsset(name);
      return this;
    }

    let that = this;
    return new Proxy(this, {
      get(target: any, p: string | symbol, receiver: any): any {
        if (!p.toString().endsWith('$')) return target[p];

        that.unsubscribeIfIsset(p);

        return (...args) =>
          new Proxy(target[p].apply(target, args), {
            get(target: any, subP: string | symbol, receiver: any): any {
              if (subP === 'subscribe') {
                return (...subArgs) => {
                  let res = target[subP].apply(target, subArgs);
                  that.prevSubscribes.set(p, res);
                  return res;
                };
              }

              return target[subP];
            },
          });
      },
    });
  }

  private unsubscribeIfIsset(name) {
    if (
      this.prevSubscribes.has(name) &&
      typeof this.prevSubscribes.get(name)?.unsubscribe === 'function'
    ) {
      this.prevSubscribes.get(name)?.unsubscribe();
    }
  }
}
