import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadableItemClass } from '../../../../core/class/loadable-item.class';

import { LocalStorageService } from '../../../../core/service/localStorage/local-storage.service';
import {
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_NAMES,
  TemplateApiService,
} from '../../../../core/service/api/template-api/template-api.service';
import { StateService } from '@uirouter/core';

const ID_STORAGE = 'templateListFilters';

@Component({
  selector: 'app-template-list',
  templateUrl: './templates-list-page.component.html',
  styleUrls: ['./templates-list-page.component.scss'],
  providers: [LocalStorageService],
})
export class TemplatesListPageComponent implements OnInit {
  @Input() isForSelect = false;
  @Input() options: {
    selectedTemplateId?: number;
    onlyActiveTemplates?: boolean;
  } = {};

  @Output() onChange = new EventEmitter();

  public templateItems: LoadableItemClass<any> = new LoadableItemClass();
  public locationNames = new Map();

  public selectedTemplate: any;
  public TEMPLATE_STATUS_NAMES = TEMPLATE_STATUS_NAMES;
  public templateStatus = Object.entries(TEMPLATE_STATUS_NAMES).map(
    ([key, value]) => ({ key, value })
  );

  constructor(
    public templateApiService: TemplateApiService,
    public localStorageService: LocalStorageService,

    public $state: StateService
  ) {}

  async ngOnInit() {
    await this.loadPastParams();
    this.loadItems();
  }

  async loadPastParams() {
    await this.templateApiService.queryParams.loadFromStorage(ID_STORAGE);
    this.templateApiService.queryParams.reset();

    let status = this.templateApiService.queryParams.getFilterValues('states');
    if (typeof status === 'undefined')
      this.templateApiService.queryParams.setFilterValue(
        'states',
        Object.keys(TEMPLATE_STATUS_NAMES).filter(
          i => i != TEMPLATE_STATUS.archive
        )
      );
  }

  loadItems() {
    if (this.templateItems.isLoading) return;

    this.templateApiService
      .query$(this.templateApiService.queryParams.params)
      .pipe(this.templateItems.loadingOperator)
      .subscribe((result: any) => {
        switch (true) {
          case typeof this.options.selectedTemplateId !== 'undefined':
            result = result.filter(
              i => i.id === this.options.selectedTemplateId
            );
            break;

          case this.isForSelect || this.options.onlyActiveTemplates:
            result = result.filter(i => i.state === TEMPLATE_STATUS.active);
            break;
        }

        this.templateItems.addItems(result);
      });
  }

  onSearchChange($event) {
    this.templateApiService.queryParams.search($event);
    this.resetPages();
  }

  nextPage() {
    this.templateApiService.queryParams.next();
    this.loadItems();
  }

  resetPages() {
    this.templateApiService.queryParams.reset();
    this.templateApiService.queryParams.saveToStorage(ID_STORAGE);
    this.templateItems.items = [];
    this.loadItems();
  }

  onClickItem(item) {
    if (!this.isForSelect) {
      this.$state.go('^.view', { id: item.id });
    } else {
      this.templateApiService.get$(item.id).subscribe(i => {
        this.selectedTemplate = i;
        this.onChange.emit({ type: 'template', data: Object.assign({}, i) });
      });
    }
  }

  addToFilter(filterIdm, value) {
    let currentArray =
      this.templateApiService.queryParams.getFilterValue(filterIdm) || [];
    if (currentArray.indexOf(value) === -1) currentArray.push(value);

    this.templateApiService.queryParams.setFilterValue(
      filterIdm,
      currentArray.length ? currentArray : undefined
    );
    this.resetPages();
  }

  inFilter(filterIdm, id) {
    let currentArray =
      this.templateApiService.queryParams.getFilterValue(filterIdm);
    if (!currentArray) return false;
    return currentArray.indexOf(id) >= 0;
  }

  removeFromFilter(filterIdm, id) {
    let currentArray =
      this.templateApiService.queryParams.getFilterValue(filterIdm);
    if (!currentArray) return;

    currentArray = currentArray.filter(i => i !== id);
    this.templateApiService.queryParams.setFilterValue(
      filterIdm,
      currentArray.length ? currentArray : undefined
    );
    this.resetPages();
  }

  updateFilterValues(idFilter, values = []) {
    this.templateApiService.queryParams.setFilterValue(idFilter, values);
    this.resetPages();
  }

  onAdd() {
    this.$state.go('^.view');
  }
}
