/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContentFileApi } from '../models/content-file-api';
import { FileUpdateApi } from '../models/file-update-api';
import { ListWithTotalFileActiveLocationsApi } from '../models/list-with-total-file-active-locations-api';
import { ListWithTotalPlayerApi } from '../models/list-with-total-player-api';
import { MultipartForm } from '../models/multipart-form';
import { PlayerApi } from '../models/player-api';
import { PlayerRequestApi } from '../models/player-request-api';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPartnerPartneridFileFileidSettingsQuery
   */
  static readonly PostPartnerPartneridFileFileidSettingsQueryPath = '/partner/{partnerId}/file/{fileId}/settings/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridFileFileidSettingsQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFileidSettingsQuery$Response(params: {
    partnerId: number;
    fileId: number;
    body: PlayerRequestApi
  }): Observable<StrictHttpResponse<ListWithTotalPlayerApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostPartnerPartneridFileFileidSettingsQueryPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalPlayerApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridFileFileidSettingsQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFileidSettingsQuery(params: {
    partnerId: number;
    fileId: number;
    body: PlayerRequestApi
  }): Observable<ListWithTotalPlayerApi> {

    return this.postPartnerPartneridFileFileidSettingsQuery$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalPlayerApi>) => r.body as ListWithTotalPlayerApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridFileFileidSettings
   */
  static readonly PostPartnerPartneridFileFileidSettingsPath = '/partner/{partnerId}/file/{fileId}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridFileFileidSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFileidSettings$Response(params: {
    partnerId: number;
    fileId: number;
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<PlayerApi>>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostPartnerPartneridFileFileidSettingsPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlayerApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridFileFileidSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFileidSettings(params: {
    partnerId: number;
    fileId: number;
    body?: Array<number>
  }): Observable<Array<PlayerApi>> {

    return this.postPartnerPartneridFileFileidSettings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlayerApi>>) => r.body as Array<PlayerApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFilePreviewfileFileid
   */
  static readonly GetPartnerPartneridFilePreviewfileFileidPath = '/partner/{partnerId}/file/previewfile/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFilePreviewfileFileid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFilePreviewfileFileid$Response(params: {
    partnerId: number;
    fileId: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFilePreviewfileFileidPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFilePreviewfileFileid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFilePreviewfileFileid(params: {
    partnerId: number;
    fileId: number;
  }): Observable<Blob> {

    return this.getPartnerPartneridFilePreviewfileFileid$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFileFileid
   */
  static readonly GetPartnerPartneridFileFileidPath = '/partner/{partnerId}/file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFileFileid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFileid$Response(params: {
    partnerId: number;
    fileId: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFileFileidPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFileFileid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFileid(params: {
    partnerId: number;
    fileId: number;
  }): Observable<Blob> {

    return this.getPartnerPartneridFileFileid$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFileFilename
   */
  static readonly GetPartnerPartneridFileFilenamePath = '/partner/{partnerId}/file/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFileFilename()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFilename$Response(params: {
    partnerId: number;
    fileName: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFileFilenamePath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFileFilename$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFilename(params: {
    partnerId: number;
    fileName: string;
  }): Observable<Blob> {

    return this.getPartnerPartneridFileFilename$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPartnerPartneridPlatformPlatformidFileFileid
   */
  static readonly GetPartnerPartneridPlatformPlatformidFileFileidPath = '/partner/{partnerId}/platform/{platformId}/file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridPlatformPlatformidFileFileid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlatformPlatformidFileFileid$Response(params: {
    partnerId: number;
    platformId: number;
    fileId: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridPlatformPlatformidFileFileidPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('platformId', params.platformId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridPlatformPlatformidFileFileid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridPlatformPlatformidFileFileid(params: {
    partnerId: number;
    platformId: number;
    fileId: string;
  }): Observable<Blob> {

    return this.getPartnerPartneridPlatformPlatformidFileFileid$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFileFileidInfo
   */
  static readonly GetPartnerPartneridFileFileidInfoPath = '/partner/{partnerId}/file/{fileId}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFileFileidInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFileidInfo$Response(params: {
    partnerId: number;
    fileId: number;
  }): Observable<StrictHttpResponse<ContentFileApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFileFileidInfoPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContentFileApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFileFileidInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileFileidInfo(params: {
    partnerId: number;
    fileId: number;
  }): Observable<ContentFileApi> {

    return this.getPartnerPartneridFileFileidInfo$Response(params).pipe(
      map((r: StrictHttpResponse<ContentFileApi>) => r.body as ContentFileApi)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFileidFileid
   */
  static readonly GetPartnerPartneridFileidFileidPath = '/partner/{partnerId}/fileId/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFileidFileid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileidFileid$Response(params: {
    partnerId: number;
    fileId: number;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFileidFileidPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFileidFileid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileidFileid(params: {
    partnerId: number;
    fileId: number;
  }): Observable<Array<string>> {

    return this.getPartnerPartneridFileidFileid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation postPartnerPartneridFileFilenameFilenameUpdate
   */
  static readonly PostPartnerPartneridFileFilenameFilenameUpdatePath = '/partner/{partnerId}/file/fileName/{fileName}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridFileFilenameFilenameUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFilenameFilenameUpdate$Response(params: {
    partnerId: number;
    fileName: string;
    body: FileUpdateApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostPartnerPartneridFileFilenameFilenameUpdatePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileName', params.fileName, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridFileFilenameFilenameUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridFileFilenameFilenameUpdate(params: {
    partnerId: number;
    fileName: string;
    body: FileUpdateApi
  }): Observable<void> {

    return this.postPartnerPartneridFileFilenameFilenameUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridFileFilenameDelete
   */
  static readonly DeletePartnerPartneridFileFilenameDeletePath = '/partner/{partnerId}/file/{fileName}/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridFileFilenameDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridFileFilenameDelete$Response(params: {
    partnerId: number;
    fileName: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeletePartnerPartneridFileFilenameDeletePath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridFileFilenameDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridFileFilenameDelete(params: {
    partnerId: number;
    fileName: string;
  }): Observable<void> {

    return this.deletePartnerPartneridFileFilenameDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation putPartnerPartneridCsvfile
   */
  static readonly PutPartnerPartneridCsvfilePath = '/partner/{partnerId}/csvfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridCsvfile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  putPartnerPartneridCsvfile$Response(params: {
    partnerId: number;
    body: MultipartForm
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PutPartnerPartneridCsvfilePath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridCsvfile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  putPartnerPartneridCsvfile(params: {
    partnerId: number;
    body: MultipartForm
  }): Observable<any> {

    return this.putPartnerPartneridCsvfile$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation putPartnerPartneridFileCatalogidCatalogidNameName
   */
  static readonly PutPartnerPartneridFileCatalogidCatalogidNameNamePath = '/partner/{partnerId}/file/catalogId/{catalogId}/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridFileCatalogidCatalogidNameName()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  putPartnerPartneridFileCatalogidCatalogidNameName$Response(params: {
    partnerId: number;
    catalogId: number;
    name: string;
    body: MultipartForm
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PutPartnerPartneridFileCatalogidCatalogidNameNamePath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('catalogId', params.catalogId, {});
      rb.path('name', params.name, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridFileCatalogidCatalogidNameName$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  putPartnerPartneridFileCatalogidCatalogidNameName(params: {
    partnerId: number;
    catalogId: number;
    name: string;
    body: MultipartForm
  }): Observable<any> {

    return this.putPartnerPartneridFileCatalogidCatalogidNameName$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getPartnerPartneridFileContentfileidActiveLocations
   */
  static readonly GetPartnerPartneridFileContentfileidActiveLocationsPath = '/partner/{partnerId}/file/{contentFileId}/active/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridFileContentfileidActiveLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileContentfileidActiveLocations$Response(params: {
    partnerId: number;
    contentFileId: number;
  }): Observable<StrictHttpResponse<ListWithTotalFileActiveLocationsApi>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPartnerPartneridFileContentfileidActiveLocationsPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('contentFileId', params.contentFileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWithTotalFileActiveLocationsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridFileContentfileidActiveLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridFileContentfileidActiveLocations(params: {
    partnerId: number;
    contentFileId: number;
  }): Observable<ListWithTotalFileActiveLocationsApi> {

    return this.getPartnerPartneridFileContentfileidActiveLocations$Response(params).pipe(
      map((r: StrictHttpResponse<ListWithTotalFileActiveLocationsApi>) => r.body as ListWithTotalFileActiveLocationsApi)
    );
  }

}
