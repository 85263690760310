import { APP_INITIALIZER, NgModule } from '@angular/core';
import { UserApiService } from '../../core/service/api/user-api/user-api.service';
import { TransitionService, UIRouter, UrlService } from '@uirouter/core';
import { APP_ROUTES } from '../../../app-routes.variables';
import { PartnerApiService } from '../../core/service/api/partner-api/partner-api.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        user: UserApiService,
        router: UIRouter,
        urlService: UrlService
      ) => {
        return () =>
          user.testLogged(router).then(
            result => {
              urlService.listen();
              urlService.sync();
              return result;
            },
            result => {
              urlService.listen();
              urlService.sync();
              return Promise.resolve(true);
            }
          );
      },
      deps: [UserApiService, UIRouter, UrlService],
      multi: true,
    },
  ],
})
export class AuthModule {
  constructor(
    private $transition: TransitionService,
    private userApiService: UserApiService,
    private partnerApiService: PartnerApiService
  ) {
    this.initAuthTransition();
  }

  initAuthTransition() {
    this.$transition.onBefore({}, transition => {
      if (
        [APP_ROUTES.registration.name].includes(transition.to().name || "")
      ) {
        return
      }

      if (
        !this.userApiService.isLogged &&
        !!transition.to().name &&
        ![APP_ROUTES.login.name, APP_ROUTES.link.name, APP_ROUTES.registration.name].includes(transition.to().name || "")
      ) {
        return transition.router.stateService.target(APP_ROUTES.login.name);
      }

      if (
        !this.partnerApiService.loggedPartner &&
        !!transition.to().name &&
        ![APP_ROUTES.login.name, APP_ROUTES.link.name, APP_ROUTES.partner.name].includes(transition.to().name || "")
      ) {
        return transition.router.stateService.target(APP_ROUTES.partner.name);
      }

      return;
    });
  }
}
