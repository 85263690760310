export const FONT_SIZES = {
  '0.5em': $localize`:|@@template.half:Половина`,
  '0.8em': $localize`:|@@template.decreased:Уменьшенный`,
  '1em': $localize`:|@@template.normal:Нормальный`,
  '1.2em': $localize`:|@@template.increased:Увеличенный`,
  '1.5em': $localize`:|@@template.big:Большой`,
  '2em': $localize`:|@@template.double:Двойной`,
};

export enum TEMPLATE_STATUSES {
  'draft' = 'draft',
  'active' = 'active',
  'archive' = 'archive',
}
