import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
})
export class AppNavComponent implements OnInit {
  constructor(public $state: StateService) {}

  navTree: any[any] = [
    {
      name: $localize`:|@@app-nav_components.schedules:Расписания`,
      icon: 'fas fa-rocket',
      uiPath: 'root.schedule.list',
      activePath: 'root.schedule',
    },
    {
      name: $localize`:|@@app-nav_components.playlists:Плейлисты`,
      icon: 'fas fa-photo-video',
      uiPath: 'root.playlist.list',
      activePath: 'root.playlist',
    },
    {
      name: $localize`:|@@app-nav_components.content-management:Управление контентом`,
      icon: 'fas fa-hdd',
      uiPath: 'root.storage.files',
      activePath: ['root.storage', 'root.templates'],
      children: [
        {
          name: $localize`:|@@app-nav_components.files:Файлы`,
          icon: 'fas fa-disk',
          uiPath: 'root.storage.files',
          activePath: 'root.storage',
        },
        {
          name: $localize`:|@@app-nav_components.templates:Шаблоны`,
          icon: 'fas fa-chart-pie',
          uiPath: 'root.templates.list',
          activePath: 'root.templates',
        },
      ],
    },

    {
      name: $localize`:|@@app-nav_components.devices:Устройства`,
      icon: 'fas fa-desktop',
      uiPath: 'root.device.list',
      activePath: ['root.platform', 'root.player', 'root.device', 'root.label'],
      children: [
        {
          name: $localize`:|@@app-nav_components.devices2:Устройства`,
          icon: 'fas fa-chart-pie',
          activePath: 'root.device',
          uiPath: 'root.device.list',
        },
        {
          name: $localize`:|@@app-nav_components.device-type:Тип устройства`,
          icon: 'fas fa-chart-pie',
          activePath: 'root.device-type',
          uiPath: 'root.device-type.list',
        },
        {
          name: $localize`:|@@app-nav_components.platforms:Платформы`,
          icon: 'fas fa-chart-pie',
          activePath: 'root.platform',
          uiPath: 'root.platform.list',
        },
        {
          name: $localize`:|@@app-nav_components.players:Плееры`,
          icon: 'fas fa-chart-pie',
          activePath: 'root.player',
          uiPath: 'root.player.list',
        },
        {
          name: $localize`:|@@app-nav_components.tags:Метки`,
          icon: 'fas fa-chart-pie',
          activePath: 'root.label',
          uiPath: 'root.label.list',
        },
      ],
    },
    {
      name: $localize`:|@@app-nav_components.locations:Локации`,
      icon: 'fas fa-map-marked-alt',
      uiPath: 'root.locationsGroup.list',
      activePath: ['root.locationsGroup', 'root.locations', 'root.zones'],
      children: [
        {
          name: $localize`:|@@app-nav_components.locations2:Локации`,
          icon: 'fas fa-folder',
          uiPath: 'root.locationsGroup.list',
          activePath: 'root.locationsGroup',
        },
        {
          name: $localize`:|@@app-nav_components.shopping-facilities:Торговые объекты`,
          icon: 'fas fa-folder',
          uiPath: 'root.locations.list',
          activePath: 'root.locations',
        },
      ],
    },
    {
      name: $localize`:|@@app-nav_components.reports:Отчёты`,
      icon: 'fas fa-chart-pie',
      uiPath: 'root.reports.statMedia',
      activePath: 'root.reports',
      children: [
        {
          name: $localize`:|@@app-nav_components.by-reproduction:По воспроизведению`,
          icon: 'fas fa-chart-bar',
          uiPath: 'root.reports.statMedia',
          activePath: 'root.reports.statMedia',
        },
      ],
    },
    {
      name: $localize`:|@@app-nav_components.reference-books:Справочники`,
      icon: 'fas fa-folder',
      uiPath: 'root.users',
      activePath: 'root.users',
      permission: {
        path: 'users',
      },
      children: [
        {
          name: $localize`:|@@app-nav_components.users:Пользователи`,
          icon: 'fas fa-folder',
          uiPath: 'root.users',
          activePath: 'root.users',
        },
      ],
    },
    {
      name: $localize`:|@@app-nav_components.change-logs:Журнал изменений`,
      icon: 'fas fa-book',
      uiPath: 'root.journal.changelog',
      activePath: 'root.journal',
      permission: {
        path: 'journal',
      },
      children: [
        {
          name: $localize`:|@@app-nav_components.change-logs2:Журнал изменений`,
          icon: 'fas fa-book',
          uiPath: 'root.journal.changelog',
          activePath: 'root.journal.changelog',
        },
      ],
    },
    {
      name: $localize`:|@@app-nav_components.settings:Настройки`,
      icon: 'fas fa-cog',
      uiPath: 'root.settings.partner',
      activePath: 'root.settings',
      permission: {
        path: 'settings',
      },
      children: [
        {
          name: $localize`:|@@app-nav_components.partner:Партнёр`,
          icon: 'fas fa-book',
          uiPath: 'root.settings.partner',
          activePath: 'root.settings.partner',
          path: 'settings',
        },
      ],
    },

    /*    {
      name: $localize`:|@@app-nav_components.analytics:Аналитика`,
      icon: 'fas fa-chart-pie',
      uiPath: 'tree1',
    },*/
  ];

  ngOnInit(): void {}

  isActivePath(path: string | string[] = '') {
    path = Array.isArray(path) ? path : [path];
    return path.some(i => this.$state.includes(i));
  }

  hasActiveChild(childrens = []) {
    return childrens.some((i: any) => this.isActivePath(i?.activePath));
  }
}
